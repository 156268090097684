<script>
export default {
    name   : "MixinFunctionClickHandler",
    methods: {

        handleFunctionClick( which )
        {

            this.handleUnswipe()
            if( false !== this.injectedHandlers
                && undefined !== this.injectedHandlers[ which ] )
            {
                return this.injectedHandlers[ which ]()
            }

            if( true === this.$props.fakeElement )
            {
                return
            }

            switch( which )
            {
                case 'archive':
                case 'unarchive':
                case 'delete':
                    this.handleRemovals( which )
                    break
                case 'edit':
                    this.handleEdit()
                    break
                case 'print':
                    this.handlePrint()
                    break
                case 'share':
                    this.handleShare()
                    break
                case 'share-with-students':
                    this.handleShareWithStudent()
                    break
                case 'hide':
                    this.handleHide()
                    break
                case 'pin':
                    this.handlePin()
                    break
                case 'stats':
                    this.$core
                        .getUi()
                        .showStatsOverlay( this.item )
                    break
                case 'accept':
                    this.$core.getEventManager()
                        .dispatchAndRemove( 'on-invite-accepted-' + this.item.localId, this.item )
                    break
                default:
                    this.$core.getEventManager()
                        .dispatch( 'on-function-click-' + which, this.item )
                    break
            }

        },

        isPrintable()
        {
            let printables = [ 'Note', 'Todo' ]
            if( this.$core.getLicense().isAllowed( 'print' )
                && -1 < printables.indexOf( this.$props.viewItem ) )
            {
                return true
            }
        },

        translateInner( which )
        {
            let trans = [ 'displayName', 'listname', 'classname', 'groupname', 'title' ]
            for( let t in trans )
            {
                which = which.replace( '%%' + trans[ t ] + '%%', this.item[ trans[ t ] ] )
            }
            return which
        },

        handleDelete( item )
        {

            this.$core.getUi()
                .showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'delete-processing' ) )

            this.$core.getBaseClassHelper().get( item.type )
                .delete( item.localId, item.remoteId )
                .then( () =>
                {
                    this.$core.getUi().hideBlocker()
                } )

        },

        handleMultiDelete( items )
        {

            this.$core.getUi()
                .showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'delete-processing' ) )

            let promises = [],
                type     = this.item.type

            this.$core.setState( 'inMultiDelete', true )

            for( let i in items )
            {

                let temp     = items[ i ].split( /___/g ),
                    localId  = temp[ 0 ],
                    remoteId = temp[ 1 ]

                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {

                        this.$core.getBaseClassHelper().get( type )
                            .delete( localId, remoteId )
                            .then( () =>
                            {
                                return resolve()
                            } )
                            .catch( () =>
                            {
                                return resolve()
                            } )

                    } )
                } )

                this.$core.f()
                    .promiseRunner( promises )
                    .then( () =>
                    {
                        this.$core.getUi()
                            .delay( () => {
                                this.$core.setState( 'inMultiDelete', false )
                                this.$core.getUi().hideBlocker()
                            }, 2000 )
                    } )

            }

        },

        handleArchive( item )
        {

            this.$core.getUi()
                .showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'archive-processing' ) )

            this.$core.getBaseClassHelper().get( item.type )
                .archive( item )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.$core.getUi().hideBlocker()
                    }, 2000 )
                } )

        },

        handleUnarchive( item )
        {

            this.$core.getUi()
                .showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'unarchive-processing' ) )

            this.$core.getBaseClassHelper().get( item.type )
                .unarchive( item )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.$core.getUi().hideBlocker()
                    }, 2000 )
                } )

        },

        handleMultiArchiveState( items, state )
        {

            this.$core.getUi()
                .showBlocker( this.$core.t( 'generic-please-wait' ),
                    this.$core.t( ( false === state ? 'un' : '' ) + 'archive-processing' ) )

            let elements = []

            for( let i in items )
            {

                let temp    = items[ i ].split( /___/g ),
                    localId = temp[ 0 ]

                elements.push( this.$core.getBaseClassHelper()
                                   .get( this.item.type )
                                   .getContainerItem( localId ) )

            }

            this.$core.getBaseClassHelper().get( this.item.type )
                .multiArchiveState( elements, state )
                .then( () =>
                {
                    this.$core.getUi().hideBlocker()
                } )

        },

        handleRemovals( which )
        {

            let multi = false,
                tKey,
                tMultiKey,
                key,
                text,
                title,
                buttons,
                callback

            if( this.$core.f().isset( this.item.lists ) && 1 < this.item.lists.length
                && 'combilist' !== this.item.listType )
            {
                multi = this.item.lists
                this.$core.sort().sortObjects( multi, 'timestamp', 'descending' )
            }

            switch( which )
            {
                case 'archive':
                    tKey = 'modalArchiveText'
                    tMultiKey = 'modalMultiArchiveText'
                    break
                case 'unarchive':
                    tKey = 'modalUnarchiveText'
                    tMultiKey = 'modalMultiUnarchiveText'
                    break
                case 'delete':
                    tKey = 'modalDeleteText'
                    tMultiKey = 'modalMultiDeleteText'
                    break
            }

            key = false === multi ? tKey + '-' + this.$props.viewItem.toLowerCase()
                                  : tMultiKey + '-' + this.$props.viewItem.toLowerCase()

            text = this.$core.t( key )
            text = this.translateInner( text )

            title = this.$core.t( 'object-type-' + this.$props.viewItem.toLowerCase() )
                    + ' ' + this.$core.t( 'button-title-' + which )

            if( false !== multi )
            {
                callback = ( values ) =>
                {
                    switch( which )
                    {
                        case 'archive':
                            this.handleMultiArchiveState( values, true )
                            break
                        case 'unarchive':
                            this.handleMultiArchiveState( values, false )
                            break
                        case 'delete':
                            this.handleMultiDelete( values )
                            break
                    }
                }
            }
            else
            {
                callback = () =>
                {
                    switch( which )
                    {
                        case 'archive':
                            this.handleArchive( this.item )
                            break
                        case 'unarchive':
                            this.handleUnarchive( this.item )
                            break
                        case 'delete':
                            this.handleDelete( this.item )
                            break
                    }
                }
            }

            buttons = [
                'defaultCancel',
                {
                    type    : which,
                    title   : this.$core.t( 'button-title-' + which ),
                    callback: ( values ) =>
                    {
                        callback( values )
                        this.reset()
                    }
                }
            ]

            this.$core.getUi()
                .showModalDialog( which,
                    title,
                    text,
                    buttons,
                    multi )

        },

        handleEdit()
        {

            let objectType = this.$props.viewItem.toLowerCase() === 'class' ? 'classes' : this.$props.viewItem.toLowerCase() + 's',
                formKey    = this.$core.getUi().showForm( this.$props.viewItem, objectType, 'edit', this.item )

            this.addEvent( 'submit-form-' + formKey, () =>
            {
                this.reset()
                this.$emit( 'refresh', this.item.localId )
            } )

        },

        handlePrint()
        {
            if( this.isPrintable() )
            {
                this.$core.getPrinting().printPlainElement( this.item, true )
            }
        },

        handleShare()
        {

            this.$core.getUi().share( [ this.item ] )
            this.reset()

        },

        handleShareWithStudent()
        {
            this.$core.getUi().shareWithStudents( [ this.item ] )
            this.reset()
        },

        handleHide()
        {

            let hideState = this.item.hidden === true ? false : true,
                baseClass = this.$core.getBaseClassHelper().get( this.item.type )

            baseClass.hide( this.item, hideState )
                     .then( () =>
                     {
                         this.reset()
                     } )

        },

        handlePin()
        {

            let pinState  = this.item.pinned === true ? false : true,
                baseClass = this.$core.getBaseClassHelper().get( this.item.type )

            baseClass.pin( this.item, pinState )
                     .then( () =>
                     {
                         this.reset()
                     } )

        }

    }
}
</script>