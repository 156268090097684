/*eslint-disable*/
import Patch_2_SharesFix from "@/classes/Core/Workers/SyncWorker/SyncPatches/Patch_2_SharesFix";

export default class SyncPatches
{

    constructor( parent )
    {

        this.parent = parent
        this.client = parent.client
        this.step = 0
        this.total = 0
        this.parent.logger.clog( this.logSign, 'initialized' )

        this.getState = ( key ) =>
        {
            return this.parent.getState( key )
        }

        this.patched = false
        this.patchLevel = -1
        this.patchMax = 2

        this.logSign = 'SyncWorker::SyncPatches (PTCH)'

        this.patches = {
            2: 'Patch_2_SharesFix'
        }

    }

    destruct()
    {
        this.parent = null
        delete this.parent
    }

    patchWorker()
    {
        return new Promise( resolve =>
        {

            if( this.patchLevel < this.patchMax )
            {

                this.parent.ui.blockerText( '<strong>Patches</strong> werden ausgeführt...' )
                this.parent.logger.cdebug( this.logSign, 'patches available: applying...' )

                let promises = []

                for( let i = 0; i <= this.patchMax; i++ )
                {

                    let patch = false

                    if( undefined !== this.patches[ i ] )
                    {

                        this.parent.logger.cdebug( this.logSign, 'patch ' + i + ', ' + this.patches[ i ] + ' applying...' )
                        switch( i )
                        {
                            case 2:
                                patch = new Patch_2_SharesFix( this )
                                break
                        }

                    }

                    if( false !== patch )
                    {

                        promises.push( () =>
                        {
                            return patch.execute()
                                        .then( () =>
                                        {
                                            patch.destruct()
                                            this.parent.logger.cdebug( this.logSign, 'patch ' + i + ', ' + this.patches[ i ] + ' applied.' )
                                            this.patchLevel = i
                                        } )

                        } )

                    }

                }

                this.parent.f.promiseRunner( promises )
                    .then( () =>
                    {

                        this.setPatchLevel()
                        return resolve()

                    } )

            }
            else
            {
                this.parent.logger.cdebug( this.logSign, 'already at maximum patchlevel: ' + this.patchLevel + ' of ' + this.patchMax )
                this.patched = true
                return resolve()
            }

        } )
    }

    setPatchLevel()
    {

        let message = {
            method    : 'users.setPatchLevel',
            patchLevel: this.patchLevel
        }

        let jobId = this.parent.uuid.generate()
        this.parent.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

    }

    fixLegacyCompetences()
    {
        return new Promise( resolve =>
        {

            this.parent.logger.clog( this.logSign, 'fix legacy competences...' )
            let baseClassHelper = this.parent.getBaseClassHelper(),
                competences     = baseClassHelper.get( 'competence' )
                                                 .getCache( 'cache' )

            for( const [ localId, competence ] of competences )
            {

                let competenceNeedsPatch = false

                if( competence.subs
                    && Array.isArray( competence.subs )
                    && 0 < competence.subs.length )
                {

                    for( let s in competence.subs )
                    {

                        if( !this.parent.f.isObject( competence.subs[ s ] ) )
                        {

                            competenceNeedsPatch = true
                            let patched = {
                                value: {
                                    localId: this.parent.uuid.generate(),
                                    caption: competence.subs[ s ]
                                }
                            }

                            competence.subs[ s ] = patched

                        }

                    }

                    if( competenceNeedsPatch )
                    {
                        baseClassHelper.get( 'competence' )
                                       .update( competence,
                                           competence.localId,
                                           competence.remoteId,
                                           competence.timestamp,
                                           competence.localKey,
                                           undefined,
                                           true )
                    }

                }

            }

            return resolve()

        } )
    }

    sync()
    {

        return new Promise( resolve =>
        {

            if( !this.getState( 'first-crud-sync-done' ) )
            {
                return resolve()
            }

            if( false !== this.parent.store.getters.idSession
                && true === this.parent.store.getters.online )
            {

                this.fixLegacyCompetences()
                    .then( () =>
                    {

                        if( this.patched )
                        {
                            this.parent.logger.cdebug( this.logSign, 'patches already executed.' )
                            this.parent.disableSyncJob( 'patches' )
                            return resolve()
                        }

                        this.client
                            .request( {
                                method: 'users.getPatchLevel'
                            } )
                            .then( response =>
                            {

                                this.patchLevel = response.result !== null ? response.result : -1
                                this.patchWorker()
                                    .then( () =>
                                    {

                                        this.parent.logger.cdebug( this.logSign, 'patches done.' )
                                        return resolve()

                                    } )

                            } )
                            .catch( error =>
                            {

                                this.parent.logger.cerror( this.logSign, 'patches failed with error', error )
                                return resolve()

                            } )

                    } )

            }
            else
            {
                this.parent.logger.cdebug( this.logSign, 'in unsyncable state right now...' )
                return resolve()
            }

        } )

    }


}