<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">
        <div class="list fullscreen">
            <table :class="'list fullscreen vertical '+list.listType" :id="'list-editor-vertical-'+list.localId">
                <thead>
                <tr class="list-header" style="display:none;">
                    <th :class="'fixed first-vertical '+list.color">&nbsp;</th>
                    <th :class="'fixed align-left '+list.color">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="( line, index ) in lines" :key="'template-'+index">
                    <template v-for="( field, col ) in line"
                              :key="'template-tr-'+field.id+'-'+index">
                        <template v-if="0 === col">
                            <tr class="vert-break">
                                <td class="align-left vert-break td-vert-header" colspan="2">
                                    <component v-if="field.component !== false"
                                               cssClass="list"
                                               :refName="field.id"
                                               :id="field.id"
                                               :is="field.component"
                                               :referenceObjectId="list.localId"
                                               :placeholder="field.placeholder"
                                               :value="field.display"
                                               :disabled="readOnly"
                                               @focussed="handleFocus"
                                               @blurred="handleBlur"
                                               @update="handleFieldUpdate"/>
                                    <span v-else v-html="field.display"></span>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <TableDetailSplitted :caption="list.columns[ col ].caption"
                                                     :vertical="true"
                                                     :score="list.columns[ col ].score"/>
                                <td :class="'align-left td-vert-component '+( undefined !== tdmarks[field.id] ? tdmarks[field.id] + ' ' : '' )+(false !== field.component ? 'td-'+field.component.toLowerCase() : '')+( field.disabled ? ' disabled' : '' )+( index %2 === 1 ? ' even' : ' odd' )">
                                    <component v-if="field.component !== false"
                                               cssClass="list"
                                               :refName="field.id"
                                               :id="field.id"
                                               :is="field.component"
                                               :referenceObjectId="list.localId"
                                               :placeholder="field.placeholder"
                                               :value="field.display"
                                               :disabled="readOnly"
                                               @focussed="handleFocus"
                                               @blurred="handleBlur"
                                               @update="handleFieldUpdate"/>
                                    <span v-else v-html="field.display"></span>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <template v-if="'test' === list.listType">
                        <tr>
                            <td class="align-left">
                                Gesamt<br/><span class="score-heading">&sum; Punkte</span>
                            </td>
                            <td class="align-left"
                                :id="'total-'+line[0].id">
                                &bull;
                            </td>
                        </tr>
                        <tr>
                            <td class="align-left">
                                Note
                            </td>
                            <td class="align-left"
                                :id="'rating-'+line[0].id">
                                &bull;
                            </td>
                        </tr>
                    </template>
                    <tr :class="'printable-cutline cutline-'+list.localId" style="display: none;"
                        :id="'printable-cutline-'+index">
                        <td colspan="2" class="printable-cutline">
                            <div class="printable-cutline"></div>
                        </td>
                    </tr>
                </template>
                </tbody>
                <tfoot>
                <td class="table-footer-space foot-vertical"></td>
                <td class="table-footer-space"></td>
                </tfoot>
            </table>
        </div>
    </transition>
</template>

<script>
import TableDetailSplitted   from "@/components/elements/viewItems/listViews/tableElements/TableDetailSplitted";
import MixinListEditorBase   from "@/components/elements/viewItems/listEditor/mixins/MixinListEditorBase";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup.vue";

export default {
    name      : "ListEditorVertical",
    components: { TableDetailSplitted },
    mixins    : [ MixinListEditorBase, MixinComponentCleanup ],
    props     : {
        list            : { Type: Object, required: true },
        students        : { Type: Object, required: true },
        studentList     : { Type: Array, required: true },
        reference       : { Type: Object, required: false },
        lines           : { Type: Array, required: true },
        tdmarks         : { Type: Object, required: true },
        listFilterInline: { Type: String, required: false },
        editMode        : { Type: String, required: true },
        readOnly        : { Type: Boolean, required: false, default: false },
        selectedHistory : { Type: Boolean || Number, required: false, default: false }
    }
}
</script>