<template>
    <div class="top-messages-boundaries">
        <div class="spacer"></div>
        <div class="logo-spacer"></div>
        <div class="top-messages">
            <LicenseWarning/>
            <BounceWarning/>
            <SoftwareUpdate/>
        </div>
        <div class="spacer-right"></div>
    </div>
</template>
<script>
import SoftwareUpdate from "@/components/layout/SoftwareUpdate.vue";
import LicenseWarning from "@/components/layout/LicenseWarning.vue";
import BounceWarning  from "@/components/layout/BounceWarning.vue";

export default {
    name      : "TopMessages",
    components: { BounceWarning, LicenseWarning, SoftwareUpdate }
}
</script>