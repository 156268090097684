export default class Functions
{

    /**
     * constructor
     * @param core
     * @returns {Functions}
     */
    constructor( core )
    {

        if( !Functions.instance )
        {

            this.store = core.getStore()
            this.translation = core.getTranslation()
            this.baseClassHelper = core.getBaseClassHelper()
            this.logger = core.getLogger()
            this.listTypes = this.prepareListTypes()
            this.eventManager = null
            this.settings = null

            this.hashTimeSpent = 0

            this.getState = ( key ) =>
            {
                return core.getState( key )
            }
            this.setState = ( key, value ) =>
            {
                core.setState( key, value )
            }

            setInterval( () =>
            {

                this.logger.cdebug( 'Core::Functions::: hash time statistics (HTS)', 'hashing took', this.hashTimeSpent, 'ms CPU time' )

            }, 15000 )

            this.setStates()
            this.caches = {}

            Functions.instance = this

        }

        return Functions.instance

    }

    setStates()
    {
        this.setState( 'inMultiDelete', false )
    }

    /**
     * destruct
     */
    destruct()
    {
        delete Functions.instance
    }

    injectEventManager( eventManager )
    {
        this.eventManager = eventManager
        this.eventManager.append( 'on-settings-mountable', ( settings ) =>
        {
            this.settings = settings
        } )
        this.eventManager.append( 'on-baseclasses-available', ( baseClassHelper ) =>
        {
            this.baseClassHelper = baseClassHelper
            this.prepareLocalCaches()
        } )

    }

    prepareLocalCaches()
    {
        this.eventManager.append( 'on-refresh-cache-group', () =>
        {
            if( 'filled' === this.baseClassHelper.get( 'group' ).state )
            {
                this.caches.group = this.baseClassHelper.get( 'group' ).getCache()
            }
        } )
    }

    genderColorClass( gender )
    {

        let key        = 'divers' === gender ? 'nonBinary' : gender,
            colorClass = this.isset( key ) ? this.settings.getSetting( 'genderColor' + this.ucFirst( key ) ) : 'white'

        return 'default' === colorClass ? gender : colorClass

    }

    /**
     * prepareListTypes
     * @returns {{}}
     */
    prepareListTypes()
    {

        let types = [
            'customFixed',
            'ratinglist',
            'checklist',
            'test',
            'recallList',
            'referenceList',
            'combilist'
        ]

        let trans = {}
        for( let t in types )
        {
            trans[ types[ t ] ] = this.translation.translate( 'list-type-' + types[ t ] )
        }

        return trans

    }

    /**
     * isset
     * @param value
     * @returns {boolean}
     */
    isset( value )
    {

        if( undefined !== value
            && null !== value )
        {
            return true
        }

        return false

    }

    /**
     * isObject
     * @param value
     * @returns {boolean}
     */
    isObject( value )
    {
        return this.isset( value ) && typeof value === 'object'
    }

    /**
     * notFalse
     * @param value
     * @returns {boolean}
     */
    notFalse( value )
    {
        return ( this.isset( value ) && false !== value )
    }

    /**
     * valid
     * @param value
     * @returns {boolean}
     */
    valid( value )
    {
        if( 'string' === typeof value && '' === value.trim() )
        {
            return false
        }
        return null !== value && this.notFalse( value )
    }

    /**
     * validObject
     * @param value
     * @returns {boolean}
     */
    validObject( value )
    {
        return typeof value === 'object'
    }

    falseOrUndefined( value )
    {
        return ( value === undefined || value === false )
    }

    falseToUndef( value )
    {
        return this.falseOrUndefined( value ) ? undefined : value
    }

    archiveMatch( student, timestamp )
    {

        if( this.validObject( student )
            && student.archived !== true )
        {
            return true
        }

        let temp = student.archiveKey.split( /-/g )

        temp.shift()
        let archiveTimestamp = parseInt( temp.join( '' ) )

        if( archiveTimestamp >= parseInt( timestamp ) )
        {
            return true
        }

        return false

    }

    /**
     * validObjectReference
     * @param item
     * @returns {boolean}
     */
    validObjectReference( item )
    {
        try
        {
            if( 'string' === typeof item )
            {

                let ref = JSON.parse( item )
                return ( 'object' === typeof ref
                         && 0 < Object.keys( ref ).length )
            }

            return ( 'object' === typeof item
                     && 0 < Object.keys( item ).length )

        }
        catch
        {
            return false
        }

    }

    /**
     * isOwn
     * @param element
     * @returns {boolean}
     */
    isOwn( element, plain )
    {
        if( !this.isObject( element ) )
        {
            return false
        }
        if( parseInt( this.store.getters.idUser ) !== 184 )
        {
            let check = plain ? element : ( undefined !== element.object ? element.object : element )
            return ( parseInt( check.idOwner ) === parseInt( this.store.getters.idUser ) )
        }
        else
        {
            return true
        }
    }

    /**
     * ownerMatch
     * @param element
     * @param owner
     * @returns {boolean}
     */
    ownerMatch( element, owner )
    {
        if( !this.isObject( element )
            || !this.isObject( owner ) )
        {
            return false
        }
        return ( parseInt( element.idOwner ) === parseInt( owner.colleagueId ) )
    }

    /**
     * toHtml
     * @param body
     * @returns {string}
     */
    toHtml( body )
    {

        if( undefined === body
            || 'undefined' === body )
        {
            return ''
        }

        let nuBody = '',
            rows   = body.split( /\n/g ),
            ulOpen = false

        for( let r in rows )
        {
            if( '-' === rows[ r ].substr( 0, 1 )
                && '--' !== rows[ r ].substr( 0, 2 ) )
            {
                let line = rows[ r ].substr( 1 ).trim()
                if( !ulOpen )
                {
                    nuBody += '<ul>'
                    ulOpen = true
                }
                nuBody += '<li>' + line + '</li>'
            }
            else
            {
                if( ulOpen )
                {
                    nuBody += '</ul>'
                    ulOpen = false
                }
                nuBody += rows[ r ] + '\n'
            }
        }
        if( ulOpen )
        {
            nuBody += '</ul>'
        }

        body = nuBody

        body = body.replace( /\n/g, '<br/>' )
        body = body.replace( /<br>/g, '<br/>' )
        body = body.replace( /\*([^\\*]*)\*/g, '<span class="bold">$1</span>' )
        body = body.replace( /_([^\\*]*)_/g, '<u>$1</u>' )
        body = body.replace( /~([^\\*]*)~/g, '<em>$1</em>' )
        body = body.replace( /--([^\\*]*)--/g, '<s>$1</s>' )

        //body = body.replace( /&amp;([^\\*]*)&amp;/g, '<span class="comic">$1</span>' )

        let cleanup     = body.split( '<br/>' ),
            cleanedBody = [],
            textFound   = false,
            addOne      = 1

        for( let i = ( cleanup.length - 1 ); i >= 0; i -= 1 )
        {
            if( '' !== cleanup[ i ].trim() || textFound )
            {
                cleanedBody.push( cleanup[ i ] )
                textFound = true
            }
            else
            {
                addOne++
            }
        }

        // remove hartnäckige leerzeile am Ende
        if( '' === cleanedBody[ ( cleanup.length - addOne ) ] )
        {
            cleanedBody.pop()
        }

        body = cleanedBody.reverse().join( '<br/>' )

        return body

    }

    /**
     * toPlain
     * @param body
     * @returns {string|*}
     */
    old_toPlain( body )
    {

        if( undefined === body
            || 'undefined' === body )
        {
            return ''
        }

        body = body.replace( /<ul>/g, '' )
        body = body.replace( /<\/ul>/g, '' )
        body = body.replace( /<\/li>/g, '\n' )
        body = body.replace( /<li>/g, '- ' )

        body = body.replace( /<br\/>/g, '\n' )
        body = body.replace( /<br \/>/g, '\n' )
        body = body.replace( /<br>/g, '\n' )
        body = body.replace( /&nbsp;/g, ' ' )

        return body

    }

    sanitizePlain( text )
    {

        let returnText  = '',
            lines       = text.split( /\n/g ),
            returnLines = [],
            skip        = false

        for( let l in lines )
        {
            l = parseInt( l )
            if( !skip )
            {
                if( lines[ l ].substr( 0, 1 ) === '-'
                    && lines[ l ].trim() === '-' )
                {
                    lines[ l ] = '- ' + lines[ ( l + 1 ) ]
                    returnLines.push( lines[ l ] )
                    skip = true
                }
                else
                {
                    returnLines.push( lines[ l ] )
                }
            }
            else
            {
                skip = false
            }
        }

        returnText = returnLines.join( "\n" )

        return returnText

    }

    toPlain( body, excel )
    {

        let returnText = body
        //-- remove leading BR tags

        returnText = returnText.replace( /^<br>/gi, "" );
        returnText = returnText.replace( /^<br\s\/>/gi, "" );
        returnText = returnText.replace( /^<br\/>/gi, "" );

        //-- remove BR tags and replace them with line break
        if( excel )
        {
            returnText = returnText.replace( /<br>/gi, "--BR--" );
            returnText = returnText.replace( /<br\s\/>/gi, "--BR--" );
            returnText = returnText.replace( /<br\/>/gi, "--BR--" );
        }

        returnText = returnText.replace( /<br>/gi, "\n" );
        returnText = returnText.replace( /<br\s\/>/gi, "\n" );
        returnText = returnText.replace( /<br\/>/gi, "\n" );

        returnText = returnText.replace( /<u>(.*)<\/u>/gi, "_$1_" );
        returnText = returnText.replace( /<span class="bold">(.*)<\/span>/gi, "*$1*" );

        returnText = returnText.replace( /<\/div>/gi, "" );
        returnText = returnText.replace( /<\/span>/gi, "" );
        returnText = returnText.replace( /<\/p>/gi, "" );
        returnText = returnText.replace( /<\/header.*?>/gi, "" );
        returnText = returnText.replace( /<\/h.*?>/gi, "\n" );

        // replace formatted UL / LI to "normal plain text list"
        returnText = returnText.replace( /<ul>/g, '' )
        returnText = returnText.replace( /<\/ul>/g, '' )
        returnText = returnText.replace( /<\/li>/g, '\n' )
        returnText = returnText.replace( /<ul.*?>/gi, "" );
        returnText = returnText.replace( /<li.*?>/gi, "- " );

        //-- remove H1...6 tags but preserve what's inside of them
        returnText = returnText.replace( /<header.*?>/gi, "" );
        returnText = returnText.replace( /<h.*?>/gi, "" );

        //-- remove P and A tags but preserve what's inside of them
        returnText = returnText.replace( /<p.*?>/gi, "\n" );
        returnText = returnText.replace( /<div.*?>/gi, "\n" );
        returnText = returnText.replace( /<span.*?>/gi, "\n" );
        returnText = returnText.replace( /<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 ($1)" );

        //-- remove all inside SCRIPT and STYLE tags
        returnText = returnText.replace( /<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, "" );
        returnText = returnText.replace( /<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, "" );
        //-- remove all else
        returnText = returnText.replace( /<(?:.|\s)*?>/g, "" );

        //-- get rid of more than 2 multiple line breaks:
        returnText = returnText.replace( /(?:(?:\r\n|\r|\n)\s*){2,}/gim, "\n\n" );

        //-- get rid of more than 2 spaces:
        returnText = returnText.replace( / +(?= )/g, '' );

        //-- get rid of html-encoded characters:
        returnText = returnText.replace( /&nbsp;/gi, " " );
        returnText = returnText.replace( /&amp;/gi, "&" );
        returnText = returnText.replace( /&quot;/gi, '"' );
        returnText = returnText.replace( /&lt;/gi, '<' );
        returnText = returnText.replace( /&gt;/gi, '>' );

        return this.sanitizePlain( returnText )

    }

    htmlToPlain( html )
    {

        let doc = new DOMParser().parseFromString( html, 'text/html' )
        return doc.body.textContent

    }

    /**
     * htmlExcerpt
     * @param text
     * @param length
     * @returns {string|*}
     */
    htmlExcerpt( text, length )
    {

        if( text.length <= length )
        {
            return text
        }

        let div = document.createElement( 'div' )
        div.innerHTML = text.substr( 0, length ) + '...'
        return div.innerHTML

    }

    /**
     * ucFirst
     * @param string
     * @returns {string}
     */
    ucFirst( string )
    {
        return string.charAt( 0 ).toUpperCase() + string.slice( 1 )
    }

    /**
     * lcFirst
     * @param string
     * @returns {string}
     */
    lcFirst( string )
    {
        return string.charAt( 0 ).toLowerCase() + string.slice( 1 )
    }

    /*eslint-disable*/
    /**
     * groupFilter
     * @param filter
     * @param item
     * @param groups
     * @returns {boolean}
     */
    groupFilter( filter, item )
    {

        if( !Array.isArray( filter )
            || 0 === filter.length )
        {
            return false
        }

        let haveGroupFilter = false,
            scopes          = [ 'cache', 'archive' ]

        for( let f in filter )
        {
            if( filter[ f ].attr === 'groupId' )
            {
                haveGroupFilter = filter[ f ].value
            }
        }

        if( false === haveGroupFilter )
        {
            return false
        }
        else
        {
            for( let s in scopes )
            {
                for( const [ key, value ] of this.caches.group[ scopes[ s ] ] )
                {
                    if( this.isObject( value.students )
                        && value.localId === haveGroupFilter
                        && -1 < value.students.indexOf( item.localId ) )
                    {
                        return true
                    }
                }
            }
            return false
        }

    }

    /**
     * _keyBlocked
     * @param key
     * @returns {boolean}
     * @private
     */
    _keyBlocked( key )
    {

        let blocklist = [
            'address',
            'address_1',
            'address_2',
            'city',
            'confession',
            'parents',
            'parent_1',
            'parent_2',
            'update',
            'timestamp',
            'type',
            'localKey',
            'elementKey',
            'classId',
            'archiveKey',
            'idOwner',
            'lastEditor'
        ]

        return -1 < blocklist.indexOf( key )

    }

    /**
     * filterFulltext
     * @param item
     * @param value
     * @returns {boolean}
     */
    filterFulltext( item, value )
    {

        try
        {

            let attrs = Object.keys( item ),
                match = false

            for( let a in attrs )
            {
                let key = attrs[ a ]
                if( !this._keyBlocked( key ) )
                {
                    if( 'listType' === key )
                    {
                        let test = this.listTypes[ item[ key ] ].toLowerCase()
                        if( -1 < test.indexOf( value.toLowerCase() ) )
                        {
                            match = true
                        }
                    }
                    else if( 'string' === typeof item[ key ] )
                    {
                        let test = item[ key ].toLowerCase()
                        match = -1 < test.indexOf( value.toLowerCase() )
                    }
                }
                if( match )
                {
                    return true
                }
            }

            return false

        }
        catch
        {
            return false
        }

    }

    /**
     * referenceFilterMatch
     * @param item
     * @param filter
     */
    referenceFilterMatch( item, filter )
    {
        let temp
        switch( filter.attr )
        {
            case 'classId':
                return item.classReference === filter.value
            case 'groupId':
                return item.groupReference === filter.value
            case 'yeargroupId':
                return item.yeargroupReference === filter.value
            case 'labelId':
                if( undefined !== item.labels )
                {
                    temp = item.labels.split( /,/g )
                    return -1 < temp.indexOf( filter.value )
                }
                break
        }
        return false
    }

    /**
     * filterMatch
     * @param filter
     * @param item
     * @returns {boolean}
     */
    filterMatch( filter, item )
    {

        if( this.isObject( filter )
            && this.isObject( item ) )
        {

            if( 0 === filter.length )
            {
                return true
            }

            for( let f in filter )
            {


                if( this.isObject( filter[ f ] )
                    && ( this.isset( filter[ f ].attr ) || true === filter[ f ].isFulltext )
                    && this.isset( filter[ f ].value )
                    && ( this.isset( item[ filter[ f ].attr ] ) || true === filter[ f ].isFulltext ) )
                {

                    if( true === filter[ f ].isFulltext )
                    {
                        return this.filterFulltext( item, filter[ f ].value )
                    }
                    else
                    {
                        if( item[ filter[ f ].attr ] === filter[ f ].value )
                        {
                            return true
                        }
                    }
                }
                else
                {
                    if( this.referenceFilterMatch( item, filter[ f ] ) )
                    {
                        return true
                    }
                    else
                    {
                        if( item.type === 'list'
                            && this.isObject( filter[ f ] ) )
                        {
                            let columns = undefined !== item.lists ? item.lists[ 0 ].columns : item.columns
                            if( undefined !== columns
                                && undefined !== columns[ 0 ] )
                            {
                                if( columns[ 0 ].filter + 'Id' === filter[ f ].attr
                                    && columns[ 0 ].filterBy === filter[ f ].value )
                                {
                                    return true
                                }
                            }
                        }
                    }
                }
            }

        }

        return false
    }

    /**
     * filterEmpty
     * @param filters
     * @returns {boolean}
     */
    filterEmpty( filters )
    {
        for( let type in filters )
        {
            if( Array.isArray( filters[ type ] )
                && 0 < filters[ type ].length )
            {
                return false
            }
        }
        return true
    }

    /**
     * allFilterMatch
     * @param element
     * @param filters
     * @param filterTypes
     * @returns {boolean}
     */
    allFilterMatch( element, filters, filterTypes )
    {

        let allFiltersEmpty = true

        if( undefined === filters
            || 0 === Object.keys( filters ).length )
        {
            return true
        }

        if( true === filterTypes )
        {

            let filteredTypes = []
            for( let f in filters )
            {
                let filterList = filters[ f ]
                for( let i in filterList )
                {
                    if( 'type' === filterList[ i ].attr )
                    {
                        filteredTypes.push( filterList[ i ].value )
                    }
                }
            }

            let hasOtherFilter = false

            for( let f in filters )
            {
                let filterList = filters[ f ]
                for( let i in filterList )
                {
                    allFiltersEmpty = false
                    if( 'type' !== filterList[ i ].attr )
                    {
                        hasOtherFilter = true
                        if( ( 0 === filteredTypes.length || -1 < filteredTypes.indexOf( element.type ) )
                            && element[ filterList[ i ].attr ] === filterList[ i ].value )
                        {
                            return true
                        }
                    }
                }
            }

            if( !hasOtherFilter && -1 < filteredTypes.indexOf( element.type ) )
            {
                return true
            }

        }
        else
        {
            for( let f in filters )
            {
                let filterList = filters[ f ]
                for( let i in filterList )
                {
                    allFiltersEmpty = false
                    if( true !== filterList[ i ].isFulltext )
                    {
                        if( element[ filterList[ i ].attr ] === filterList[ i ].value )
                        {
                            return true
                        }
                    }
                    else
                    {
                        if( this.filterFulltext( element, filterList[ i ].value ) )
                        {
                            return true
                        }
                    }
                }
            }
        }

        return allFiltersEmpty

    }

    /**
     * skip
     * @param event
     */
    skip( event )
    {
        if( undefined !== event )
        {
            try
            {
                event.preventDefault()
                event.stopPropagation()
            }
            catch
            { /*is-empty-block*/
            }
        }
    }

    /**
     * isOnlineSyncableState
     * @returns {boolean}
     */
    isOnlineSyncableState()
    {
        return ( true === this.store.getters.authorized
                 && true === this.store.getters.online
                 && false === this.store.getters.offlineAuthorized )
    }

    /**
     * now
     * @returns {number}
     */
    now()
    {
        return Date.now()
    }

    /**
     * isFullscreen
     * @returns {boolean}
     */
    isFullscreen()
    {

        let heightMaxDiff = 30

        if( screen.width > screen.height )
        {
            if( window.matchMedia( '(orientation: portrait)' ).matches )
            {
                return ( screen.width === window.innerHeight && ( window.innerWidth + heightMaxDiff ) >= screen.height )
            }
            else
            {
                return ( screen.width === window.innerWidth && ( window.innerHeight + heightMaxDiff ) >= screen.height )
            }
        }
        else
        {
            if( window.matchMedia( '(orientation: portrait)' ).matches )
            {
                return ( screen.width === window.innerWidth && ( window.innerHeight + heightMaxDiff ) >= screen.height )
            }
            else
            {
                return ( ( window.innerHeight + heightMaxDiff ) >= screen.width && window.innerWidth === screen.height )
            }
        }

    }

    /**
     * hexToRgbA
     * @param hex
     * @param opacity
     * @param asArray
     * @returns {string|number[]}
     */
    hexToRgbA( hex, opacity, asArray )
    {
        let c
        if( /^#([A-Fa-f0-9]{3}){1,2}$/.test( hex ) )
        {
            c = hex.substring( 1 ).split( '' )
            if( c.length == 3 )
            {
                c = [ c[ 0 ], c[ 0 ], c[ 1 ], c[ 1 ], c[ 2 ], c[ 2 ] ]
            }
            c = '0x' + c.join( '' )
            if( asArray )
            {
                return [ ( c >> 16 ) & 255, ( c >> 8 ) & 255, c & 255 ]
            }
            return 'rgba(' + [ ( c >> 16 ) & 255, ( c >> 8 ) & 255, c & 255 ].join( ',' ) + ',' + opacity + ')'
        }
        throw new Error( 'Bad Hex' )
    }

    /**
     * removeFromArray
     * @param array
     * @param what
     */
    removeFromArray( array, what )
    {

        if( undefined === array )
        {
            return
        }

        let index = array.indexOf( what )
        if( -1 < index )
        {
            array.splice( index, 1 )
        }

    }

    /**
     * isTruely
     * @param value
     * @returns {boolean}
     */
    isTruely( value )
    {
        return ( 1 === value || true === value )
    }

    /**
     * deref
     * - removes Proxies and reference from object for easier heap cleanup
     * @param object
     * @returns {any}
     */
    deref( object )
    {
        return JSON.parse( JSON.stringify( object ) )
    }

    /**
     * insertIntoArray
     * @param array
     * @param what
     * @param where
     */
    insertIntoArray( array, what, where )
    {
        if( undefined === where )
        {
            array.push( what )
        }
        else
        {
            let index = array.indexOf( where )
            if( -1 < index )
            {
                array.splice( ( 1 + index ), 0, what )
            }
            else
            {
                array.push( what )
            }
        }
    }

    getHashContents( item )
    {

        let strip    = [
                'elementKey'
            ],
            hashable = JSON.parse( JSON.stringify( item ) ),
            keys     = Object.keys( hashable )

        for( let k in keys )
        {
            let key = keys[ k ]
            if( key.substring( 0, 1 ) === '_' || -1 < strip.indexOf( key ) )
            {
                delete hashable[ key ]
            }
        }

        return JSON.stringify( hashable )

    }

    /**
     * objectHash
     * @param item
     * @param returnVal
     */
    _objectHash( item, returnVal )
    {

        let start = Date.now()

        if( !item )
        {
            return
        }

        let remoteUpdateTimestampBackup,
            hadTrackers = false

        if( item.type === 'list' )
        {
            delete item.listContentKey
        }

        if( undefined !== item.elementKey )
        {
            delete item.elementKey
            hadTrackers = true
        }
        if( undefined !== item.remoteUpdateTimestamp )
        {
            remoteUpdateTimestampBackup = item.remoteUpdateTimestamp
            delete item.remoteUpdateTimestamp
            hadTrackers = true
        }

        let str     = this.getHashContents( item ),
            content = JSON.stringify( {
                columns  : item.columns,
                values   : item.values,
                update   : item.update,
                timestamp: item.timestamp,
            } )

        let i, l, key, contentKey,
            hval  = 0x2961987,
            hval2 = 0x1111111

        for( i = 0, l = str.length; i < l; i++ )
        {
            hval ^= str.charCodeAt( i )
            hval += ( hval << 1 ) + ( hval << 4 ) + ( hval << 7 ) + ( hval << 8 ) + ( hval << 24 )
        }
        key = ( '00000000' + ( hval >>> 0 ).toString( 16 ) ).substr( -8 )

        for( i = 0, l = content.length; i < l; i++ )
        {
            hval2 ^= content.charCodeAt( i )
            hval2 += ( hval2 << 1 ) + ( hval2 << 4 ) + ( hval2 << 7 ) + ( hval2 << 8 ) + ( hval2 << 24 )
        }
        contentKey = ( '00000000' + ( hval2 >>> 0 ).toString( 16 ) ).substring( -8 )

        if( 'object' === typeof item
            && !returnVal )
        {
            item.elementKey = key
            item.contentKey = contentKey
        }

        if( 'object' === typeof item
            && hadTrackers
            && !returnVal )
        {
            item.remoteUpdateTimestamp = remoteUpdateTimestampBackup
        }

        let diff = Date.now() - start
        this.hashTimeSpent += diff

        return key

    }

    objectHash( item, returnVal )
    {


        let start = Date.now()

        if( !item )
        {
            return
        }

        let remoteUpdateTimestampBackup,
            hadTrackers = false

        if( item.type === 'list' )
        {
            delete item.listContentKey
        }

        if( undefined !== item.elementKey )
        {
            delete item.elementKey
            hadTrackers = true
        }
        if( undefined !== item.remoteUpdateTimestamp )
        {
            remoteUpdateTimestampBackup = item.remoteUpdateTimestamp
            delete item.remoteUpdateTimestamp
            hadTrackers = true
        }

        let str         = this.getHashContents( item ),
            content     = JSON.stringify( {
                columns  : item.columns,
                values   : item.values,
                update   : item.update,
                timestamp: item.timestamp,
            } ),
            elementHash = str.hash(),
            contentHash = content.hash()

        if( 'object' === typeof item
            && !returnVal )
        {
            item.elementKey = elementHash
            item.contentKey = contentHash
        }

        if( 'object' === typeof item
            && hadTrackers
            && !returnVal )
        {
            item.remoteUpdateTimestamp = remoteUpdateTimestampBackup
        }

        let diff = Date.now() - start
        this.hashTimeSpent += diff

        return elementHash

    }


    /**
     * mapHash
     * @param item
     * @param returnVal
     */
    mapHash( item )
    {

        let stringMap = ''

        for( const [ i, element ] of item )
        {
            stringMap += element.elementKey
        }

        let i, l, key, contentKey,
            hval = 0x2961987

        for( i = 0, l = stringMap.length; i < l; i++ )
        {
            hval ^= stringMap.charCodeAt( i )
            hval += ( hval << 1 ) + ( hval << 4 ) + ( hval << 7 ) + ( hval << 8 ) + ( hval << 24 )
        }
        key = ( '00000000' + ( hval >>> 0 ).toString( 16 ) ).substr( -8 )

        return key

    }

    /**
     * hashCyrB53
     * @param input
     * @param seed
     * @returns {string}
     */
    hashCyrB53( input, seed = 3 )
    {

        if( undefined === input )
        {
            return ''
        }

        let h1 = 0xdeadbeef ^ seed,
            h2 = 0x41c6ce57 ^ seed;

        for( let i = 0, ch; i < input.length; i++ )
        {
            ch = input.charCodeAt( i );
            h1 = Math.imul( h1 ^ ch, 2654435761 );
            h2 = Math.imul( h2 ^ ch, 1597334677 );
        }

        h1 = Math.imul( h1 ^ ( h1 >>> 16 ), 2246822507 ) ^ Math.imul( h2 ^ ( h2 >>> 13 ), 3266489909 );
        h2 = Math.imul( h2 ^ ( h2 >>> 16 ), 2246822507 ) ^ Math.imul( h1 ^ ( h1 >>> 13 ), 3266489909 );

        return ( 4294967296 * ( 2097151 & h2 ) + ( h1 >>> 0 ) ).toString( 16 ).padStart( 15, '0' )
    }

    /**
     * hash
     * @returns {*}
     */
    hash()
    {
        let string = '' + Date.now()
        return this.objectHash( string )
    }

    /**
     * promiseRunner
     * @param promises
     * @returns {Promise<unknown>}
     */
    promiseRunner( promises )
    {
        return new Promise( resolve =>
        {

            if( 0 === promises.length )
            {
                return resolve()
            }

            let promise = promises.reduce( ( todo, task ) =>
            {
                return todo.then( () =>
                {
                    return task()
                } )
            }, Promise.resolve() )

            promise.then( () =>
            {
                return resolve()
            } )

        } )
    }

    /**
     * listColumns
     * @param list
     * @returns {null|*}
     */
    listColumns( list )
    {
        if( undefined !== list.columns )
        {
            return list.columns
        }
        else
        {
            if( undefined !== list.lists
                && undefined !== list.lists[ 0 ]
                && undefined !== list.lists[ 0 ].columns )
            {
                return list.lists[ 0 ].columns
            }
            return null
        }
    }

    randomNumber( min, max )
    {
        return Math.floor( Math.random() * ( max - min + 1 ) + min )
    }

    awaitCoreState( state, expectedValue )
    {
        return new Promise( resolve =>
        {

            if( this.getState( state ) === expectedValue )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitCoreState( state, expectedValue ) )
                }, 300 )
            }

        } )
    }

    getStudentsListForListElement( list )
    {

        let studentList      = [],
            filter           = list.columns[ 0 ].filterBy,
            studentContainer = this.baseClassHelper
                                   .getObjectById( filter )

        if( null !== studentContainer
            && undefined !== studentContainer.students )
        {
            for( let s in studentContainer.students )
            {
                studentList.push( this.baseClassHelper.get( 'student' ).getById( studentContainer.students[s] ) )
            }
        }

        return studentList

    }

}