export default class ListUpdateManager
{

    constructor( core )
    {

        if( !ListUpdateManager.instance )
        {

            this.logger = core.getLogger()
            this.f = core.f()
            this.store = core.getStore()
            this.queueWorker = core.getQueueWorker()
            this.client = core.getClient()
            this.cryptoHelper = core.getCryptoHelper()
            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.eventManager = core.getEventManager()
            this.baseClassHelper = core.getBaseClassHelper()
            this.timer = core.getCoreTimer()
            this.fieldHistoryHelper = core.getFieldHistoryHelper()
            this.snapshotHelper = core.getListSnapshotHelper()

            ListUpdateManager.instance = this

            this.logSign = 'Core::ListUpdateManager (LUM)'
            this.hooks = {}
            this.unhookQueue = {}

            this.updateInterval = 2000

            this.timer.addInterval( 'listUpdateManager', 1000, () =>
            {
                this.checkHooks()
                this.checkUnhooks()
            } )

            this.logger.cconstructed( this.logSign, 'list update manager initialized' )

        }

        return ListUpdateManager.instance

    }

    hasHook( localId )
    {
        return undefined !== this.hooks[ localId ] && undefined === this.unhookQueue[ localId ]
    }

    hook( localId, editItem )
    {

        delete this.unhookQueue[ localId ]

        if( undefined === this.hooks[ localId ] )
        {
            this.hooks[ localId ] = {
                lastUpdate    : Date.now(),
                editItem      : editItem,
                lastEdited    : null,
                valueClone    : undefined !== editItem.values ? JSON.parse( JSON.stringify( editItem.values ) ) : {},
                valueHistory  : null,
                changed       : false,
                createSnapshot: false
            }
            this.logger.clog( this.logSign, 'setting up hook for list #' + localId )
            return
        }

    }

    unhook( localId )
    {

        if( undefined !== this.hooks[ localId ]
            && undefined === this.unhookQueue[ localId ] )
        {
            this.unhookQueue[ localId ] = this.hooks[ localId ]
            this.unhookQueue[ localId ].unhookTime = Date.now()
        }

    }

    deleteHook( localId )
    {
        if( this.hooks[ localId ].createSnapshot )
        {
            this.logger.clog( this.logSign, 'creating value snapshot for list #' + localId + ' before unhook...' )
            let item = this.hooks[ localId ].editItem
            this.snapshotHelper.createSnapshot( localId, this.hooks[ localId ].valueClone, item._keys )
        }

        this.hooks[ localId ] = null
        this.unhookQueue[ localId ] = null
        delete this.hooks[ localId ]
        delete this.unhookQueue[ localId ]

        this.logger.log( this.logSign, 'removed hook for list #' + localId )
    }

    checkUnhooks()
    {

        for( let u in this.unhookQueue )
        {
            if( this.unhookQueue[u].unhookTime <= ( Date.now() - ( this.updateInterval - 500 ) ) )
            {
                if( this.hooks[u].changed !== true )
                {
                    this.deleteHook( u )
                }
                else
                {
                    this.checkHooks( u )
                }
            }
        }
    }

    checkHooks( forceLocalId )
    {

        for( let localId in this.hooks )
        {
            if( ( this.hooks[ localId ].changed
                  && this.hooks[ localId ].lastUpdate <= Date.now() - this.updateInterval )
                || forceLocalId === localId )
            {

                if( forceLocalId )
                {
                    this.logger.clog( this.logSign, 'update hook #' + localId + ' forced run: preparing update.' )
                }
                else
                {
                    this.logger.clog( this.logSign, 'update hook #' + localId + ' older than ' + this.updateInterval + 'ms: preparing update.' )
                }

                this.eventManager
                    .dispatch( 'storing-' + localId )

                this.hooks[ localId ].editItem.fieldHistory = this.fieldHistoryHelper.reduce( this.hooks[ localId ].editItem.fieldHistory )

                let item = JSON.parse( JSON.stringify( this.hooks[ localId ].editItem ) )
                this.hooks[ localId ].valueHistory = undefined !== item.values ? JSON.parse( JSON.stringify( item.values ) ) : undefined

                this.baseClassHelper
                    .get( 'list', item.listType )
                    .update(
                        item,
                        item.localId,
                        item.remoteId,
                        item.timestamp,
                        item.localKey
                    )

                this.hooks[ localId ].changed = false

            }
        }
    }

    isUpdate( localId, values )
    {

        let checksumLast = this.f.objectHash( this.hooks[ localId ].valueClone, true ),
            checksumNew  = this.f.objectHash( values, true )

        return checksumLast !== checksumNew

    }

    update( localId, valueClone, valueHistory )
    {

        if( undefined !== this.hooks[ localId ]
            && this.isUpdate( localId, valueClone ) )
        {

            this.logger.clog( this.logSign, 'update for #' + localId )

            this.hooks[ localId ].valueClone = JSON.parse( JSON.stringify( valueClone ) )
            this.hooks[ localId ].editItem.values = valueClone

            this.hooks[ localId ].editItem.fieldHistory = this.hooks[ localId ].editItem.fieldHistory || {}

            if( Array.isArray( this.hooks[ localId ].editItem.fieldHistory )
                || 'create' === this.hooks[ localId ].editItem.fieldHistory )
            {
                this.hooks[ localId ].editItem.fieldHistory = {}
            }

            if( undefined !== valueHistory )
            {
                this.hooks[ localId ].editItem.fieldHistory[ valueHistory.id_author + '-' + valueHistory.timestamp ] = valueHistory
            }

            this.hooks[ localId ].lastEdited = JSON.parse( JSON.stringify( this.hooks[ localId ].editItem ) )
            this.hooks[ localId ].lastUpdate = Date.now()
            this.hooks[ localId ].changed = true
            this.hooks[ localId ].createSnapshot = true

        }
    }

    updateDoneDefinition( localId, values )
    {

        let list = this.baseClassHelper
                       .get( 'list' )
                       .getContainer( localId )

        if( 1 === list.length )
        {

            list[0].doneFields = values
            this.baseClassHelper
                .get( 'list', list[0].listType )
                .update(
                    list[0],
                    list[0].localId,
                    list[0].remoteId,
                    list[0].timestamp,
                    list[0].localKey
                )

        }

    }

}
