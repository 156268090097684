<template>
    <div class="planner-day-wrapper" v-if="visible && ready">
        <transition appear :name="$core.settings().getTransition( 'next' )">
            <div class="planner-day-container" v-if="visible">
                <div class="detail-view">
                    <div class="headline">
                        <strong v-if="date.isToday === true">Heute</strong>
                        <strong v-else>{{
                                $core.getFriendlyTimestamp().friendlyDate( date.dateRange.from )
                            }}</strong>
                        <transition name="fade">
                            <ProcessingIndicator v-if="processing"/>
                        </transition>
                        <Button type="close" @clicked="handleClose()"/>
                        <Button type="navigate next topnavigation" @clicked="navigate( 'next' )"/>
                        <Button type="navigate last topnavigation" @clicked="navigate( 'last' )"/>
                    </div>
                    <div class="body" :id="viewId">
                        <ScrollToTop :base="false" :attach="viewId"/>
                        <div class="filler"></div>
                        <div class="head-functions">
                            <template v-if="!$core.getLicense().forceReadOnly()">
                                <Button type="large add" @clicked="handleAdd()"/>
                            </template>
                            <ColorFilter @filter="setFilters"/>
                        </div>
                        <div :key="'planner-day-'+changeKey">
                            <template v-if="tabs.length > 0">
                                <TabbedContent :tabs="tabs" :active="tab" additionalClass="marge-top"
                                               @switched="setTab"/>
                                <template v-for="t in tabs">
                                    <div class="tabbed-content" v-if="t.id === tab" :key="'tab-'+t.id">
                                        <DefaultViewItemList :scope="tab"
                                                             :nested="true"
                                                             :viewId="viewId"
                                                             :itemList="prepared[tab]"
                                                             :itemZooms="zooms[tab]"
                                                             :listKey="'list-'+viewKey"
                                                             :filterKey="'flt-'+viewKey"
                                                             :viewItem="tab"
                                                             :viewKey="viewKey"
                                                             :filtered="filtered"
                                                             :viewIsFiltered="viewIsFiltered"
                                                             :singleItems="true"
                                                             :nonEditable="[ 'list' ]"
                                                             :triggerOpen="triggerOpen"
                                                             :objectType="t.id+'s'"
                                                             :key="'def-view-item-list-'+t.id"/>
                                    </div>
                                </template>
                            </template>
                            <InlineMessage v-else
                                           :active="true"
                                           classname="empty"
                                           title="Keine Elemente für diesen Tag"
                                           message=""/>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import ProcessingIndicator from '@/components/elements/indicators/ProcessingIndicator'
import ScrollToTop         from '@/components/elements/defaults/ScrollToTop'
import TabbedContent       from '@/components/layout/tabs/TabbedContent'
import Button              from '@/components/elements/defaults/Button'
import InlineMessage       from '@/components/messages/InlineMessage'
import DefaultViewItemList from "@/components/defaults/DefaultViewItemList";
import MixinFilterableView from "@/components/defaults/defaultListPage/MixinFilterableView";
import { reactive }        from "vue";

export default {

    name      : 'PlannerDay',
    components: { DefaultViewItemList, InlineMessage, Button, TabbedContent, ScrollToTop, ProcessingIndicator },
    props     : {
        date     : { type: Object, required: false, default: undefined },
        changeKey: { type: String, required: false }
    },

    emits: [ 'close', 'refresh', 'navigate', 'requestRefresh' ],

    mixins: [ MixinFilterableView ],

    watch: {
        changeKey : {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( newKey !== oldKey )
                {
                    this.$emit( 'requestRefresh ')
                    this.setTabs()
                }
            }
        },
        cacheKeys: {
            immediate: true,
            deep: true,
            handler( newKey, oldKey )
            {
                if( undefined !== oldKey
                    && JSON.stringify( newKey ) !== JSON.stringify( oldKey ) )
                {
                    this.$emit( 'requestRefresh ')
                    this.setTabs()
                }
            }
        }
    },

    data()
    {
        return {
            viewId      : 'planner-day-' + this.$core.getUuid().generate(),
            viewKey     : this.$core.getUuid().generate(),
            visible     : true,
            processing  : false,
            triggerOpen : false,
            filterList  : {},
            tab         : false,
            tabs        : [],
            zooms       : {},
            elements    : {},
            preparedList: [],
            prepared    : {},
            cacheKeys   : reactive({} ),
            ready       : false,
            queueForType: false
        }
    },

    created()
    {

        this.setTabs()
        this.hookKeys()

    },

    mounted()
    {
        this.attachFunctionBox()
    },

    beforeUnmount()
    {
        this.hookKeys( false )
    },

    methods: {

        setTabs()
        {

            this.tabs = []
            this.elements = {}
            this.prepared = {}
            this.preparedList = []
            this.zooms = {}

            let tabs = []
            let alternativeTab = false

            for( let e in this.$props.date.elements )
            {

                let element = this.$props.date.elements[ e ],
                    queue   = element.type

                if( undefined !== queue )
                {

                    if( undefined === this.prepared[ queue ] )
                    {
                        this.prepared[ queue ] = []
                    }
                    if( !this.$core.f().isset( this.elements[ element.type ] ) )
                    {
                        alternativeTab = !alternativeTab ? element.type : alternativeTab
                        if( !this.tab )
                        {
                            this.tab = element.type
                        }
                        tabs.push( element.type )
                        this.cacheKeys[ element.type ] = this.$core.getBaseClassHelper()
                                                             .getCacheKey( element.type )
                        this.elements[ element.type ] = []
                    }

                    this.elements[ element.type ].push( element )
                    this.prepared[ queue ].push( element.localId )
                    if( undefined === this.zooms[ queue ] )
                    {
                        this.zooms[ queue ] = {}
                    }
                    this.zooms[ queue ][ element.localId ] = element

                }

            }

            if( tabs.length === 0
                || tabs.indexOf( this.tab ) === -1 )
            {
                this.tab = alternativeTab ? alternativeTab : false
            }

            for( let t in tabs )
            {
                this.tabs.push( {
                    id     : tabs[ t ],
                    caption: this.$core.getTranslation().translate( 'object-type-' + tabs[ t ] + 's' )
                } )
            }

            this.ready = true

        },

        /*
        setFilters( type, filters )
        {

            if( undefined === filters )
            {
                delete this.filterList[ type ]
                this.filterList[ type ] = []
            }
            else
            {
                this.filterList[ type ] = filters
            }
            this.viewKey = this.$core.getUuid().generate()

        },

         */

        handleAdd()
        {

            let elementType = this.tab !== false ? this.tab : 'element'
            let objectType = this.tab !== false ? this.tab + 's' : 'element'

            let date = this.$props.date.dateRange.from

            let params = {
                date          : this.$core.getFriendlyTimestamp().formattedDate( date ),
                forceTimestamp: date,
                forceCreate   : true
            }

            let formKey = this.$core.getUi().showForm( elementType, objectType, 'add', params )

            if( 'lists' === objectType
                && this.$core.settings().getSetting( 'openAfterCreate' ) === true )
            {
                this.$core.getEventManager().add( 'created-object-' + formKey, ( localId ) =>
                {
                    this.triggerOpen = localId
                } )
            }

        },

        handleClose()
        {
            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 400 )
        },

        setTab( tab )
        {
            this.tab = tab
        },

        attachFunctionBox()
        {
            if( document.querySelector( '#' + this.viewId ) !== null )
            {
                this.$core.getUi().getFloatingFunctionBox().attachToView( this.viewId )
            }
            else
            {
                setTimeout( () =>
                {
                    this.attachFunctionBox()
                }, 100 )
            }
        },

        navigate( direction )
        {
            this.$emit( 'navigate', direction )
        },

        hookKeys( state )
        {

            switch( state )
            {
                case undefined:
                    document.onkeydown = ( event ) =>
                    {

                        if( 'ArrowLeft' === event.key )
                        {
                            this.navigate( 'last' )
                        }
                        if( 'ArrowRight' === event.key )
                        {
                            this.navigate( 'next' )
                        }

                    }
                    break
                case false:
                    document.onkeydown = null
                    break
            }

        },


    }

}
</script>