<template>
    <AppReady v-if="translationsReady"/>
    <AppInitializing v-else/>
</template>

<script>
import AppInitializing from "@/components/app/AppInitializing";
import AppReady        from "@/components/app/AppReady";

export default {

    name: 'entzettelt',

    components: {
        AppReady,
        AppInitializing
    },

    created()
    {

        this.$core.getEventManager().add( 'on-translations-ready', () =>
        {
            this.translationsReady = true
        } )

        this.writeStatistics()

    },

    data()
    {
        return {
            translationsReady: false
        }
    },

    methods: {

        getCookie()
        {

            let lookup = document.cookie.split( ';' )
            for( let l in lookup )
            {
                if( lookup[ l ].startsWith( '_ezStatisticsWritten' ) )
                {
                    return true
                }
            }

            return false

        },

        writeStatistics()
        {

            let cookieExpiry = new Date()
            cookieExpiry.setTime( cookieExpiry.getTime() + ( 2 * 60 * 60 * 1000 ) )
            document.cookie = '_ezStatisticsWritten=1;' + cookieExpiry + ';path=/';

            /*eslint-disable-next-line*/
            if( !this.getCookie() || true )
            {

                setTimeout( () => {

                    let referrer  = document.referrer,
                        userAgent = window.navigator.userAgent

                    let message = {
                        method   : 'basic.writeStatistics',
                        referrer : referrer,
                        userAgent: userAgent,
                        idUser   : this.$store.getters.idUser
                    }

                    this.$core.getClient()
                        .request( message )
                        .then( () => {
                            this.$core.getLogger().clog( 'App.vue::statistics', 'collected basic usage statistics' )
                        })
                        .catch( e => {
                            this.$core.getLogger().cerror( 'App.vue::statistics', 'failed to collect basic usage statistics:', e  )
                        })

                }, 5000 )

            }

        }
    }

}
</script>
