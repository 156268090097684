<template>
    <span :class="'list-state-marker right '+stateClass"
          :key="'lsm-'+contentKey">
        <template v-if="true === state.done">
            erledigt
        </template>
        <template v-else>
            <template v-if="0 < days">
                fällig in <strong>{{ days }}</strong> Tagen
            </template>
            <template v-else-if="0 === days">
                heute fällig
            </template>
            <template v-else>
                überfällig seit <strong>{{ ( -1 * days ) }}</strong> Tagen
            </template>
        </template>
    </span>
</template>

<script>
export default {

    name : 'ListStateMarker',
    props: {
        contentKey: { Type: String, required: true },
        state     : { Type: Object, required: true }
    },

    computed: {
        stateClass()
        {
            if( true === this.$props.state.done )
            {
                return 'done'
            }

            let days = this.days
            if( days > 3 )
            {
                return 'warn'
            }
            return 'error'

        },

        days()
        {

            let timestamp = this.$props.state.duedate,
                valid     = false

            if( isNaN( parseInt( this.$props.state.duedate ) )
                || '' + parseInt( this.$props.state.duedate ) !== '' + this.$props.state.duedate )
            {
                if( undefined !== timestamp
                    && null !== timestamp
                    && typeof timestamp.indexOf === 'function'
                    && -1 < timestamp.indexOf( '.' ) )
                {

                    let temp  = timestamp.split( /\./g ),
                        mysql = temp.reverse().join( '-' )

                    valid = true
                    timestamp = this.$core.getFriendlyTimestamp().timestampFromMysql( mysql + ' 12:00:00' )
                }
            }

            return valid === true ? this.$core.getFriendlyTimestamp().dueDays( timestamp ) : 0

        }
    }

}
</script>