import { reactive } from 'vue'

export default class FastBus
{
    constructor( core )
    {
        if( !FastBus.instance )
        {
            this.logger = core.getLogger()
            this.logger.cconstructed( 'FastBus::instancing', 'FastBus reactive Event-Trigger starting...' )

            this.triggers = reactive( {
                lastObjectCreated: null,
                lastObjectType   : null
            } )

            this.eventManager = core.getEventManager()
            this.eventManager.add( 'set-bus-trigger', set =>
            {
                this.setTrigger( set[ 0 ], set[ 1 ] )
            } )

            FastBus.instance = this
        }
        return FastBus.instance
    }

    setTrigger( key, value )
    {
        this.logger.cdebug( 'FastBus::setTrigger', 'setting', key, ':', '' + value )
        this.triggers[ key ] = value
    }

    resetTrigger( key )
    {
        this.logger.cdebug( 'FastBus::resetTrigger', 'setting', key, ': null' )
        this.triggers[ key ] = null
    }

}