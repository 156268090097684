import Countries from '@/classes/Helpers/Countries'

export default class Reformatter
{

    constructor( core )
    {

        if( !Reformatter.instance )
        {
            
            this.defaultCountry = false
            this.countryHelper = new Countries()
            this.settings = core.settings()
            this.eventManager = core.getEventManager()
            this.punycode = require( 'punycode/' )
            
            this.initialize( core )
            
            Reformatter.instance = this
        }

        return Reformatter.instance

    }
    
    destruct()
    {
        
        this.defaultCountry = false
        delete this.countryHelper
        this.countryHelper = null
        
        delete Reformatter.instance
        
    }

    initialize( core )
    {
        if( !this.settings )
        {
            this.eventManager.append( 'on-settingsworker-ready', () => {
                
                this.settings = core.settings()
                
            })
        }
    }
    
    date( value )
    {

        let parts = value.split( '.' )
        if( 3 == parts.length )
        {
            parts[ 0 ] = ( 1 == parts[ 0 ].length ? '0' : '' ) + parts[ 0 ]
            parts[ 1 ] = ( 1 == parts[ 1 ].length ? '0' : '' ) + parts[ 1 ]
            parts[ 2 ] = '' == parts[ 2 ].trim() ? new Date().getFullYear()
                : ( 2 == parts[ 2 ].length ? '20' : '' ) + parts[ 2 ]

            return parts.join( '.' )
        }
        if( 2 == parts.length )
        {
            parts[ 0 ] = ( 1 == parts[ 0 ].length ? '0' : '' ) + parts[ 0 ]
            parts[ 1 ] = ( 1 == parts[ 1 ].length ? '0' : '' ) + parts[ 1 ]
            parts[ 2 ] = new Date().getFullYear()

            return parts.join( '.' )
        }

        return value

    }

    time( value )
    {

        if( '' === value.trim() )
        {
            return ''
        }

        let parts = value.split( ':' )
        if( 2 == parts.length )
        {
            parts[ 0 ] = ( 1 == parts[ 0 ].length ? '0' : '' ) + parts[ 0 ]
            parts[ 1 ] = ( 1 == parts[ 1 ].length ? '0' : '' ) + parts[ 1 ]
            return parts.join( ':' )
        }

        return ( 1 == value.length ? '0' : '' ) + value + ':00'

    }

    dateFromTimestamp( value, plain )
    {

        let dateVal = new Date( parseInt( value ) )
        let fromDate = dateVal.getDate() + '.' + ( dateVal.getMonth() + 1 ) + '.' + dateVal.getFullYear()
        if( plain !== undefined )
        {
            return fromDate
        }
        return this.date( fromDate )

    }

    float( value )
    {
        let number = parseFloat( ( '' + value ).replace( ',', '.' ) ).toFixed( 2 ),
            test = ( ''+number ).split( '.' )
        if( undefined !== test[1] )
        {
            if( '00' === test[1] )
            {
                return parseFloat( test[0] )
            }
            if( '0' === test[1].substr( 1, 1 ) )
            {
                return parseFloat( parseFloat( number ).toFixed( 1 ) )
            }
        }
        return parseFloat( number )
    }

    isFloat( value )
    {
        let test = this.float( value )
        return !isNaN( test )
    }

    int( int )
    {
        let value = parseInt( '' + int )
        return isNaN( value ) ? 'n/v' : value
    }

    localizedFloat( value, precision )
    {

        value = this.float( value )
        if( isNaN( value ) )
        {
            return undefined
        }

        value = value.toFixed( precision )
        return ( '' + value ).replace( '.', ',' )

    }

    floatToLocal( value )
    {
        value = this.float( value )
        if( isNaN( value ) )
        {
            return undefined
        }
        return ( '' + value ).replace( '.', ',' )
    }

    toBoolean( input )
    {
        return ( parseInt( input ) === 1 || 'true' === input || true === input ? true : false )
    }
    
    booleantoInt(input)
    {
        return ( parseInt( input ) === 1 || 'true' === input || true === input ? 1 : 0 )
    }
    
    toPhoneURI( input )
    {
        let phoneUri = this.countryHelper
            .getPhoneHtml( this.settings.getSetting( 'defaultCountry'), input,
                this.settings.getSetting( 'callAnonymous' ) )

        return phoneUri
    }
    
    url( input )
    {
        return this.punycode.toASCII( input )
    }
    
    timeForArchiveKey( object )
    {
        if( undefined !== object.archiveKey )
        {
            
            let temp = object.archiveKey.split( '-' )
            let year = temp[0]
            let datestring = this.dateFromTimestamp( temp[1] )
            
            return 'bis '+year+' ('+datestring+')'
            
        }
        return ''
    }

    email( value )
    {
        if( undefined !== value )
        {
            return '<a href="mailto:'+value+'" target="_blank">'+value+'</a>'
        }
        return ''
    }

    reformat( input, format )
    {
        switch( format )
        {
            case 'phonenumber':
                return this.toPhoneURI( input )
            case 'boolean':
                return this.toBoolean( input )
            case 'boolean2int':
                return this.booleantoInt( input )
            case 'rating':
                return ''+input
            case 'int':
                return this.int( input )
            case 'email':
                return this.email( input )
            case 'float':
                return this.float( input )
            case 'currency':
                return this.localizedFloat( input, 2 )+'&euro;'
            case 'localizedFloat':
                return this.localizedFloat( input, 2 )
            case 'localizedScore':
                return this.localizedFloat( input, 1 )
            case 'date':
                return this.date( input )
            case 'dateFromTimestamp':
                return this.dateFromTimestamp( input )
            case 'time':
                return this.time( input )
            case 'url':
                return this.url( input )
        }
    }

}