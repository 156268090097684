/*eslint-disable*/
import * as XLSX from "xlsx";

export default class Excel
{

    constructor( core )
    {

        this.logger = core.getLogger()
        this.friendlyTimestamp = core.getFriendlyTimestamp()
        this.ui = core.getUi()
        this.s = core.s()
        this.settings = core.settings()
        this.setState = ( key, value ) =>
        {
            core.setState( key, value )
        }

        this.options = {
            elementCount: false,
            object      : false,
            append      : false,
            appendPage  : false,
            element     : false,
            inline      : false
        }

        return this

    }

    destruct()
    {

        this.logger = null
        this.friendlyTimestamp = null
        this.ui = null
        this.settings = null

        delete this

    }

    export( object, element, elementCount, inline )
    {

        this.ui.showBlocker( 'Liste wird als Excel-Tabelle exportiert...', 'Augenblick bitte' )
        this.s.count( 'excel' )

        let uuid   = element.split( '-' ),
            append = false

        if( uuid.length > 5 )
        {
            while( uuid.length > 5 )
            {
                uuid.shift()
            }
            append = 'append-printable-' + uuid.join( '-' )
        }

        this.setState( 'excel-exporting', true )

        setTimeout( () =>
        {

            let options  = {
                    elementCount: elementCount,
                    object      : object,
                    append      : append,
                    element     : element,
                    inline      : inline
                },
                filename = object.listname.replace( /\ /g, '_' )

            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.table_to_sheet( document.getElementById( element ), { raw: true, cellStyles: true } )

            for( let key in ws )
            {
                if( key.substring( 0, 1 ) !== '!' )
                {
                    ws[key].v = ws[key].v.replace( /--BR--/g, "\n" )
                }
            }

            wb.SheetNames.push( 'entzettelt.de' )
            wb.Sheets[ 'entzettelt.de' ] = ws

            let wbout       = XLSX.write( wb, { bookType: 'xlsx', bookSST: true, type: 'binary' } ),
                arrayBuffer = new ArrayBuffer( wbout.length ),
                view        = new Uint8Array( arrayBuffer )

            for( let i = 0; i < wbout.length; i++ )
            {
                view[ i ] = wbout.charCodeAt( i ) & 0xFF
            }

            let file    = new Blob( [ arrayBuffer ], { type: 'application/xlsx' } ),
                fileURL = window.URL.createObjectURL( file )

            const link = document.createElement( 'a' )
            link.href = fileURL
            link.setAttribute( 'download', filename + '.xlsx' )
            document.body.appendChild( link )
            link.click()

            setTimeout( () =>
            {

                this.setState( 'excel-exporting', false )
                this.ui.hideBlocker()

            }, 1000 )

        }, 300 )
    }

    import( file, hasCaptions )
    {

        let workbook = XLSX.read( file, { type: 'binary', cellText: false, cellDates: true } ),
            rows     = []

        workbook.SheetNames.forEach( function( sheetName )
        {

            let XL_row_object = XLSX.utils.sheet_to_row_object_array( workbook.Sheets[ sheetName ], {
                raw   : false,
                defval: '',
                dateNF: 'dd"."mm"."yyyy'
            } )

            for( let r in XL_row_object )
            {

                let data      = XL_row_object[ r ],
                    headlines = [],
                    first     = [],
                    row       = [],
                    i         = 0

                Object.keys( data ).forEach( ( key ) =>
                {

                    if( r == 0 )
                    {
                        if( hasCaptions )
                        {
                            headlines.push( key )
                        }
                        else
                        {
//                            headlines.push( 'Feld ' + i )
                            first.push( key )
                        }
                    }
                    row.push( data[ key ] )
                    i++

                } )

                if( 0 < headlines.length )
                {
                    rows.push( headlines.join( ';' ) )
                }
                if( 0 < first.length )
                {
                    rows.push( first.join( ';' ) )
                }
                rows.push( row.join( ';' ) )

            }

        } )

        return rows

    }

}