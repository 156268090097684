<template>
    <div>
        <div :class="'select-wrapper'+( extendedFilter ? ' has-extended' : '' )" v-if="initialized">
            <select class="select_element" :id="'form-element-'+refName" @click="$core.f().skip"
                    @change="checkSelect()" :key="'form-element-'+refName">
                <option value="">bitte wählen...</option>
                <template v-if="allowForeign !== true">
                    <option v-for="student in students" :value="student.localId"
                            :selected="student.localId === display"
                            :key="'key_studsel_'+student.localId">{{ student.lastname }}, {{
                            student.firstname
                        }}
                    </option>
                </template>
                <template v-if="allowForeign === true">
                    <optgroup label="meine Schüler:innen">
                        <option v-for="student in students" :value="student.localId"
                                :selected="student.localId === display"
                                :key="'key_studsel_'+student.localId">{{ student.lastname }}, {{
                                student.firstname
                            }}
                        </option>
                    </optgroup>
                    <optgroup v-for="coll in colleagues"
                              v-show="undefined !== foreigns[coll.colleagueId] && foreigns[coll.colleagueId].length > 0"
                              :key="'key_coll_'+coll.colleagueId"
                              :label="'Schüler:innen von '+coll.firstname+' '+coll.lastname">
                        <option v-for="student in foreigns[coll.colleagueId]" :value="student.localId"
                                :selected="student.localId === display"
                                :key="'key_studsel_'+student.localId">{{ student.lastname }}, {{
                                student.firstname
                            }}
                        </option>
                    </optgroup>
                </template>
            </select>
        </div>
        <div v-if="extendedFilter" class="extended-filter">
            <Button type="extended-filter" @click="handleExtendedFilter()"/>
        </div>
    </div>
</template>

<script>
import Button              from '@/components/elements/defaults/Button'
import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {

    name      : 'StudentSelector',
    mixins    : [ MixinCachePreheater ],
    components: { Button },
    props     : {
        value         : { Type: Object, required: false },
        refName       : { Type: String, required: true },
        id            : { Type: String, required: false },
        validator     : { Type: String, required: false },
        reformatter   : { Type: String, required: false },
        editor        : { Type: Boolean, required: false },
        eventKey      : { Type: String, required: false },
        flags         : { Type: String, required: false },
        prefetch      : { Type: String, required: false },
        onlyUnassigned: { Type: String, required: false },
        allValues     : { Type: Object, required: false },
        allowForeign  : { Type: Boolean, required: false },
        unique        : { Type: Boolean, required: false },
        ignoreClassId : { Type: Boolean, required: false },
        extendedFilter: { Type: Boolean, required: false },
        editItem      : { Type: Object, required: false }

    },

    emits: [ 'extendedFilterSelection', 'update', 'change' ],

    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.initialize()
                this.display = this.$props.value
                this.$core.getEventManager()
                    .add( 'on-extended-filter', ( list ) =>
                    {
                        this.handleSelection( list )
                    } )

            } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-extended-filter' )
    },

    mounted()
    {
        if( this.$props.unique === true )
        {
            this.filterUnique()
        }
    },

    data()
    {
        return {
            neededCaches      : [ 'student', 'colleague' ],
            Student           : false,
            students          : false,
            display           : false,
            data              : {},
            foreigns          : {},
            colleagues        : {},
            initialized       : false,
            showExtendedFilter: false
        }
    },

    watch  : {

        prefetch: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.initialize()
                }
            },
            deep: true
        },

        value: {
            deep: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.display = newValue
                    this.initialized = false
                    this.initialize()
                }
            }
        },

        allValues: {
            deep: true,
            handler()
            {
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
            }
        }

    },
    methods: {

        _getSortMap( mapKey )
        {

            mapKey = mapKey || 'cache'

            let sortMap = this.$core.getBaseClassHelper()
                              .get( 'student' )
                              .registry
                              .sortMap.get( mapKey )
            return sortMap

        },

        initialize()
        {

            this.data = this.$props.prefetch

            let colleagues = this.$core.getBaseClassHelper()
                                 .get( 'colleague' )
                                 .getCache( 'cache' ),
                studentsC  = this.$core.getBaseClassHelper()
                                 .get( 'student' )
                                 .getCache( 'cache' ),
                sortmap    = this._getSortMap(),
                students   = []

            /*eslint-disable*/
            for( const [ c, colleague ] of colleagues )
            {
                this.colleagues[ colleague.colleagueId ] = colleague
            }
            for( let s in sortmap )
            {
                students.push( studentsC.get( sortmap[s] ) )
            }
            /*eslint-enable*/

            if( undefined === this.data )
            {

                this.data = {}
                this.students = []
                this.foreigns = {}

                this.data.all = students

                for( let d in students )
                {
                    if( this.$core.f().isOwn( students[ d ] ) )
                    {
                        this.students.push( students[ d ] )
                    }
                    else
                    {
                        if( this.$props.allowForeign === true )
                        {
                            if( undefined === this.foreigns[ students[ d ].idOwner ] )
                            {
                                this.foreigns[ students[ d ].idOwner ] = []
                            }
                            this.foreigns[ students[ d ].idOwner ].push( students[ d ] )
                        }
                    }
                }

                if( true === this.$props.unique )
                {
                    this.filterUnique( this.$props.value )
                }

                this.initialized = true

            }
            else
            {
                this.filterUnique( this.$props.value )
                this.initialized = true
            }

            this.filterOwnStudents()

        },

        handleExtendedFilter()
        {
            this.$core.getUi().showExtendedStudentFilter( this.$props.onlyUnassigned )
        },

        filterOwnStudents()
        {

            let filteredList = []

            for( let s in this.students )
            {

                let student = this.students[ s ]
                if( parseInt( this.$core.getStore().getters.idUser ) === parseInt( student.idOwner )
                    || student.idOwner === undefined )
                {
                    filteredList.push( student )
                }
                else
                {
                    if( true === this.$props.allowForeign )
                    {

                        if( undefined === this.foreigns[ student.idOwner ] )
                        {
                            this.foreigns[ student.idOwner ] = []
                        }

                        this.foreigns[ student.idOwner ].push( student )

                    }
                }

            }

            this.students = filteredList

        },

        checkSelect()
        {

            let element = document.querySelector( '#form-element-' + this.$props.refName )

            if( null !== element )
            {
                if( '' !== element.value.trim() )
                {
                    this.$emit( 'change', this.$props.id, element.value )
                    this.$emit( 'update', this.$props.refName, 'studentSelector', 'update', undefined, element.value )
                }
                else
                {
                    this.$emit( 'change', this.$props.id, undefined )
                    this.$emit( 'update', this.$props.refName, 'studentSelector', 'update', undefined, undefined )
                }
            }
        },

        filterUnique()
        {

            this.$nextTick()
                .then( () =>
                {

                    let uniqueBut = ( this.$core.f().isset( this.$props.editItem ) ? this.$props.editItem.localId : false ),
                        excludes  = []

                    for( let a in this.$props.allValues )
                    {
                        excludes.push( this.$props.allValues[ a ].value )
                    }

                    let newList = []

                    for( let s in this.students )
                    {
                        let student = this.students[ s ]
                        if( !this.$props.ignoreClassId )
                        {
                            if( ( !this.$core.f().isset( student.classId ) || ( student.classId === uniqueBut ) )
                                && ( -1 === excludes.indexOf( student.localId ) || student.localId === this.display ) )
                            {
                                newList.push( student )
                            }
                        }
                        else
                        {
                            if( -1 === excludes.indexOf( student.localId ) || student.localId === this.display )
                            {
                                newList.push( student )
                            }
                        }
                    }

                    let newForeigns = {}

                    for( let c in this.foreigns )
                    {
                        newForeigns[ c ] = []
                        for( let s in this.foreigns[ c ] )
                        {
                            let student = this.foreigns[ c ][ s ]
                            if( !this.$props.ignoreClassId )
                            {
                                if( ( !this.$core.f().isset( student.classId ) || ( student.classId === uniqueBut ) )
                                    && ( -1 === excludes.indexOf( student.localId ) || student.localId === this.display ) )
                                {

                                    newForeigns[ c ].push( student )
                                }
                            }
                            else
                            {
                                if( -1 === excludes.indexOf( student.localId ) || student.localId === this.display )
                                {
                                    newForeigns[ c ].push( student )
                                }
                            }
                        }

                    }

                    this.students = newList
                    this.foreigns = newForeigns

                } )

        },

        handleSelection( list )
        {
            this.$emit( 'extendedFilterSelection', this.$props.refName, list )
        }
    }

}
</script>