import Button                     from '@/components/elements/defaults/Button'
import LabelsFilter               from '@/components/elements/filters/LabelsFilter';
import ClassesFilter              from '@/components/elements/filters/ClassesFilter'
import ColorFilter                from '@/components/elements/filters/ColorFilter'
import FunctionButton             from '@/components/elements/defaults/FunctionButton'
import StudentFilter              from '@/components/elements/filters/StudentFilter'
import Toggle                     from '@/components/form/elements/Toggle'
import PreheatingIndicator        from '@/components/elements/indicators/PreheatingIndicator'
import Form                       from '@/components/form/Form'
import Touchable                  from "@/components/defaults/Touchable";
import AdminGeneralRights         from "@/components/routes/secretary/AdminGeneralRights"
import AdminStudentAccess         from "@/components/routes/secretary/AdminStudentAccess"
import Date                       from "@/components/elements/viewItems/Date"
import Note                       from "@/components/elements/viewItems/Note"
import Todo                       from "@/components/elements/viewItems/Todo";
import Student                    from "@/components/elements/viewItems/Student";
import Class                      from "@/components/elements/viewItems/Class";
import Group                      from "@/components/elements/viewItems/Group";
import Team                       from "@/components/elements/viewItems/Team";
import Yeargroup                  from "@/components/elements/viewItems/Yeargroup";
import List                       from "@/components/elements/viewItems/List";
import Colleague                  from "@/components/elements/viewItems/Colleague";
import Message                    from "@/components/elements/viewItems/Message";
import Media                      from "@/components/elements/viewItems/Media";
import OrganizerContent           from "@/components/elements/viewItems/OrganizerContent";
import Organizer                  from "@/components/elements/viewItems/Organizer";
import Competence                 from "@/components/elements/viewItems/Competence.vue";
import CompetenceCategory         from "@/components/elements/viewItems/CompetenceCategory.vue";
import CompetenceTemplate         from "@/components/elements/viewItems/CompetenceTemplate.vue";
import CompetenceCategoryTemplate from "@/components/elements/viewItems/CompetenceCategoryTemplate.vue";

const components = [
    Button,
    FunctionButton,
    LabelsFilter,
    ClassesFilter,
    ColorFilter,
    StudentFilter,
    Toggle,
    PreheatingIndicator,
    Form,
    AdminGeneralRights,
    AdminStudentAccess,
    Touchable,
    Date,
    Note,
    Todo,
    Student,
    Class,
    Group,
    Team,
    Yeargroup,
    List,
    Colleague,
    Message,
    Media,
    OrganizerContent,
    Organizer,
    Competence,
    CompetenceCategory,
    CompetenceTemplate,
    CompetenceCategoryTemplate
]

export default components