import SyncCrudCleanRemote        from '@/classes/Core/Workers/SyncWorker/SyncCrud/SyncCrudCleanRemote'
import SyncCrudTimeDiff           from '@/classes/Core/Workers/SyncWorker/SyncCrud/SyncCrudTimeDiff'
import SyncCrudPrepareSyncables   from '@/classes/Core/Workers/SyncWorker/SyncCrud/SyncCrudPrepareSyncables'
import SyncCrudHandleQueue        from '@/classes/Core/Workers/SyncWorker/SyncCrud/SyncCrudHandleQueue'
import SyncCrudDeletions          from '@/classes/Core/Workers/SyncWorker/SyncCrud/SyncCrudDeletions'
import SyncCrudKeyUpdates         from '@/classes/Core/Workers/SyncWorker/SyncCrud/SyncCrudKeyUpdates'
import SyncCrudPersonalAttributes from '@/classes/Core/Workers/SyncWorker/SyncCrud/SyncCrudPersonalAttributes'
import SyncCrudMissingUploads     from "@/classes/Core/Workers/SyncWorker/SyncCrud/SyncCrudMissingUploads";

export default class SyncCrud
{

    constructor( parent )
    {

        if( !SyncCrud.instance )
        {

            this.parent = parent
            this.client = parent.client
            this.f = parent.f
            this.sorter = parent.sorter

            this.registry = false
            this.syncing = false

            this.getState = ( key ) =>
            {
                return parent.getState( key )
            }

            this.setState = ( key, state ) =>
            {
                parent.setState( key, state )
            }

            this.parent.logger.clog( 'SyncWorker::SyncCrud', 'initialized (new version)' )

            this.logger = this.parent.logger

            this.noSync = []
            this.parent.eventManager.add( 'block-sync-for-deletion', ( localId ) =>
            {
                this.noSync.push( localId )
                setTimeout( () =>
                {
                    this.f.removeFromArray( this.noSync, localId )
                }, 30000 )
            } )

            this.parent.eventManager.add( 'block-sync-for-update', ( localId ) =>
            {
                this.noSync.push( localId )
                setTimeout( () =>
                {
                    this.f.removeFromArray( this.noSync, localId )
                }, 30000 )
            } )

            this.step = 0
            this.total = 0
            this.diff = 0

            this.changes = 0

            this.queue = []
            this.updatedTypes = []
            this.mxTriggers = []
            this.remoteObjects = []
            this.allTypes = []

            this.parent.eventManager.add( 'sync-trigger-crud', () =>
            {
                this.sync()
                    .then( () =>
                    {
                        this.parent.logger.clog( 'SyncWorker::Event::sync-trigger-crud', 'synced smoothly.' )
                    } )
                    .catch( err =>
                    {
                        this.parent.logger.cerror( 'SyncWorker::Event::sync-trigger-crud', 'sync failed (for now)', err )
                    } )
            } )

            this.parent.eventManager.add( 'on-push-pinning', () =>
            {
                this.workers.personalAttributes.triggerPushPins()
            } )

            this.parent.eventManager.append( 'on-core-components-reset', () =>
            {

                this.parent.eventManager.dispatch( 'on-crud-reset' )
                this.step = 0
                this.total = 0
                this.diff = 0
                this.queue = []

                this.updatedTypes = []
                this.remoteObjects = []

                this.allTypes = []

            } )

            this.workers = {}
            this.prepare()

            SyncCrud.instance = this

        }

        return SyncCrud.instance

    }

    destruct()
    {

        this.step = 0
        this.total = 0
        this.diff = 0

        this.queue = []
        this.mxTriggers = []
        this.updatedTypes = []
        this.remoteObjects = []
        this.allTypes = []
        this.workers = {}

        this.parent.eventManager.remove( 'sync-trigger-crud' )

        delete this.parent
        delete SyncCrud.instance

    }

    prepare()
    {
        this.workers.timeDiff = new SyncCrudTimeDiff( this )
        this.workers.cleanRemote = new SyncCrudCleanRemote( this )
        this.workers.prepareSyncables = new SyncCrudPrepareSyncables( this )
        this.workers.handleQueue = new SyncCrudHandleQueue( this )
        this.workers.deletions = new SyncCrudDeletions( this )
        this.workers.keyUpdates = new SyncCrudKeyUpdates( this )
        this.workers.personalAttributes = new SyncCrudPersonalAttributes( this )
        this.workers.missingUploads = new SyncCrudMissingUploads( this )
    }

    showProgress( text, force )
    {
        if( !this.parent.silent || force )
        {
            this.parent.ui.blockerText( ( undefined === text ? '<strong>Elemente</strong> werden synchronisiert...' : text ) )
            this.parent.ui.updateProgress( this.total, this.step )
        }
    }

    triggerMx()
    {

        return new Promise( resolve =>
        {

            let trigger = 'full' !== this.parent.getSyncParameters().mode

            while( 0 < this.mxTriggers.length )
            {
                let set = this.mxTriggers.shift()
                if( trigger )
                {
                    this.parent.eventManager
                        .dispatch( 'on-mxregistry-update', set )
                }
            }

            return resolve()

        } )

    }

    triggerUpdated()
    {

        return new Promise( resolve =>
        {

            while( 0 < this.updatedTypes.length )
            {
                let type = this.updatedTypes.shift()
                this.parent.eventManager
                    .dispatch( 'on-crudsync', type )
            }

            return resolve()

        } )

    }

    singleSync( localId )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.parent.client
                .request( {
                    method  : 'objects.getObjectById',
                    id_local: localId
                } )
                .then( response =>
                {

                    return resolve( response )

                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )
    }

    /*eslint-disable*/
    /**
     * sync
     * @returns {Promise<unknown>}
     */
    sync()
    {

        return new Promise( resolve =>
        {

            if( false === this.parent.store.getters.online )
            {
                this.parent.eventManager.dispatch( 'after-first-crud-sync' )
                return resolve()
            }

            if( this.f.isOnlineSyncableState()
                && ( !this.parent.paused || this.parent.flags.is( 'demouser' ) )
                && !this.syncing )
            {

                this.syncing = true
                this.logger.clog( 'SyncWorker::SyncCrud', 'working main crudsync loop...' )

                let loop = [];

                if( this.parent.flags.is( 'demouser' ) )
                {
                    loop = [
                        'timeDiff',
                        'prepareSyncables',
                        'handleQueue'
                    ]
                }
                else
                {
                    loop = [
                        'timeDiff',
                        'cleanRemote',
                        'prepareSyncables',
                        'handleQueue',
                        'deletions',
                        'keyUpdates',
                        'personalAttributes',
                        'missingUploads'
                    ]
                }

                let promises = [],
                    updates  = 0

                for( let l in loop )
                {
                    let act = loop[ l ]
                    if( this.workers[ act ].shouldSync() )
                    {
                        this.logger.clog( 'SyncWorker::SyncCrud:sync', 'processing', act )
                        promises.push( () =>
                        {
                            return this.workers[ act ].sync()
                                                      .then( result =>
                                                      {
                                                          if( undefined !== result )
                                                          {
                                                              updates += result
                                                          }
                                                      } )
                        } )
                    }
                }

                this.f.promiseRunner( promises )
                    .then( () =>
                    {
                        this.triggerMx()
                            .then( () =>
                            {

                                this.setState( 'sync-had-updates', ( 0 < updates ) )
                                this.logger.cdebug( 'SyncWorker::SyncCrud', 'reporting ' + updates + ' updates from crudsync...' )
                                this.syncing = false
                                if( true !== this.getState( 'first-crud-sync-done' ) )
                                {
                                    this.setState( 'first-crud-sync-done', true )
                                    this.parent.eventManager.dispatch( 'onCrudSyncDone' )
                                }
                                this.parent.eventManager.dispatch( 'after-first-crud-sync' )
                                return resolve( updates )

                            } )
                    } )

            }
            else
            {
                if( !this.parent.paused )
                {
                    this.logger.cdebug( 'SyncWorker::SyncCrud', 'in unsyncable state right now', ( this.syncing ? ': in sync run right now' : '' ) + '...' )
                }
                else
                {
                    this.logger.cdebug( 'SyncWorker::SyncCrud', 'SyncWorker is paused.' )
                }
                return resolve()
            }

        } )

    }

}