<template>
    <div v-if="ready">
        <RowAdder component="PlainSelectBox"
                  :id="refName"
                  :refName="refName"
                  :valueSet="columns"
                  :value="allValues || undefined"
                  :unique="unique"
                  :dragBlocked="true"
                  :asPlainList="asPlainList"
                  :exclude="excludeList"
                  :maxRowCount="maxRowCount"
                  @update="handleUpdate"/>
    </div>
</template>

<script>
import RowAdder from "@/components/form/elements/RowAdder";

export default {
    name      : "AvailableColumnsSelector",
    components: { RowAdder },
    props     : {

        refName    : { Type: String, required: true },
        value      : { Type: String, required: false },
        self       : { Type: String, required: false },
        allValues  : { Type: Array, required: false },
        unique     : { Type: Boolean, required: false },
        asPlainList: { Type: Boolean, required: false, default: true },
        exclude    : { Type: Array, required: false, default: [] },
        index      : { Type: Number, required: true },
        maxRowCount: { Type: Number, required: false }

    },

    emits: [ 'update' ],

    data()
    {
        return {
            ready      : false,
            columns    : [],
            excludeList: [],
            setup      : {}
        }
    },

    created()
    {

        this.$core.getCoreTimer()
            .addInterval( 'active-column-selector-' + this.$props.refName, 300, () =>
            {
                this.scanColumns()
            } )

    },

    beforeUnmount()
    {
        this.$core.getCoreTimer()
            .removeInterval( 'active-column-selector-' + this.$props.refName )
    },

    mounted()
    {
        this.scanColumns()
        this.performExclude()
        this.$nextTick()
            .then( () =>
            {
                this.ready = true
            } )
    },

    watch: {
        exclude: {
            immediate: true,
            deep     : true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue && newValue !== oldValue )
                {
                    this.performExclude()
                }
            }
        }
    },

    methods: {

        performExclude()
        {
            this.excludeList = []
            for( let l in this.setup.linked )
            {
                this.excludeList.push( this.setup.linked[ l ] )
            }
        },

        scanColumns()
        {

            let idx          = 0,
                foundColumns = [],
                column       = document.querySelector( '#form-element-adder-columns-' + idx )

            try
            {

                while( null !== column )
                {
                    if( column.value !== undefined
                        && column.value.trim() !== '' )
                    {
                        if( -1 < column.value.indexOf( '|||' ) )
                        {
                            let temp   = column.value.split( '|||' ),
                                scan   = temp[ 1 ],
                                object = JSON.parse( scan )

                            foundColumns.push( object.value )
                        }
                        else
                        {
                            foundColumns.push( column.value )
                        }
                    }
                    idx++
                    column = document.querySelector( '#form-element-adder-columns-' + idx )
                }

                idx = 0
                column = document.querySelector( '#calc-label-' + idx )

                while( null !== column )
                {

                    if( column.value !== this.$props.self )
                    {

                        if( column.value !== undefined
                            && column.value.trim() !== '' )
                        {
                            foundColumns.push( column.value )
                        }

                    }

                    idx++
                    column = document.querySelector( '#calc-label-' + idx )

                }

                this.performExclude()
                this.columns = foundColumns

            }
            catch( e )
            {
                //empty by default
            }

        },

        handleUpdate( refName, type, component, call, value )
        {

            this.setup = {
                linked: []
            }

            for( let v in value )
            {
                if( undefined !== value[ v ].value
                    && 'function' === typeof value[ v ].value.trim
                    && '' !== value[ v ].value.trim() )
                {
                    this.setup.linked.push( value[ v ].value.trim() )
                }
            }

            this.performExclude()

            this.$emit( 'update', this.$props.index, 'calculatedColumnSelector', 'calculatedColumns', 'form-element-' + this.$props.refName, this.setup )

        }

    }

}
</script>