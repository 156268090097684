<template>
    <div class="padded-bottom" v-if="initialized">
        <h2>{{ $core.t( 'headline-colleague-shared-with-me' ) }}</h2>

        <TabbedContent v-if="0 < Object.keys( tabs ).length"
                       :tabs
                       :active="tab"
                       additionalClass="marge-top"
                       @switched="setTab"/>
        <template v-for="t in tabs" :key="'tabbed-'+t.id">
            <div v-if="t.id === tab">
                <div v-for="e in shared[t.id]" :key="'shareable-'+t.id+'-'+e.localId"
                     class="element-rights-switch">
                    <Touchable :localId="e.localId"
                               :itemZoom="e"
                               :viewItem="t.id"
                               :noDetailView="true"
                               :noShareIndicator="true"
                               :singleItems="true"
                               scope="own"/>
                    <div class="rights switchboard">
                        <div class="rights-switches">
                            <template v-for="( s, index ) in getSwitchesFor( t.id )"
                                      :key="'rights-switches-'+index">
                                <div class="half rights-pad">
                                    <div class="switch">
                                        <Toggle :id="'elements-'+e.localId+'-'+s.value"
                                                :refName="'elements-'+e.localId+'-'+s.value"
                                                :disabled=true
                                                :value="undefined !== values['elements-'+e.localId+'-'+s.value] ? values['elements-'+e.localId+'-'+s.value] : defaultValue( e.type, s.value )"/>
                                    </div>
                                    <div class="caption"><span
                                        :class="'strong'+( 'delete' === s.value ? ' error' : '' )">
                                        {{ $core.f().ucFirst( $core.t( s.value ) ) }}</span> <span class="comment">{{
                                            undefined !== values[ 'elements-' + e.localId + '-' + s.value ] ? '' : $core.t( 'general-right-marker' )
                                        }}</span>
                                    </div>
                                </div>
                                <div v-if="index%2 == 1" class="clearfix"></div>
                            </template>
                        </div>
                    </div>
                    <div class="spacer"></div>
                </div>
            </div>
        </template>
        <template v-if="0 === tabs.length">
            <InlineMessage :active="initialized"
                           key="'message-inline-shares"
                           classname="empty"
                           :title="$core.t( 'default-caption-empty' )"
                           :message="$core.t( 'message-empty-shared-with-me', item.firstname+' '+item.lastname )"/>
        </template>
    </div>
</template>

<script>
import Toggle               from '@/components/form/elements/Toggle'
import TabbedContent        from '@/components/layout/tabs/TabbedContent'
import MixinWatchElementKey from '@/mixins/MixinWatchElementKey'
import InlineMessage        from '@/components/messages/InlineMessage'
import Touchable            from "@/components/defaults/Touchable";

/*eslint-disable*/
export default {

    name      : 'ColleagueSharedWithMe',
    components: { Touchable, InlineMessage, TabbedContent, Toggle },
    props     : {
        item: { type: Object, required: true }
    },

    mixins: [ MixinWatchElementKey ],

    data()
    {
        return {
            updateTimer: false,
            initialized: false,
            itemClone  : false,
            values     : {},
            tabs       : [],
            shared     : {},
            tab        : false,
            setters    : [
                {
                    id      : 'classElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'groupElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'yeargroupElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'studentElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'readMeta'
                        },
                        {
                            value: 'changeMeta'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'noteElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'todoElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'markDone'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'dateElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'listElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'fill'
                        },
                        {
                            value: 'clone'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'media',
                    id      : 'mediaElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'avatarElements',
                    switches: [
                        {
                            value: 'read'
                        }
                    ]
                }
            ]
        }
    },

    created()
    {

        this.$core.getEventManager().add( 'mx-rights-refresh', () =>
        {
            this.prepare()
        } )

        this.prepare()

    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'mx-rights-refresh' )
    },

    methods: {

        prepare()
        {
            this.shared = {}
            this.values = {}
            this.tabs = []
            this.itemClone = JSON.parse( JSON.stringify( this.$props.item ) )
            this.initializeShares()
                .then( () =>
                {
                    this.initializeValues()
                    this.initialized = true
                } )
        },

        defaultValue( type, key )
        {

            return this.$core.r().getDefaultRights( this.itemClone.colleagueId, type, key )

        },

        setTab( which )
        {
            this.tab = which
        },

        getSwitchesFor( tab )
        {
            for( let s in this.setters )
            {
                if( this.setters[ s ].id === tab + 'Elements' )
                {
                    return this.setters[ s ].switches
                }
            }
        },

        isInShares( key )
        {
            for( let s in this.setters )
            {

                let setter = this.setters[ s ].id,
                    type   = setter.replace( 'Elements', '' )

                if( type === key )
                {
                    return true
                }

            }

            return false

        },

        _appendShare( object )
        {
            if( !this.$core.f().isset( this.shared[ object.type ] ) )
            {
                this.shared[ object.type ] = []
                this.tabs.push( {
                    id     : object.type,
                    caption: this.$core.getTranslation().translate( 'object-type-' + object.type + ( 'class' === object.type ? 'es' : 's' ) )
                } )
                if( !this.tab )
                {
                    this.tab = object.type
                }
            }
            object.editLocked = true
            this.shared[ object.type ].push( object )
        },

        initializeShares()
        {
            return new Promise( resolve =>
            {

                let shareables = this.$core.getBaseClassHelper().shareableQueues

                for( let q in shareables )
                {

                    let queue = shareables[ q ]

                    if( this.isInShares( queue ) )
                    {

                        let list = this.$core.getBaseClassHelper()
                                       .get( queue )
                                       .readCache( 'cache' )

                        for( let e in list )
                        {

                            let elm

                            if( list[ e ].type === 'list' )
                            {
                                elm = this.$core.getBaseClassHelper()
                                          .get( queue )
                                          .getListContainer( list[ e ].referenceKey )
                            }
                            else
                            {
                                elm = this.$core.getBaseClassHelper()
                                          .get( queue )
                                          .getById( list[ e ].localId )
                            }

                            if( undefined === elm )
                            {
                                continue
                            }

                            if( !this.$core.f().isOwn( elm )
                                && this.$core.f().ownerMatch( elm, this.$props.item ) )
                            {
                                if( this.$core.f().isset( elm.lists ) )
                                {
                                    for( let l in elm.lists )
                                    {
                                        let listElm = elm.lists[ l ]
                                        this._appendShare( listElm )
                                    }
                                }
                                else
                                {
                                    this._appendShare( elm )
                                }

                            }
                        }

                    }

                }

                return resolve()

            } )

        },

        initializeValues()
        {

            for( let t in this.shared )
            {
                for( let e in this.shared[ t ] )
                {

                    let elm = this.shared[ t ][ e ]
                    for( let tab in this.setters )
                    {
                        let type = this.setters[ tab ].id.replace( 'Elements', '' )
                        if( elm.type === type )
                        {
                            for( let r in this.setters[ tab ].switches )
                            {
                                let right = this.setters[ tab ].switches[ r ].value
                                this.values[ 'elements-' + elm.localId + '-' + right ] = this.$core.r().isAllowed( elm, right )

                            }
                        }
                    }
                }
            }

        }

    }

}
</script>