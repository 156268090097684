<script>
export default {
    name: "MixinHasStudentCount",

    data()
    {
        return {
            initialized: false,
            students   : {}
        }
    },

    computed: {
        studentCount()
        {

            if( -1 < this.$props.fakeCount )
            {
                return this.$props.fakeCount
            }

            let count = 0
            for( let s in this.$props.item.students )
            {
                if( true === this.students[ this.$props.item.students[ s ] ] )
                {
                    count++
                }
            }
            return count
        }
    },

    created()
    {

        let scope = this.$props.item.archived ? 'archive' : 'cache'

        this.$core.getBaseClassHelper()
            .get( 'student' )
            .getPreparedCache( scope )
            .then( list =>
            {

                /* eslint-disable-next-line no-unused-vars */
                for( const [ localId, student ] of list )
                {
                    this.students[ localId ] = true
                }

                this.initialized = true

            } )
    }

}
</script>