<template>
    <div v-if="prepared" class="competence-selector">
        <template v-if="0 < Array.isArray( tree.subs ) && 0 < tree.subs.length">
            <template v-for="item in tree.subs"
                      :key="'checkbox-'+id+'-'+item.localId">
                <template v-if="Array.isArray( item.subs ) && 0 < item.subs.length">
                    <div class="head-level-1">
                        <strong>{{ item.label }}</strong>
                    </div>
                    <template v-for="sub in item.subs"
                              :key="id+'-'+sub.localId">
                        <template v-if="Array.isArray( sub.subs ) && 0 < sub.subs.length">
                            <div class="sub-level-1">
                                <div class="head-level-2">
                                    <strong>{{ sub.label }}</strong>
                                </div>
                                <div class="sub-level-2">
                                    <template v-for="subsub in sub.subs"
                                              :key="id+'-'+subsub.localId">
                                        <template v-if="Array.isArray( subsub.subs)
                                                        && 0 < subsub.subs.length">
                                            -- def fail 2 --
                                        </template>
                                        <template v-else>
                                            <input type="checkbox"
                                                   :title="getTitle( subsub.localId )"
                                                   :class="getClass( subsub.localId )"
                                                   :id="id+'-'+subsub.localId"
                                                   :disabled="isDisabled( subsub.localId )"
                                                   :checked="-1 < checked.indexOf( subsub.localId ) || isDisabled( subsub.localId )"
                                                   class="compselect-input"
                                                   @change="handleChange( $event, subsub.localId )"/>
                                            <label
                                                :for="id+'-'+subsub.localId"
                                                class="compselect-label">{{ subsub.label }}</label>
                                            <div class="clearfix"></div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="sub-level-2">
                                <input type="checkbox"
                                       :id="id+'-'+sub.localId"
                                       :title="getTitle( sub.localId )"
                                       :class="getClass( sub.localId  )"
                                       :checked="-1 < checked.indexOf( sub.localId ) || isDisabled( sub.localId )"
                                       :disabled="isDisabled( sub.localId )"
                                       class="compselect-input"
                                       @change="handleChange( $event, sub.localId )"/>
                                <label
                                    :for="id+'-'+sub.localId"
                                    class="compselect-label">{{ sub.label }}</label>
                                <div class="clearfix"></div>
                            </div>
                        </template>
                    </template>
                </template>
                <template v-else>
                    <div class="sub-level-1">
                        <input type="checkbox"
                               :id="id+'-'+item.localId"
                               :title="getTitle( item.localId )"
                               :class="getClass( item.localId )"
                               :checked="-1 < checked.indexOf( item.localId ) || isDisabled( item.localId )"
                               :disabled="isDisabled( item.localId )"
                               class="compselect-input"
                               @change="handleChange( $event, item.localId )"/>
                        <label
                            :for="id+'-'+item.localId"
                            class="compselect-label">{{ item.label }}</label>
                        <div class="clearfix"></div>
                    </div>
                </template>
            </template>
        </template>
        <template v-else>
            <span class="comment">keine Kompetenzen definiert</span>
        </template>
    </div>
    <div v-else>
        <Placeholder type="select"/>
    </div>
</template>

<script>
import MixinCachePreheater from "@/mixins/MixinCachePreheater.vue";
import Placeholder         from "@/components/form/elements/Placeholder.vue";

export default {

    name      : "CompetenceSelectorListElement",
    components: { Placeholder },

    mixins: [ MixinCachePreheater ],

    props: {
        refName          : { type: String, required: true },
        id               : { type: String, required: true },
        value            : { type: Array || String, required: false },
        placeholder      : { type: String, required: false },
        disabled         : { type: Boolean, required: false },
        display          : { type: String, required: false },
        cssClass         : { type: String, required: false },
        referenceObjectId: { type: String, required: false }
    },

    emits: [ 'focussed', 'blurred', 'update' ],

    data()
    {
        return {
            neededCaches       : [ 'competence', 'competenceCategory' ],
            referenceId        : false,
            subReferenceId     : false,
            referenceCompetence: false,
            tree               : [],
            selection          : false,
            hasSubs            : new Map(),
            prepared           : false,
            checked            : [],
            disabledElements   : [],
            studentLocalId     : false,
            referenceTimestamp : false
        }
    },

    created()
    {

        this.referenceTimestamp = this.$core.getBaseClassHelper().get( 'list' ).findListById( this.$props.referenceObjectId ).timestamp
        this.studentLocalId = this.$props.id.split( /___/ ).shift()
        this.selection = '' + this.$props.value
        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepare()
            } )
    },

    methods: {

        getClass( subId )
        {
            if(  this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete
                 && this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete > this.referenceTimestamp )
            {
                return 'future-check'
            }
            if(  this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete
                 && this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete < this.referenceTimestamp )
            {
                return 'past-check'
            }
            return ''
        },

        getTitle( subId )
        {
            if(  this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete
                 && this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete !== this.referenceTimestamp )
            {
                return 'erreicht am '+this.$core.getFriendlyTimestamp().formattedDate( this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete )
            }
            return ''
        },

        isDisabled( subId )
        {
            return this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete
                   && this.calculation[ this.studentLocalId ][ subId ]._listComplete !== this.$props.referenceObjectId
        },

        /*eslint-disable*/
        identifier( item, label )
        {

            let localId    = item.localId,
                prefix     = item.prefix || '',
                suffix     = item.suffix || '',
                identifier = prefix + ( localId || '' ) + ( '' !== suffix ? suffix : '' )

            return this.$core.getSanitizers().cleanId( identifier ) + '-' + this.$core.getSanitizers().cleanId( label )

        },

        getReferenceId()
        {

            let temp         = this.$props.id.split( /___/g ),
                reference    = temp[ 1 ],
                tempId       = reference.split( /--CLN--/g ),
                referenceId  = tempId[ 1 ],
                subReference = tempId[ 2 ] || undefined

            this.referenceId = referenceId
            this.subReferenceId = subReference

        },

        /*eslint-disable*/
        prepare()
        {


            this.getReferenceId()

            this.$core.getCompetenceCategoryTree()
                .awaitReadiness()
                .then( () =>
                {

                    this.tree = this.$core.getCompetenceCategoryTree().tree.byRoot[ this.referenceId ]
                    this.checked = this.$props.value || []
                    this.calculation = this.$core
                                           .getCompetenceCategoryCalculator()
                                           .calculateResults( undefined, this.tree, false, this.referenceId ).calculation.byStudent
                    this.prepared = true

                } )

        },

        handleChange( event, id )
        {

            if( 'on' === event.target.value
                && event.target.checked
                && -1 === this.checked.indexOf( id ) )
            {
                this.checked.push( id )
            }
            else
            {
                this.$core.f().removeFromArray( this.checked, id )
            }

            this.handleUpdate()

        },

        handleUpdate()
        {

            this.$emit( 'update', this.$props.refName, 'competenceSelector', 'update', undefined, this.checked )

        }

    }

}
</script>

<style scoped lang="less">



</style>