<template>
    <div class="listeditor-inner padding-20"
         :key="'listEditorSelector-'+ownId">
        <div class="listeditor-inner-head">

            <h2>{{ $core.t( 'list-open-another-list' ) }}</h2>

            <ElementCounter :visibleCounter="elementCounter.visible"
                            :invisibleCounter="elementCounter.invisible"
                            :filterCounter="parseInt( ''+filtered.length )"
                            :viewIsFiltered
                            position="top"/>

            <HeadFunctions :has="headFunctions"
                           objectType="list"
                           :availableFilters="[ 'LabelsFilter', 'ClassesFilter', 'ColorFilter' ]"
                           :isArchivedScope="false"
                           :isOwnScope="true"
                           :hiddenVisible="hiddenVisible"
                           :key="tabKey"
                           :selectMode="false"
                           :selectCount="0"
                           viewItem="List"
                           @toggleHiddenVisible="$emit( 'toggleHiddenVisible' )"
                           @click="$emit( 'click', $event )"
                           @print="$emit( 'print' )"
                           @excel="$emit( 'excel' )"
                           @qrkey="$emit( 'qrkey' )"
                           @resetSelection="$core.f.skip"
                           @search="$core.f.skip"
                           @filter="setFilters"/>

            <TabbedContent :tabs="tabs"
                           :active="tab"
                           @switched="handleTabSwitch"/>

        </div>

        <div class="listeditor-inner-itemlist">

            <DefaultNestedItemList v-if="itemListPrepared"
                                   :itemList="elementList"
                                   :viewKey="controlKey"
                                   viewItem="list"
                                   :filtered
                                   :filterKey
                                   :galleryMode="false"
                                   :hiddenVisible="hiddenVisible"
                                   :viewIsFiltered
                                   :noShareIndicator="noShareIndicator"
                                   :selectMode="selectMode"
                                   :scope="tab"
                                   :inListEditor="true"
                                   @findTab="$emit( 'findTab', $event )"
                                   @clicked="$emit( 'open', $event )"/>

        </div>
    </div>
</template>
<script>
/* eslint-disable */
import TabbedContent         from "@/components/layout/tabs/TabbedContent.vue";
import HeadFunctions         from "@/components/layout/HeadFunctions.vue";
import ElementCounter        from "@/components/elements/defaults/ElementCounter.vue";
import MixinFilterableView   from "@/components/defaults/defaultListPage/MixinFilterableView.vue";
import MixinEvents           from "@/mixins/MixinEvents.vue";
import DefaultNestedItemList from "@/components/defaults/DefaultNestedItemList.vue";

export default {

    name      : "ListEditorSelector",
    components: { DefaultNestedItemList, ElementCounter, HeadFunctions, TabbedContent },
    mixins    : [ MixinFilterableView, MixinEvents ],
    props     : {
        reference       : { Type: Object, required: true },
        archived        : { Type: Boolean, required: true },
        hiddenVisible   : { Type: Boolean, required: true },
        noShareIndicator: { Type: Boolean, required: true },
        selectMode      : { Type: Boolean, required: true },
        headFunctions   : { Type: Object, required: true },
    },

    data()
    {
        return {
            scope                    : this.$route.name,
            ownId                    : this.$core.getUuid().generate(),
            tabKey                   : this.$core.getUuid().generate(),
            controlKey               : this.$core.getUuid().generate(),
            itemListPrepared         : false,
            tabs                     : [],
            tab                      : false,
            selection                : false,
            referenceSetup           : false,
            studentFileShowOnlyFilled: this.$core.settings().getSetting( 'studentFileShowOnlyFilled' ) === true,
            studentInGroup           : {},
            studentInYeargroup       : {},
            elementList              : [],
            elementCounter           : {
                visible  : 0,
                invisible: 0
            },
            objectFilter             : {},
            prepared                 : {}
        }
    },

    watch: {
        tab: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( false !== newValue
                    && newValue !== oldValue )
                {
                    this.prepare()
                }
            }
        }
    },

    emits: [ 'open', 'filter', 'qrkey', 'print', 'excel', 'click', 'findTab', 'setReference', 'toggleHiddenVisible' ],

    created()
    {
        this.addEvent( 'on-add-list-to-editor', ( list ) =>
        {
            this.$emit( 'open', list.localId )
        } )
        this.prepareSelector()
    },

    methods: {

        handleTabSwitch( tab )
        {
            this.tab = tab
            this.tabKey = this.$core.getUuid().generate()
            this.controlKey = this.$core.getUuid().generate()
        },

        prepare()
        {

            this.referenceId = '' + ( false !== this.$props.reference ? this.$props.reference.localId : false )
            this.setupFilter( this.$props.reference )
            this.setup()
            this.prepareElementList()

        },

        setup()
        {

            this.referenceSetup = {
                localId    : this.$props.reference.localId,
                classId    : this.$props.reference.classId || undefined,
                groupId    : this.$props.reference.inGroups || [],
                yeargroupId: this.$props.reference.yeargroupId || undefined
            }

        },

        prepareSelector()
        {

            switch( this.scope )
            {
                case 'office':
                    this.tabs = [
                        {
                            id         : 'own',
                            caption    : 'Listen & Klassenarbeiten',
                            elementType: 'list',
                            filter     : {
                                archived: this.$props.archived
                            }
                        }
                    ]
                    this.tab = 'own'
                    break
                case 'students':
                    this.tabs = [
                        {
                            id            : 'file',
                            caption       : 'pädagogische Akte',
                            objectType    : 'lists',
                            viewItem      : 'List',
                            elementType   : 'list',
                            filterOverride: 'office'
                        },
                        {
                            id            : 'lists_only_test',
                            caption       : 'Benotung',
                            objectType    : 'lists',
                            viewItem      : 'List',
                            elementType   : 'list',
                            filterOverride: 'office'
                        }
                    ]
                    this.tab = 'file'
                    break
                case 'classes':
                    this.tabs = [
                        {
                            id            : 'lists_not_test',
                            caption       : 'Büro',
                            objectType    : 'lists',
                            elementType   : 'list',
                            viewItem      : 'List',
                            filterOverride: 'office'
                        },
                        {
                            id            : 'lists_only_test',
                            caption       : 'Benotung',
                            objectType    : 'lists',
                            elementType   : 'list',
                            viewItem      : 'List',
                            filterOverride: 'office'
                        }
                    ]
                    this.tab = 'lists_not_test'
                    break
                case 'groups':
                case 'yeargroups':
                    this.tabs = [
                        {
                            id            : 'lists',
                            caption       : 'Büro',
                            objectType    : 'lists',
                            elementType   : 'list',
                            viewItem      : 'List',
                            filterOverride: 'office'
                        }
                    ]
                    this.tab = 'lists'
                    break
                default:
                    this.tabs = [
                        {
                            id     : 'own',
                            caption: this.scope
                        }
                    ]
                    break
            }
        },

        setupFilter( object )
        {

            if( this.$core.f().valid( this.tab )
                && false !== object )
            {

                let objectFilter = this.tab.split( '_' )
                this.detailObjectType = objectFilter[ 0 ].type

                if( 1 === objectFilter.length )
                {
                    this.objectList = {
                        type  : objectFilter[ 0 ],
                        filter: false,
                        except: [],
                        only  : []
                    }
                }
                else
                {
                    if( 3 === objectFilter.length )
                    {
                        this.objectList = {
                            type  : objectFilter[ 0 ],
                            filter: objectFilter[ 1 ],
                            except: [],
                            only  : []
                        }
                        if( objectFilter[ 1 ] === 'only' )
                        {
                            this.objectList.only.push( objectFilter[ 2 ] )
                        }
                        else
                        {
                            this.objectList.except.push( objectFilter[ 2 ] )
                        }
                    }
                }

            }

        },

        setupReferences()
        {

            return new Promise( resolve =>
            {

                if( false !== this.$props.reference
                    && undefined !== this.$props.reference )
                {

                    switch( this.$props.reference.type )
                    {
                        case 'student':

                            if( this.$core.f().isset( this.$props.reference.classId ) )
                            {
                                this.referenceClassId = this.$props.reference.classId
                            }
                            if( undefined !== this.$props.reference.inGroups )
                            {
                                for( let g in this.$props.reference.inGroups )
                                {
                                    this.referenceGroupId.push( this.$props.reference.inGroups[ g ].groupId )
                                }
                            }
                            if( undefined !== this.$props.reference.yeargroupId )
                            {
                                this.referenceYeargroupId.push( this.$props.reference.yeargroupId )
                            }

                            break
                        case 'class':
                            this.referenceClassId = this.referenceId
                            break
                        case 'group':
                            this.studentInGroup[ this.$props.reference.localId ] = this.$props.reference.students
                            this.referenceGroupId.push( this.referenceId )
                            break
                        case 'yeargroup':
                            this.studentInYeargroup[ this.$props.reference.localId ] = this.$props.reference.students
                            this.referenceYeargroupId.push( this.referenceId )
                            break

                    }

                }

                return resolve()

            } )

        },

        hasContentsForReference( object )
        {

            let referenceId = this.$props.reference.localId

            for( let l in object.lists )
            {
                let list = object.lists[ l ]
                if( this.$core.f().isset( list.values ) )
                {
                    for( const [ key, value ] of Object.entries( list.values ) )
                    {
                        if( key.substring( 0, referenceId.length ) === referenceId )
                        {
                            if( this.$core.f().isset( value ) )
                            {
                                return true
                            }
                        }
                    }
                }
            }

            return false
        },

        checkSelection()
        {
            for( let t in this.tabs )
            {
                if( this.tabs[ t ].id === this.tab )
                {
                    return this.tabs[ t ]
                }
            }
        },

        listRangeMatch( object )
        {

            if( this.$props.reference.archived !== true )
            {
                return true
            }

            let temp = this.$props.reference.archiveKey.split( /-/g )
            temp.shift()
            let timestamp = parseInt( temp[ 0 ] )

            if( undefined !== object.tsmpEnd )
            {
                if( parseInt( object.tsmpEnd ) < timestamp
                    && parseInt( object.tsmpStart ) > parseInt( this.$props.reference.timestamp ) )
                {
                    return true
                }
                else
                {
                    return false
                }
            }
            else
            {
                if( parseInt( object.timestamp ) < timestamp
                    && parseInt( object.timestamp ) > parseInt( this.$props.reference.timestamp ) )
                {
                    return true
                }
                return false
            }

        },

        filterMatch( object )
        {

            /* filter only lists with existing entries for student */
            if( 'student' === this.$props.reference.type
                && this.studentFileShowOnlyFilled
                && !this.hasContentsForReference( object ) )
            {
                return false
            }

            if( undefined !== this.objectList )
            {
                /* list type not in except list */
                if( 'not' === this.objectList.filter
                    && this.$core.f().isObject( this.objectList.except )
                    && -1 < this.objectList.except.indexOf( object.listType ) )
                {
                    return false
                }

                /* list type not in only list AND list has no scorebox */
                if( 'only' === this.objectList.filter
                    && this.$core.f().isObject( this.objectList.only )
                    && ( -1 === this.objectList.only.indexOf( object.listType ) && !this.hasScoreBox( object ) ) )
                {
                    return false
                }
            }

            /* list studentlist contains reference class (or all) or reference group id */
            if( this.$core.f().isObject( object.lists )
                && this.$core.f().isObject( object.lists[ 0 ] )
                && this.$core.f().isObject( object.lists[ 0 ].columns )
                && ( ( 'student' === this.$props.reference.type && ( object.lists[ 0 ].columns[ 0 ].filterBy === 'all' && this.listRangeMatch( object ) ) )
                     || object.lists[ 0 ].columns[ 0 ].filterBy === this.referenceClassId
                     || ( this.$core.f().isObject( this.referenceGroupId ) && -1 < this.referenceGroupId.indexOf( object.lists[ 0 ].columns[ 0 ].filterBy ) )
                     || ( this.$core.f().isObject( this.referenceYeargroupId ) && -1 < this.referenceYeargroupId.indexOf( object.lists[ 0 ].columns[ 0 ].filterBy ) ) ) )
            {
                return true
            }

            return false

        },

        hasScoreBox( object )
        {
            if( undefined === object.lists
                || undefined === object.lists[ 0 ] )
            {
                return false
            }
            for( let c in object.lists[ 0 ].columns )
            {
                let col = object.lists[ 0 ].columns[ c ]
                if( col.type.substr( 0, 8 ) === 'scoreBox' )
                {
                    return true
                }
            }
        },

        _setCounter( list )
        {
            if( list.hidden !== true )
            {
                this.elementCounter.visible++
            }
            else
            {
                this.elementCounter.invisible++
            }
        },

        _getSortMap( sortKey, mapKey )
        {

            mapKey = mapKey || 'cache'

            return this.$core.getBaseClassHelper()
                       .get( sortKey )
                       .registry
                       .sortMap.get( mapKey )

        },

        prepareElementList()
        {

            let setup   = this.checkSelection(),
                sortMap = this._getSortMap( setup.elementType, this.$props.archived ? 'archive' : 'cache' )
            /*                baseClass = this.$core
                                            .getBaseClassHelper()
                                            .get( setup.elementType )

                        baseClass.listAll()
                                 .then( list =>
                                 {
            */

            let filtered = [],
                archived = this.$props.reference ? this.$props.reference.archived : this.$props.archived

            this.elementCounter.visible = 0
            this.elementCounter.invisible = 0

            for( let s in sortMap )
            {

                let isOwn    = this.$core.getBaseClassHelper()
                                   .get( setup.elementType ).registry.isOwn.get( sortMap[ s ] ) === true,
                    isHidden = this.$core.getBaseClassHelper().isElementHidden( sortMap[ s ], setup.elementType )

                filtered.push( sortMap[ s ] )
                this.elementCounter.visible += ( !isHidden ? 1 : 0 )
                this.elementCounter.invisible += ( isHidden ? 1 : 0 )

            }

            this.elementList = filtered
            this.itemListPrepared = true
            /*
                        for( let l in list )
                        {

                            if( undefined !== setup.filter )
                            {
                                for( let f in setup.filter )
                                {
                                    if( list[ l ].archived === archived
                                        && list[ l ][ f ] === setup.filter[ f ] )
                                    {
                                        this._setCounter( list[ l ] )
                                        filtered.push( list[ l ] )
                                    }
                                }
                            }
                            else
                            {
                                if( list[ l ].archived === archived
                                    && this.filterMatch( list[ l ] ) )
                                {
                                    this._setCounter( list[ l ] )
                                    filtered.push( list[ l ] )
                                }
                            }
                        }

                        let sortingDirections = this.$core.settings().getSetting( 'sortingDirections' )

                        this.elementList = new WeakRef( filtered ).deref()
                        this.prepared[ this.tab ] = this.elementList

                        this.$core.getSorter()
                            .multiSortObjects( this.elementList, sortingDirections[ setup.filterOverride ? setup.filterOverride : this.scope ] )

                        this.itemListPrepared = true

                        filtered = null
                        //list = null

            //                     } )*/

        }

    }

}
</script>