<template>
    <transition appear :name="$core.settings().getTransition( 'next' )" mode="in-out">
        <div :class="'list-filters'+( editormode ? ' editor' : '' )"
             :key="'listFilters-'+ownId"
             :id="'listFilters-'+ownId">
            <div class="list-filters-filter no-float">
                <div class="list-filters-select" id="classes-filter-inner">
                    <select class="list-filter" @change="filter">
                        <option value="">{{ title || 'Klassen & Gruppen' }}</option>
                        <optgroup label="Klassen">
                            <option v-for="classItem in classes"
                                    v-bind:key="'key_option_'+classItem.localId"
                                    :value="classItem.localId">{{ classItem.className || classItem.classname }}
                            </option>
                        </optgroup>
                        <optgroup label="Gruppen">
                            <option v-for="groupItem in groups"
                                    v-bind:key="'key_option_'+groupItem.localId"
                                    :value="groupItem.localId">{{ groupItem.groupName || classItem.groupname }}
                            </option>
                        </optgroup>
                        <optgroup label="Jahrgänge" v-if="$core.getLicense().isAllowed( 'page_yeargroups' )">
                            <option v-for="groupItem in yeargroups"
                                    v-bind:key="'key_option_'+groupItem.localId"
                                    :value="groupItem.localId">{{ groupItem.yeargroupname || classItem.yeargroupname }}
                            </option>
                        </optgroup>
                    </select>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </transition>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {

    name  : 'ListFilter',
    mixins: [ MixinCachePreheater ],
    props : {
        title     : { type: String, required: false },
        archived  : { type: Boolean, require: false, default: false },
        own       : { type: Boolean, require: false, default: false },
        editormode: { type: Boolean, require: false, default: false }
    },

    emits: [ 'filter' ],

    data()
    {
        return {
            ownId       : this.$core.getUuid().generate(),
            neededCaches: [ 'class', 'group', 'yeargroup' ],
            initialized : false,
            classes     : [],
            groups      : [],
            yeargroups  : []
        }
    },

    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.initialize()
            } )
    },

    methods: {

        filterByOwner( element, own )
        {
            let isOwn = this.$core.f().isOwn( element )
            return own ? isOwn : true
        },

        initialize()
        {

            this.initialized = false

            let isArchived = this.$props.archived === true,
                own        = this.$props.own === undefined ? true : this.$props.own,
                classes    = this.$core.getBaseClassHelper()
                                 .get( 'class' )
                                 .getCache( 'cache' ),
                groups     = this.$core.getBaseClassHelper()
                                 .get( 'group' )
                                 .getCache( 'cache' ),
                yeargroups = this.$core.getBaseClassHelper()
                                 .get( 'yeargroup' )
                                 .getCache( 'cache' )

            /*eslint-disable*/
            for( const [ c, classItem ] of classes )
            {
                if( isArchived === classItem.archived
                    && this.filterByOwner( classItem, own ) )
                {
                    this.classes.push( {
                        localId  : classItem.localId,
                        className: classItem.classname + ( isArchived ? ' ' + this.$core.getReformatter().timeForArchiveKey( classItem ) : '' )
                    } )
                }
            }
            this.$core.getSorter().sortObjects( this.classes, 'className' )

            for( const [ g, group ] of groups )
            {
                if( isArchived === group.archived
                    && this.filterByOwner( group, own ) )
                {
                    this.groups.push( {
                        localId  : group.localId,
                        groupName: group.groupname
                    } )
                }
            }
            this.$core.getSorter().sortObjects( this.groups, 'groupName' )

            for( const [ y, yeargroup ] of yeargroups )
            {
                if( isArchived === yeargroup.archived
                    && this.filterByOwner( yeargroup, own ) )
                {
                    this.yeargroups.push( {
                        localId      : yeargroup.localId,
                        yeargroupname: yeargroup.yeargroupname
                    } )
                }
            }
            this.$core.getSorter().sortObjects( this.yeargroups, 'yeargroupname' )

            /*eslint-enable*/
            this.initialized = true

        },

        filter( event )
        {

            this.$core.s().count( 'clicked-list-filter' )
            let value = event.target.value
            this.emitValue( value )

        },

        emitValue( value )
        {
            if( value === '' )
            {
                this.$emit( 'filter', 'classes', false )
                return
            }

            for( let c in this.classes )
            {
                if( this.classes[ c ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ { 'attr': 'classId', value: this.classes[ c ].localId } ] )
                }
            }

            for( let g in this.groups )
            {
                if( this.groups[ g ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ { 'attr': 'groupId', value: this.groups[ g ].localId } ] )
                }
            }

            for( let g in this.yeargroups )
            {
                if( this.yeargroups[ g ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ {
                        'attr': 'yeargroupId',
                        value : this.yeargroups[ g ].localId
                    } ] )
                }
            }
        }

    }

}
</script>