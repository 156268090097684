<template>
    <div v-if="initialized">
        <TabbedContent :tabs="tabs" :active="tab" @switched="handleTabSwitch"/>
    </div>
</template>

<script>
import TabbedContent from "@/components/layout/tabs/TabbedContent";

export default {

    name      : 'ListSetupSelector',
    components: { TabbedContent },
    props     : {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        styleUpdate: { Type: Boolean, required: false },
        skipInit   : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'formUpdate' ],

    created()
    {

        let tabs = this.tabs,
            direction = this.$core.settings().getSetting( 'listSetupTemplatesFirst' ) === true ? 'descending' : 'ascending'

        this.$core.getSorter().sortObjects( tabs, 'id', direction )

        this.tabs = tabs
        this.tab = window.listSetupTab || tabs[ 0 ].id

    },

    mounted()
    {
        this.handleTabSwitch( this.tab )
        window.listSetupTab = this.tab
    },

    beforeUnmount()
    {
        setTimeout( () => {
            delete window.listSetupTab
        }, 1000 )
    },

    data()
    {
        return {
            initialized: true,
            tabs       : [
                {
                    id     : 'template',
                    caption: this.$core.t( 'list-setup-use-template' )
                },
                {
                    id     : 'freestyle',
                    caption: this.$core.t( 'list-setup-use-freestyle' )
                }
            ],
            tab        : 'template',
            first      : true,
        }
    },

    methods: {
        handleTabSwitch( tab )
        {

            window.listSetupTab = tab

            if( !this.first
                && tab === 'freestyle' )
            {
                this.$core.getEventManager().dispatch( 'on-form-reset' )
            }

            this.tab = tab
            this.$core.getEventManager()
                .dispatchIndexed( 'on-list-setup-change', this.tab )

            if( !this.first )
            {
                this.$core.getEventManager().dispatch( 'formUseTemplate', false )
            }

            this.first = false

        }
    }

}
</script>