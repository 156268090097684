export default class TemplateClass
{

    constructor( core )
    {

        if( !TemplateClass.instance )
        {
    
            this.ready = false
    
            this.logger = core.getLogger()
            this.db = core.getDatabase()
            this.eventManager = core.getEventManager()
            this.sanitizers = core.getSanitizers()
            this.store = core.getStore()
            this.sort = core.getSorter()
            this.uuid = core.getUuid()

            this.baseClassHelper = core.getBaseClassHelper()
            this.initTemplates()
    
            this.eventManager.add( 'templates-refreshed', () =>
            {
                this.handleTemplateRefresh()
            } )
    
            TemplateClass.instance = this
    
        }
        
        return TemplateClass.instance

    }

    destruct()
    {
        delete TemplateClass.instance
    }
    
    readCustomerTemplates( templates )
    {
        
        return new Promise( resolve => {

            this.baseClassHelper
                .get( 'template' )
                .getPreparedCache( 'cache' )
                .then( tpl => {
    
                    let count = 0
                    /* eslint-disable-next-line no-unused-vars */
                    for( const[ t, template ] of tpl )
                    {
                        count++
                        let templateItem = template.flatDefinition.item
                        templates[ templateItem.type+'_'+templateItem.identifier ] = templateItem
                    }
    
                    return resolve( count )
    
                })
                .catch( () => {
                    return resolve( 0 )
                })
            
        })
        
    }
    
    initTemplates()
    {

        let templates = {}

        this.db
            .readAllObjects( 'templates' )
            .then( result =>
            {

                let count = 0

                for( let r in result )
                {

                    let tpl = result[ r ].item
                    if( undefined === tpl.type )
                    {
                        tpl.type = 'vendorPreset'
                    }

                    let templateId = result[ r ].key
                    templates[ templateId ] = tpl
                    
                    count++

                }

                this.readCustomerTemplates( templates )
                    .then( counter => {

                        count+= counter
                        this.templates = templates

                        this.ready = true
                        if( this.eventManager.isRegistered( 'on-templates-loaded' ) )
                        {
                            this.eventManager.dispatchAndRemove( 'on-templates-loaded' )
                        }

                        this.logger.clog( 'TemplateClass:initTemplates', count + ' templates loaded' )

                    })

            } )

    }

    handleTemplateRefresh()
    {

        if( this.refreshTimer )
        {
            clearTimeout( this.refreshTimer )
        }

        this.refreshTimer = setTimeout( () =>
        {

            this.logger.clog( 'TemplateClass:handleTemplateRefresh', 'triggered template refresh...' )
            this.initTemplates()

        }, 500 )

    }

    load( id )
    {

        let idTemp = id.split( '_' )
        idTemp.pop()
        id = idTemp.join( '_' )

        return new Promise( ( resolve, reject ) =>
        {

            if( undefined !== this.templates[ id ] )
            {
                return resolve( this.templates[ id ] )
            }
            return reject( 'ERROR_NOT_FOUND' )

        } )
    }

    write( list, type )
    {

        let columns = []
        let todo = JSON.parse( JSON.stringify( list.columns ) )

        for( let c in todo )
        {
            let col = todo[ c ]
            let column = ''
            let columnType = false
            switch( list.listType )
            {
                case 'checklist':
                    column = col
                    columnType = 'checkbox'
                    break
                case 'ratinglist':
                    column = col
                    columnType = 'rateselector'
                    break
                default:
                    if( Array.isArray( col ) )
                    {
                        columnType = col.shift()
                        column = col.shift()
                    }
                    break
            }

            columns.push( {
                type   : columnType,
                id     : c + '_' + this.sanitizers.cleanId( column ),
                caption: column
            } )

        }

        let tpl = {
            id              : this.uuid.generate(),
            identifier      : list.listname,
            originalListType: list.listType,
            listType        : list.listType,
            columns         : columns,
            type            : type,
            author          : this.store.getters.idUser,
            updateTimestamp : Date.now()
        }

        this.db.write( 'templates', tpl.id, tpl )
            .then( () =>
            {
                this.logger.clog( 'TemplateClass:store', 'stored template ' + tpl.id + ' in database' )
                this.initTemplates()
            } )

    }

}