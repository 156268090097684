<template>
    <div class="list-functions" :key="'listFunctions-'+item.localId+'-'+lastKey" v-if="true === ready">
        <Button :type="open ? 'list-closer' : 'list-opener'" addClass="list-function" @clicked="$emit( 'toggleopen' )"/>
        <Button v-if="$core.getLicense().isUnrestricted( 'listFunctions', 'listForToday' )
                && listForToday" type="clone-for-today" addClass="list-function"
                @clicked="$emit( 'cloneForToday' )"/>
        <Button v-if="$core.getLicense().isUnrestricted( 'listFunctions', 'showTodaysList' )
                && showTodaysList" type="show-for-today" addClass="list-function"
                @clicked="$emit( 'showForToday' )"/>
        <template v-if="!nested">
            <template v-if="'test' === item.listType
                  && $core.getLicense().isUnrestricted( 'listFunctions', 'toggleListMode' )">
                <Button v-if="'scores' !== mode" type="scores" addClass="list-function"
                        @clicked="$emit( 'showScores', 'scores' )"/>
                <Button v-if="'summary' !== mode" type="averages" addClass="list-function"
                        @clicked="$emit( 'showScores', 'summary' )"/>
            </template>
            <template v-if="'test' !== item.listType
                  && $core.getLicense().isUnrestricted( 'listFunctions', 'toggleListMode' )">
                <template v-if="!isStudentFilter">
                    <Button v-if="'details' === mode" type="vertical-on" addClass="list-function"
                            @clicked="$emit( 'showScores', 'vertical' )"/>
                    <Button v-if="'summary' === mode" type="sums" addClass="list-function"
                            @clicked="$emit( 'showScores', 'details' )"/>
                    <Button v-if="'vertical' === mode" type="summary" addClass="list-function"
                            @clicked="$emit( 'showScores', 'summary' )"/>
                </template>
                <template v-else>
                    <Button v-if="'details' === mode" type="vertical-on" addClass="list-function"
                            @clicked="$emit( 'showScores', 'vertical' )"/>
                    <Button v-if="'vertical' === mode" type="summary" addClass="list-function"
                            @clicked="$emit( 'showScores', 'details' )"/>
                </template>
            </template>
        </template>
        <Button v-if="showCalendar
                && $core.getLicense().isUnrestricted( 'listFunctions', 'showCalendar' )"
                type="calendar" addClass="list-function"
                @clicked="handleShowCalendar()"/>
        <template v-if="!nested">
            <Button v-if="showPrint
                && $core.getLicense().isUnrestricted( 'listFunctions', 'print' )"
                    type="print" addClass="list-function"
                    @clicked="$emit( 'print' )"/>
            <Button v-if="showPrint
                && $core.getLicense().isUnrestricted( 'listFunctions', 'excel' )
                && 0 === $store.getters.isStudent" type="excel" addClass="list-function"
                    @clicked="$emit( 'excel' )"/>
            <Button
                v-if="open && showFilters && 0 === $store.getters.isStudent && $core.getLicense().isUnrestricted( 'listFunctions', 'filter' )"
                type="search" addClass="list-function"
                @clicked="$emit( 'toggleListFilter' )"/>
        </template>
    </div>
    <div :key="'listFunctions-SyncMarker-'+ownId"
         class="list-functions" v-else>
        <Button v-for="i in [1,2,3]"
                :key="'shadow-'+i"
                type="shadow"
                addClass="list-function synchronizing"/>
    </div>
</template>

<script>
import Button      from '@/components/elements/defaults/Button'
import MixinEvents from "@/mixins/MixinEvents.vue";

export default {

    name      : 'ListFunctions',
    components: { Button },
    mixins    : [ MixinEvents ],

    props: {
        open       : { Type: Boolean, required: true },
        item       : { Type: Object, required: true },
        mode       : { Type: String, required: true },
        elementKey : { Type: String, required: true },
        filter     : { Type: Object, required: false },
        singleItems: { Type: Boolean, required: false, default: false },
        nested     : { Type: Boolean, required: false, default: false }
    },

    emits: [ 'toggleopen', 'cloneForToday', 'showForToday', 'showScores', 'showDetails', 'showVertical', 'openElement',
             'createElement', 'toggleListFilter', 'print', 'excel' ],

    watch: {
        elementKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( this.ready
                    && undefined !== oldValue
                    && this.lastKey !== newValue )
                {
                    this.lastKey = newValue
                    this.update()
                }
            }
        },
    },

    data()
    {
        return {
            ownId          : this.$core.getUuid().generate(),
            listForToday   : false,
            showTodaysList : false,
            showCalendar   : false,
            showPrint      : false,
            showFilters    : true,
            filterBy       : false,
            indexKey       : false,
            ready          : false,
            lastKey        : false,
            isStudentFilter: false
        }
    },

    created()
    {

        this.addIndexedEvent( 'on-rights-ready', () =>
        {
            this.update()
        } )

        this.isStudentFilter = this.$props.filter && this.$props.filter.type === 'student'

        this.$core.getLicense()
            .waitReady()
            .then( () =>
            {
                this.update()
            } )

    },

    computed: {
        hasListForToday()
        {
            for( let l in this.$props.item.lists )
            {
                if( this.$core.getFriendlyTimestamp()
                        .timestampIsToday( this.$props.item.lists[ l ].timestamp ) )
                {
                    return true
                }
            }
            return false
        }
    },

    methods: {

        update()
        {

            let tmp = this.$core.getState( 'detailViewFor' )
            if( undefined !== tmp )
            {
                let temp = tmp.split( ':' )
                this.filterBy = temp[ 0 ]
            }

            if( this.$core.getLicense().isAllowed( 'print' ) )
            {
                this.showPrint = true
            }

            if( this.$core.getLicense().forceReadOnly()
                || true === this.$props.item.archived )
            {
                this.ready = true
                return
            }

            if( this.$props.singleItems === true
                || this.$props.item.archived === true )
            {
                this.showCalendar = false
                this.listForToday = false
                this.showTodaysList = false
                this.ready = true
                return
            }

            switch( this.$props.item.listType )
            {
                case 'checklist':
                case 'ratinglist':
                case 'customFixed':
                    this.showCalendar = true
                    this.listForToday = !this.hasListForToday && this.$core.r().isAllowed( this.$props.item, 'clone' )
                    this.showTodaysList = this.hasListForToday
                    break
                default:
                    break
            }
            this.ready = true
        },

        handleShowCalendar()
        {

            let readyKey = this.$core.getUi().showCalendar( this.$props.item.lists )
            this.addEvent( readyKey, ( message ) =>
            {
                this.handleEventAction( message )
            } )

        },

        handleEventAction( message )
        {
            this.$core.getUi().hideCalendar()
            switch( message.action )
            {
                case 'show':
                    this.$emit( 'openElement', message.params )
                    break
                case 'create':
                    this.$emit( 'createElement', message.params )
                    break
            }
        }


    }

}
</script>