<template>
    <div :class="'competence-category hoverable '+item.color+( selected ? ' selected-item' : '' )"
         :id="'printable-'+item.localId"
         :key="'competence-'+item.localId+'-'+$core.getBaseClassHelper().getElementKey( 'competence', undefined, item.localId )">
        <div class="header">
            <div class="category-title">
                {{ item.title }}
            </div>
            <div class="category-functions">
                <Button :type="open ? 'list-closer' : 'list-opener'" addClass="list-function" @clicked="open = !open"/>
            </div>
        </div>
        <div class="competence-body sensitive competenceBody">
            <div class="element-info infoflex">
                <div v-if="0 < competences.length" class="infobox first">
                    <span class="element-counter">{{ competences.length }}</span>
                </div>
                <div class="infobox second">
                    <span v-html="timeInfo"></span>
                    <LastEditor :reference="item" :elementKey="item.elementKey" :isTemplate="true"/>
                </div>
                <div class="markers">
                    <ReferenceMarkers :item="item"/>
                </div>
            </div>
            <div v-if="ready && open" class="competences">
                <template v-if="Array.isArray( item.subCompetences ) && 0 < item.subCompetences.length">
                    <template v-for="subCompetence in subCompetences"
                              :key="'subComp-'+subCompetence.localId">

                        <div v-if="undefined !== subCompetence.caption"
                             class="subcomptence-wrapper">

                            <div class="sub-header">
                                <div class="title">
                                    {{ subCompetence.caption }}
                                </div>
                                <div class="category-functions">
                                    <Button
                                        :type="openSubs[ subCompetence.localId ] === true ? 'list-closer' : 'list-opener'"
                                        addClass="list-function"
                                        @clicked="openSubs[ subCompetence.localId ] = !openSubs[ subCompetence.localId ]"/>
                                </div>
                            </div>
                            <div class="body">
                                <p>{{ subCompetence.description }}</p>
                            </div>
                            <div class="subcompetence-list" v-if="openSubs[ subCompetence.localId ] === true">
                                <DefaultViewItemList viewItem="CompetenceTemplate"
                                                     :filterKey
                                                     :controlKey
                                                     :itemList="subCompetence.subCompetences"
                                                     :viewIsFiltered="false"
                                                     :showEmptyState="false"
                                                     :sortable="true && 1 < subCompetence.subCompetences.length"
                                                     @resort="(...args) => handleSubResort( [subCompetence.localId, ...args ] )"
                                                     scope="own"/>
                            </div>
                        </div>

                    </template>
                </template>
                <template v-else>
                    <DefaultViewItemList viewItem="CompetenceTemplate"
                                         :filterKey
                                         :controlKey
                                         :itemList="competences"
                                         :viewIsFiltered="false"
                                         :showEmptyState="false"
                                         :sortable="true && 1 < competences.length"
                                         @resort="handleResort"
                                         scope="own"/>
                </template>
            </div>
            <div v-if="!ready && open" class="competences">
                <PreheatingIndicator/>
            </div>
        </div>
    </div>
</template>

<script>
import ViewItemHandler       from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import LastEditor            from '@/components/elements/markers/LastEditor'
import ReferenceMarkers      from '@/components/elements/markers/ReferenceMarkers'
import MixinWatchElementKey  from "@/mixins/MixinWatchElementKey";
import MixinLivingTimestamps from "@/mixins/MixinLivingTimestamps";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup";
import Button                from "@/components/elements/defaults/Button.vue";
import DefaultViewItemList   from "@/components/defaults/DefaultViewItemList.vue";
import PreheatingIndicator   from "@/components/elements/indicators/PreheatingIndicator.vue";
import MixinEvents           from "@/mixins/MixinEvents.vue";

/*eslint-disable*/
export default {

    name      : 'CompetenceCategoryTemplate',
    components: { PreheatingIndicator, DefaultViewItemList, Button, ReferenceMarkers, LastEditor },
    extends   : ViewItemHandler,
    mixins    : [ MixinWatchElementKey, MixinLivingTimestamps, MixinComponentCleanup, MixinEvents ],

    props: {
        item     : { type: Object, required: true },
        changeKey: { type: String, required: false },
        viewKey  : { type: String, required: false },
        selected : { type: Boolean, required: false, default: false }
    },

    emits: [ 'click' ],

    data()
    {
        return {
            ready         : false,
            unblurred     : false,
            open          : false,
            openSubs      : {},
            filterKey     : this.$core.getUuid().generate(),
            controlKey    : this.$core.getUuid().generate(),
            competences   : [],
            subCompetences: [],
            awaitUpdates  : [],
            sortMapCount  : 0,
            processing    : false
        }
    },

    computed: {
        cacheKey()
        {
            return this.$core.getBaseClassHelper().get( 'competenceTemplate' ).registry.cacheKey + '-' + this.$core.getBaseClassHelper().get( 'competenceTemplate' ).registry.archiveKey
        },
        classList()
        {
            return ( !this.unblurred && this.$props.item.blurry ) ? 'blurred' : ''
        },
        timeInfo()
        {
            return 'angelegt am <strong>' + this.$core.getFriendlyTimestamp().friendlyDate( this.$props.item.timestamp ) + '</strong>'
                   + ( 0 < this.$props.item.update ? '<br/><span class="updatetimestamp">zuletzt aktualisiert am <strong>' +
                   this.$core.getFriendlyTimestamp().friendlyDate( this.$props.item.update ) + '</strong></span>' : '' )
        }
    },

    watch: {
        cacheKey: {
            immediate: true,
            handler( newVal, oldVal )
            {
                if( undefined !== oldVal && newVal !== oldVal )
                {
                    this.prepare( true )
                }
            }
        }
    },

    created()
    {
        this.prepare()
        this.addEvent( 'on-function-click-useTemplate', ( item ) =>
        {
            if( !this.processing )
            {
                this.processing = true
                this.cloneTemplate( item )
            }
        } )
    },

    methods: {

        prepare( afterResort )
        {

            this.$core.getBaseClassHelper()
                .get( 'competenceTemplate' )
                .cacheHeatup()
                .then( () =>
                {

                    let competences = [],
                        scope       = this.$props.item.archived ? 'archive' : 'cache'

                    this.competences = []
                    this.subCompetences = []

                    for( const [ localId, map ] of this.$core.getBaseClassHelper()
                                                       .get( 'competenceTemplate' ).registry.indexMaps )
                    {
                        if( map.competenceCategory === this.$props.item.localId )
                        {
                            competences.push( localId )
                        }
                    }

                    let sortMap = this.$core.getBaseClassHelper().get( 'competenceTemplate' ).registry.sortMap.get( scope )

                    for( let s in sortMap )
                    {
                        if( -1 < competences.indexOf( sortMap[ s ] ) )
                        {
                            this.competences.push( sortMap[ s ] )
                        }
                    }

                    if( Array.isArray( this.$props.item.subCompetences ) && 0 < this.$props.item.subCompetences.length )
                    {
                        for( let i in this.$props.item.subCompetences )
                        {

                            let subCompetence = {
                                caption       : this.$props.item.subCompetences[ i ].value.caption,
                                description   : this.$props.item.subCompetences[ i ].value.description,
                                localId       : this.$props.item.subCompetences[ i ].value.localId,
                                subCompetences: []
                            }

                            for( const [ localId, map ] of this.$core.getBaseClassHelper()
                                                               .get( 'competenceTemplate' ).registry.indexMaps )
                            {
                                if( map.competenceCategory === 'subCompetence:' + subCompetence.localId )
                                {
                                    subCompetence.subCompetences.push( localId )
                                }
                            }

                            if( !afterResort )
                            {
                                this.openSubs[ subCompetence.localId ] = false
                            }
                            this.subCompetences.push( subCompetence )

                        }
                    }

                    this.filterKey = this.$core.getUuid().generate()
                    if( afterResort )
                    {
                        this.controlKey = this.$core.getUuid().generate()
                    }
                    this.ready = true

                } )
        },

        handleClick()
        {
            if( this.$props.item.blurry )
            {
                this.unblurred = !this.unblurred
            }
        },

        processClones( competences, mapTemplateIds )
        {
            return new Promise( resolve =>
            {

                mapTemplateIds = mapTemplateIds || new Map()

                if( 0 < competences.length )
                {
                    let competence = competences.shift(),
                        categoryId = this.$core.getUuid().generate(),
                        baseClass,
                        element
                    switch( competence.type )
                    {
                        case 'competenceCategoryTemplate':
                            baseClass = this.$core.getBaseClassHelper()
                                            .get( 'competenceCategory' )

                            element = {
                                title         : competence.title,
                                description   : competence.description,
                                localId       : categoryId,
                                color         : competence.color,
                                subCompetences: []
                            }

                            mapTemplateIds.set( competence.localId, categoryId )

                            for( let s in competence.subCompetences )
                            {
                                let sub = this.$core.f().deref( competence.subCompetences[ s ] )
                                sub.value.localId = this.$core.getUuid().generate()
                                mapTemplateIds.set( competence.subCompetences[ s ].value.localId, sub.value.localId )
                                mapTemplateIds.set( 'subCompetence:' + competence.subCompetences[ s ].value.localId, 'subCompetence:' + sub.value.localId )
                                element.subCompetences.push( sub )
                            }
                            break
                        default:
                            baseClass = this.$core.getBaseClassHelper()
                                            .get( 'competence' )
                            element = {
                                title               : competence.title,
                                description         : competence.description,
                                idCompetenceCategory: mapTemplateIds.get( competence.idCompetenceCategory ),
                                subs                : []
                            }

                            for( let s in competence.subs )
                            {
                                element.subs.push( {
                                    mode : 'remove',
                                    value: {
                                        '_historic': s,
                                        type       : competence.subs[ s ].value.type,
                                        caption    : competence.subs[ s ].value.caption,
                                        localId    : this.$core.getUuid().generate()
                                    }
                                } )
                            }
                            break
                    }

                    baseClass.create(
                        element,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        element.localId,
                        undefined
                    )

                    return resolve( this.processClones( competences, mapTemplateIds ) )

                }
                else
                {
                    this.$core.getUi()
                        .setPageMessage( 'Vorlage übernommen', 'Du findest die Kompetenzen nun in deiner Liste "meine Kompetenzen"', 'ok' )
                    this.$core.getEventManager()
                        .dispatch( 'after-cache-force-competenceCategories' )
                    return resolve()
                }

            } )
        },

        cloneTemplate( item )
        {

            this.$core.getUi()
                .showBlocker( 'Augenblick bitte', 'Die Vorlage wird übernommen...' )

            let category               = this.$core.getBaseClassHelper()
                                             .getObjectById( item.localId ),
                allCompetenceTemplates = this.$core.getBaseClassHelper()
                                             .get( 'competenceTemplate' )
                                             .readCache( 'cache' ),
                competences            = []

            competences.push( category )
            for( let a in allCompetenceTemplates )
            {
                if( allCompetenceTemplates[ a ].templateId === category.templateId )
                {
                    competences.push( allCompetenceTemplates[ a ] )
                }
            }

            this.processClones( competences )
                .then( () =>
                {

                    setTimeout( () =>
                    {
                        this.$core.getUi().hideBlocker()
                    }, 1000 )

                } )
        }

    }

}
</script>