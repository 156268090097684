<template>
    <div v-if="ready">
        <CompetenceCategoryCreator v-if="createVisible"
                                   @close="handleClosedCreator()"/>
        <div class="row-adder-wrapper">
            <div class="left">
                <div class="select-wrapper">
                    <select :id="'form-element-'+refName" @change="select">
                        <option value="">{{ $core.t( 'please-select-competence-category' ) }}</option>
                        <template v-for="competenceCategory in categories"
                                  :key="'key-'+refName+'-'+competenceCategory.id">
                            <option :value="competenceCategory.localId"
                                    :class="competenceCategory.color"
                                    :selected="value === competenceCategory.localId || createdId === competenceCategory.localId"
                                    style="font-weight:bold;">
                                {{ competenceCategory.title }}
                            </option>
                            <template v-if="Array.isArray( competenceCategory.subCompetences )
                                            && 0 < competenceCategory.subCompetences.length">
                                <optgroup :label="'&nbsp;&nbsp;&nbsp;Teilkompetenzen von '+competenceCategory.title">
                                    <template v-for="sub in competenceCategory.subCompetences"
                                              :key="sub.value.localId">
                                        <option v-if="sub.value.localId"
                                                :value="'subCompetence:'+sub.value.localId"
                                                :selected="value === 'subCompetence:'+sub.value.localId || createdId === sub.value.localId">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ sub.value.caption }}
                                        </option>
                                    </template>
                                </optgroup>
                            </template>
                        </template>
                    </select>
                </div>
            </div>
            <div class="right">
                <Button type="add small" @click="createVisible = true"/>
            </div>
        </div>

    </div>
</template>

<script>
/*eslint-disable*/
import CompetenceCategoryCreator from "@/components/form/elements/CompetenceCategoryCreator.vue";
import MixinCachePreheater       from "@/mixins/MixinCachePreheater.vue";
import MixinEvents               from "@/mixins/MixinEvents.vue";

export default {

    name      : 'CompetenceCategorySelector',
    components: { CompetenceCategoryCreator },
    mixins    : [ MixinEvents, MixinCachePreheater ],
    emits     : [ 'enterPressed', 'backspacePressed', 'update', 'selected', 'adderValid',
                  'adderInvalid' ],

    props: {

        value       : { Type: String, required: false },
        set         : { Type: Object, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false },

    },

    data()
    {
        return {
            ready        : false,
            formElm      : false,
            selectedValue: false,
            categories   : [],
            tree         : [],
            createVisible: false,
            createdId    : false,
            neededCaches : [ 'competenceCategory' ]
        }
    },

    created()
    {

        this.prepare()
        this.addIndexedEvent( 'on-storable-create-competenceCategory', ( localId ) =>
        {

            this.createdId = localId
            this.prepare()

        } )

    },

    mounted()
    {
    },

    methods: {

        prepare()
        {
            this.$core.getBaseClassHelper()
                .get( 'competenceCategory' )
                .getPreparedCache()
                .then( categories =>
                {

                    let list = []
                    /* eslint-disable-next-line no-unused-vars */
                    for( const [ localId, category ] of categories.cache )
                    {
                        list.push( category )
                    }

                    this.categories = list
                    this.ready = true

                } )
        },

        getMaxLevel()
        {
            let competences = this.$core.getBaseClassHelper()
                                  .get( 'competence' )
                                  .getCache( 'cache' ),
                maxLevel    = 0

            for( const [ c, competence ] of competences )
            {
                if( competence.idCompetenceCategory === this.selectedValue
                    && competence.level > maxLevel )
                {
                    maxLevel = competence.level
                }
            }

            return ( maxLevel + 1 )

        },

        select( event )
        {

            this.createdId = false
            this.selectedValue = event.target.value
            let levelElement = document.querySelector( '#form-element-level' )
            if( null !== levelElement )
            {
                if( 0 === parseInt( levelElement.value ) )
                {
                    levelElement.value = this.getMaxLevel()
                    this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-level', this.getMaxLevel() )
                }
            }

        },

        handleClosedCreator()
        {
            this.createVisible = false
        }

    }

}
</script>