<template>
    <div class="login-status">
        <div class="inner">
            <div v-if="$store.getters.authorized" class="logout-icon" :title="$store.getters.username + ' abmelden'"
                 @click="logout()"></div>
            <div :class="'user-icon '+( $store.getters.authorized ? 'active' : 'inactive' )" :style="getStyle()"
                 @click="active = !active">
            </div>
            <transition appear name="next">
                <div v-if="active" class="user-info">
                    <span v-if="$store.getters.authorized">angemeldet als <strong>{{ $store.getters.username }}</strong> | <span
                        class="clickable" @click="logout()">abmelden</span></span>
                    <span v-else>du bist nicht angemeldet</span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import MixinCachePreheater from "@/mixins/MixinCachePreheater.vue";
import MixinEvents         from "@/mixins/MixinEvents.vue";

export default {

    name  : 'LoginStatus',
    mixins: [ MixinCachePreheater, MixinEvents ],

    data()
    {
        return {
            active      : false,
            avatar      : false,
            neededCaches: [ 'avatar' ],
            prepareTimer: null,
            repeatCount : 0
        }
    },

    emits: [ 'visibilityChange' ],

    watch: {
        active                     : {
            immediate: true,
            handler( state )
            {
                this.$emit( 'visibilityChange', state )
                if( true === state )
                {
                    setTimeout( () =>
                    {
                        this.active = false
                    }, 20000 )
                }
            }
        },
        '$store.getters.authorized': {
            immediate: true,
            handler( newState, lastState )
            {
                if( undefined !== lastState
                    && newState !== lastState )
                {
                    this.prepareAvatar()
                }
            }
        }
    },

    mounted()
    {

        this.appendEvent( 'on-refresh-cache-avatar', () =>
        {
            this.performPrepareAvatar()
        } )

        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepareAvatar()
            } )

    },

    methods: {

        prepareAvatar( retry )
        {

            clearTimeout( this.prepareTimer )

            this.prepareTimer = null
            this.prepareTimer = setTimeout( () =>
            {

                retry = retry || false

                if( true === this.$store.getters.authorized )
                {

                    if( retry )
                    {
                        this.$core.getBaseClassHelper()
                            .get( 'avatar' )
                            .cacheHeatup()
                            .then( () =>
                            {
                                this.prepareAvatar( retry )
                            } )
                    }
                    else
                    {
                        this.performPrepareAvatar( retry )
                    }

                }
                else
                {
                    this.avatar = false
                }

            }, ( this.$store.getters.authorized !== true ? 10 : 500 ) )

        },

        performPrepareAvatar( retry )
        {

            retry = retry || false

            if( this.$store.getters.authorized !== true )
            {
                this.avatar = false
                return
            }

            try
            {

                let avatars = this.$core.getBaseClassHelper()
                                  .get( 'avatar' )
                                  .getCache( 'cache' )

                /*eslint-disable-next-line*/
                for( const [ localId, avatar ] of avatars )
                {
                    if( avatar.colleagueId === this.$store.getters.idUser )
                    {
                        this.avatar = avatar.dataUri
                    }
                }

                if( !this.avatar
                    && ( !retry || this.repeatCount < 10 ) )
                {
                    this.repeatCount++
                    setTimeout( () =>
                    {
                        this.prepareAvatar( true )
                    }, 100 )
                }

            }
            catch( e )
            {
                this.$core.getLogger().clog( 'LoginStatus.vue::prepareAvatar', 'cache not ready yet...' )
                this.prepareAvatar()
            }

        },

        getStyle()
        {
            if( this.avatar )
            {
                return 'background-image:url( "' + this.avatar + '" )!important;'
            }
            return ''
        },

        logout()
        {
            this.$core.getAuthenticator().logout()
        }

    }
}
</script>