<script>
import ListDataTypeHelper  from '@/classes/Helpers/ListDataType'
import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {

    name  : 'MixinBasicPrepares',
    mixins: [ MixinCachePreheater ],

    data()
    {
        return {
            neededCaches: [ 'class', 'student' ]
        }
    },

    created()
    {

        this.dataTypeHelper = new ListDataTypeHelper( this.$core )

    },

    beforeUnmount()
    {

        this.preparedFields = []
        this.dynamicFields = {}

    },

    methods: {

        prepareClassFilter()
        {

            this.awaitNeededCaches()
                .then( () =>
                {

                    let classFilter = false,
                        elm         = document.querySelector( '#classes-filter-select' )

                    if( null !== elm
                        && '' !== elm.value )
                    {

                        classFilter = elm.value
                        let obj = this.$core.getBaseClassHelper()
                                      .get( 'class' )
                                      .getById( classFilter )

                        if( this.$core.f().valid( obj ) )
                        {

                            this.classFilter = classFilter
                            this.className = obj.classname

                            this.$emit( 'appendClassReference', this.classFilter, this.className )

                        }

                    }

                } )

        },

        _setupMatch( setup )
        {
            if( this.$core.settings()
                    .getSetting( setup[ 0 ] ) !== setup[ 1 ] )
            {
                return false
            }
            return true
        },

        _fieldId( field )
        {
            return ( this.$core.f().isset( field.id ) ? field.id :
                     ( this.$core.f().isset( field.ref ) ? field.ref : undefined ) )
        },

        fieldWalker( fields, lastField )
        {

            this.fixedResultingType = undefined
            for( let f in fields )
            {

                let field = fields[ f ],
                    push  = true

                if( undefined === field.id )
                {
                    field.id = this._fieldId( field )
                }

                if( undefined !== field.setting )
                {
                    if( !this._setupMatch( field.setting.split( ':' ) ) )
                    {
                        push = false
                    }
                }

                if( field.ref === 'columns'
                    && undefined !== field.resultingType )
                {
                    this.fixedResultingType = field.resultingType
                }

                if( push )
                {

                    this.preparedFields.push( field )
                    if( true === field.extended )
                    {
                        this.hasExtended = true
                    }

                    let lastId = this._fieldId( field )
                    if( false !== lastField )
                    {
                        this.nextField[ lastField ] = lastId
                    }

                    lastField = lastId

                }

            }

            return lastField

        },

        prepareForm( reset )
        {

            this.hasExtended = false

            let lastField     = false,
                fields        = this.$props.fields,
                dynamicFields = this.dynamicFields

            if( reset )
            {
                this.nextField = {}
                this.preparedFields = []
            }

            lastField = this.fieldWalker( fields, lastField )
            this.fieldWalker( dynamicFields, lastField )

            if( undefined !== this.$props.editItem
                && false !== this.$props.editItem )
            {
                if( this.$props.editItem.type === 'list'
                    && this.$core.f().isObject( this.$props.editItem )
                    && this.$core.f().isObject( this.$props.editItem.lists[ 0 ] ) )
                {
                    this.prepareForList()
                    this.prepared = true
                }
                else
                {
                    this.prepareValues()
                        .then( () =>
                        {
                            this.prepared = true
                        } )
                }
            }
            else
            {
                this.prepareValues()
                    .then( () =>
                    {
                        this.prepared = true
                    } )
            }

            if( reset )
            {
                this.$core.getEventManager().dispatchAndRemove( 'after-form-field-update' )
            }

        },
        /* eslint-disable */
        prepareForList( list )
        {

            if( undefined !== this.$props.editItem.inDetailView )
            {
                return
            }

            list = undefined === list ? this.$props.editItem.lists[ 0 ] : list

            for( let p in this.preparedFields )
            {

                let field = this.preparedFields[ p ]
                this.dataTypeHelper.setupField( field, list, this )
                this.values[ field.ref ] = field.value

            }

        },

        _prepareMultiRef( field, key, element )
        {

            if( undefined === element )
            {
                return
            }

            let refs = field.ref.split( ',' )
            for( let r in refs )
            {
                let lookup = refs[ r ]
                field.value = field.value || {}
                if( undefined !== element[ lookup ] )
                {
                    field.value[ lookup ] = element[ lookup ]
                }
                else
                {
                    field.value[ lookup ] = ''
                }
            }

        },

        _resolveHistoricStudents()
        {

            let studentList = this.$core.getBaseClassHelper()
                                  .get( 'student' )
                                  .getCache( 'cache' ),
                historic    = []

            /*eslint-disable*/
            for( const [ s, studentItem ] of studentList )
            {
                let student = this.$core.getBaseClassHelper()
                                  .get( 'student' )
                                  .getById( studentItem.localId )

                switch( this.$props.editItem.type )
                {
                    case 'class':
                        if( student.classId === this.$props.editItem.localId
                            && -1 === historic.indexOf( student.localId ) )
                        {
                            historic.push( student.localId )
                        }
                        break;
                    case 'group':
                    case 'yeargroup':
                        if( -1 < Object.values( this.$props.editItem.students ).indexOf( student.localId )
                            && -1 === historic.indexOf( student.localId ) )
                        {
                            historic.push( student.localId )
                        }
                        break
                }
            }
            /*eslint-enable*/

            return historic.join( ',' )

        },

        _resolveActiveStudents()
        {

            let studentList = this.$core.getBaseClassHelper()
                                  .get( 'student' )
                                  .getCache( 'cache' ),
                active      = []

            /*eslint-disable*/
            for( const [ s, studentItem ] of studentList )
            {
                let student = this.$core.getBaseClassHelper()
                                  .get( 'student' )
                                  .getById( studentItem.localId )

                switch( this.$props.editItem.type )
                {
                    case 'class':
                        if( student.classId === this.$props.editItem.localId
                            && -1 === active.indexOf( student.localId ) )
                        {
                            active.push( student.localId )
                        }
                        break;
                    case 'group':
                    case 'yeargroup':
                        if( -1 < Object.values( this.$props.editItem.students ).indexOf( student.localId )
                            && -1 === active.indexOf( student.localId ) )
                        {
                            active.push( student.localId )
                        }
                        break
                }
            }
            /*eslint-enable*/

            return active

        },

        prepareValues()
        {

            return new Promise( resolve =>
            {

                if( this.$props.scope === 'lists'
                    && undefined !== this.$props.editItem.inDetailView )
                {
                    return resolve()
                }

                this.awaitNeededCaches()
                    .then( () =>
                    {

                        let values = {}

                        for( let f in this.$props.fields )
                        {
                            let field = this.$props.fields[ f ]
                            if( 'historicStudents' === field.ref )
                            {
                                values[ field.ref ] = this._resolveHistoricStudents()
                            }
                            else if( 'students' === field.ref )
                            {
                                values[ field.ref ] = this._resolveActiveStudents()
                            }
                            else
                            {
                                if( undefined !== field.ref
                                    && -1 < field.ref.indexOf( ',' ) )
                                {
                                    this._prepareMultiRef( field, field.ref, this.$props.editItem || field.value )
                                    values[ field.ref ] = field.value
                                }
                                else if( undefined !== field.ref )
                                {
                                    values[ field.ref ] = this.$props.editItem[ field.ref ] || field.value
                                }
                            }
                        }

                        this.values = values
                        return resolve()

                    } )

            } )
        }

    }

}
</script>