export default class SyncNotifications
{

    constructor( parent )
    {

        if( !SyncNotifications.instance )
        {

            this.ready = false
            this.parent = parent
            this.friendlyTimestamp = parent.core.getFriendlyTimestamp()
            this.cryptoHelper = parent.core.getCryptoHelper()
            this.f = parent.core.f()
            this.baseClassHelper = parent.getBaseClassHelper()

            if( undefined === this.baseClassHelper )
            {
                this.parent.eventManager.append( 'on-baseclasses-available', () =>
                {
                    this.baseClassHelper = this.parent.getBaseClassHelper()
                } )
            }

            this.t = ( key, params ) =>
            {
                return parent.core.t( key, params )
            }

            this.getState = ( which ) =>
            {
                return this.parent.core.getState( which )
            }

            this.lastCounts = new Map()

            this._logKey = 'SyncWorker::SyncNotifications::'

            this.lastSync = 0
            this.minSyncInterval = 120000
            this.sentNotifications = []

            SyncNotifications.instance = this

        }

        return SyncNotifications.instance

    }

    /*eslint-disable*/
    destruct()
    {

        this.t = null
        this.friendlyTimestamp = null
        this.cryptoHelper = null
        this.parent = null
        delete this.parent
        delete this.cryptoHelper
        delete this.friendlyTimestamp
        delete SyncNotifications.instance

    }

    getNotificationElements()
    {

        let setup = [
                { queue: 'dates', key: 'reminder', cache: 'date', lookup: 'timestamp' },
                { queue: 'todos', key: 'todo', cache: 'todo', lookup: 'timestamp', value: 'duedate', ifNot: 'done' },
                { queue: 'birthdays', key: 'birthday', cache: 'student', lookup: 'birthdate', transform: 'birthday' },
                {
                    queue   : 'recalllists',
                    key     : 'recall',
                    cache   : 'list',
                    child   : 'lists',
                    lookup  : 'timestamp',
                    value   : 'duedate',
                    listType: 'recallList'
                },
                { queue: 'messages', cache: 'message', key: 'inbox' }
            ],
            todo  = []

        for( let s in setup )
        {
            if( this.parent.settings.getSetting( 'notificationsFor' + this.f.ucFirst( setup[ s ].queue ) ) )
            {
                todo.push( setup[ s ] )
            }
        }

        return todo

    }

    _resolveTimestamp( elm, setup )
    {

        let notificationTime = ( this.parent.settings.getSetting( 'notificationDeliveryTime' ) || 30 ) * 60 * 1000

        let list   = [ elm ],
            result = []

        if( undefined !== setup.child
            && undefined !== elm[ setup.child ] )
        {
            list = []
            for( let l in elm[ setup.child ] )
            {
                list.push( elm[ setup.child ][ l ] )
            }
        }

        for( let l in list )
        {

            let element = list[ l ],
                value   = null,
                temp    = null

            if( this.f.valid( element[ setup.lookup ] )
                && '' !== ( '' + element[ setup.lookup ] ).trim() )
            {

                if( undefined !== setup.transform )
                {

                    switch( setup.transform )
                    {
                        case 'birthday':
                            temp = element[ setup.lookup ].split( /\./g )
                            temp.pop()
                            value = new Date( new Date().getFullYear() + '-' + temp.reverse().join( '-' ) + ' 09:00:00' ).getTime()
                            break
                        default:
                            continue
                            break
                    }

                    value -= notificationTime

                }
                else
                {

                    if( undefined !== setup.listType
                        && element.listType === setup.listType )
                    {
                        if( '' !== element[ setup.value ]
                            && undefined !== element[ setup.value ] )
                        {
                            let date = element[ setup.value ].split( /\./g ).reverse().join( '-' ) + ' 09:00:00',
                                tsmp = new Date( date ).getTime()

                            value = tsmp - notificationTime
                        }
                    }
                    else
                    {
                        if( undefined === setup.listType )
                        {
                            if( undefined !== setup.ifNot )
                            {
                                if( 0 === parseInt( element[ setup.ifNot ] )
                                    || false === element[ setup.ifNot ]
                                    || undefined === element[ setup.ifNot ]
                                    || null === element[ setup.ifNot ] )
                                {
                                    value = element[ setup.value || setup.lookup ] - notificationTime
                                }
                                else
                                {
                                    continue
                                }
                            }
                            else
                            {
                                value = element[ setup.value || setup.lookup ] - notificationTime
                            }
                        }
                    }
                }

            }

            if( null !== value )
            {
                result.push( {
                    notificationTime: value,
                    key             : setup.key,
                    element         : element
                } )
            }

        }

        if( 0 < result.length )
        {
            return result
        }

        return null

    }

    resolveNotificationItems( queue, result )
    {

        return new Promise( resolve =>
        {

            result = result || []

            if( 0 < queue.length )
            {

                let setup       = queue.shift(),
                    lastCount   = this.lastCounts.get( setup.cache ),
                    actualCount = this.baseClassHelper.get( setup.cache ).getCacheCount()

                if( 'filled' === this.baseClassHelper.get( setup.cache ).state
                    && ( undefined === lastCount || actualCount > lastCount ) )
                {

                    this.lastCounts.set( setup.cache, actualCount )

                    this.baseClassHelper
                        .get( setup.cache )
                        .getPreparedCache()
                        .then( elements =>
                        {

                            /* eslint-disable-next-line no-unused-vars */
                            for( const [ e, element ] of elements.cache )
                            {

                                let resolved = this._resolveTimestamp( element, setup )
                                if( null !== resolved )
                                {
                                    result = [ ...result, ...resolved ]
                                }

                            }

                            return resolve( this.resolveNotificationItems( queue, result ) )

                        } )

                }
                else
                {
                    return resolve( this.resolveNotificationItems( queue, result ) )
                }

            }
            else
            {
                return resolve( result )
            }

        } )

    }

    _prepareNotification( item )
    {

        let notification = {
            id_local         : item.element.localId,
            title            : this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key ) ),
            body             : '',
            caption_1        : this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key + '-button-1-caption' ) ),
            caption_2        : this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key + '-button-2-caption' ) ),
            action_1         : this.cryptoHelper.encryptForServer( JSON.stringify( {
                action: 'open',
                target: null
            } ) ),
            action_2         : this.cryptoHelper.encryptForServer( JSON.stringify( {
                action: 'dismiss'
            } ) ),
            datetime_delivery: this.friendlyTimestamp.mysqlTimestamp( item.notificationTime )
        }
        switch( item.key )
        {
            case 'birthday':
                notification.title = this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key, [ item.element.firstname ] ) )
                notification.body = this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key + '-body', [ item.element.firstname + ' ' + item.element.lastname + ( '' !== item.element.classname ? ' (' + item.element.classname + ')' : '' ) ] ) )
                break
            case 'reminder':
                notification.body = this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key + '-body', [ item.element.title, ( item.start ? 'um ' + item.element.start + ' Uhr' : 'heute' ) ] ) )
                break
            case 'recall':
                notification.title = this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key, [ item.element.listname ] ) )
                notification.body = this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key + '-body', [ item.element.listname ] ) )
                break
            case 'todo':
                notification.title = this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key, [ item.element.title ] ) )
                notification.body = this.cryptoHelper.encryptForServer( this.t( 'push-' + item.key + '-body', [ item.element.title ] ) )
                break

        }

        this.sentNotifications.push( item.element.localId )

        return notification

    }

    _buildNotifications( items )
    {

        let notifications = []

        for( let i in items )
        {

            let item = items[ i ]
            if( item.notificationTime > Date.now()
                && -1 === this.sentNotifications.indexOf( item.element.localId ) )
            {

                notifications.push( this._prepareNotification( item ) )

            }

        }

        return notifications

    }

    prepareNotificationsQueue()
    {
        return new Promise( ( resolve, reject ) =>
        {

            let notifications = []

            this.resolveNotificationItems( this.getNotificationElements() )
                .then( items =>
                {

                    notifications = this._buildNotifications( items )
                    if( 0 < notifications.length )
                    {

                        let message = {
                            method       : 'users.queueNotifications',
                            notifications: notifications
                        }

                        this.parent.queueWorker.enqueue( 'message', this.parent.uuid.generate(), 'socketMessage', JSON.stringify( message ) )
                        this.parent.logger.clog( this._logKey + 'prepareNotificationsQueue', 'prepared ' + notifications.length + ' notifications for delivery' )
                        return resolve()

                    }
                    else
                    {
                        this.parent.logger.clog( this._logKey + 'prepareNotificationsQueue', notifications.length + ' notifications to be synced for delivery' )
                        return resolve()
                    }

                } )

        } )

    }

    sync()
    {

        return new Promise( resolve =>
        {

            if( true !== this.getState( 'first-crud-sync-done' ) )
            {
                return resolve()
            }

            if( true !== this.parent.settings.getSetting( 'notificationsAllowed' ) )
            {
                this.parent.logger.clog( this._logKey + 'sync', 'notifications disabled: aborting.' )
                return resolve()
            }

            if( Date.now() > ( this.lastSync + this.minSyncInterval ) )
            {

                this.parent.logger.clog( this._logKey + 'sync', 'synchronizing notifications' )

                this.prepareNotificationsQueue()
                    .then( () =>
                    {
                        this.lastSync = Date.now()
                        return resolve()

                    } )
                    .catch( e =>
                    {
                        return resolve()
                    } )

            }
            else
            {
                this.parent.logger.clog( this._logKey + 'sync', 'skipping notifications' )
                return resolve()
            }

        } )

    }

}