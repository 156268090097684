<template>
    <transition appear :name="$core.settings().getTransition( 'next' )" mode="out-in">
        <div v-if="fakeElement" class="tutorial-element-wrap">
            <Touchable v-if="fakeElement"
                       :key="'tpl-fake'"
                       :localId="fakeElement.localId"
                       scope="own"
                       :viewItem="fakeElement.type"
                       :fakeCount="fakeElement.fakeCount"
                       :fakeElement="true"
                       @clicked="$core.f().skip"
                       @refresh="$core.f().skip"/>
        </div>
    </transition>
</template>

<script>
import Touchable from "@/components/defaults/Touchable";

export default {

    name      : 'TutorialElement',
    components: { Touchable },
    props     : {
        fakeElement: { Type: Object | Boolean, required: false, default: false }
    }

}
</script>