export default class beforeCreateCompetence
{

    constructor( core )
    {

        this.f = core.f()
        this.sorter = core.getSorter()
        this.baseClass = core.getBaseClassHelper()

    }

    /*eslint-disable-next-line*/
    beforeCreate( competence )
    {

        if( undefined !== competence.idCompetenceCategory )
        {

            let maxLevel    = -1,
                competences = this.baseClass.get( 'competence' ).readCache( 'cache' )

            for( let c in competences )
            {
                if( competences[ c ].idCompetenceCategory === competence.idCompetenceCategory )
                {
                    if( competences[ c ].level > maxLevel )
                    {

                        maxLevel = competences[ c ].level

                    }
                }
            }

            maxLevel++
            competence.level = maxLevel

        }

    }

}