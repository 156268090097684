7<template>
    <div>
        <template v-if="$store.getters.online === true">
            <template v-if="!error">
                <template v-if="!isProcessing">
                    <div class="file_uploader">
                        <template v-if="$core.getLicense().isAllowedByOption( 'mediaHandling' )">
                            <CameraButton v-if="!display"
                                          @image="handleMedia"/>

                            <label v-if="!display" class="file-selector"
                                   :id="'file-label-'+id"
                                   :for="'file-selector-'+id">
                                <input type="file" class="file-selector"
                                       :id="'file-selector-'+id"
                                       @change="handleMedia()"/>
                            </label>

                            <AudioRecorder v-if="!display"
                                           :emitChange="true"
                                           @change="handleMedia"/>

                            <label v-if="!display"
                                   class="media-gallery"
                                   @click="handleMediaGallery"></label>

                        </template>
                        <div v-if="display" class="file_preview hoverable">
                            <AudioPlayer v-if="'audio/mp3' === fileInfo.format"
                                         :item="display"/>
                            <img v-if="'image/jpeg' === fileInfo.format
                                    || 'application/pdf' === fileInfo.format"
                                 class="file-preview" :src="display"
                                 @click="handleImageZoom"/>
                            <template v-if="$core.getLicense().isAllowedByOption( 'mediaHandling' )">
                                <Button type="delete"
                                        @click="handleDelete"/>
                            </template>
                        </div>
                        <template v-if="!$core.getLicense().isAllowedByOption( 'mediaHandling' ) && !display">
                            <div class="info-nag transparent-50">
                                um Dokumente anlegen oder ändern zu können, benötigst du das Lizenz-Upgrade
                                "Dokumente-Speicher"
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="file-preview-processing-align">
                        <div class="file-preview-processing"></div>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="file-uploader">
                    <div class="info-nag transparent-50">
                        Das Dokument steht (noch) nicht zur Verfügung.
                    </div>
                </div>
            </template>
        </template>
        <FileBoxOfflineMessage v-if="false === $store.getters.online" />
    </div>
</template>

<script>
import MixinResettableComponent from '@/mixins/MixinResettableComponent'
import FileBoxOfflineMessage    from "@/components/form/elements/FileBoxOfflineMessage";
import MixinCachePreheater      from "@/mixins/MixinCachePreheater";
import AudioRecorder            from "@/components/elements/defaults/AudioRecorder";
import AudioPlayer              from "@/components/elements/defaults/AudioPlayer";
import CameraButton             from "@/components/form/elements/CameraButton";

export default {

    name: 'MediaBox',

    components: { CameraButton, AudioPlayer, AudioRecorder, FileBoxOfflineMessage },
    mixins    : [ MixinResettableComponent, MixinCachePreheater ],

    props: {
        id          : { Type: String, required: true },
        refName     : { Type: String, required: false },
        vModel      : { Type: String, required: false },
        value       : { Type: String | Object, required: false },
        reformatter : { Type: String, required: false },
        highlighted : { Type: Object, required: false, default: [] },
        eventKey    : { Type: String, required: false },
        disabled    : { Type: Boolean, required: false, default: false },
        placeholder : { Type: String, required: false },
        autocomplete: { Type: String, required: false },
        className   : { Type: String, required: false },
        next        : { Type: String, required: false },
        type        : { Type: String, required: false },
        steps       : { Type: Number, required: false },
        plain       : { Type: Boolean, required: false, default: false },
        allValues   : { Type: Object, required: false },
        unique      : { Type: Boolean, required: false }
    },

    emits: [ 'update', 'enterPressed', 'backspacePressed', 'focussed', 'blurred' ],

    data()
    {
        return {
            neededCaches: [ 'media' ],
            initializing: true,
            timer       : false,
            hasErrors   : false,
            error       : false,
            display     : false,
            mediaId     : false,
            fileId      : false,
            fileInfo    : false,
            isProcessing: false,
            initialValue: false,
            clicked     : false
        }
    },

    computed: {
        foreignHighlighted()
        {
            return this.$props.className + ( this.hasErrors ? ' error' : '' ) + ' '
                   + ( -1 < this.$props.highlighted.indexOf( this.$props.refName ) ? 'highlighted' : '' )
        }
    },

    created()
    {

        this.mediaHelper = this.$core.getMediaHelper()
        if( undefined === this.$props.value )
        {
            this.initializing = false
        }

        this.$core
            .getEventManager()
            .add( 'on-media-gallery-select-' + this.$props.id, ( localId ) =>
            {

                this.mediaId = localId
                this.initialValue = localId

                this.prepareMedia()

            } )

    },

    beforeUnmount()
    {

        this.$core
            .getEventManager()
            .remove( 'on-media-gallery-select-' + this.$props.id )

        delete this.mediaHelper

    },

    mounted()
    {

        this.awaitNeededCaches()
            .then( () =>
            {

                let fileIdElm = document.querySelector( '#form-element-fileId' )
                if( null !== fileIdElm
                    && 'create' !== fileIdElm.value
                    && this.$core.f().valid( fileIdElm.value ) )
                {
                    this.initialValue = fileIdElm.value || false
                }
                else
                {
                    this.initialValue = ( ( undefined !== this.$props.value && false !== this.$props.value ) ? '' + this.$props.value : false )
                }

                this.mediaId = this.initialValue !== false ? this.initialValue : false
                this.prepareMedia()

            } )

    },

    methods: {

        prepareMedia()
        {

            if( false !== this.mediaId )
            {

                let file = this.$core
                               .getBaseClassHelper()
                               .get( 'media' )
                               .getById( this.mediaId )

                if( undefined !== file )
                {
                    this.fileId = file.fileId
                }

                this.preparePreview()

            }
        },

        handleMedia( data )
        {

            this.isProcessing = true
            this.mediaHelper.storeMedia( 'file-selector-' + this.$props.refName, data )
                .then( result =>
                {

                    let media = {
                        mimetype: result.plain.type,
                        filename: result.plain.name,
                        label   : result.label || result.plain.name,
                        fileId  : result.response.id,
                        fileSize: result.response.targetSize,
                    }

                    this.mediaId = this.$core
                                       .getBaseClassHelper()
                                       .get( 'media' )
                                       .create( media )

                    this.fileId = media.fileId

                    this.$core
                        .getEventManager()
                        .append( 'storable-after-update-' + this.mediaId, () =>
                        {

                            this.update()
                            this.preparePreview()

                        } )

                } )

        },

        performDelete()
        {

            this.isProcessing = true
            this.$core
                .getBaseClassHelper()
                .get( 'media' )
                .delete( this.mediaId )
                .then( () =>
                {

                    this.display = false
                    this.mediaId = false
                    this.fileId = false
                    this.isProcessing = false
                    this.update()

                } )

        },

        performRemoveFromList()
        {

            this.isProcessing = true
            this.display = false
            this.mediaId = false
            this.fileId = false
            this.isProcessing = false
            this.update()

        },

        handleDelete()
        {

            let buttons = [
                {
                    type    : 'delete',
                    title   : 'nur aus der Liste entfernen',
                    callback: () =>
                    {
                        this.performRemoveFromList()
                    }
                },
                {
                    type    : 'delete',
                    title   : 'vollständig löschen',
                    callback: () =>
                    {
                        this.performDelete()
                    }
                }
            ]

            this.$core
                .getUi()
                .showModalDialog(
                    'delete',
                    'Dokument entfernen',
                    'Du bist dabei, ein Dokument zu entfernen: Soll es nur aus der Liste entfernt werden, oder möchtest du das Dokument vollständig löschen?',
                    buttons )


        },

        preparePreview()
        {

            this.isProcessing = true
            this.error = false

            this.mediaHelper.getFileById( this.fileId )
                .then( result =>
                {

                    this.display = result.dataUrl
                    this.fileInfo = result

                } )
                .catch( () =>
                {

                    this.error = true
                    this.display = false
                    this.mediaId = false
                    this.fileId = false

                } )
                .finally( () =>
                {

                    this.isProcessing = false
                    if( !this.error
                        && !this.initializing )
                    {
                        this.update()
                    }

                    this.initializing = false

                } )
        },

        handleImageZoom()
        {
            this.$core.getUi()
                .imageZoom( this.fileInfo )
        },

        handleMediaGallery()
        {
            this.$core.getUi()
                .showMediaGallery( this.$props.id )
        },

        handleImage( media )
        {

            this.isProcessing = true
            this.mediaHelper.storeMedia( 'file-selector-' + this.$props.refName, media )
                .then( result =>
                {

                    this.fileId = result.response.id
                    this.update()
                    this.preparePreview()

                } )
                .catch( e =>
                {

                    this.reset()
                    switch( e )
                    {
                        case 'E_PDF_SIZE':
                            this.$core.getUi()
                                .showModalDialog( 'error',
                                    'Ooops: Deine Datei ist zu groß!',
                                    'Aktuell liegt die maximale Dateigröße für PDF-Dateien bei 10MB', [ 'defaultOk' ] )
                            break
                        default:
                            this.$core.getUi()
                                .showModalDialog( 'error',
                                    'Ooops: Das hat nicht funktioniert!',
                                    'Wir konnten das Dateiformat nicht erkennen.', [ 'defaultOk' ] )
                            break
                    }

                } )

        },

        populateFormFields()
        {

            let filenameElm = document.querySelector( '#form-element-filename' ),
                mimetypeElm = document.querySelector( '#form-element-mimetype' ),
                fileIdElm   = document.querySelector( '#form-element-fileId' ),
                fileSizeElm = document.querySelector( '#form-element-fileSize' )

            if( null !== filenameElm )
            {
                filenameElm.value = this.fileInfo.fileName
                this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-filename', this.fileInfo.fileName )
            }
            if( null !== mimetypeElm )
            {
                mimetypeElm.value = this.fileInfo.format
                this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-mimetype', this.fileInfo.format )
            }
            if( null !== fileIdElm )
            {
                fileIdElm.value = this.fileId
                this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-fileId', this.fileId )
            }
            if( null !== fileSizeElm )
            {
                fileSizeElm.value = this.fileInfo.size
                this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-fileSize', this.fileInfo.size )
            }

        },

        update()
        {

            if( this.$core.getLicense().isAllowedByOption( 'mediaHandling' ) )
            {
                this.$emit( 'update', this.$props.refName, 'fileBox', 'update', undefined, ( false !== this.mediaId ? this.mediaId : undefined ) )
                this.populateFormFields()
            }

        },


    }

}
</script>

<style scoped>

    .file_uploader
    {
        min-width: 250px;
    }

</style>