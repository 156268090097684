<template>
    <div :class="'class hoverable '+item.color+( selected ? ' selected-item' : '' )"
         :key="'class-'+item.localId" @click="$emit( 'clicked' )">
        <div :class="'classname'+classLongClassnames" :style="widthLongClassnames">
            {{ item.classname }}
        </div>
        <template v-if="initialized">
            <div class="classcount" :style="marginLongClassnames">
                <strong>{{ studentCount }}</strong> {{ $core.t( 'generic-students' ) }}
            </div>
        </template>
        <template v-else>
            <div class="classcount syncing" :style="marginLongClassnames">
                {{ $core.t( 'generic-students' ) }} {{ $core.t( 'are-being-initialized' ) }}
            </div>
        </template>
    </div>
</template>

<script>
import MixinWatchElementKey from "@/mixins/MixinWatchElementKey";
import MixinHasStudentCount from "@/mixins/MixinHasStudentCount";

export default {

    name : 'Class',
    props: {
        item      : { type: Object, required: true },
        selected  : { type: Boolean, required: false, default: false },
        fakeCount : { type: Number, required: false, default: -1 },
        elementKey: { type: String, required: false, default: '' }
    },

    emits : [ 'clicked' ],
    mixins: [ MixinWatchElementKey, MixinHasStudentCount ],

    computed: {

        classLongClassnames()
        {
            if( -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) )
            {
                return ' long'
            }
            return ''
        },

        widthLongClassnames()
        {
            if( -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) )
            {
                return 'width: ' + Math.ceil( ( this.$core.settings().getSetting( 'maxClassNameLength' ) / 4 ) * 100 ) + 'px'
            }
            return ''
        },

        marginLongClassnames()
        {
            if( -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) )
            {
                return 'margin-left: ' + Math.ceil( ( this.$core.settings().getSetting( 'maxClassNameLength' ) / 4 ) * 100 ) + 'px'
            }
            return ''
        }

    }

}
</script>