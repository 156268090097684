<template>
    <div class="headline list-headline"
         :key="'listEditorHeadline-'+instanceId">
        <div class="list-switch" :id="'list-boundaries-'+instanceId">
            <transition-group appear :name="$core.settings().getTransition( 'crossfade' )">
                <div v-if="leftVisible" class="list-switch-scroll-button list-switch-button left"
                     @click="applyScroll( 'left' )">
                    &#9664;
                </div>
                <div v-if="rightVisible" class="list-switch-scroll-button list-switch-button right"
                     @click="applyScroll( 'right' )">
                    &#9654;
                </div>
            </transition-group>
            <ul @scroll="handleScroll" :id="'list-switcher-'+instanceId">
                <li class="list-switch-inner"
                    v-for="[ localId, list ] in lists"
                    :class="'list-switch-inner'+( activeListId === localId ? ' active' : '' )"
                    :key="'inner-'+list.localId"
                    @click="$emit( 'setActiveList', localId )">
                    <strong>{{ list.listname }}</strong> <small> | vom
                    {{ $core.getFriendlyTimestamp().friendlyDate( list.timestamp ) }}</small>
                    <ListStoringIndicator :localId="localId"/>
                    <Button v-if="1 < lists.size"
                            type="close"
                            addClass="small marge-left"
                            @clicked="$emit( 'closeList', localId )"/>
                </li>
                <li :class="'list-switch-inner list-add-one list-switch-button'+( false === activeListId ? ' active' : '' )"
                    v-if="!disableMulti"
                    @click="handleAddOne()">
                    +
                </li>
            </ul>
        </div>
        <div class="list-buttons">
            <Button type="close" addClass="marge-left" @clicked="$emit( 'close' )"/>
            <Button v-if="activeList.listType === 'recallList'" type="options" addClass="marge-left"
                    @clicked="$emit( 'recallVisibleChange' )"/>
            <Button v-if="'vertical' === editorMode" type="vertical-off" addClass="marge-left"
                    @clicked="$emit( 'modeSwitch', 'horizontal' )"/>
            <Button v-if="'horizontal' === editorMode" type="vertical-on" addClass="marge-left"
                    @clicked="$emit( 'modeSwitch', 'vertical' )"/>
            <Button v-if="listHasCalcFields" :type="showCalcFields ? 'calc-on' : 'calc-off'"
                    @clicked="$emit( 'toggleCalcFields' )"/>
            <Button v-if="$core.getLicense().isAllowed( 'print' )" type="print" @clicked="$emit( 'print' )"/>
            <Button v-if="$core.getLicense().isAllowed( 'print' )" type="excel" @clicked="$emit( 'excel' )"/>
            <Button type="search" @clicked="$emit( 'filterVisibleChange' )"
                    addClass="list-editor inhead marge-left"/>
            <template v-if="colleaguesReady">
                <Button type="history" v-if="snapshotsVisible"
                        @click="handleHistoryClick" :addClass="historyOpen ? 'clicked' : ''"/>
                <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">
                    <div v-if="historyOpen" class="element-value-history">
                        <div class="select-wrapper value-history">
                            <select name="historic-values" @change="handleSelect">
                                <option value="">bitte wählen...</option>
                                <template v-for="( snapshot, index ) in snapshots"
                                          :key="'hist-dropdown-'+snapshot.id">
                                    <option v-if="0 < index"
                                            :value="snapshot.id"
                                            v-html="snapshotTitle( snapshot )">
                                    </option>
                                </template>
                                87
                            </select>
                        </div>
                    </div>
                </transition>
            </template>
        </div>
        <EditAllIndicator v-show="editingAll"/>
    </div>
</template>

<script>
/*eslint-disable*/
import ListStoringIndicator  from "@/components/elements/indicators/ListStoringIndicator";
import EditAllIndicator      from "@/components/elements/indicators/EditAllIndicator";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup.vue";

export default {
    name      : "ListEditorHeadline",
    components: { EditAllIndicator, ListStoringIndicator },
    mixins    : [ MixinComponentCleanup ],

    props: {
        lists            : { Type: Object, required: true },
        activeListId     : { Type: String, required: true },
        snapshots        : { Type: Array, required: false },
        editingAll       : { Type: Boolean, required: true },
        recallVisible    : { Type: Boolean, required: true },
        listFilterVisible: { Type: Boolean, required: true },
        editorMode       : { Type: String, required: true },
        showCalcFields   : { Type: Boolean, required: false, default: false },
        disableMulti     : { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            historyOpen      : false,
            colleaguesReady  : false,
            viewKey          : false,
            snapshotsVisible : false,
            timerIndex       : false,
            activeList       : false,
            leftVisible      : false,
            rightVisible     : false,
            listHasCalcFields: false,
            instanceId       : this.$core.getUuid().generate()
        }
    },

    emits: [ 'close', 'closeList', 'modeSwitch', 'print', 'excel', 'history', 'filterVisibleChange', 'recallVisibleChange', 'showListSelect', 'setActiveList', 'toggleCalcFields' ],

    watch: {
        activeListId: {
            immediate: true,
            handler()
            {
                this.handleScroll()
            }
        },
        lists       : {
            deep     : true,
            immediate: true,
            handler()
            {
                this.$nextTick()
                    .then( () =>
                    {
                        this.handleScroll()
                    } )
            }
        }
    },

    created()
    {

        if( this.$props.lists instanceof Map )
        {
            this.$props.lists.forEach( ( list, localId ) =>
            {

                if( localId === this.$props.activeListId )
                {

                    this.activeList = list
                    this.listHasCalcFields = Array.isArray( this.activeList.calculatedColumns ) && this.activeList.calculatedColumns.length > 0
                }
            } )
        }

        this.$core.cc().awaitReadiness()
            .then( () =>
            {
                this.colleaguesReady = true
                this.timerIndex = setInterval( () =>
                {
                    this.refreshSnapshots()
                }, 1000 )
            } )
    },

    mounted()
    {
        this.handleScroll()
    },

    beforeUnmount()
    {
        clearInterval( this.timerIndex )
    },

    methods: {

        refreshSnapshots()
        {
            this.snapshotsVisible = Array.isArray( this.$props.snapshots ) && 1 < this.$props.snapshots.length
        },

        handleHistoryClick()
        {
            this.historyOpen = !this.historyOpen
        },

        snapshotTitle( snapshot )
        {

            let tsmp          = this.$core.getFriendlyTimestamp()
                                    .timestampFromMysql( snapshot.datetime_snapshot ),
                timestamp     = this.$core.getFriendlyTimestamp()
                                    .formattedDateTime( tsmp ),
                colleague     = this.$core.cc().getById( snapshot.id_author ),
                colleagueName = 'unbekannt'

            if( undefined !== colleague )
            {
                colleagueName = colleague.firstname + ' ' + colleague.lastname
            }
            else
            {
                if( this.$store.getters.idUser === snapshot.id_author )
                {
                    colleagueName = 'von mir'
                }
                else
                {
                    colleagueName += ' (' + snapshot.id_author + ')'
                }
            }
            return timestamp + ': ' + colleagueName
        },

        handleSelect( event )
        {
            this.$emit( 'history', event.target.value )
        },

        getLastSelectableList( elm )
        {

            let ul     = elm.querySelector( 'ul' ),
                lastLi = ul.lastElementChild

            return lastLi

        },

        handleScroll()
        {

            let elm = document.querySelector( '#list-switcher-' + this.instanceId )

            if( null !== elm )
            {

                let boundaries = document.querySelector( '#list-boundaries-' + this.instanceId )

                if( elm.scrollLeft > 10 )
                {
                    this.leftVisible = true
                }
                else
                {
                    this.leftVisible = false
                }

                let lastElm = elm.lastElementChild

                if( null !== lastElm )
                {
                    let maxX          = elm.offsetWidth - lastElm.offsetWidth,
                        maxScrollable = elm.scrollWidth - elm.scrollLeft,
                        scrolledToEnd = maxScrollable <= ( boundaries.offsetWidth + 10 )

                    if( elm.scrollLeft < ( maxX - 10 ) && elm.scrollWidth > boundaries.offsetWidth )
                    {
                        this.rightVisible = true
                    }
                    else
                    {
                        this.rightVisible = false
                    }

                    if( scrolledToEnd )
                    {
                        this.rightVisible = false
                    }

                }

            }

        },

        applyScroll( dir )
        {

            let elm         = document.querySelector( '#list-switcher-' + this.instanceId ),
                scrollWidth = elm.offsetWidth

            switch( dir )
            {
                case 'left':
                    elm.scrollBy( {
                        left    : ( scrollWidth * -1 ),
                        behavior: 'smooth'
                    } )
                    break
                case 'right':
                    elm.scrollBy( {
                        left    : scrollWidth,
                        behavior: 'smooth'
                    } )
                    break
            }
        },

        handleAddOne()
        {
            this.$emit( 'showListSelect' )
            this.applyScroll( 'right' )
        }

    }
}
</script>

<style scoped>

.element-value-history {
    float: right;
    margin-right: 25px;
    z-index: 999;
}

div.value-history {
    border-radius: 10px;
    background: #ffffff;
    font-size: 0.8em;
    padding-right: 15px;
}

</style>