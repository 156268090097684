<template>
    <div>
        <div>
            <input class="score" :type="getFieldType()" :value="elementValue" placeholder=""
                   :ref="refName"
                   :id="'form-element-'+refName" @keyup="handleValue" @change="handleValue"
                   :disabled="disabled"
                   @click="skip"/>
        </div>
    </div>
</template>

<script>

    import MixinResettableComponent from '@/mixins/MixinResettableComponent'

    export default {
        name      : 'ScoreBoxQuarters',

        mixins: [ MixinResettableComponent ],

        props: {
            id          : { Type: String, required: true },
            refName     : { Type: String, required: false },
            value       : { Type: String, required: false },
            validator   : { Type: String, required: false },
            disabled    : { Type: Boolean, required: false, default: false },
            singleScoreBox : { Type: Boolean, required: false }
        },
        data()
        {
            return {
                elementValue   : '',
                toggling       : false,
                initializing   : true
            }
        },
        watch     : {
            elementValue: function( newValue, oldValue )
            {
                if( !this.initializing
                    && newValue !== oldValue )
                {
                    this.handleValue()
                }
            }
        },
        mounted()
        {
            this.setValue()
            setTimeout( () => {
                this.initializing = false
            }, 200 )

        },
        methods   : {

            getFieldType()
            {

                return 'text'

            },

            setValue()
            {

                this.elementValue = this.$props.value
                document.querySelector( '#form-element-'+this.$props.refName ).value = this.elementValue

                if( !this.initializing )
                {
                    this.handleValue()
                }

            },

            skip( event )
            {
                event.stopPropagation()
            },

            validate( value )
            {

                let valids = [ '1+', '1', '1-', '1-2', '2+', '2', '2-', '2-3', '3+', '3', '3-', '3-4', '4+', '4', '4-', '4-5', '5+', '5', '5-', '5-6', '6+', '6' ]

                if( '' !== value.trim() )
                {

                    if( -1 < valids.indexOf( value.trim() ) )
                    {
                        return value.trim()
                    }

                }

                return ''

            },

            handleValue( event )
            {

                let value = this.validate( event && event.target ? event.target.value : this.$refs[this.$props.refName].value )
                this.elementValue = value.trim() !== '' ? value : undefined
                document.querySelector( '#form-element-'+this.$props.refName ).value = this.elementValue ? this.elementValue : ''

                this.$emit( 'change', this.$props.id, this.elementValue )

                if( !this.initializing )
                {
                    this.$emit( 'update', this.$props.refName, 'scoreBox', 'update', undefined, this.elementValue )
                }

            }
        }

    }
</script>
