<script>
export default {

    name: 'MixinOpenEditor',

    data()
    {
        return {
            viewItemList: [ 'List' ],
            ready       : false
        }
    },

    watch: {
        '$bus.triggers.lastObjectCreated': {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( null !== newValue
                    && this.ready )
                {
                    this.handleBusTrigger( newValue, oldValue )
                }
            }
        }
    },

    created()
    {

        this.$core.setState( 'last-trigger-open', null )
        setTimeout( () => {
            this.ready = true
        }, 500 )

    },

    methods: {

        handleBusTrigger( newValue, oldValue )
        {

            if( -1 === this.viewItemList.indexOf( this.$props.viewItem ) )
            {
                return
            }

            if( true !== this.$core.settings().getSetting( 'openAfterCreate' ) )
            {
                return
            }

            let lastTrigger = this.$core.getState( 'last-trigger-open' )

            if( undefined !== newValue
                && newValue !== oldValue
                && ( null === lastTrigger || undefined === lastTrigger )
                && true !== this.$core.getState( 'listEditorOpen' )
                && 'list' === this.$bus.triggers.lastObjectType )
            {

                this.triggerListOpenById( newValue )
                return

            }

            if( undefined !== newValue
                && newValue !== oldValue
                && ( null === lastTrigger || undefined === lastTrigger )
                && 'list' === this.$bus.triggers.lastObjectType
                && null !== this.$bus.triggers.lastObjectCreated
                && true === this.$core.getState( 'listEditorOpen' )
                && true === this.$core.getState( 'last-action-cloned' ) )
            {

                this.triggerAddListToEditorById( newValue )
                return

            }

        },

        triggerListOpen( list )
        {

            if( undefined !== list )
            {

                this.$core.setState( 'last-trigger-open', list.localId )
                setTimeout( () =>
                {
                    this.$core.setState( 'last-trigger-open', null )
                }, 1000 )
                this.launchEditor( list )

            }

        },

        triggerListOpenById( localId )
        {

            let list = this.$core
                           .getBaseClassHelper()
                           .get( 'list' )
                           .findListById( localId, 'cache' )

            if( undefined !== list
                && null === this.$core.getState( 'last-trigger-open' ) )
            {
                this.triggerListOpen( list )
            }

        },

        triggerAddListToEditor( list )
        {

            if( undefined !== list )
            {
                this.$core.setState( 'last-trigger-open', list.localId )
                setTimeout( () =>
                {
                    this.$core.setState( 'last-trigger-open', null )
                }, 1000 )
                this.$core.getEventManager().dispatch( 'on-add-list-to-editor', list )
            }

        },

        triggerAddListToEditorById( localId )
        {

            let list = this.$core
                           .getBaseClassHelper()
                           .get( 'list' )
                           .findListById( localId, 'cache' )

            if( undefined !== list
                && null === this.$core.getState( 'last-trigger-open' ) )
            {
                this.triggerAddListToEditor( list )
            }

        },

        launchEditor( item )
        {

            this.$core.getEventManager()
                .add( 'on-definition-update-' + this.ownId, ( event ) =>
                {
                    this.handleDefinitionUpdate( event )
                } )

            this.$core.getEventManager()
                .add( 'on-close-editor-' + this.ownId, ( event ) =>
                {
                    this.handleClose( event )
                } )

            setTimeout( () => {

                this.$core.getUi()
                    .showOverlay( {
                        type        : 'listEditor',
                        targetId    : null, //this.ownId,
                        editItem    : item,
                        inListEditor: false,
                        escapeNested: true,
                        mode        : 'horizontal', //this.mode,
                        listFilter  : [] //this.listFilter
                    } )

            }, 400 )

        }


    }

}
</script>