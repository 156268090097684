export default class CompetenceCategoryCalculator
{

    /*eslint-disable*/

    constructor( core )
    {

        if( !CompetenceCategoryCalculator.instance )
        {

            this.logger = core.getLogger()
            this.config = core.getConfig()
            this.f = core.f()
            this.uuid = core.getUuid()
            this.eventManager = core.getEventManager()
            this.baseClassHelper = core.getBaseClassHelper()
            this.settings = core.settings()
            this.sorter = core.getSorter()

            this.competenceCategoryTree = core.getCompetenceCategoryTree()

            this.initialized = false
            this.logSign = 'Core::Helpers::CompetenceCategoryCalculator (CCCALC)'
        }

    }

    calculateResults( reference, tree, archivedOverride, referenceIdOverride )
    {

        let archived     = undefined !== archivedOverride ? archivedOverride : reference.archived,
            referenceId  = undefined !== referenceIdOverride ? referenceIdOverride : reference.localId,
            lists        = this.prepareLists(),
            keys         = this.competenceCategoryTree.getListFieldKeys( referenceId ),
            sortingRules = this.settings.getSetting( 'sortingDirections' ),
            elementList  = [],
            studentList  = []

        for( const [ localId, list ] of lists )
        {
            for( let c in list.columns )
            {
                if( 'competenceSelector' === list.columns[ c ].type
                    && -1 < keys.indexOf( list.columns[ c ].caption ) )
                {

                    this.sorter
                        .multiSortObjects( list.lists, [ [ 'timestamp', 'descending' ] ], true )

                    let students = this.f.getStudentsListForListElement( list )
                    this.appendStudents( studentList, students )

                    elementList.push( {
                        localId : localId,
                        list    : list,
                        students: studentList
                    } )

                }

            }
        }

        this.sorter.multiSortObjects( studentList, sortingRules.students )

        return {
            calculation: this.calculateCompetenceTree( tree, elementList, studentList ),
            allStudents: studentList,
            elementList: elementList
        }

    }

    prepareLists()
    {

        let lists = new Map(),
            allCompetenceLists = this.baseClassHelper.get( 'list' ).registry.hasCompetences

        for( let a in allCompetenceLists )
        {
            let list = this.baseClassHelper.get( 'list' ).getById( allCompetenceLists[a] )
            if( list )
            {
                lists.set( allCompetenceLists[a], list )
            }
        }

        return lists

    }

    appendStudents( studentList, students )
    {

        for( let s in students )
        {
            if( undefined !== students[s]
                && !studentList.find( o => o.localId === students[ s ].localId ) )
            {
                studentList.push( students[ s ] )
            }
        }

    }

    hasSubs( elm )
    {
        return Array.isArray( elm.subs ) && 0 < elm.subs.length
    }

    prepareCalculationTree( tree, allStudents )
    {

        let calcTree  = JSON.parse( JSON.stringify( tree ) ),
            byStudent = {}

        for( let a in allStudents )
        {
            byStudent[ allStudents[ a ].localId ] = {}
            byStudent[ allStudents[ a ].localId ][ calcTree.localId ] = this.f.deref( calcTree )
            byStudent[ allStudents[ a ].localId ][ calcTree.localId ]._allIds = []
            byStudent[ allStudents[ a ].localId ][ calcTree.localId ]._countComplete = 0
            byStudent[ allStudents[ a ].localId ][ calcTree.localId ]._countTotal = 1
            if( this.hasSubs( calcTree ) )
            {
                byStudent[ allStudents[ a ].localId ][ calcTree.localId ]._countTotal = calcTree.subs.length

                for( let s in calcTree.subs )
                {
                    byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].localId ] = this.f.deref( calcTree.subs[ s ] )
                    byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].localId ]._allIds = []
                    byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].localId ]._countComplete = 0
                    byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].localId ]._countTotal = 1

                    if( this.hasSubs( calcTree.subs[ s ] ) )
                    {

                        byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].localId ]._countTotal = calcTree.subs[ s ].subs.length

                        for( let ss in calcTree.subs[ s ].subs )
                        {
                            byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].localId ] = this.f.deref( calcTree.subs[ s ].subs[ ss ] )
                            byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].localId ]._allIds = []
                            byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].localId ]._countComplete = 0
                            byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].localId ]._countTotal = 1

                            if( this.hasSubs( calcTree.subs[ s ].subs[ ss ] ) )
                            {
                                byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].localId ]._countTotal = calcTree.subs[ s ].subs[ ss ].subs.length

                                for( let sss in calcTree.subs[ s ].subs[ ss ].subs )
                                {

                                    byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].subs[ sss ].localId ] = this.f.deref( calcTree.subs[ s ].subs[ ss ].subs[ sss ] )
                                    byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].subs[ sss ].localId ]._allIds = []
                                    byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].subs[ sss ].localId ]._countComplete = 0
                                    byStudent[ allStudents[ a ].localId ][ calcTree.subs[ s ].subs[ ss ].subs[ sss ].localId ]._countTotal = 1

                                }
                            }
                        }
                    }
                }
            }
        }

        return {
            byCategory: calcTree,
            byStudent : byStudent
        }

    }

    checkIsComplete( countDone, countTodo, neededRatio )
    {

        let ratio = ( countDone / countTodo ) * 100,
            done  = neededRatio <= ratio

        return done

    }

    inTree( competenceId, tree )
    {

        if( !tree )
        {
            return false
        }

        if( tree.localId === competenceId )
        {
            return true
        }
        if( this.hasSubs( tree ) )
        {
            for( let s in tree.subs )
            {
                if( this.inTree( competenceId, tree.subs[ s ] ) )
                {
                    return true
                }
            }
        }
        return false

    }

    countResult( studentLocalId, values, listElement, calculation, completeRatio )
    {

        let timestamp   = listElement.timestamp,
            listLocalId = listElement.localId

        for( let v in values )
        {

            let valueId = values[ v ]

            if( this.uuid.validate( valueId ) )
            {

                for( let c in calculation.byStudent[ studentLocalId ] )
                {

                    if( calculation.byStudent[ studentLocalId ][ c ].localId === valueId )
                    {

                        if( -1 === calculation.byStudent[ studentLocalId ][ c ]._allIds.indexOf( valueId ) )
                        {
                            calculation.byStudent[ studentLocalId ][ c ]._countComplete++
                            calculation.byStudent[ studentLocalId ][ c ]._allIds.push( valueId )
                            if( undefined === calculation.byStudent[ studentLocalId ][ c ]._tsmpComplete
                                || calculation.byStudent[ studentLocalId ][ c ]._tsmpComplete > timestamp )
                            {
                                calculation.byStudent[ studentLocalId ][ c ]._listComplete = listLocalId
                                calculation.byStudent[ studentLocalId ][ c ]._tsmpComplete = timestamp
                            }
                        }
                    }

                    if( this.hasSubs( calculation.byStudent[ studentLocalId ][ c ] ) )
                    {
                        for( let s in calculation.byStudent[ studentLocalId ][ c ].subs )
                        {

                            if( calculation.byStudent[ studentLocalId ][ c ].subs[ s ].localId === valueId
                                && -1 === calculation.byStudent[ studentLocalId ][ c ]._allIds.indexOf( valueId ) )
                            {


                                calculation.byStudent[ studentLocalId ][ c ]._allIds.push( valueId )
                                calculation.byStudent[ studentLocalId ][ c ]._countComplete++
                                if( this.checkIsComplete( calculation.byStudent[ studentLocalId ][ c ]._countComplete, calculation.byStudent[ studentLocalId ][ c ]._countTotal, completeRatio ) )
                                {
                                    if( undefined === calculation.byStudent[ studentLocalId ][ c ]._tsmpComplete
                                        || calculation.byStudent[ studentLocalId ][ c ]._tsmpComplete > timestamp )
                                    {
                                        calculation.byStudent[ studentLocalId ][ c ]._tsmpComplete = timestamp
                                    }
                                }

                            }

                        }

                    }

                }

            }

        }

    }

    calculateCompetenceResults( lists, calculation )
    {

        let competenceCompleteRatio = this.settings.getSetting( 'competenceCompleteRatio' ) || 50

        for( let l in lists )
        {

            if( undefined !== lists[ l ].values )
            {
                for( let v in lists[ l ].values )
                {
                    try
                    {

                        let temp           = v.split( /___/g ),
                            studentLocalId = temp[ 0 ],
                            values         = lists[ l ].values[ v ]

                        this.countResult( studentLocalId, values, lists[ l ], calculation, competenceCompleteRatio )

                    }
                    catch( e )
                    {
                    }
                }
            }

        }

        return calculation

    }

    checkRootComplete( calculation )
    {
        for( let sId in calculation.byStudent )
        {
            for( let c in calculation.byStudent[ sId ] )
            {

                if( 'subCompetenceField' === calculation.byStudent[ sId ][ c ].type )
                {
                    let total     = calculation.byStudent[ sId ][ c ].subs.length,
                        done      = 0,
                        timestamp = 0

                    for( let s in calculation.byStudent[ sId ][ c ].subs )
                    {

                        let branch = calculation.byStudent[ sId ][ calculation.byStudent[ sId ][ c ].subs[ s ].localId ]
                        if( undefined !== branch._tsmpComplete )
                        {
                            done++
                            if( branch._tsmpComplete > timestamp )
                            {
                                timestamp = branch._tsmpComplete
                            }
                        }

                    }

                    if( 0 !== total  && done === total )
                    {
                        calculation.byStudent[ sId ][ c ]._tsmpComplete = 0 !== timestamp ? timestamp : false
                    }

                }
            }
        }
    }

    calculateCompetenceTree( tree, elementList, allStudents )
    {

        let summary     = {},
            calculation = this.prepareCalculationTree( tree, allStudents )

        for( let e in elementList )
        {
            for( let c in elementList[ e ].list.columns )
            {
                if( 'competenceSelector' === elementList[ e ].list.columns[ c ].type )
                {

                    let temp    = elementList[ e ].list.columns[ c ].caption.split( /:/g ),
                        localId = temp[ 1 ]

                    if( this.inTree( localId, tree ) )
                    {

                        this.calculateCompetenceResults( elementList[ e ].list.lists, calculation )

                    }

                }
            }

        }

        this.checkRootComplete( calculation )

        return calculation

    }

}