<template>
    <div :class="'select-wrapper'+getDisabledClass()">
        <select :disabled="singleModel" class="select_element" :id="'form-element-'+refName" @click="$core.f().skip"
                @change="checkSelect()">
            <option v-for="option in optionValues"
                    :selected="option.key === value"
                    :key="'key_option_'+option.key"
                    :value="option.key">{{ option.caption }}
            </option>
        </select>
    </div>
</template>

<script>

export default {
    name: 'ScoreModelSelector',

    props: {
        id         : { Type: String, required: true },
        component  : { Type: String, required: false },
        ruleSet    : { Type: String, required: false },
        refName    : { Type: String, required: false },
        value      : { Type: String, required: false },
        display    : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        validator  : { Type: String, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'update', 'enterPressed', 'backspacePressed', 'selected' ],

    created()
    {

        let models = this.$core.settings().getSetting( 'scoreModel' )
        if( !Array.isArray( models ) )
        {
            let newModels = []
            newModels.push( models )
            models = newModels
        }

        this.singleModel = 1 === models.length
        let selectedModel = undefined

        for( let m in models )
        {
            selectedModel = selectedModel || models[ m ]
            switch( models[ m ] )
            {
                case 'basic':
                    this.optionValues.push( {
                        sortIndex: 0,
                        key      : 'basic',
                        caption  : 'ganze Noten (sehr gut - ungenügend)'
                    } )
                    break
                case 'extended':
                    this.optionValues.push( {
                        sortIndex: 1,
                        key      : 'extended',
                        caption  : 'halbe Noten (1+, 1, 1-, 2+, 2, 2-, ...)'
                    } )
                    break
                case 'quarters':
                    this.optionValues.push( {
                        sortIndex: 2,
                        key      : 'quarters',
                        caption  : 'Viertel- und Halbnoten (1+, 1, 1-, 1-2, 2+, 2, 2-, 2-3, ...)'
                    } )
                    break
                case 'points':
                    this.optionValues.push( {
                        sortIndex: 3,
                        key      : 'points',
                        caption  : 'Punkte (1-15)'
                    } )
                    break
                case 'bremen':
                    this.optionValues.push( {
                        sortIndex: 4,
                        key      : 'bremen',
                        caption  : 'Bremen: Beurteilungssystem (sicher, überwiegend sicher...)'
                    } )
                    break
                case 'hamburgEG':
                    this.optionValues.push( {
                        sortIndex: 5,
                        key      : 'hamburgEG',
                        caption  : 'Hamburg: E- und G-Noten'
                    } )
                    break
                case 'hamburgEGplus':
                    this.optionValues.push( {
                        sortIndex: 6,
                        key      : 'hamburgEGplus',
                        caption  : 'Hamburg: halbe E- und G-Noten (E1+, E1, E1-, E2+, ...)'
                    } )
                    break
                case 'austria':
                    this.optionValues.push( {
                        sortIndex: 7,
                        key      : 'austria',
                        caption  : 'Österreich: ganze Noten (sehr gut - nicht genügend)'
                    } )
                    break
            }
        }

        this.$core.getSorter().sortObjects( this.optionValues, 'sortIndex', 'ascending' )
        this.setSelect( selectedModel )


    },

    mounted()
    {
        this.initialized = true
    },

    data()
    {
        return {
            selected    : false,
            direction   : false,
            selectedType: false,
            singleModel : false,
            prefilled   : false,
            initialized : false,
            optionValues: []
        }
    },

    watch: {

        value: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( this.initialized && newValue !== oldValue )
                {
                    this.$nextTick()
                        .then( () =>
                        {
                            this.checkSelect()
                        } )
                }
            }
        }

    },

    methods: {

        setSelect( value )
        {
            this.$core.getEventManager().dispatch( 'on-scoremodel-change', value )
            this.$emit( 'update', this.$props.refName, 'scoreModelSelector', 'update', undefined, value )
        },

        checkSelect()
        {
            let elementSelect = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== elementSelect )
            {
                this.$core.getEventManager().dispatch( 'on-scoremodel-change', elementSelect.value )
                this.$emit( 'update', this.$props.refName, 'scoreModelSelector', 'update', undefined, elementSelect.value )
            }
        },

        getDisabledClass()
        {
            return this.singleModel ? ' disabled-select' : ''
        }

    }

}
</script>