export default class SyncCrudKeyUpdates
{

    constructor( parent )
    {

        this.p = parent
        this.parent = this.p.parent

        this.getState = ( key ) =>
        {
            return this.parent.getState( key )
        }

        this.triggerCount = 0

        return this

    }

    shouldSync()
    {
        return true
    }

    cleanup( list )
    {
        return new Promise( resolve =>
        {

            let promises = []

            while( 0 < list.length )
            {

                let id = list.shift()
                promises.push( () =>
                {

                    return this.parent.database.readType( id )
                               .then( type =>
                               {

                                   if( 'holidays' !== type
                                       && 'schoolHolidays' !== type )
                                   {

                                       let storable = this.parent.getBaseClassHelper()
                                                          .get( type )
                                       storable.delete( id )
                                               .then( () =>
                                               {
                                                   this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudKeyUpdates:cleanup', 'deleted object', id )
                                               } )

                                   }

                               } )

                } )
            }

            this.parent.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    sync()
    {

        return new Promise( resolve =>
        {

            this.triggerCount++

            if( 1 !== this.triggerCount
                && 0 !== this.triggerCount % 10 )
            {
                return resolve()
            }

            if( this.getState( 'listEditorOpen' ) )
            {

                this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudDeletions', 'list editor open: avoiding conflicts and awaiting next run...' )
                return resolve()

            }

            this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudKeyUpdates', 'performing sync run...' )

            let list = []

            this.parent.database.readAllObjects( 'objects' )
                .then( elements =>
                {

                    for( let e in elements )
                    {
                        if( undefined !== elements[ e ].item
                            && this.parent.f.isOwn( elements[ e ].item, true )
                            && undefined !== elements[ e ].item.remoteId )
                        {
                            list.push( elements[ e ].key )
                        }
                    }

                    let message = {
                        method: 'objects.getOwnKeylist',
                        list  : list
                    }

                    this.parent.client.request( message )
                        .then( response =>
                        {

                            let keylist = response.keylist.result
                            for( let k in keylist )
                            {
                                if( keylist[ k ].keyCount > 0 )
                                {
                                    let idx = list.indexOf( keylist[ k ].id_local )
                                    if( -1 < idx )
                                    {
                                        list.splice( idx, 1 )
                                    }
                                }
                            }

                            return resolve()


                        } )
                        .catch( () =>
                        {
                            return resolve()
                        } )

                } )

        } )

    }

}