import ListsObjectClass from "@/objects/ListsObjectClass";

export default class RecallListClass extends ListsObjectClass
{

    constructor( core )
    {

        super( core )
        this.logger = core.getLogger()
        this.eventManager = core.getEventManager()
        this.baseClassHelper = core.getBaseClassHelper()

        if( undefined === this.baseClassHelper )
        {
            this.eventManager.append( 'on-baseclass-available', ( helper ) =>
            {
                this.baseClassHelper = helper
            } )
        }

        this.listType = 'recallList'
        this.setStudentEditable( this.listType, true )

        /** for future use **/
        this.isPooled = false

        let fields = [
            {
                ref        : 'referenceKey',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : null
            },
            {
                ref        : 'listname',
                caption    : 'Name',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'listname'
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                extended   : true,
                validator  : 'text'
            },
            {
                ref        : 'duedate',
                caption    : 'Fälligkeitsdatum',
                type       : 'DateBox',
                reformatter: 'date',
                optional   : true,
                validator  : 'date'
            },
            {
                ref        : 'selectedClass',
                caption    : 'Schüler:innen',
                type       : 'StudentGroupSelector',
                reformatter: null,
                optional   : false,
                validator  : 'select'
            },
            {
                ref        : 'columns',
                caption    : 'Spalten',
                type       : 'RowAdder',
                component  : 'FieldSelector',
                reformatter: null,
                optional   : false,
                validator  : 'select'
            },
            {
                ref        : 'linkedColumns',
                caption    : 'Verknüpfte Spalten',
                type       : 'LinkedColumnsSelector',
                styleUpdate: false,
                reformatter: null,
                extended   : true,
                validator  : null
            },
            {
                ref        : 'calculatedColumns',
                caption    : 'Rechenspalten',
                type       : 'CalculatedColumnsSelector',
                styleUpdate: false,
                reformatter: null,
                extended   : true,
                validator  : null
            },
            {
                ref        : 'forceTimestamp',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'storeAsTemplate',
                caption    : 'als Vorlage speichern',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false,
                extended   : true
            },
            {
                ref        : 'organizerSlot',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            }
        ]

        if( this.isPooled )
        {
            fields.push( {
                ref        : 'storeAsSchoolTemplate',
                caption    : 'als Schulvorlage speichern',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false,
                extended   : true
            } )
        }

        this.setListFormFields( this.listType, fields )

        this.setColumnParser( this.listType, ( columns, values ) =>
        {

            for( let col in values.columns )
            {

                let setup   = values.columns[ col ],
                    type    = setup.value.type,
                    caption = undefined !== setup.value.caption ? setup.value.caption : setup.value.value

                if( undefined === caption && undefined === type
                    && undefined !== setup.type )
                {
                    type = setup.type
                    caption = undefined !== setup.caption ? setup.caption : setup.value
                }

                let id = col + '_' + this.sanitizers.cleanId( caption )

                if( undefined !== type && undefined !== caption )
                {
                    if( caption.trim() !== '' )
                    {
                        columns.push( {
                            type   : type,
                            id     : id,
                            caption: caption
                        } )
                    }
                }

            }

        } )

        this.setBeforeUpdate( this.listType, ( rawObject ) =>
        {

            if( undefined !== rawObject.values
                && undefined !== rawObject.doneFields
                && 0 < Object.keys( rawObject.values ).length
                && 0 < rawObject.doneFields.length )
            {

                let filterBy   = rawObject.columns[ 0 ].filterBy,
                    element    = this.baseClassHelper.getObjectById( filterBy ),
                    count      = 0,
                    doneCount  = 0,
                    doneFields = []

                for( let f in rawObject.doneFields )
                {

                    let temp  = rawObject.doneFields[ f ].split( /_/g ),
                        idx   = temp.shift(),
                        field = temp.join( '_' )

                    doneFields.push( '___' + field + '___' + ( parseInt( idx ) + 1 ) )

                }

                if( undefined !== element )
                {
                    count = Array.isArray( element.students ) ? element.students.length : 0
                }

                count = count * doneFields.length

                for( let v in rawObject.values )
                {
                    for( let d in doneFields )
                    {
                        if( -1 < v.indexOf( doneFields[ d ] )
                            && undefined !== rawObject.values[ v ]
                            && null !== rawObject.values[ v ] )
                        {
                            doneCount++
                        }
                    }
                }

                rawObject.isDone = doneCount === count

            }

        } )

        return this

    }

}