<template>
    <div :class="'todo-wrapper hoverable '+dueClass+' '+item.color+( selected ? ' selected-item' : '' )"
         :id="'printable-'+item.localId"
         :key="'todo-'+item.localId">
        <div class="todo-item" @click="handleClick">
            <div class="todo-title">{{ item.title }}</div>
            <div class="todo-inner">
                <div class="todo-left">

                    <div class="header">
                        <div class="element-information">
                            <div class="base-meta">
                                <div :key="headerKey">
                                    <span
                                        v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.timestamp )"></span>
                                    <template v-if="0< item.update"> - zuletzt bearbeitet
                                        <span v-if="0 < item.update"
                                              v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.update )"></span>
                                    </template>
                                </div>
                            </div>
                            <div class="markers">
                                <ReferenceMarkers :item="item"/>
                            </div>
                        </div>
                    </div>

                    <div class="todo-status">
                        <template v-if="item.done === 1">
                            &bull; <strong>erledigt</strong> <span
                            v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.done_timestamp )"></span>
                        </template>
                        <template v-else>
                            &bull; <strong v-if="dueDays < 0">war </strong><strong>fällig</strong> <span
                            v-html="$core.getFriendlyTimestamp().friendlyDue( item.duedate )"></span>
                        </template>
                    </div>
                    <div :class="'todo-body'+( open ? ' open' : '' )" @click="handleClick">
                        <p v-if="undefined !== item.body
                                     && 'undefined' !== item.body
                                     && item.body.length > 0" :class="classList" v-html="$core.f().toHtml( item.body )"></p>
                        <p v-else><span class="ignore">- kein Text vorhanden -</span></p>
                    </div>
                    <TodoList
                        v-if="open && undefined !== item.todos && 0 < item.todos.length && '' !== item.todos[0].trim()"
                        :item="item"
                        :done="item.todos_done"
                        @stateChange="handleTodoListStateChange"/>

                </div>
                <div :class="'todo-right '+dueClass" @click="$core.f().skip">
                    <DueBox v-if="1 !== item.done" :dueDays="dueDays"/>
                    <TodoCheck :item="item"
                               :done="item.done"
                               @stateChange="handleStateChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import ViewItemHandler       from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import DueBox                from '@/components/elements/viewItems/todo/DueBox'
import TodoCheck             from '@/components/elements/viewItems/todo/TodoCheck'
import TodoList              from '@/components/elements/viewItems/todo/TodoList'
import ReferenceMarkers      from '@/components/elements/markers/ReferenceMarkers'
import MixinWatchElementKey  from "@/mixins/MixinWatchElementKey";
import MixinLivingTimestamps from "@/mixins/MixinLivingTimestamps";

export default {

    name      : 'Todo',
    components: {
        ReferenceMarkers,
        TodoList,
        TodoCheck,
        DueBox,
    },
    extends   : ViewItemHandler,
    mixins    : [ MixinWatchElementKey, MixinLivingTimestamps ],
    props     : {
        item      : { type: Object, required: true },
        selected  : { type: Boolean, required: false, default: false },
        selectMode: { type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            unblurred: false,
            open     : false
        }
    },

    computed: {

        classList()
        {
            return ( !this.unblurred && this.$props.item.blurry ) ? 'blurred' : ''
        },

        dueDays()
        {
            return this.$core.getFriendlyTimestamp().dueDays( this.$props.item.duedate )
        },

        dueClass()
        {

            if( this.$props.item.done === 1 )
            {
                return 'done'
            }

            let dueDiff = this.$core.getFriendlyTimestamp().dueDays( this.$props.item.duedate )

            if( dueDiff < 0 )
            {
                return 'overdue'
            }

            if( dueDiff < 4 )
            {
                return 'dueshortly'
            }

            return 'due'
        }

    },

    mounted()
    {
        this.open = this.$core.getState( 'todo-open-'+this.$props.item.localId ) || false
    },

    methods: {

        handleClick( event )
        {
            if( !this.$props.selectMode )
            {
                this.$core.f().skip( event )
                this.open = !this.open
                this.$core.setState( 'todo-open-'+this.$props.item.localId, this.open )
            }
        },

        handleStateChange( state )
        {

            let todo = JSON.parse( JSON.stringify( this.$props.item ) )

            todo.done = state === true ? 1 : false
            todo.done_timestamp = state === true ? Date.now() : ''

            this.$core.getBaseClassHelper()
                .get( 'todo' )
                .update(
                    todo,
                    todo.localId,
                    todo.remoteId,
                    todo.timestamp,
                    todo.localKey
                )

        },

        handleTodoListStateChange( lastChange, state )
        {

            let todo  = JSON.parse( JSON.stringify( this.$props.item ) ),
                todos = todo.todos.length,
                done  = 0

            todo.todos_done = state

            for( let t in todo.todos )
            {
                if( undefined !== todo.todos_done
                    && true === todo.todos_done[ todo.todos[ t ] ] )
                {
                    done++
                }
            }

            todo.done = ( done === todos ? 1 : 0 )
            todo.done_timestamp = ( done === todos ? Date.now() : '' )
            this.$core.getBaseClassHelper()
                .get( 'todo' )
                .update(
                    todo,
                    todo.localId,
                    todo.remoteId,
                    todo.timestamp,
                    todo.localKey
                )

        }

    }

}
</script>