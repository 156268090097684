<template>
    <div v-if="initialized">
        <ListScoresOverview v-if="!empty"
                            :objectReference="objectReference"
                            :setup="setup"
                            :filter="'student' === objectType ? objectReference.localId : undefined"
                            :changeKey="changeKey"/>

        <InlineMessage :active="empty && !viewIsFiltered"
                       :key="'message-inline-own-scorings'"
                       classname="empty"
                       :title="$core.t( 'default-caption-empty' )"
                       :message="$core.t( 'message-empty-scorings' )"/>
        <InlineMessage :active="empty && viewIsFiltered"
                       :key="'message-inline-own-scorings'"
                       classname="nothing"
                       :title="$core.t( 'caption-all-filtered' )"
                       :message="$core.t( 'message-all-filtered' )"/>
    </div>
    <PreheatingIndicator v-else/>
</template>

<script>
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";
import ListScoresOverview  from "@/components/elements/viewItems/listViews/ListScoreOverview";
import InlineMessage       from "@/components/messages/InlineMessage";

export default {

    name      : "TabScorings",
    components: { InlineMessage, ListScoresOverview, PreheatingIndicator },
    emits     : [ 'count' ],

    props: {
        objectType     : { type: String, required: false },
        objectReference: { type: Object, required: false },
        tabParams      : { type: Object, required: false },
        filterList     : { type: Object, required: false },
        fixedfilter    : { type: Object, required: false }
    },

    data()
    {
        return {
            initialized   : false,
            setup         : null,
            empty         : false,
            viewIsFiltered: false,
            activeFilters : {},
            changeKey     : this.$core.getUuid().generate()
        }
    },

    created()
    {

        this.activeFilters = {}
        this.prepare()

    },

    watch: {
        filterList: {
            immediate: true,
            deep     : true,
            handler( filterList )
            {
                this.activeFilters = this._prepareFilters( filterList )
                this.prepare()
            }
        }
    },

    methods: {

        setCount( count )
        {
            this.$emit( 'count', count )
        },

        _hasScoreBox( list )
        {

            let cols  = ( undefined !== list.columns ? list.columns
                                                     : undefined !== list.lists && undefined !== list.lists[ 0 ].columns ? list.lists[ 0 ].columns : [] ),
                found = false

            for( let c in cols )
            {
                if( this.$core.f().isset( cols[ c ].type )
                    && 'scoreBox' === cols[ c ].type )
                {

                    found = true

                }
            }

            return found

        },

        _prepareFilters( filterList )
        {

            let activeKey   = Object.keys( filterList ),
                filters     = {},
                filterCount = 0

            for( let f in activeKey )
            {

                if( undefined === filters[ activeKey[ f ] ] )
                {
                    filters[ activeKey[ f ] ] = []
                }

                for( let i in filterList[ activeKey[ f ] ] )
                {
                    filters[ activeKey[ f ] ].push( filterList[ activeKey[ f ] ][ i ].value )
                }

                filterCount += filters[ activeKey[ f ] ].length

            }

            this.viewIsFiltered = filterCount !== 0
            return filters

        },

        _getGroups()
        {
            let groups = this.$core.getBaseClassHelper()
                             .get( 'group' )
                             .getCache( true === this.$props.objectReference.archived ? 'archive' : 'cache' ),
                result = []

            /*eslint-disable*/
            for( const[ g, group ] of groups )
            {
                if( -1 < group.students.indexOf( this.$props.objectReference.localId ) )
                {
                    result.push( group.localId )
                }
            }
            /*eslint-enable*/
            return result

        },

        /*eslint-disable*/
        _filterMatch( element )
        {

            if( undefined === element )
            {
                return false
            }

            let match   = true,
                list    = ( undefined === element.lists ? element : element.lists[ 0 ] ),
                columns = this.$core.f().listColumns( element )

            if( null !== columns )
            {

                let listFilterBy = columns[ 0 ].filterBy,
                    keys         = Object.keys( this.activeFilters ),
                    filterCount  = 0,
                    attr         = '' + ( undefined !== this.$props.fixedfilter ? this.$props.fixedfilter.attr : '' ),
                    value        = '' + ( undefined !== this.$props.fixedfilter ? this.$props.fixedfilter.value : '' ),
                    groups       = []

                if( undefined !== list )
                {

                    if( 'student' === this.$props.objectType )
                    {
                        value = this.$props.objectReference.classId
                        groups = this._getGroups()
                    }

                    if( undefined !== this.$props.fixedfilter
                        && 'filterById' === attr
                        && 'all' !== listFilterBy
                        && -1 === groups.indexOf( listFilterBy )
                        && listFilterBy !== value )
                    {
                        return false
                    }

                    for( let k in keys )
                    {
                        let key = keys[ k ]
                        if( 0 < this.activeFilters[ key ].length )
                        {
                            filterCount++
                            switch( key )
                            {
                                case 'color':
                                    if( -1 === this.activeFilters[ key ].indexOf( list.color ) )
                                    {
                                        return false
                                    }
                                    break
                                case 'classes':
                                    if( -1 === this.activeFilters[ key ].indexOf( listFilterBy )
                                        && 'all' !== listFilterBy )
                                    {
                                        return false
                                    }
                                    break
                            }
                        }
                    }

                    return filterCount === 0 ? true : match

                }

            }

            return false

        },

        _getListOfLists( lists )
        {

            let parse = []

            for( let l in lists )
            {

                let list = lists[ l ]
                if( this._filterMatch( list ) )
                {

                    if( undefined === list.lists )
                    {
                        parse.push( list )
                    }
                    else
                    {
                        for( let ll in list.lists )
                        {
                            parse.push( list.lists[ ll ] )
                        }
                    }

                }

            }

            return parse

        },

        _prepareScoreColumns( list )
        {

            let setup = {
                columns: [],
                flat   : [],
                type   : undefined
            }

            switch( list.listType )
            {
                case 'test':
                    for( let c in list.columns )
                    {
                        if( 'fixed' !== list.columns[ c ].type )
                        {

                            let rawColumn = list.columns[ c ]
                            if( undefined === rawColumn.key )
                            {
                                rawColumn.key = this.$core.getSanitizers().cleanId( rawColumn.caption ) + '___' + c
                            }

                            setup.columns.push( {
                                raw: rawColumn,
                                id : c
                            } )
                            setup.columns.push( {
                                raw: 'points',
                                id : 'points'
                            } )
                            setup.flat.push( {
                                listId: list.localId,
                                raw   : list.columns[ c ],
                                id    : c
                            } )
                        }
                        setup.type = 'test'
                    }
                    break
                default:
                    for( let c in list.columns )
                    {
                        if( 'scoreBox' === list.columns[ c ].type )
                        {

                            let rawColumn = list.columns[ c ]
                            if( undefined === rawColumn.key )
                            {
                                rawColumn.key = this.$core.getSanitizers().cleanId( rawColumn.caption ) + '___' + c
                            }

                            setup.columns.push( {
                                raw: list.columns[ c ],
                                id : c
                            } )
                            setup.flat.push( {
                                listId: list.localId,
                                raw   : list.columns[ c ],
                                id    : c
                            } )
                        }
                        setup.type = 'fixed'
                    }
                    break
            }

            return setup

        },

        /*eslint-disable*/
        _prepareStudentList( list, studentList )
        {

            let filter  = list.columns[ 0 ].filterBy,
                element = 'all' !== filter ? this.$core.getBaseClassHelper().getObjectById( filter )
                                           : this.$core.getBaseClassHelper().get( 'student' ).readCache()

            if( undefined !== element
                && undefined !== element.students )
            {

                for( let s in element.students )
                {

                    let localId = element.students[ s ]
                    if( !studentList.has( localId ) )
                    {
                        let student = this.$core.getBaseClassHelper().getObjectById( localId )
                        if( this.$core.f().isset( student ) )
                        {
                            studentList.set( localId, student )
                        }
                    }

                }
            }

        },

        _hasComments( list )
        {
            if( 'test' !== list.listType )
            {
                return false
            }

            for( let c in list.columns )
            {
                if( list.columns[ c ].type === 'testcomment' )
                {
                    return true
                }
            }

            return false

        },

        _filterView( lists )
        {

            let rows        = [],
                columns     = [],
                parse       = this._getListOfLists( lists ),
                sortRules   = this.$core.settings()
                                  .getSetting( 'sortingDirections' ),
                studentList = new Map()

            this.$core.sort().multiSortObjects( parse, sortRules[ 'office' ] )

            for( let p in parse )
            {

                let list         = parse[ p ],
                    columnName   = this.$core.ts().formattedDate( list.timestamp ) + ': ' + list.listname,
                    rawTimestamp = this.$core.ts().formattedDate( list.timestamp ),
                    rawName      = list.listname,
                    scoreColumns = this._prepareScoreColumns( list )

                columns.push( {
                    name        : columnName,
                    rawTimestamp: rawTimestamp,
                    rawName     : rawName,
                    listType    : this.$core.t( 'list-type-' + list.listType ),
                    rawListType : list.listType,
                    scores      : scoreColumns,
                    hasComments : this._hasComments( list ),
                    distribution: list.distribution,
                    scoreModel  : list.scoreModel,
                    color       : list.color,
                    key         : list.referenceKey,
                    id          : p,
                    values      : list.values
                } )

                this._prepareStudentList( list, studentList )

            }

            studentList.forEach( ( value, key ) =>
            {
                rows.push( value )
            } )

            this.$core.sort().multiSortObjects( rows, this.$core.settings().getSetting( 'sortingDirections' )[ 'students' ] )

            this.setCount( columns.length )

            return {
                columns: columns,
                rows   : rows
            }

        },

        prepare()
        {

            let allLists = this.$core.getBaseClassHelper()
                               .get( 'list' )
                               .readCache( 'cache' ),
                lists    = []

            this.initialized = false
            this.setup = null

            for( let a in allLists )
            {

                let container = this.$core.getBaseClassHelper()
                                    .get( 'list' )
                                    .getListContainer( allLists[ a ].referenceKey )

                if( undefined !== container
                    && undefined !== container.lists )
                {
                    for( let l in container.lists )
                    {

                        let list = container.lists[ l ]

                        if( this._filterMatch( list )
                            && ( list.listType === 'test'
                                 || this._hasScoreBox( list ) ) )
                        {

                            lists.push( list )

                        }

                    }
                }

            }

            this.empty = 0 === lists.length
            this.setup = this._filterView( lists )
            this.changeKey = this.$core.getUuid().generate()
            this.initialized = true

        }
    }
}
</script>