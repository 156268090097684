<template>
    <div>
        <div class="select-wrapper">
            <select :id="'form-element-'+refName" @click="$core.f().skip" :disabled="getDisabled()"
                    :class="hasErrors ? 'error' : ''"
                    @change="handleUpdate">
                <option value="">bitte wählen...</option>
                <option value="all" :selected="value === 'all' || value === 'class:all'"
                        v-html="globalWording || 'alle Schüler:innen'">
                </option>
                <optgroup label="meine Klassen" v-if="0 < localClasses.length">
                    <option v-for="classInfo in localClasses" :value="'class:'+classInfo.localId"
                            :key="'key_classSel_'+classInfo.localId"
                            :selected="selection === 'class:'+classInfo.localId">
                        {{ classInfo.classname }}
                    </option>
                </optgroup>
                <template v-for="colleague in $core.cc().getAll()">
                    <optgroup :label="'Klassen von '+colleague.firstname+' '+colleague.lastname"
                              :key="'coll-'+colleague.localId"
                              v-if="undefined !== foreignClasses[ colleague.colleagueId ]
                                    && 0 < foreignClasses[ colleague.colleagueId ].length">
                        <option v-for="classInfo in foreignClasses[ colleague.colleagueId ]"
                                :value="'class:'+classInfo.localId"
                                :selected="selection === 'class:'+classInfo.localId"
                                :key="'key_classSel_'+classInfo.localId">
                            {{ classInfo.classname }}
                        </option>
                    </optgroup>
                </template>
                <optgroup label="meine Gruppen" v-if="0 < localGroups.length">
                    <option v-for="groupInfo in localGroups" :value="'group:'+groupInfo.localId"
                            :selected="selection === 'group:'+groupInfo.localId"
                            :key="'key_groupSel_'+groupInfo.localId">
                        {{ groupInfo.groupname }}
                    </option>
                </optgroup>
                <template v-for="colleague in $core.cc().getAll()">
                    <optgroup :label="'Gruppen von '+colleague.firstname+' '+colleague.lastname"
                              :key="'coll-'+colleague.localId"
                              v-if="undefined !== foreignGroups[ colleague.colleagueId ]
                                    && 0 < foreignGroups[ colleague.colleagueId ].length">
                        <option v-for="groupInfo in foreignGroups[ colleague.colleagueId ]"
                                :value="'group:'+groupInfo.localId"
                                :selected="selection === 'group:'+groupInfo.localId"
                                :key="'key_groupSel_'+groupInfo.localId">
                            {{ groupInfo.groupname }}
                        </option>
                    </optgroup>
                </template>
                <optgroup label="meine Jahrgänge" v-if="0 < localYeargroups.length">
                    <option v-for="groupInfo in localYeargroups" :value="'yeargroup:'+groupInfo.localId"
                            :selected="selection === 'yeargroup:'+groupInfo.localId"
                            :key="'key_groupSel_'+groupInfo.localId">
                        {{ groupInfo.yeargroupname }}
                    </option>
                </optgroup>
                <template v-for="colleague in $core.cc().getAll()">
                    <optgroup :label="'Jahrgänge von '+colleague.firstname+' '+colleague.lastname"
                              :key="'coll-'+colleague.localId"
                              v-if="undefined !== foreignYeargroups[ colleague.colleagueId ]
                                    && 0 < foreignYeargroups[ colleague.colleagueId ].length">
                        <option v-for="groupInfo in foreignYeargroups[ colleague.colleagueId ]"
                                :value="'yeargroup:'+groupInfo.localId"
                                :selected="selection === 'yeargroup:'+groupInfo.localId"
                                :key="'key_groupSel_'+groupInfo.localId">
                            {{ groupInfo.yeargroupname }}
                        </option>
                    </optgroup>
                </template>
            </select>
        </div>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {

    name  : 'StudentGroupSelector',
    emits : [ 'update', 'change' ],
    mixins: [ MixinCachePreheater ],

    props: {
        value        : { Type: String, required: false },
        refName      : { Type: String, required: true },
        validator    : { Type: String, required: false },
        reformatter  : { Type: String, required: false },
        display      : { Type: String, required: false },
        hasErrors    : { Type: Boolean, required: false },
        editor       : { Type: String, required: false },
        styleUpdate  : { Type: Boolean, required: false },
        eventKey     : { Type: String, required: false },
        globalWording: { Type: String, required: false },
    },

    created()
    {

        this.initialize()
            .then( () =>
            {
                if( undefined !== this.$props.value )
                {
                    this.selection = this.$props.value
                    this.$core.getEventManager().dispatchAndRemove( 'on-' + this.$props.refName + '-ready' )
                }
                this.initialized = true
            } )

    },

    mounted()
    {
        let classFilter = this.$core.getState( 'detailViewFor' )
        if( undefined !== classFilter )
        {
            this.selection = classFilter
        }
    },

    data()
    {
        return {
            neededCaches     : [ 'class', 'group', 'yeargroup' ],
            selection        : false,
            initialized      : false,
            localClasses     : [],
            localGroups      : [],
            localYeargroups  : [],
            colleagues       : {},
            foreignClasses   : {},
            foreignGroups    : {},
            foreignYeargroups: {}
        }
    },

    methods: {

        initialize()
        {
            return new Promise( resolve =>
            {

                this.awaitNeededCaches()
                    .then( () =>
                    {

                        let rules = this.$core.settings().getSetting( 'sortingDirections' )

                        if( undefined === rules[ 'groups' ] )
                        {
                            let defaultSet = this.$core.settings().getDefaultSetting( 'sortingDirections' )
                            rules[ 'groups' ] = defaultSet[ 'groups' ]
                        }

                        let colleagues    = this.$core.cc().getAll(),
                            colleagueList = {}

                        for( let c in colleagues )
                        {
                            colleagueList[ colleagues[ c ].colleagueId ] = colleagues[ c ]
                        }

                        let allClassesC    = this.$core.getBaseClassHelper()
                                                 .get( 'class' )
                                                 .getCache( 'cache' ),
                            allGroupsC     = this.$core.getBaseClassHelper()
                                                 .get( 'group' )
                                                 .getCache( 'cache' ),
                            allYeargroupsC = this.$core.getBaseClassHelper()
                                                 .get( 'yeargroup' )
                                                 .getCache( 'cache' ),
                            allClasses     = [],
                            allGroups      = [],
                            allYeargroups  = []

                        /*eslint-disable*/
                        for( const [ i, classItem ] of allClassesC )
                        {
                            allClasses.push( classItem )
                        }
                        for( const [ i, group ] of allGroupsC )
                        {
                            allGroups.push( group )
                        }
                        for( const [ i, yeargroup ] of allYeargroupsC )
                        {
                            allYeargroups.push( yeargroup )
                        }
                        /*eslint-enable*/

                        this.$core.sort().multiSortObjects( allClasses, rules[ 'classes' ] )
                        this.$core.sort().multiSortObjects( allGroups, rules[ 'groups' ] )
                        this.$core.sort().multiSortObjects( allYeargroups, rules[ 'yeargroups' ] )

                        let localClasses      = [],
                            localGroups       = [],
                            localYeargroups   = [],
                            foreignClasses    = {},
                            foreignGroups     = {},
                            foreignYeargroups = {}

                        for( let c in allClasses )
                        {
                            let classItem = allClasses[ c ]
                            if( !classItem.hidden
                                && 'demo-class-object' !== classItem.localId )
                            {
                                if( this.$core.f().isOwn( classItem ) )
                                {
                                    localClasses.push( classItem )
                                }
                                else
                                {
                                    if( undefined === foreignClasses[ classItem.idOwner ] )
                                    {
                                        foreignClasses[ classItem.idOwner ] = []
                                    }
                                    foreignClasses[ classItem.idOwner ].push( classItem )
                                }
                            }
                        }

                        for( let c in allGroups )
                        {
                            let groupItem = allGroups[ c ]
                            if( !groupItem.hidden )
                            {
                                if( this.$core.f().isOwn( groupItem ) )
                                {
                                    localGroups.push( groupItem )
                                }
                                else
                                {
                                    if( undefined === foreignGroups[ groupItem.idOwner ] )
                                    {
                                        foreignGroups[ groupItem.idOwner ] = []
                                    }
                                    foreignGroups[ groupItem.idOwner ].push( groupItem )
                                }
                            }
                        }

                        for( let c in allYeargroups )
                        {
                            let groupItem = allYeargroups[ c ]
                            if( !groupItem.hidden )
                            {
                                if( this.$core.f().isOwn( groupItem ) )
                                {
                                    localYeargroups.push( groupItem )
                                }
                                else
                                {
                                    if( undefined === foreignYeargroups[ groupItem.idOwner ] )
                                    {
                                        foreignYeargroups[ groupItem.idOwner ] = []
                                    }
                                    foreignYeargroups[ groupItem.idOwner ].push( groupItem )
                                }
                            }
                        }

                        this.localGroups = localGroups
                        this.localClasses = localClasses
                        this.localYeargroups = localYeargroups

                        this.foreignClasses = foreignClasses
                        this.foreignGroups = foreignGroups
                        this.foreignYeargroups = foreignYeargroups

                        return resolve()

                    } )

            } )
        },

        getDisabled()
        {
            if( true === window.lockedStudentSelector )
            {
                return 'disabled'
            }
            return false
        },

        isEditor()
        {
            return ( undefined === this.$props.display || this.$props.editor === true )
        },

        setValue( value )
        {
            let elm = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== elm )
            {
                elm.value = value
            }
        },

        handleUpdate()
        {
            let elm = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== elm )
            {
                this.$core.getEventManager().dispatchIndexed( 'student-group-select', elm.value )
                this.$emit( 'change', this.$props.refName, elm.value )
                this.$emit( 'update', this.$props.refName, 'studentGroupSelector', 'update', undefined, elm.value )
            }

        }
    }

}
</script>