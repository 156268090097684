export default class ColleagueCache
{

    constructor( core )
    {

        if( !ColleagueCache.instance )
        {

            this.eventManager = core.getEventManager()

            this.initialized = false
            this.ready = false
            this.colleagues = []
            this.f = core.f()
            this.ww = core.ww()
            this.cacheKey = false
            this.registry = false
            this.baseClass = false

            this.eventManager.append( 'on-filled-state-colleague', () =>
            {
                this.refresh()
            } )

            this.eventManager.append( 'on-network-synced', () =>
            {
                this.refresh( true )
            } )

            ColleagueCache.instance = this

        }

        return ColleagueCache.instance

    }

    injectBaseClass( baseClassHelper )
    {
        this.baseClass = baseClassHelper
        this.refresh()
    }

    refresh( bySync )
    {

        this.initialized = false
        this.baseClass
            .get( 'colleague' )
            .cacheHeatup()
            .then( () => {

                let scopes     = [ 'cache', 'archive' ],
                    colleagues = this.baseClass
                                     .get( 'colleague' )
                                     .getCache(),
                    list       = []

                for( let s in scopes )
                {
                    /* eslint-disable-next-line no-unused-vars */
                    for( const [ localId, colleague ] of colleagues[ scopes[ s ] ] )
                    {
                        if( 2 === colleague.state )
                        {
                            list.push( colleague )
                        }
                    }
                }

                this.colleagues = list
                this.ww
                    .call( 'Crypto', 'refreshColleaguesCache', JSON.parse( JSON.stringify( list ) ) )

                this.initialized = true
                this.cacheKey = this.f.objectHash( this.colleagues, true )

                if( bySync )
                {
                    this.eventManager.dispatch( 'on-colleague-cache-refresh' )
                }

                this.ready = true

            })

    }

    awaitInitialized()
    {
        return new Promise( resolve =>
        {

            if( this.initialized )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitInitialized() )
                }, 200 )
            }

        } )
    }

    awaitReadiness()
    {
        return new Promise( resolve =>
        {

            if( this.ready )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitReadiness() )
                }, 200 )
            }

        } )
    }

    getByUuid( uuid )
    {
        for( let c in this.colleagues )
        {
            if( this.colleagues[ c ].uuid === uuid )
            {
                return this.colleagues[ c ]
            }
        }
        return undefined
    }

    getByLocalId( localId )
    {
        for( let c in this.colleagues )
        {
            if( this.colleagues[ c ].localId === localId )
            {
                return this.colleagues[ c ]
            }
        }
        return undefined
    }

    getById( id )
    {
        for( let c in this.colleagues )
        {
            if( parseInt( this.colleagues[ c ].colleagueId ) === parseInt( id ) )
            {
                return this.colleagues[ c ]
            }
        }
        return undefined
    }

    getAll()
    {
        return this.colleagues
    }

}