export default class afterAllCompetenceCategory
{

    constructor( core )
    {

        this.f = core.f()

    }

    /*eslint-disable-next-line*/
    afterFetch( competenceCategories, skipArchived )
    {

        return new Promise( resolve =>
        {

            for( let c in competenceCategories )
            {
                if( competenceCategories[ c ].archived !== true || !skipArchived )
                {
                    competenceCategories[ c ].displayName = 'Kompetenzbereich "' + competenceCategories[ c ].title+'"'
                }
            }

            return resolve( competenceCategories )

        } )

    }

    afterUpdate( competences, skipArchived )
    {
        return new Promise( resolve =>
        {
            return resolve( this.afterFetch( competences, skipArchived ) )
        } )
    }

}