export default class DevHelper
{

    constructor( core )
    {

        if( !DevHelper.instance )
        {

            this.logger = core.getLogger()
            this.f = core.f()
            this.baseClassHelper = core.getBaseClassHelper()
            this.eventManager = core.getEventManager()

            this.init()

            DevHelper.instance = this

        }

        return DevHelper.instance

    }

    init()
    {

/*        setTimeout( () =>
        {

        }, 1000 )*/

    }

}