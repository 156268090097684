<script>
/*eslint-disable*/
export default {

    name: "MixinListEditorCollab",

    data()
    {
        return {
            foreignHighlighted: [],
            active            : false,
            editorActive      : false,
            timer             : null,
            triggerBlock      : false,
            localFocussed     : []
        }
    },

    created()
    {
        /*
        this.$core.getEventManager()
            .add( 'notify-colleague-editor-value-' + this.$props.list.localId, ( payload ) =>
            {
                //this.handleForeignValue( payload )
            } )
        this.$core.getEventManager()
            .add( 'notify-colleague-editor-focus-' + this.$props.list.localId, ( payload ) =>
            {
                if( -1 === this.foreignHighlighted.indexOf( payload.additional ) )
                {
                    this.foreignHighlighted.push( payload.additional )
                }
            } )
        this.$core.getEventManager()
            .add( 'notify-colleague-editor-blur-' + this.$props.list.localId, ( payload ) =>
            {
                let index = this.foreignHighlighted.indexOf( payload.additional )
                if( -1 < index )
                {
                    this.foreignHighlighted.splice( index, 1 )
                }
                else
                {
                    if( payload.additional.substr( 0, 4 ) === 'all:' )
                    {
                        let id = payload.additional.replace( 'all:', '' ).split( '___' )
                        id.pop()
                        let lookup = id.join( '___' )
                        let newHighlight = []
                        for( let i in this.foreignHighlighted )
                        {
                            if( -1 === this.foreignHighlighted[ i ].indexOf( lookup ) )
                            {
                                newHighlight.push( this.foreignHighlighted[ i ] )
                            }
                        }
                        this.foreignHighlighted = newHighlight
                    }
                }
            } )
        */
        this.checkEditorActive()
        this.timer = setInterval( () =>
        {
            this.checkEditorActive()
        }, 2000 )
    },

    beforeUnmount()
    {
        /*
        this.$core.getEventManager()
            .remove( 'notify-colleague-editor-value-' + this.$props.list.localId )
        this.$core.getEventManager()
            .remove( 'notify-colleague-editor-focus-' + this.$props.list.localId )
        this.$core.getEventManager()
            .remove( 'notify-colleague-editor-blur-' + this.$props.list.localId )

*/
        clearInterval( this.timer )
        this.timer = null
    },

    methods: {

        checkEditorActive()
        {
            this.triggerNetwork( 'notifyEditorStart' )
            this.editorActive = this.$core.getState( 'colleague-editor-open-' + this.$props.list.localId )
            if( undefined !== this.editorActive )
            {
                this.active = true
                this.setNotification( this.editorActive )
            }
            else
            {
                this.active = false
                this.notificationActive = false
            }
        },

        handleValueTrigger( which, value )
        {

            if( this.active
                && which !== this.triggerBlock )
            {
                this.triggerNetwork( 'notifyEditorValue', { id: which, value: value } )
            }
        },

        handleFocus( which )
        {
            if( this.active )
            {
                this.triggerNetwork( 'notifyEditorFocus', which )
            }
        },

        handleBlur( which )
        {
            if( this.active )
            {
                this.triggerNetwork( 'notifyEditorBlur', which )
            }
        },

        triggerChange( element )
        {
            if( "createEvent" in document )
            {
                let evt = document.createEvent( "HTMLEvents" );
                evt.initEvent( "change", false, true );
                element.dispatchEvent( evt );
            }
            else
            {
                element.fireEvent( "onchange" );
            }
        },

        afterPlaceValue()
        {

            if( this.afterPlaceTimer )
            {
                clearTimeout( this.afterPlaceTimer )
            }

            this.afterPlaceTimer = setTimeout( () =>
            {

                this.$emit( 'update', this.valueClone )

                if( this.$props.list.listType === 'test' )
                {
                    this.updateRatings()
                }

            }, 1000 )

        },

        placeValue( id, value )
        {

            if( -1 < this.localFocussed.indexOf( id ) )
            {
                return
            }

            this.valueClone = this.$core.f().isset( this.valueClone ) ? this.valueClone : {}
            this.valueClone[ id ] = value

            for( let l in this.lines )
            {
                let line = this.lines[ l ]
                for( let f in line )
                {
                    let field = line[ f ]
                    if( field.id === id )
                    {
                        field.value = value
                        field.display = value
                        this.$core.getEventManager()
                            .dispatch( 'on-value-change-' + id, value )
                        this.handleValueTrigger( id, value )
                    }
                }
            }

            this.afterPlaceValue()

        },

        handleForeignValue( payload )
        {
            if( this.active )
            {

                let fieldId = payload.additional.id
                let fieldValue = payload.additional.value
                this.triggerBlock = fieldId

                let field = document.querySelector( '#form-element-' + fieldId )
                if( null === field || null === field.type )
                {
                    field = document.querySelector( '#elm_' + fieldId )
                }

                if( null !== field
                    && null !== field.type )
                {
                    switch( field.type )
                    {
                        case 'hidden':
                        case 'text':
                            field.value = undefined !== fieldValue ? fieldValue : ''
                            this.triggerChange( field )
                            if( 'hidden' === field.type )
                            {
                                this.triggerChange( field )
                            }
                            break
                        case 'number':
                            field.value = undefined !== fieldValue ? fieldValue : ''
                            this.triggerChange( field )
                            break
                        case 'checkbox':
                            if( ( field.checked && ( fieldValue === 0 || fieldValue === undefined ) )
                                || ( !field.checked && fieldValue === 1 ) )
                            {
                                field.click()
                            }
                            break

                    }
                    this.placeValue( fieldId, fieldValue )
                }

                setTimeout( () =>
                {
                    this.triggerBlock = false
                }, 300 )

            }
        },

        resolveNetworkTrigger( uuid, mode, id, additional )
        {

            let colleague = this.$core.cc().getByUuid( uuid )

            if( undefined !== colleague )
            {
                return {
                    trigger     : mode,
                    id_colleague: colleague.colleagueId,
                    payload     : id,
                    additional  : additional
                }

            }

            return undefined

        },

        triggerNetwork( mode, additionalPayload )
        {

            /*
            let triggers = []

            for( let k in this.$props.list._keys )
            {

                if( this.$props.list._keys[ k ].uuid !== this.$store.getters.uuid )
                {

                    let trigger = this.resolveNetworkTrigger( this.$props.list._keys[ k ].uuid, mode, this.$props.list.localId, additionalPayload )
                    if( undefined !== trigger )
                    {
                        triggers.push( trigger )
                    }

                }

            }

            if( 0 < triggers.length )
            {
                let request = {
                    method  : 'network.trigger',
                    triggers: triggers
                }
                this.$core.getClient().request( request )
            }
            */

        },

        localFocus( id )
        {

            this.$core.getCoreTimer()
                .removeTimeout( 'remove-focustimer-' + id )

            if( -1 === this.localFocussed.indexOf( id ) )
            {
                this.localFocussed.push( id )
            }

        },

        localBlur( id )
        {
            this.$core.getCoreTimer()
                .addTimeout( 'remove-focustimer-' + id, 2000, () =>
                {
                    if( this.$core.f().valid( this.localFocussed ) )
                    {

                        this.$core.f().removeFromArray( this.localFocussed, id )
                    }
                } )
        }

    }

}
</script>