<template>
    <transition appear name="fade">
        <div class="edit-all-warning">
            <strong>Wichtig</strong>: Über die Zeile "<strong>Alle Zeilen</strong>" ersetzt du den
            Inhalt aller Zeilen der jeweiligen Spalte mit dem in "Alle Zeilen" enthaltenen, neuen Wert.<br/>
            <strong>Versehentlich überschriebene Werte können nicht wiederhergestellt werden</strong>.
        </div>
    </transition>
</template>

<script>
export default {
    name: "ListEditAllWarning"
}
</script>