export default class SyncProfile
{
    
    constructor( parent )
    {
        this.parent = parent
        this.client = parent.client
        this.step = 0
        this.total = 0
        this.parent.logger.clog( 'SyncWorker::SyncProfile', 'initialized' )
    }
    
    destruct()
    {
        this.parent = null
        delete this.parent
    }
    
    mergeProfiles( local, remote )
    {
        
        return new Promise( resolve =>
        {
            
            let changes = false
            
            for( let k in local )
            {
                if( 'localKey' !== k
                    && local[ k ] !== remote[ k ] )
                {
                    
                    local[ k ] = remote[ k ]
                    changes = true
                    
                }
            }
            
            if( changes )
            {
                
                this.parent.cryptoHelper.encrypt( JSON.stringify( local ) )
                    .then( localEncrypted => {
                        this.parent.store.commit( 'setMetadata', localEncrypted )
                        return resolve( true )
                    })

            }
            else
            {
                return resolve( true )
            }

        } )
        
    }
    
    syncFlags()
    {
        
        return new Promise( resolve =>
        {
            
            let message = {
                method: 'users.getFlags'
            }
            
            this.client.request( message )
                .then( data =>
                {
                    
                    let rawFlags = data.result
                    let flags = []
                    
                    for( let k in Object.keys( rawFlags ) )
                    {
                        
                        let key = Object.keys( rawFlags )[k]
                        if( 'id_user' !== key )
                        {
                            let value = rawFlags[ key ]
                            if( 1 === value )
                            {
                                flags.push( key )
                            }
                        }
                    }
    
                    this.parent.store.commit( 'setFlags', flags )
                    this.parent.eventManager.dispatchAndRemove( 'flags-initialized' )
                    
                    return resolve()
                    
                } )
                .catch( error =>
                {
                    this.parent.logger.cerror( 'SyncProfile::syncFlags', 'unable to fetch user flags: ' + error )
                } )
            
        } )
    }
    
    performSync( data )
    {
        
        return new Promise( resolve =>
        {
            
            let profileData = this.parent.store.getters.metadata
            
            let profile = {
                firstname     : '',
                lastname      : '',
                email         : '',
                school        : '',
                school_zipcity: ''
            }
            
            if( null === profileData )
            {
                profileData = profile
                this.mergeProfiles( profileData, data )
                    .then( () =>
                    {
                        return resolve( this.syncFlags() )
                    } )
            } else
            {
                this.parent.cryptoHelper.decrypt( profileData )
                    .then( decryptedProfileData => {
                        this.mergeProfiles( decryptedProfileData, data )
                            .then( () =>
                            {
                                return resolve( this.syncFlags() )
                            } )
                    })
            }

        } )
        
    }
    
    /**
     * prepareSyncables
     */
    prepareSyncables()
    {
        
        return new Promise( resolve =>
        {
            
            let message = {
                method      : 'objects.getProfileData',
                initialFetch: this.parent.initialFetch,
                since       : this.parent.getSyncParameters().since
            }
            
            this.client.request( message )
                .then( data =>
                {

                    this.step++
                    this.showProgress()
                    return resolve( data.result )
                    
                } )
                .catch( () =>
                {
                    this.step++
                    this.showProgress()
                    return resolve( false )
                } )
            
        } )
        
    }
    
    showProgress( text )
    {
        if( !this.parent.silent )
        {
            this.parent.ui.blockerText( ( undefined === text ? '<strong>Profilinformationen</strong> werden synchronisiert...' : text )
                + '<br/><br/><strong>' + this.step + '</strong> von <strong>' + this.total + '</strong>' )
        }
    }
    
    sync()
    {
    
        return new Promise( resolve =>
        {
            if( false !== this.parent.store.getters.idSession
                && true === this.parent.store.getters.online )
            {

                this.total = 1
                this.step = 0
    
                this.prepareSyncables()
                    .then( data =>
                    {

                        if( false !== data )
                        {
                            return resolve( this.performSync( data ) )
                        } else
                        {
                            return resolve()
                        }
            
                    } )
            }
            else
            {
                this.parent.logger.cdebug( 'SyncWorker::SyncProfile', 'in unsyncable state right now...' )
                return resolve()
            }
            
        } )
        
    }
    
    
}