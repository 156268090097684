<template>
    <div :class="'labels-filter'+( '' !== filterValue && false !== filterValue? ' selection' : '')" id="labels-filter">
        <div :class="'select-arrow'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"
             id="labels-filter-inner"
             v-if="initialized">
            <div :class="'label-icon'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"></div>
            <select :class="'labels-filter'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"
                    name="label-filter"
                    id="labels-filter-select" @change="filter">
                <option value="" :selected="filterValue === false">{{ $core.t( 'generic-please-select') }}</option>
                <option value="_labelManager">Labels verwalten</option>
                <optgroup label="Deine Labels">
                    <option v-for="labelItem in labels"
                            v-bind:key="'key_option_'+labelItem.localId"
                            :selected="filterValue === labelItem.localId"
                            :value="labelItem.localId">{{ labelItem.caption }}
                    </option>
                </optgroup>
            </select>
        </div>
        <div class="labels-filter-select syncing" v-else>
            ...
        </div>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";
import MixinFilterState    from "@/mixins/MixinFilterState";
import MixinEvents         from "@/mixins/MixinEvents.vue";

export default {

    name  : 'LabelsFilter',
    mixins: [ MixinCachePreheater, MixinFilterState, MixinEvents ],

    props: {
        title   : { type: String, required: false },
        archived: { type: Boolean, required: false },
        own     : { type: Boolean, required: false },
        list    : { type: Object, required: false }
    },

    emits: [ 'filter', 'detach', 'attach' ],

    data()
    {
        return {
            labels      : [],
            filters     : [],
            filterValue : false,
            initialized : false,
            neededCaches: [ 'label' ]
        }
    },

    watch: {
        archived: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined === this.$props.list )
                {
                    this.labels = []
                    this.filters = []
                    this.initialize()
                    this.emitValue( '' )
                }
            },
            deep: true
        },
        own     : {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined === this.$props.list )
                {
                    this.labels = []
                    this.filters = []
                    this.initialize()
                    this.emitValue( '' )
                }
            }
        }
    },

    created()
    {

        this.$nextTick()
            .then( () =>
            {

                this.addEvent( 'on-label-filter', ( label ) => {
                    this.filter( undefined, label.localId )
                })
                this.addEvent( 'on-labelfilter-preselect', ( value ) =>
                {
                    this.emitValue( value )
                } )
                if( undefined === this.$props.list )
                {
                    this.initialize()
                }
                else
                {
                    this.labels = this.$props.list.labels || []
                    this.initialized = true
                }

            } )

    },

    beforeUnmount()
    {
        this.$emit( 'detach' )
    },

    methods: {

        filterByOwner( element, own )
        {
            let isOwn = this.$core.f().isOwn( element )
            return own ? isOwn : true
        },

        initialize()
        {

            this.initialized = false

            let isArchived = this.$props.archived === true,
                own        = this.$props.own === undefined ? true : this.$props.own

            this.awaitNeededCaches()
                .then( () =>
                {

                    let labels = this.$core
                                     .getBaseClassHelper()
                                     .get( 'label' )
                                     .getCache( isArchived ? 'archive' : 'cache' )

                    /*eslint-disable*/
                    for( const [ l, label ] of labels )
                    {
                        if( isArchived === label.archived
                            && this.filterByOwner( label, own ) )
                        {
                            this.labels.push( {
                                localId: label.localId,
                                caption: label.caption + ( isArchived ? ' ' + this.$core.getReformatter().timeForArchiveKey( label ) : '' )
                            } )
                        }
                    }
                    /*eslint-enable*/

                    this.$core.getSorter().sortObjects( this.labels, 'caption' )

                    this.$emit( 'attach', {
                        type  : 'label',
                        filter: this.filters
                    } )

                    this.initialized = true
                    this.restoreFilters()

                } )

        },

        filter( event, override )
        {

            if( undefined === override && '_labelManager' === event.target.value )
            {
                this.$core.getUi().showLabelManager( true, [], false )
                this.resetFilters()
            }
            else
            {

                if( false !== this.filterValue && this.filterValue === override )
                {
                    this.resetFilters()
                }
                else
                {
                    this.$core.s().count( 'clicked-labels-filter' )
                    this.filterValue = override || event.target.value
                    this.emitValue()
                    this.storeFilterState( this.filterValue )
                }

            }

        },

        emitValue()
        {

            let value = this.filterValue

            if( false === value || value === '' )
            {
                this.$emit( 'filter', 'labels', undefined )
                return
            }

            for( let l in this.labels )
            {
                if( this.labels[ l ].localId === value )
                {
                    this.$emit( 'filter', 'labels', [ { 'attr': 'labelId', value: this.labels[ l ].localId } ] )
                }
            }

        },


        resetFilters()
        {
            this.filterValue = false
            this.storeFilterState( false )
            this.emitValue()
        },

        restoreFilters()
        {

            let filterValue = this.getStoredFilterState()

            if( ( Array.isArray( filterValue ) && 0 === filterValue.length )
                || false === filterValue )
            {
                return
            }

            this.filterValue = filterValue
            this.emitValue()
        }

    }
}
</script>