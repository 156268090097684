<script>
export default {

    name: "ListFilterable",

    props: {
        listFilter: { type: [ Object, Boolean ], required: true }
    },

    watch: {
        listFilterInline: {
            handler( newValue, oldValue )
            {

                if( newValue !== oldValue
                    && this.prepared )
                {

                    this.isVisible = new Map()

                    if( false === newValue )
                    {
                        this.listFilterMatches = -1
                    }
                    else
                    {
                        this.listFilterMatches = 0
                    }

                    this.listFilterList = newValue
                    this.prepareFilterList()

                }
            }
        },
        listFilter      : {
            handler( newValue, oldValue )
            {

                if( newValue !== oldValue
                    && this.ready )
                {

                    this.isVisible = new Map()

                    if( false === newValue )
                    {
                        this.listFilterMatches = -1
                    }
                    else
                    {
                        this.listFilterMatches = 0
                    }

                    this.listFilterList = newValue
                    this.prepareFilterList()

                }
            }
        },
        ready           : {
            immediate: true,
            handler( newValue )
            {
                if( newValue === true )
                {
                    this.prepareFilterList()
                }
            }
        },
        initialized     : {
            immediate: true,
            handler( newValue )
            {
                if( newValue === true )
                {
                    this.prepareFilterList()
                }
            }
        }
    },

    mounted()
    {
        this.prepareFilterList()
    },

    data()
    {
        return {
            listFilterList   : false,
            listFilterMatches: 0,
            isVisible        : new Map()
        }
    },

    beforeUnmount()
    {
        this.listFilterList = null
        this.listFilterMatches = null
        /*eslint-disable*/
        this.isVisible.forEach( ( key, value ) =>
        {
            this.isVisible.delete( key )
        } )
        /*eslint-enable*/
        this.isVisible = null
    },

    methods: {

        filterMatch( row )
        {

            if( !this.listFilterList )
            {
                this.listFilterMatches = 0
                return true
            }

            let filterMatch = true

            for( let f in this.listFilterList )
            {
                let filter = this.listFilterList[ f ],
                    group  = this.$core.getBaseClassHelper()
                                 .getObjectById( filter.value )

                switch( filter.attr )
                {
                    case 'groupId':
                        if( -1 === group.students.indexOf( row.localId ) )
                        {
                            filterMatch = false
                        }
                        break
                    default:
                        if( row[ filter.attr ] !== filter.value )
                        {
                            filterMatch = false
                        }
                        break
                }

            }

            if( filterMatch )
            {
                this.listFilterMatches++
            }

            return filterMatch


        },

        prepareFilterList()
        {
            for( let s in this.students )
            {

                let row = this.students[ s ]
                this.isVisible.set( row.localId, this.filterMatch( row ) )

            }
        }
    }

}
</script>