<template>
    <div :class="'touchable'+( galleryMode ? ' gallery' : '' )"
         :id="'nested-boundaries-'+ownId"
         :key="'nested-boundaries-'+ownId">
        <Quickmarks :item
                    :noShareIndicator
                    :scope/>
        <div :class="'touchable-left '+additionalCssClasses"
             :id="'nested-touchable-'+viewItem+'-'+scope+'-'+item.localId+'-'+ownId">
            <component :is="$core.f().ucFirst( viewItem )"
                       :item
                       :selected
                       :selectMode
                       :escapeNested
                       :singleItems
                       :inListEditor
                       @prepared="prepared = true"
                       @dragging="$emit( 'dragging', $event )"
                       @scrolling="handleIsScrolling"
                       @findTab="$emit( 'findTab', $event )"
                       @injectHandlers="handleInjectedHandlers"
                       @click="handleClick"
                       @dataUpdate="handleDataUpdate"/>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
/*eslint-disable*/
import Quickmarks from "@/components/defaults/defaultTouchable/Quickmarks";
import NestedList from "@/components/elements/viewItems/nested/List.vue";

export default {

    name      : "NestedTouchable",
    components: {
        Quickmarks,
        NestedList
    },

    emits: [ 'refresh', 'clicked', 'select', 'answer', 'forward', 'dragging', 'findTab' ],

    props: {
        localId         : { Type: String, required: true },
        viewItem        : { Type: String, required: true },
        scope           : { Type: String, required: true },
        clickDisabled   : { Type: Boolean, required: false, default: false },
        bare            : { Type: Boolean, required: false, default: false },
        escapeNested    : { Type: Boolean, required: false, default: false },
        selectMode      : { Type: Boolean, required: false, default: false },
        singleItems     : { Type: Boolean, required: false, default: false },
        startOpen       : { Type: Boolean, required: false, default: false },
        fakeElement     : { Type: Boolean, required: false, default: false },
        fakeCount       : { Type: Number, required: false, default: false },
        noShareIndicator: { Type: Boolean, required: false, default: false },
        galleryMode     : { Type: Boolean, required: false, default: false },
        inListEditor    : { Type: Boolean, required: false, default: false },
    },

    data()
    {
        return {
            ownId                : this.$core.getUuid().generate(),
            injectedHandlers     : false,
            positionChanged      : false,
            scrolling            : false,
            swiped               : false,
            swipedRight          : false,
            functionsDisappearing: false,
            additionalCssClasses : '',
            lastClick            : 0,
            selected             : false,
            prepared             : false,
            timer                : false,
            item                 : null
        }
    },

    created()
    {
        switch( this.$core.f().ucFirst( this.$props.viewItem ) )
        {
            case 'NestedList':
            case 'List':
                this.item = this.$core.getBaseClassHelper()
                                .get( 'list' )
                                .getContainer( this.$props.localId, undefined, true )
                break
            default:
                this.item = this.$core.getBaseClassHelper()
                                .getObjectById( this.$props.localId )
        }
    },

    beforeUnmount()
    {
        this.item = null
    },

    methods: {

        handleDataUpdate()
        {

            this.additionalCssClasses = 'data-updated shine'
            this.$core.getUi()
                .delay( () =>
                {

                    this.additionalCssClasses = ''

                }, 4000 )

        },

        handleClick( localId )
        {

            if( true === this.$props.inListEditor )
            {
                this.$emit( 'clicked', localId )
                return
            }

            if( !this.$props.clickDisabled
                && this.$core.f().now() > this.lastClick + 500 )
            {

                this.lastClick = this.$core.f().now()
                if( false === this.$props.selectMode )
                {
                    this.$emit( 'clicked' )
                }
                else
                {
                    this.selected = !this.selected
                    this.$emit( 'select', this.item.localId, this.selected )
                }

            }

        },

        handleIsScrolling()
        {

            this.scrolling = true
            if( false !== this.timer )
            {
                this.$core.getCoreTimer()
                    .removeTimeout( this.timer )
            }
            this.timer = this.$core.getUi()
                             .delay( () =>
                             {
                                 this.scrolling = false
                             }, 500 )

        },

        handleInjectedHandlers( handlers )
        {
            this.injectedHandlers = handlers
        }

    }

}
</script>