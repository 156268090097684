<template>
    <td :class="'td-caption padded-left'+( vertical ? ' td-vertical-caption' : '' )">
        <span class="strong" v-html="preparedCaption"></span>
        <template v-if="score">
            <span style="float:right;">{{ score }} Punkte</span>
        </template>
    </td>
</template>

<script>
/*eslint-disable*/
export default {
    name : "TableDetailSplitted",
    props: {
        caption : { Type: String, required: true },
        score   : { Type: String, required: false, default: undefined },
        vertical: { Type: Boolean, required: false, default: false },
    },
    data()
    {
        return {
            preparedCaption: ''
        }
    },
    mounted()
    {

        if( 0 === this.$props.caption.indexOf( 'competence:' )
            || 0 === this.$props.caption.indexOf( 'subCompetence:' )
            || 0 === this.$props.caption.indexOf( 'cat:' )
            || 0 === this.$props.caption.indexOf( 'subCompetenceSub:' ) )
        {
            this.needsTransition = true
        }

        this.preparedCaption = this.prepareCaption( this.$props.caption )

    },

    methods: {

        fetchCompetenceHeader( captionHtml )
        {

            let temp    = captionHtml.split( /:/g ),
                localId = temp[ 1 ],
                item    = this.$core.getCompetenceCategoryTree().tree.byRoot[ localId ]

            return item.label

        },

        prepareCaption( captionHtml )
        {

            captionHtml = this.needsTransition ? this.fetchCompetenceHeader( captionHtml ) : captionHtml

            let w = this.getCaptionWidth( captionHtml )
            if( 25 > w )
            {
                return captionHtml
            }
            else
            {

                let parts = captionHtml.split( / /g )
                let rows = []
                let row = ''
                let i = 0
                while( parts.length > 0 )
                {
                    while( this.getCaptionWidth( row ) <= 25
                           && parts.length > 0 )
                    {
                        let p = parts.shift()
                        row += p + ' '
                    }
                    rows.push( row )
                    row = ''
                }
                return rows.join( '<br/>' )

            }

        },

        getCaptionWidth( captionHtml )
        {

            let span = document.createElement( 'span' )
            span.innerHTML = captionHtml
            return span.innerText.length

        }

    }

}
</script>