import ListsObjectClass from "@/objects/ListsObjectClass";

export default class CombilistClass extends ListsObjectClass
{

    constructor( core )
    {

        super( core )

        this.logger = core.getLogger()
        this.listType = 'combilist'
        /** for future use **/
        this.isPooled = false

        let fields = [
            {
                ref        : 'referenceKey',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : null
            },
            {
                ref        : 'listname',
                caption    : 'Name',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'listname'
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                extended   : true,
                validator  : 'text'
            },
            {
                ref        : 'selectedClass',
                caption    : 'Schüler:innen',
                type       : 'StudentGroupSelector',
                reformatter: null,
                optional   : false,
                validator  : 'select'
            },
            {
                ref          : 'columns',
                caption      : 'Spalten',
                type         : 'RowAdder',
                component    : 'ListAndColumnSelector',
                reformatter  : null,
                optional     : false,
                unique       : true,
                resultingType: 'combilistSetup',
                validator    : 'select'
            },
            {
                ref        : 'forceTimestamp',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'storeAsTemplate',
                caption    : 'als Vorlage speichern',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false,
                extended   : true
            }
        ]

        if( this.isPooled )
        {
            fields.push( {
                ref        : 'storeAsSchoolTemplate',
                caption    : 'als Schulvorlage speichern',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false,
                extended   : true
            } )
        }

        super.setListFormFields( this.listType, fields )

        super.setColumnParser( this.listType, ( columns, values ) =>
        {

            for( let col in values.columns )
            {

                let temp = values.columns[ col ]
                if( undefined !== temp.value )
                {
                    columns.push( {
                        type : 'setup',
                        setup: values.columns[ col ]
                    } )
                }

            }

        } )

        return this

    }

}