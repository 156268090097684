<template>
    <div>
        <Component :is="'listElement-'+type"
                   :value
                   :row
                   :referenceObjectId/>
    </div>
</template>

<script>
import ListElementCheckbox  from '@/components/elements/viewItems/listViews/listElements/listElement-checkbox'
import ListElementTextbox   from '@/components/elements/viewItems/listViews/listElements/listElement-textbox'
import ListElementImage     from "@/components/elements/viewItems/listViews/listElements/listElement-image";
import ListElementNumberbox from '@/components/elements/viewItems/listViews/listElements/listElement-numberbox'
import ListElementTextarea  from '@/components/elements/viewItems/listViews/listElements/listElement-textarea'
import ListElementRateselector
                            from '@/components/elements/viewItems/listViews/listElements/listElement-rateselector'
import ListElementRateplantselector
                            from '@/components/elements/viewItems/listViews/listElements/listElement-rateplantselector'
import ListElementRatesmileselector
                            from '@/components/elements/viewItems/listViews/listElements/listElement-ratesmileselector'
import ListElementRatesmileselectorreverse
                            from '@/components/elements/viewItems/listViews/listElements/listElement-ratesmileselectorreverse'
import ListElementThreewaytoggle
                            from '@/components/elements/viewItems/listViews/listElements/listElement-threewaytoggle'
import ListElementDatebox   from '@/components/elements/viewItems/listViews/listElements/listElement-datebox'
import listElementStudentData
                            from '@/components/elements/viewItems/listViews/listElements/listElement-studentdata'
import ListElementScoreBox  from '@/components/elements/viewItems/listViews/listElements/listElement-scorebox'
import ListElementSmallnumberbox
                            from "@/components/elements/viewItems/listViews/listElements/listElement-smallnumberbox";
import ListElementRateflexselector
                            from "@/components/elements/viewItems/listViews/listElements/listElement-rateflexselector";

import ListElementCompetenceSelector
    from "@/components/elements/viewItems/listViews/listElements/listElement-competenceSelector.vue";

export default {
    name      : 'ListElement',
    components: {
        ListElementSmallnumberbox,
        listElementStudentData,
        ListElementDatebox,
        ListElementThreewaytoggle,
        ListElementRateselector,
        ListElementRateplantselector,
        ListElementRatesmileselector,
        ListElementRatesmileselectorreverse,
        ListElementRateflexselector,
        ListElementTextarea,
        ListElementTextbox,
        ListElementNumberbox,
        ListElementCheckbox,
        ListElementScoreBox,
        ListElementImage,
        ListElementCompetenceSelector
    },
    props     : {
        type             : { type: String, required: false, default: undefined },
        value            : { type: [ String, Number ], required: false, default: undefined },
        row              : { type: [ String ], required: false, default: undefined },
        referenceObjectId: { type: String, required: false }
    }
}

</script>