<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-show="visible" :key="'zoom-'+zoomId+'-'+changeKey" class="default-zoom-overlay">
            <div class="default-form-container">
                <div :class="'default-zoom-wrapper'+selectedColor">
                    <Button type="close"
                            @clicked="close()"
                            addClass="absolute top right inverted"/>
                    <div class="spacer space-60"></div>
                    <Touchable :scope="scope"
                               :viewItem="viewItem"
                               :itemZoom="item"
                               :localId="item.localId"
                               :escapeNested="true"
                               :startOpen="true"
                               :singleItems="true"
                               @refresh="handleRefresh"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Touchable   from "@/components/defaults/Touchable";
import MixinEvents from "@/mixins/MixinEvents.vue";

export default {

    name      : 'DefaultElementZoom',
    components: { Touchable },
    mixins    : [ MixinEvents ],
    props     : {
        item     : { Type: Object, required: true },
        readyKey : { Type: String, required: false },
        changeKey: { Type: String, required: false },
        zoomId   : { Type: String, required: false }
    },

    data()
    {
        return {
            eventKey     : false,
            selectedColor: '',
            visible      : true,
            formFields   : false,
            formButtons  : false,
            nestedForm   : false
        }
    },

    emits: [ 'close', 'requestUpdate' ],

    computed: {

        scope()
        {
            return this.$core.f().isOwn( this.$props.item ) ? 'own' : 'foreign'
        },
        viewItem()
        {
            return this.$props.item.type
        }

    },

    watch: {
        changeKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( true !== this.$core.getState( 'listEditorOpen' )
                    && undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.$emit( 'requestUpdate' )
                }
            }
        }
    },

    created()
    {
        this.$core.setState( 'in-element-zoom', true )
        this.addEvent( 'on-undef-touchable', () => {
            this.close()
        })
    },

    mounted()
    {
        this.addHandler()
    },

    beforeUnmount()
    {
        this.$core.deleteState( 'in-element-zoom' )
    },

    methods: {

        addHandler()
        {
            this.addEvent( 'after-handle-close', () =>
            {
                this.$core.getUi().hideBlocker()
            } )
        },

        close()
        {
            this.$core.getEventManager()
                .dispatch( 'on-close-zoom-' + this.zoomId )
            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, this.$core.settings().getSetting( 'animations' ) ? 500 : 10 )
        },

        handleRefresh()
        {
            this.$core.getEventManager()
                .dispatch( 'on-update-zoom-' + this.zoomId )
        }

    }

}
</script>