<template>
    <div class="placeholder-wrapper">
        <div :class="'placeholder '+type"></div>
    </div>
</template>

<script>
export default {
    name : "Placeholder",
    props: {
        type: { type: String, required: true }
    }
}
</script>
