<template>
    <div :key="'synced-'+localId+'-'+elementKey+'-'+cacheKey" class="quickmark"
         v-bind:class="synced ? 'synced' : 'not-synced'"
         @click="handleSync"></div>
</template>

<script>
export default {
    name : 'SyncStateIndicator',
    props: {
        localId    : { Type: String, required: true },
        elementKey : { Type: String, required: true },
        elementType: { Type: String, required: true },
        remoteId   : { Type: String | Number, required: false }
    },

    computed: {
        cacheKey()
        {
            return this.$props.elementType ? this.$core.getBaseClassHelper().get( this.$props.elementType ).registry.cacheKey
                                             + '-' + this.$core.getBaseClassHelper().get( this.$props.elementType ).registry.archiveKey : null
        },
        synced()
        {
            return undefined !== this.$props.remoteId && 0 < parseInt( this.$props.remoteId )
        }
    },

    methods: {
        handleSync()
        {

            let list = []
            list.push( { type: this.$props.elementType, localId: this.$props.localId } )

            this.$core.getDatabase()
                .writeUploadsList( list )
                .then( () =>
                {
                    list = undefined
                } )
                .catch( () =>
                {
                    list = undefined
                } )

        }
    }
}
</script>