<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-if="prepared"
             v-show="visible" class="default-form-overlay">
            <div class="default-form-container">
                <div :class="'default-form-wrapper '+selectedColor" :key="test">
                    <Button type="close"
                            @clicked="closeForm()"
                            addClass="absolute top right inverted"/>

                    <h2>{{ formTitle }}{{ formAppend }}</h2>
                    <Form :elevated="true"
                          :scope="objectType"
                          :eventKey
                          :fields="formFields"
                          :buttons="formButtons"
                          :editItem
                          :organizerSlot
                          @nestedForm="handleNestedForm"
                          @appendClassReference="handleAppendClassReference"/>
                    <div v-if="nestedForm" class="nested-form">
                        <Form :elevated="true"
                              :scope="nestedForm.objectType"
                              :eventKey="nestedForm.eventKey"
                              :fields="nestedForm.fields"
                              :buttons="nestedForm.formButtons"
                              :editItem="nestedForm.editItem"
                              :organizerSlot="organizerSlot"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {

    name: 'DefaultFormOverlay',

    props: {
        objectType   : { Type: String, required: true },
        viewItem     : { Type: String, required: true },
        method       : { Type: String, required: true },
        editItem     : { Type: Object, required: false },
        readyKey     : { Type: String, required: false },
        organizerSlot: { Type: Object, required: false }
    },

    data()
    {
        return {
            eventKey     : false,
            selectedColor: '',
            visible      : true,
            formFields   : false,
            formButtons  : false,
            nestedForm   : false,
            hiddenRefs   : {},
            classFilter  : false,
            className    : false,
            prepared     : false,
            merge        : false,
            test         : 'abc'
        }
    },

    emits: [ 'nestedForm', 'submit-form', 'close', 'append-class-reference' ],

    computed: {
        formTitle()
        {
            return this.$core.t( 'object-type-' + this.$props.objectType + '-single' )
                   + ' ' + this.$core.t( 'form-method-' + this.$props.method )
        },
        formAppend()
        {

            let slotName = ''
            if( undefined !== this.$props.organizerSlot
                && 'object' === typeof ( this.$props.organizerSlot ) )
            {
                slotName = ', ' + this.$props.organizerSlot.label
            }

            return this.$core.f().valid( this.classFilter ) && this.$core.f().valid( this.className )
                   ? slotName + ' (Klasse ' + this.className + ')' : slotName + ''

        }
    },

    created()
    {

        this.init()

    },

    beforeUnmount()
    {
        this.merge = false
        this.$core.deleteState( 'classFilter' )
        if( this.$core.f().isset( this.nestedForm.eventKey ) )
        {
            this.$core.getEventManager()
                .remove( 'on-color-selected-' + this.nestedForm.eventKey )
        }
        this.$core.getEventManager()
            .remove( 'on-color-selected-' + this.eventKey )
        this.$core.getEventManager()
            .remove( 'on-form-close-' + this.eventKey + this.$props.readyKey )
    },

    methods: {

        init()
        {
            let key = this.$core.f().lcFirst( this.$props.viewItem )

            if( this.$props.editItem
                && this.$core.getBaseClassHelper()
                       .get( key ).mergeAtUpdate )
            {
                this.fillMerger( key )
                    .then( result =>
                    {
                        this.merge = result
                        this.prepareForm( key )
                    } )
            }
            else
            {
                this.prepareForm( key )
            }
        },

        fillMerger( key )
        {
            return new Promise( resolve =>
            {

                let element = this.$core.getBaseClassHelper()
                                  .get( key )
                                  .getById( this.$props.editItem.localId ),
                    deref   = JSON.parse( JSON.stringify( element ) )

                delete ( deref.elementKey )
                return resolve( deref )

            } )
        },

        prepareForm( key )
        {

            let sub = undefined

            if( undefined !== this.$props.editItem
                && undefined !== this.$props.editItem.listType )
            {
                sub = this.$props.editItem.listType
                this.formFields = this.$core.getBaseClassHelper()
                                      .get( key )
                                      .getListFormFields( sub )
            }
            else
            {

                let baseClass = this.$core.getBaseClassHelper()
                                    .get( key )

                this.formFields = baseClass
                    .getFormFields()

                if( typeof baseClass.getHiddenRefs === 'function' )
                {

                    let hidden = baseClass.getHiddenRefs()
                    for( let h in hidden )
                    {
                        this.hiddenRefs[ hidden[ h ] ] = this.$props.editItem[ hidden[ h ] ]
                    }
                }

            }

            this.eventKey = this.$core.getUuid().generate()
            let submitCaption = 'message' === key ? 'caption-send' : 'caption-submit'

            this.formButtons = {
                left : {
                    type    : 'button',
                    isCancel: true,
                    caption : this.$core.getTranslation().translate( 'caption-cancel' ),
                    callback: () =>
                    {
                        this.closeForm()
                    }
                },
                right: {
                    type    : 'submit',
                    caption : this.$core.getTranslation().translate( submitCaption ),
                    callback: ( values, historic ) =>
                    {
                        this.handleSubmit( values, historic )
                    }
                }
            }

            this.$core.getEventManager()
                .add( 'on-color-selected-' + this.eventKey, ( value ) =>
                {
                    this.colorUpdate( value )
                } )

            this.$core.getEventManager()
                .add( 'on-form-close-' + this.eventKey + this.$props.readyKey, () =>
                {
                    this.visible = false
                } )

            this.prepared = true

        },

        handleAppendClassReference( classFilter, className )
        {
            this.classFilter = classFilter
            this.className = className
            this.$core.setState( 'classFilter', this.classFilter )
        },

        colorUpdate( value )
        {
            this.selectedColor = '' !== value.trim() ? ' ' + value.trim() : ''
        },

        closeForm()
        {

            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 300 )

        },

        handleNestedForm( set )
        {

            if( this.$core.f().isset( this.nestedForm.eventKey ) )
            {
                this.nestedForm = false
                this.$core.getEventManager()
                    .remove( 'on-color-selected-' + this.nestedForm.eventKey )
            }

            this.$nextTick()
                .then( () =>
                {

                    this.nestedForm = set

                    let buttonRow = document.querySelector( '#button-row-' + this.eventKey )
                    if( false === set )
                    {
                        buttonRow.classList.remove( 'hidden' )
                    }
                    else
                    {
                        buttonRow.classList.add( 'hidden' )
                        let nestedButtons = {
                            left : {
                                type    : 'button',
                                caption : this.$core.getTranslation().translate( 'caption-cancel' ),
                                callback: () =>
                                {
                                    this.closeForm()
                                }
                            },
                            right: {
                                type    : 'submit',
                                caption : this.$core.getTranslation().translate( 'caption-submit' ),
                                callback: ( values, historic ) =>
                                {
                                    this.handleSubmit( values, historic, this.nestedForm.baseClass, true )
                                }
                            }
                        }

                        this.$core.getEventManager()
                            .add( 'on-color-selected-' + set.eventKey, ( value ) =>
                            {
                                this.colorUpdate( value )
                            } )

                        set.formButtons = nestedButtons

                    }

                } )

        },

        /* eslint-disable */
        handleSubmit( values, historic, baseClass, forceCreate )
        {

            if( false !== this.merge )
            {
                let newSetup = JSON.parse( JSON.stringify( this.merge ) )
                for( let k in Object.keys( values ) )
                {
                    let key = Object.keys( values )[ k ]
                    newSetup[ key ] = values[ key ]
                }

                values = newSetup
            }

            if( undefined !== values.listType
                && -1 < values.listType.indexOf( 'template' ) )
            {
                values.listType = values.originalListType
            }

            if( undefined !== this.$props.organizerSlot )
            {
                values.organizerSlot = JSON.stringify( {
                    organizerSlotId: this.$props.organizerSlot.localId,
                    timestamp      : values.forceTimestamp
                } )
            }

            forceCreate = undefined !== forceCreate ? forceCreate :
                          ( this.$core.f().isset( this.$props.editItem.forceCreate ) ? this.$props.editItem.forceCreate : undefined )

            let willAppend = ( false !== this.classFilter )

            for( let h in this.hiddenRefs )
            {
                if( undefined === values[ h ]
                    && undefined !== this.hiddenRefs[ h ] )
                {
                    values[ h ] = this.hiddenRefs[ h ]
                }
            }

            this.$emit( 'submit-form', values, historic, baseClass, forceCreate, willAppend )
            if( willAppend )
            {
                this.$emit( 'append-class-reference', this.classFilter )
            }

            this.closeForm()

        }

    }

}
</script>