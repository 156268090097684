<template>
    <div :class="'pagination'+( noMargin ? ' no-margin' : '')+' '+position" :key="filterKey">
        <template v-if="1 < pages.length">
            <strong>{{ $core.t( 'select-page' ) }}</strong>:
            <div class="button-wrap">
                <div v-for="( p, index ) in pages"
                     :key="'pagination-page-'+index"
                     @click="handleClick( index )"
                     :class="'pagebutton'+( page === ( index + 1 ) ? ' active' : '' )">
                    {{ ( index + 1 ) }}
                </div>
                <div class="clearfix"></div>
            </div>
        </template>
    </div>
</template>

<script>
export default {

    name: 'Pagination',

    props: {
        page     : { Type: Number, required: true },
        pages    : { Type: Object, required: true },
        filterKey: { Type: String, required: false, default: '' + Date.now() },
        noMargin : { Type: Boolean, required: false, default: false },
        position : { Type: String, required: false, default: '' },
    },

    emits: [ 'pageChange' ],

    methods: {
        handleClick( index )
        {
            this.$core.s().count( 'clicked-pagination' )
            this.$emit( 'pageChange', ( index + 1 ) )
        }
    }

}
</script>