<template>
    <div class="default-list-page" v-if="ready">
        <div v-if="studentAccess" class="hidden" :id="'access_qr_'+studentAccess.studentLocalId">
            <StudentAccessQr :localAccess="studentAccess.data"/>
        </div>
        <StudentAccessInfo v-if="false !== accessVisible"
                           :studentReference="studentReference"
                           :studentAccess="accessList[ studentReference.localId ]"
                           @close="accessVisible = false "/>
        <table class="list">
            <thead>
            <tr>
                <th><strong>Schüler:in</strong></th>
                <th><strong>Klasse</strong></th>
                <th><strong>Zugang</strong></th>
                <th><strong>zuletzt aktiv</strong></th>
                <th style="width: 50px;"></th>
            </tr>
            </thead>
            <tbody>
            <transition-group :name="$core.settings().getTransition( 'list' )" appear>
                <template v-for="student in students">
                    <tr v-if="-1 < visibleList.indexOf( student.localId )"
                        :class="'hoverable clickable '+( 'gender' === this.colorModel ? student.gender : student.color )"
                        :key="'std-'+student.localId"
                        @click="handleShow( student.localId )">
                        <td><strong>{{ student.lastname }}</strong>, {{ student.firstname }}</td>
                        <td><strong>{{ 'Z_FALSE' !== student.classname ? student.classname : '-' }}</strong></td>
                        <td><strong>{{
                                undefined !== accessList[ student.localId ] ? accessList[ student.localId ].username : 'n/a'
                            }}</strong>
                        </td>
                        <td>{{
                                undefined !== accessList[ student.localId ] ? accessList[ student.localId ].lastAccess : 'n/a'
                            }}
                        </td>
                        <td style="text-align: center;">
                            <template v-if="undefined === accessList[ student.localId ]">
                                <Button type="add"
                                        @click="handleAdd( $event, student.localId )"
                                        title="Zugang anlegen"
                                        addClass="centered"/>
                            </template>
                            <template v-else>
                                <Button type="delete"
                                        @click="handleDelete( student.localId )"
                                        title="Zugang löschen"
                                        addClass="centered"/>
                            </template>
                        </td>
                    </tr>
                </template>
            </transition-group>
            </tbody>
        </table>
    </div>
    <PreheatingIndicator v-else/>
</template>

<script>
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";
import StudentAccessInfo   from "@/components/routes/secretary/studentAccess/StudentAccessInfo";
import StudentAccessQr     from "@/components/routes/secretary/studentAccess/StudentAccessQr";
import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {
    name      : "AdminStudentAccess",
    components: { StudentAccessQr, StudentAccessInfo, PreheatingIndicator },
    mixins    : [ MixinCachePreheater ],
    props     : {
        filterList: { type: Object, required: true }
    },

    data()
    {
        return {
            neededCaches    : [ 'studentAccess', 'student' ],
            ready           : false,
            colorModel      : false,
            students        : [],
            visibleList     : [],
            accessList      : {},
            accessVisible   : false,
            studentReference: false,
            studentAccess   : false,
            listKey         : this.$core.getUuid().generate()
        }
    },

    created()
    {

        this.awaitNeededCaches()
            .then( () =>
            {

                this.prepareStudentsList()
                    .then( () =>
                    {

                        this.filterView()
                        this.prepareAccessList()

                        this.$core.getEventManager()
                            .add( 'on-student-access-generated', ( studentAccess ) =>
                            {

                                this.studentAccess = {
                                    data          : studentAccess,
                                    studentLocalId: studentAccess.studentLocalId
                                }
                            } )

                        this.ready = true

                    } )

            } )

    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-student-access-generated' )
    },

    watch: {
        listKey                          : {

            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.filterView()
                }
            }

        },
        '$bus.triggers.lastObjectCreated': {
            immediate: true,
            handler( value )
            {
                if( undefined !== value
                    && 'studentAccess' === this.$bus.triggers.lastObjectType )
                {
                    this.prepareAccessList()
                }
            }
        }
    },

    methods: {

        prepareStudentsList()
        {

            return new Promise( resolve =>
            {

                this.students = []
                this.colorModel = this.$core.settings().getSetting( 'studentColorModel' )

                let studentList = this.$core.getBaseClassHelper()
                                      .get( 'student' )
                                      .readCache( 'cache' )

                for( let s in studentList )
                {
                    this.students.push( studentList[ s ] )
                }

                this.$core.getSorter().multiSortObjects( this.students,
                    this.$core.settings().getSetting( 'sortingDirections' ).students )

                return resolve()

            } )

        },

        filterView()
        {

            for( let s in this.students )
            {

                let student = this.students[ s ]
                if( this.$core.f().allFilterMatch( student, this.filterList ) )
                {
                    if( -1 === this.visibleList.indexOf( student.localId ) )
                    {
                        this.visibleList.push( student.localId )
                    }
                }
                else
                {
                    let idx = this.visibleList.indexOf( student.localId )
                    if( -1 < idx )
                    {
                        this.visibleList.splice( idx, 1 )
                    }
                }

            }

        },

        getStudentActivityList()
        {

            return new Promise( resolve =>
            {

                this.$core.getClient()
                    .request( {
                        method: 'users.getStudentActivityList'
                    } )
                    .then( response =>
                    {
                        return resolve( response.result )
                    } )
                    .catch( () =>
                    {
                        return resolve( [] )
                    } )

            } )

        },

        /*eslint-disable*/
        prepareAccessList()
        {

            this.getStudentActivityList()
                .then( activities =>
                {

                    this.$core.getBaseClassHelper()
                        .get( 'studentAccess' )
                        .listAll()
                        .then( allAccesses =>
                        {

                            for( let a in allAccesses )
                            {

                                let access = allAccesses[ a ]

                                this.accessList[ access.studentLocalId ] = {
                                    username  : access.username,
                                    lastAccess: 'n/a',
                                    data      : access
                                }

                            }

                        } )

                } )

        },

        processDelete( localId )
        {

            let message = {
                method        : 'users.deleteStudentAccess',
                studentLocalId: localId
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    let access = this.accessList[ localId ].data
                    if( undefined !== access )
                    {

                        this.$core.getBaseClassHelper()
                            .get( 'studentAccess' )
                            .delete( access.localId, access.remoteId )
                            .then( () =>
                            {
                                delete this.accessList[ localId ]
                                this.prepareAccessList()
                            } )

                    }
                    this.$core.getUi()
                        .setPageMessage( 'Löschung erfolgreich', 'Der Zugang wurde deaktiviert und alle hiermit verknüpften Inhalte gelöscht.', 'ok', true )

                } )
                .catch( () =>
                {

                    this.$core.getUi()
                        .setPageMessage( 'Das hat nicht funktioniert', 'Der Zugang konnte nicht deaktiviert werden: Bitte überprüfe deine Internetverbindung und versuch es in ein paar Minuten noch einmal.', 'error', true )

                } )

        },

        handleDelete( localId )
        {
            this.$core.getUi()
                .showModalDialog(
                    'delete',
                    'Zugang löschen',
                    'Bist du dir sicher, dass du den Zugang löschen möchtest?',
                    [
                        'defaultCancel',
                        {
                            type    : 'delete',
                            title   : 'löschen',
                            callback: () =>
                            {
                                this.processDelete( localId )
                            }
                        }
                    ]
                )
        },

        handleAdd( event, localId )
        {

            this.$core.f().skip( event )
            this.$core.getRegisterStudentAccessHelper()
                .registerStudentAccess( this.$core.getBaseClassHelper().get( 'student' ).getById( localId ) )

        },

        handleShow( localId )
        {
            if( undefined !== this.accessList[ localId ] )
            {
                this.studentReference = this.$core.getBaseClassHelper().get( 'student' ).getById( localId )
                this.accessVisible = true
            }
        }


    }
}
</script>