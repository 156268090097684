<!--
    Checkbox.vue
    * simple checkbox element
-->
<template>
    <div class="relative">
        <input type="checkbox"
               :class="'checkbox'+foreignHighlighted"
               :disabled="getDisabled()"
               :checked="getChecked"
               :appearanceState="getAppearance()"
               :id="getElementRefName()"
               @change="emitValue"
               @mouseenter="$emit( 'focussed', refName )"
               @mouseleave="$emit( 'blurred', refName )"
               @prefillAdder="prefillAdder"/>
        <label v-if="caption" v-html="caption" :for="getElementRefName()" class="checklabel"/>
        <span class="hidden" v-if="getChecked === 'checked'">✓</span>
    </div>
</template>

<script>

import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {

    name  : 'Checkbox',
    mixins: [ MixinResettableComponent ],

    props: {
        refName    : { Type: String, required: true },
        value      : { Type: String, required: false },
        disabled   : { Type: Boolean, required: false },
        highlighted: { Type: Object, required: false, default: [] },
        inline     : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false },
        caption    : { Type: String, required: false }
    },

    data()
    {
        return {
            localValue: false,
            elementKey: false,
            uiElement : null
        }
    },

    mounted()
    {
        this.uiElement = document.querySelector( '#' + this.getElementRefName() )
        this.update()
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-field-update-' + this.getElementRefName() )
    },

    computed: {
        getChecked()
        {
            return ( 1 === this.localValue || 'on' === this.localValue ) ? 'checked' : undefined
        },
        foreignHighlighted()
        {
            return ( -1 < this.$props.highlighted.indexOf( this.$props.refName ) ? ' highlighted' : '' )
        }
    },

    watch: {
        value: {
            immediate: true,
            handler()
            {

                this.update()

            }
        }
    },

    emits: [ 'update', 'focussed', 'blurred' ],

    methods: {

        update()
        {
            this.localValue = this.$props.value
            if( true === this.localValue )
            {
                this.localValue = 1
            }
            this.elementKey = this.$core.getUuid().generate()
        },

        getAppearance()
        {
            return ( 1 === this.localValue || 'on' === this.localValue ) ? 'On' : 'Off'
        },
        getDisabled()
        {
            return true === this.$props.disabled
        },
        getElementRefName()
        {
            return 'form-element-' + this.$props.refName
        },
        prefillAdder( event )
        {

            let values = event.detail.values
            if( values.type === 'testcomment' )
            {
                let elm = document.querySelector( '#' + this.getElementRefName() )
                elm.checked = true
            }
            this.emitValue()

        },

        emitValue( event )
        {

            this.localValue = event.target.checked ? 1 : undefined
            this.$emit( 'update', this.$props.refName, 'checkBox', 'update', undefined, this.localValue )
            this.elementKey = this.$core.getUuid().generate()

        }

    }

}
</script>