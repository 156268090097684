/* eslint-disable */
import SyncLicense       from '@/classes/Core/Workers/SyncWorker/SyncLicense'
import SyncCrud          from '@/classes/Core/Workers/SyncWorker/SyncCrud'
import SyncSettings      from '@/classes/Core/Workers/SyncWorker/SyncSettings'
import SyncNetwork       from '@/classes/Core/Workers/SyncWorker/SyncNetwork'
import SyncProfile       from '@/classes/Core/Workers/SyncWorker/SyncProfile'
import SyncBackgrounds   from '@/classes/Core/Workers/SyncWorker/SyncBackgrounds'
import SyncStatistics    from '@/classes/Core/Workers/SyncWorker/SyncStatistics'
import SyncTemplates     from '@/classes/Core/Workers/SyncWorker/SyncTemplates'
import SyncSlotTemplates from '@/classes/Core/Workers/SyncWorker/SyncSlotTemplates'
import SyncVersion       from '@/classes/Core/Workers/SyncWorker/SyncVersion'
import SyncHolidays      from '@/classes/Core/Workers/SyncWorker/SyncHolidays'
import SyncBubbles       from '@/classes/Core/Workers/SyncWorker/SyncBubbles'
import SyncMessages      from '@/classes/Core/Workers/SyncWorker/SyncMessages'
import SyncPatches       from '@/classes/Core/Workers/SyncWorker/SyncPatches'
import SyncNotifications from '@/classes/Core/Workers/SyncWorker/SyncNotifications'
import SyncShadowCopies  from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies";
import SyncShares        from "@/classes/Core/Workers/SyncWorker/SyncShares";
import SyncCompetencesTemplates from "@/classes/Core/Workers/SyncWorker/SyncCompetencesTemplates";

export default class SyncWorker
{
    constructor( core )
    {

        if( !SyncWorker.instance )
        {

            this.interval = 10000
            this.firstSync = true
            this.repeatedSync = false

            this.core = core

            this.f = core.f()
            this.s = core.s()
            this.cc = core.cc()
            this.ui = core.getUi()
            this.fieldHistoryHelper = core.getFieldHistoryHelper()

            this.nthCounter = 0

            this.getBaseClassHelper = () =>
            {
                return this.core.getBaseClassHelper()
            }

            this.getMediaHelper = () =>
            {
                return this.core.getMediaHelper()
            }

            this.logger = core.getLogger()
            this.client = core.getClient()
            this.store = core.getStore()
            this.database = core.getDatabase()
            this.timer = core.getCoreTimer()
            this.eventManager = core.getEventManager()
            this.cryptoHelper = core.getCryptoHelper()
            this.queueWorker = core.getQueueWorker()
            this.uuid = core.getUuid()
            this.flags = core.getFlags()
            this.settings = core.settings()
            this.sorter = core.getSorter()
            this.sanitizers = core.getSanitizers()
            this.rights = core.r()

            this.waitIndex = null
            if( undefined === this.rights )
            {
                this.waitIndex = this.eventManager.addIndexed( 'on-rights-ready', () =>
                {
                    this.rights = core.r()
                    this.eventManager.removeIndexedCallback( 'on-rights-ready', this.waitIndex )
                    this.waitIndex = null
                } )
            }

            this.disabledSyncs = []

            this.license = false
            this.paused = false

            this.setState = ( key, value ) =>
            {
                core.setState( key, value )
            }
            this.getState = ( key ) =>
            {
                return core.getState( key )
            }

            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.share = false

            this.ui = core.getUi()

            this.logger.cconstructed( 'SyncWorker:constructor', 'syncworker is launching...' )

            this.syncBackground = new SyncBackgrounds( this )

            this.registryTriggeredOnce = false
            this.syncQueue = []
            this.mainSyncLoop = []
            this.typeSyncs = {}

            this.activeSync = false
            this.syncedBefore = false
            this.lastFetch = false
            this.ready = false
            this.silent = false
            this.byReset = false

            this.setup()

            this.eventManager.append( 'on-share-ready', ( share ) =>
            {
                this.share = share
            } )
            this.eventManager.append( 'on-license-read', () =>
            {
                this.license = core.getLicense()
            } )

            this.resetIndex = this.eventManager.addIndexed( 'core-component-reset', () =>
            {

                this.reset()

            } )

            SyncWorker.instance = this

        }

        return SyncWorker.instance

    }

    destruct()
    {
        this.eventManager.unregisterIndexedCallback( 'core-component-reset', this.resetIndex )
        delete SyncWorker.instance
    }

    reset( close )
    {

        this.timer.removeInterval( 'sync-worker-timer' )

        this.logger.clog( 'SyncWorker:reset', 'resetting syncworker to state zero after core components reset trigger' )

        this.syncQueue = []
        this.mainSyncLoop = []
        this.typeSyncs = {}

        this.activeSync = false
        this.ready = false
        this.silent = true

        this.setup( true )

        if( undefined === close )
        {
            this.timer.addInterval( 'sync-worker-timer', this.interval, () =>
            {

                this.sync()

            }, true )
        }

    }

    setup( skipEvents )
    {

        this.logger.clog( 'SyncWorker:setup', 'setting up syncworker main queue and event handlers...' )
        this.setupTypeSyncs()
        this.setupSyncLoop()

        if( undefined === skipEvents )
        {

            this.eventManager.append( 'on-store-ready', () =>
            {
                this.silent = false !== this.store.getters.lastSyncRun
                if( this.store.getters.shouldSync === true
                    && !this.syncedBefore
                    && this.store.getters.authorized === true )
                {
                    this.triggerFirstSync()
                }
            } )

            this.eventManager.append( 'on-websocket-open', () =>
            {
                this.triggerFirstSync()
            } )

            this.eventManager.append( 'on-login-state-change', () =>
            {
                this.timer.addTimeout( 'sync-after-login-state-change', 2000, () =>
                {
                    this.triggerFirstSync( false )
                } )
            } )
        }

    }

    triggerFirstSync( forceSilent )
    {

        this.silent = forceSilent !== undefined ? forceSilent : ( false !== this.store.getters.lastSyncRun )
        this.setState( 'mxCache-initialize-after-sync', true )

        if( !this.syncedBefore
            && this.store.getters.authorized === true )
        {
            this.timer.addInterval( 'sync-worker-timer', this.interval, () =>
            {

                this.sync()

            }, true )
        }

    }

    setupTypeSyncs()
    {

        this.typeSyncs.license = new SyncLicense( this )
        this.typeSyncs.crud = new SyncCrud( this )
        this.typeSyncs.settings = new SyncSettings( this )
        this.typeSyncs.network = new SyncNetwork( this )
        this.typeSyncs.profile = new SyncProfile( this )
        this.typeSyncs.statistics = new SyncStatistics( this )
        this.typeSyncs.templates = new SyncTemplates( this )
        this.typeSyncs.competenceTemplates = new SyncCompetencesTemplates( this )
        this.typeSyncs.slotTemplates = new SyncSlotTemplates( this )
        this.typeSyncs.version = new SyncVersion( this )
        this.typeSyncs.holidays = new SyncHolidays( this )
        this.typeSyncs.bubbles = new SyncBubbles( this )
        this.typeSyncs.messages = new SyncMessages( this )
        this.typeSyncs.patches = new SyncPatches( this )
        this.typeSyncs.notifications = new SyncNotifications( this )
        this.typeSyncs.shadowCopies = new SyncShadowCopies( this )
        this.typeSyncs.shares = new SyncShares( this )

    }

    addSyncJob( job, def, interval, n )
    {

        n = undefined === n ? 1 : n
        let timestamp = Date.now() + ( interval !== undefined ? interval : 0 ) + ( ( n - 1 ) * 2000 )
        this.mainSyncLoop.push(
            {
                job        : job,
                repetitive : def.repetitive,
                nthCall    : def.nthCall || 1,
                triggerTime: timestamp
            } )

    }

    disableSyncJob( job )
    {

        this.disabledSyncs.push( job )

    }

    flushSyncLoop()
    {
        this.mainSyncLoop = []
    }

    setupSyncLoop()
    {

        return new Promise( resolve =>
        {

            this.flushSyncLoop()

            this.store.dispatch( 'waitReady' )
                .then( () =>
                {

                    /*                    switch( this.store.getters.isStudent )
                                        {
                                            case 1:
                                                this.addSyncJob( 'typesync-profile', { repetitive: true, nthCall: 6 } )
                                                this.addSyncJob( 'typesync-statistics', { repetitive: true, nthCall: 6 } )
                                                this.addSyncJob( 'typesync-license', { repetitive: true, nthCall: 2 } )
                                                this.addSyncJob( 'typesync-settings', { repetitive: true, nthCall: 3 } )

                                                if( undefined === parseInt( this.store.getters.lastSyncRun )
                                                    || isNaN( parseInt( this.store.getters.lastSyncRun ) )
                                                    || ( Date.now() - ( 86400 * 1000 ) ) > parseInt( this.store.getters.lastSyncRun ) )
                                                {
                                                    this.addSyncJob( 'typesync-crud', { repetitive: false, nthCall: 1 } )
                                                }

                                                this.addSyncJob( 'typesync-patches', { repetitive: true, nthCall: 2 } )
                                                this.addSyncJob( 'typesync-version', { repetitive: true, nthCall: 4 } )
                                                this.addSyncJob( 'typesync-holidays', { repetitive: false, nthCall: 1 } )
                                                this.addSyncJob( 'typesync-messages', { repetitive: true, nthCall: 1 } )
                                                this.addSyncJob( 'typesync-notifications', { repetitive: true, nthCall: 1 } )
                                                break
                                            default:*/
                    this.addSyncJob( 'typesync-profile', { repetitive: true, nthCall: 3 } )
                    this.addSyncJob( 'typesync-statistics', { repetitive: true, nthCall: 6 } )
                    this.addSyncJob( 'typesync-license', { repetitive: true, nthCall: 2 } )
                    this.addSyncJob( 'typesync-settings', { repetitive: true, nthCall: 3 } )

                    //      if( undefined === parseInt( this.store.getters.lastSyncRun )
                    //  || isNaN( parseInt( this.store.getters.lastSyncRun ) )
                    //  || ( Date.now() - ( 86400 * 1000 ) ) > parseInt( this.store.getters.lastSyncRun ) )
                    //      {
                    //          this.addSyncJob( 'typesync-crud', { repetitive: false, nthCall: 1 } )
                    //      }

                    this.addSyncJob( 'typesync-patches', { repetitive: true, nthCall: 2 } )
                    this.addSyncJob( 'typesync-network', { repetitive: true, nthCall: 3 } )
                    this.addSyncJob( 'typesync-bubbles', { repetitive: true, nthCall: 3 } )
                    this.addSyncJob( 'typesync-templates', { repetitive: true, nthCall: 10 } )
                    this.addSyncJob( 'typesync-competenceTemplates', { repetitive: true, nthCall: 1 } )
                    //this.addSyncJob( 'typesync-slotTemplates', { repetitive: true, nthCall: 10 } )
                    this.addSyncJob( 'typesync-version', { repetitive: true, nthCall: 3 } )
                    this.addSyncJob( 'typesync-holidays', { repetitive: false, nthCall: 1 } )
                    this.addSyncJob( 'typesync-messages', { repetitive: true, nthCall: 1 } )
                    this.addSyncJob( 'typesync-notifications', { repetitive: true, nthCall: 3 } )
                    this.addSyncJob( 'typesync-shadowCopies', { repetitive: true, nthCall: 3 } )
                    this.addSyncJob( 'typesync-shares', { repetitive: true, nthCall: 1 } )
                    /*                            break
                                        }*/

                    return resolve()

                } )

        } )

    }

    /**
     * getSyncParameters
     * - returns next sync params as "syncMode" (full sync or differential) and "since" (the timestamp)
     * @returns {{mode: (string), since: string}}
     */
    getSyncParameters( offset )
    {

        offset = undefined !== offset ? offset : 0
        let syncMode  = this.lastFetch === false ? 'full' : 'differential',
            since     = 'full' !== syncMode ? this.lastFetch : Date.now(),
            tsmpSince = new Date( since + offset ).toISOString().slice( 0, 19 ).replace( 'T', ' ' )

        return {
            since: tsmpSince,
            mode : syncMode
        }

    }

    /**
     * _getLocalKey
     * - returns the local (secret) key for an encrypted object (if any)
     * @param keys
     * @returns {boolean|*}
     * @private
     */
    _getLocalKey( keys )
    {

        let localUuid = this.store.getters.uuid,
            key       = null

        for( let k in keys )
        {
            if( keys[ k ].uuid == localUuid )
            {
                key = keys[ k ].secret
            }
        }

        return key !== null ? key : false

    }

    /**
     * _prepareKeys
     * - prepares the keychain for an encrypted object before sync
     * @param keys
     * @returns {[]}
     * @private
     */
    _prepareKeys( keys )
    {

        let remoteKeys = []
        for( let k in keys )
        {
            remoteKeys.push( {
                uuid: keys[ k ].uuid,
                key : keys[ k ].secret
            } )
        }

        return remoteKeys

    }

    afterSync()
    {

        if( !this.timer.hasTimer( 'sync-crud-timer' ) )
        {
            this.timer.addInterval( 'sync-crud-timer', 5000,
                () =>
                {
                    this.typeSyncs.crud.sync()
                }, true )
        }

        this.logger.clog( 'SyncWorker:afterSync', 'syncworker has run dry: waiting for next trigger...' )
        if( !this.silent
            || !this.syncedBefore )
        {
            this.ui.unlockBlocker()
            this.ui.hideBlocker()
        }

        this.setState( 'syncworker-aftersync', true )
        this.eventManager.dispatchAndRemove( 'on-first-sync-done' )
        this.eventManager.dispatchAndRemove( 'login-unwait' )

        if( true === this.getState( 'justLoggedIn' )
            || !this.syncedBefore )
        {
            this.setState( 'justLoggedIn', false )
            this.eventManager.dispatch( 'on-mxregistry-initialize' )
        }

        this.syncedBefore = true
        this.silent = true
        this.lastFetch = Date.now()
        this.store.commit( 'setLastSyncRun', this.lastFetch )
        this.activeSync = false

        if( !this.firstSync )
        {
            this.setState( 'all-synced', true )
        }

        if( this.flags.ready
            && this.flags.is( 'demouser' ) )
        {
            this.pause( true )
        }

        this.firstSync = false

        if( 100000 < this.nthCounter )
        {
            this.nthCounter = 0
        }
        this.nthCounter++

    }

    awaitAllSynced()
    {
        return new Promise( resolve =>
        {
            if( true !== this.firstSync )
            {
                this.setState( 'all-synced', true )
                return resolve()
            }
            else
            {
                this.timer.addTimeout( 'sw-await-all-synced', 300, () =>
                {
                    return resolve( this.awaitFirstSync() )
                } )
            }
        } )
    }

    triggerWalk( triggerList )
    {

        return new Promise( resolve =>
        {

            if( 0 < triggerList.length )
            {

                let promises = []
                for( let t in triggerList )
                {
                    promises.push( () =>
                    {

                        return new Promise( resolve =>
                        {
                            triggerList[ t ]()
                                .then( () =>
                                {
                                    return resolve()
                                } )
                        } )

                    } )
                }

                this.f.promiseRunner( promises )
                    .then( () =>
                    {
                        return resolve()
                    } )

            }
            else
            {
                return resolve()
            }

        } )

    }

    pause( state )
    {
        if( this.flags.is( 'demouser' ) && state === false )
        {
            return
        }
        this.logger.clog( 'SyncWorker:pause', 'state is now:', state ? 'paused' : 'unpaused' )
        this.paused = state
    }

    awaitFirstSync()
    {

        return new Promise( resolve =>
        {
            if( !this.syncedBefore )
            {

                this.eventManager.append( 'on-first-sync-done', () =>
                {
                    return resolve()
                } )

            }
            else
            {
                return resolve()
            }
        } )
    }

    performSync()
    {
        return new Promise( resolve =>
        {

            this.activeSync = true
            let now         = Date.now(),
                triggerList = [],
                newSyncList = [],
                oldSyncList = [],
                prefSetup   = {}

            while( 0 < this.mainSyncLoop.length )
            {

                let item = this.mainSyncLoop.shift()
                prefSetup[ item.job ] = item

                if( ( item.triggerTime <= now
                      && this.nthCounter % item.nthCall === 0 )
                    || !this.syncedBefore )
                {

                    let job = item.job.split( '-' )
                    if( 'typesync' === job[ 0 ]
                        && -1 === this.disabledSyncs.indexOf( job[ 1 ] ) )
                    {

                        let todo = job[ 1 ]
                        let p = () =>
                        {
                            return new Promise( ( resolve ) =>
                            {

                                this.logger.clog( 'SyncWorker:sync', 'running ' + item.job )

                                this.typeSyncs[ todo ].sync()
                                                      .then( () =>
                                                      {

                                                          return resolve()

                                                      } )
                                                      .catch( e =>
                                                      {
                                                          return resolve()
                                                      } )

                            } )
                        }

                        triggerList.push( p )

                    }
                    if( item.repetitive === true )
                    {
                        newSyncList.push( item.job )
                    }

                }
                else
                {
                    oldSyncList.push( item )
                }

            }

            this.triggerWalk( triggerList )
                .then( () =>
                {

                    let n = 1

                    while( 0 < oldSyncList.length )
                    {
                        let job = oldSyncList.shift()
                        this.mainSyncLoop.push( job )
                    }

                    while( 0 < newSyncList.length )
                    {

                        let job = newSyncList.shift()
                        this.addSyncJob( job, { repetitive: true, nthCall: prefSetup[ job ].nthCall }, undefined, n )
                        n++

                    }

                    this.afterSync()
                    return resolve()

                } )

            this.store.commit( 'setShouldSync', true )

        } )
    }

    /*eslint-disable*/
    sync()
    {
        this.logger.clog( 'SyncWorker:sync', 'preparing sync run' )
        return new Promise( ( resolve ) =>
        {

            if( false === this.store.getters.authorized
                || true === this.activeSync
                || true === this.paused )
            {
                return resolve()
            }

            if( this.firstSync )
            {
                this.setupSyncLoop()
                    .then( () =>
                    {
                        return resolve( this.performSync() )
                    } )
            }
            else
            {
                return resolve( this.performSync() )
            }

        } )

    }

    singleObjectSync( localId )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.typeSyncs.crud
                .singleSync( localId )
                .then( result =>
                {
                    return resolve( result )
                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )
    }

}