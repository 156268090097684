<template>
    <tr :id="'tr-'+random">
        <td :colspan="colspan" @click="handleClick()" class="month-separator">
            <strong>{{ caption }}</strong> <small>&nbsp;|&nbsp;{{ count }} {{ wording }}</small>
            <Opener @clicked="handleClick()"
                    :id="'sep-opener-'+random+'-'+shortCaption"
                    :state
                    classname="inlist"/>
        </td>
    </tr>
</template>

<script>
import Opener from '@/components/elements/defaults/Opener'

export default {
    name      : "listElementMonthSeparator",
    components: { Opener },
    props     : {
        initialState: { Type: Boolean, required: true },
        count       : { Type: Number, required: true },
        colspan     : { Type: Number, required: true },
        shortCaption: { Type: String, required: true },
        caption     : { Type: String, required: true }
    },
    
    emits: [ 'toggle' ],
    
    data()
    {
        return {
            random: this.$core.getSanitizers().cleanId( this.$props.caption ),
            state: false
        }
    },
    
    computed: {
        wording()
        {
            return 'Element'+( 1 < this.$props.count ? 'e' : '' )
        }
    },
    
    created()
    {
        this.state = this.$props.initialState
    },
    methods: {
        handleClick()
        {
            this.state = !this.state
            this.$emit( 'toggle', this.state )
        }
    }
}
</script>