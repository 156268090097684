<template>
    <!--    <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">-->
    <div v-if="visible"
         :class="'default-listeditor-overlay'+( escapeNested ? ' z-99000' : '')+additionalCssClass"
         @contextmenu="$core.f().skip"
         :key="viewKey"
         :id="viewKey">
        <div :class="'detail-view listeditor'+( forceFullScreen ? ' force-fullscreen' : '' )"
             @contextmenu="$core.f().skip">
            <!-- THE HEADLINE -->
            <ListEditorHeadline :lists="activeLists"
                                :activeListId="activeListId"
                                :editorMode="editMode"
                                :editingAll="editingAll[ activeListId ]"
                                :snapshots="snapshots[ activeListId ]"
                                :showCalcFields="showCalcFields[ activeListId ]"
                                :recallVisible="recallVisible[ activeListId ]"
                                :listFilterVisible="listFilterVisible"
                                :disableMulti
                                @toggleCalcFields="showCalcFields[ activeListId ] = !showCalcFields[ activeListId ]"
                                @showListSelect="setShowListSelect"
                                @setActiveList="setActiveList"
                                @close="handleClose( true )"
                                @closeList="handleCloseList"
                                @recallVisibleChange="recallVisible = !recallVisible"
                                @filterVisibleChange="listFilterVisible = !listFilterVisible"
                                @history="handleHistory"
                                @print="handlePrint"
                                @excel="handleExcel"
                                @modeSwitch="modeSwitch"/>

            <!-- LIST SELECTOR -->
            <!--<transition appear :name="$core.settings().getTransition( 'fastzoom' )"
                        mode="in-out">-->

            <ListEditorSelector v-if="showListSelect"
                                :reference
                                :hiddenVisible
                                :noShareIndicator
                                :headFunctions
                                :selectMode
                                :archived="list.archived"
                                @toggleHiddenVisible="hiddenVisible = !hiddenVisible"
                                @setReference="reference = $event"
                                @findTab="handleFindTab"
                                @open="handleOpenList"
                                @print="handlePrint()"
                                @excel="handleExcel()"
                                @qrkey="$emit( 'qrkey' )"/>

            <!--v-show="showListSelect"-->
            <!--</transition>-->

            <!-- ALL OPEN EDITORS -->
            <template v-for="[ localId, list ] in activeLists"
                      :key="'multilist-wrapper-'+list.localId">
                <!--<transition appear :name="$core.settings().getTransition( 'fastzoom' )"
                            mode="in-out">-->
                <div class="listeditor-inner no-scroll"
                     v-show="localId === activeListId">

                    <ListEditorMain v-if="editorActive"
                                    v-show="!closing[ activeListId ]"
                                    :list="list"
                                    :listFilter
                                    :listFilterVisible
                                    :students="students[ activeListId ]"
                                    :studentList="studentList[ activeListId ]"
                                    :escapeNested
                                    :openVertical
                                    :showCalcFields="showCalcFields[ activeListId ]"
                                    :editClosing="editClosing[ activeListId ]"
                                    :editHistory="editHistory[ activeListId ]"
                                    :editMode
                                    :editPrint="editPrint[ activeListId ]"
                                    :editExcel="editExcel[ activeListId ]"
                                    :reference
                                    @editModeSwitch="editMode = $event"
                                    @update="handleUpdate"
                                    @definitionUpdate="handleDefinitionUpdate"
                                    @containerUpdate="handleContainerUpdate"/>
                    <div v-show="closing[ activeListId ]">
                        <PreheatingIndicator
                            :captionHtml="$core.t( 'list-editor-closing-message' )"/>
                    </div>
                </div>

                <!--</transition>-->
            </template>

        </div>
    </div>
    <!--    </transition>-->
</template>

<script>
/*eslint-disable*/
import ListEditorHeadline  from "@/components/elements/viewItems/listEditor/layout/ListEditorHeadline";
import ListEditorMain      from "@/components/elements/viewItems/listEditor/layout/ListEditorMain";
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";
import MixinEvents         from "@/mixins/MixinEvents.vue";
import ListEditorSelector  from "@/components/elements/viewItems/listEditor/layout/ListEditorSelector.vue";

export default {

    name      : "ListEditor",
    components: {
        ListEditorSelector,
        PreheatingIndicator,
        ListEditorMain,
        ListEditorHeadline
    },
    emits     : [ 'close', 'definitionUpdate' ],
    mixins    : [ MixinEvents ],

    props: {
        list        : { Type: Object, required: true },
        listFilter  : { type: [ Object, Boolean ], required: true },
        loadStudents: { Type: Object, required: true },
        disableMulti: { Type: Boolean, required: false, default: false },
        escapeNested: { Type: Boolean, required: false, default: false },
        openVertical: { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            closeDelay        : 500,
            viewKey           : 'listEditor-' + this.$core.getUuid().generate(),
            showListSelect    : false,
            activeLists       : new Map(),
            activeListId      : false,
            visible           : true,
            dataPrepared      : false,
            initialized       : {},
            editingAll        : {},
            snapshots         : {},
            recallVisible     : {},
            showCalcFields    : {},
            students          : {},
            studentList       : {},
            closing           : {},
            listFilterVisible : false,
            hiddenVisible     : false,
            noShareIndicator  : false,
            selectMode        : false,
            editClosing       : {},
            editHistory       : {},
            editMode          : 'horizontal',
            editPrint         : {},
            editExcel         : {},
            listSelection     : false,
            reference         : false,
            additionalCssClass: '',
            searchVisible     : false,
            editorActive      : true,
            headFunctions     : {
                select             : false,
                add                : false,
                search             : false,
                print              : false,
                qr                 : false,
                toggleHiddenVisible: true
            },
            fieldSetup        : [
                'initialized',
                'snapshots',
                'recallVisible',
                'showCalcFields',
                'editClosing',
                'editHistory',
                'editPrint',
                'editExcel',
                'students',
                'studentList',
                'closing'
            ],
            forceFullScreen   : this.$core.settings().getSetting( 'listEditorForceFullscreen' )
        }
    },

    created()
    {
        this.start = Date.now()
        this.activeListId = this.$props.list.localId
        this.activeLists.set( this.$props.list.localId, this.$props.list )
        this.prepare( this.activeListId, true )
        this.$core.setState( 'listEditorOpen', true )
        this.$core.getEventManager().dispatch( 'on-activity' )
    },

    beforeUnmount()
    {
        this.cleanup()
        this.$core.setState( 'listEditorOpen', false )
        this.$core.getEventManager()
            .dispatchAndRemove( 'on-listeditor-closed' )
    },

    methods: {

        cleanup()
        {
            for( let s in this.fieldSetup )
            {
                for( let idx in this[ this.fieldSetup[ s ] ] )
                {
                    this[ this.fieldSetup[ s ] ][ idx ] = undefined
                }
                this[ this.fieldSetup[ s ] ] = undefined
                delete this[ this.fieldSetup[ s ] ]
            }
            this.activeLists = undefined
        },

        prepare( listId, startup )
        {

            listId = listId || this.activeListId
            for( let s in this.fieldSetup )
            {
                this[ this.fieldSetup[ s ] ][ listId ] = null
            }

            if( startup )
            {
                this.prepareFilter()
            }
            this.prepareStudents( listId )

            this.visible = true
            this.dataPrepared = true

        },

        handleOpenList( localId )
        {

            let element = this.$core.getBaseClassHelper().get( 'list' )
                              .getContainerItem( localId )

            if( undefined !== element )
            {

                this.showListSelect = false
                this.activeLists.set( localId, element )
                this.prepare( localId, false )
                this.activeListId = localId

            }

            element = undefined

        },

        _getSortMap( sortKey, mapKey )
        {

            mapKey = mapKey || 'cache'

            return this.$core.getBaseClassHelper()
                       .get( sortKey )
                       .registry
                       .sortMap.get( mapKey )

        },

        prepareStudents( listId )
        {

            let list           = this.activeLists.get( listId ),
                selector       = list.columns[ 0 ].filterBy,
                studentList    = [],
                studentObjects = [],
                studentReference

            if( 'all' === selector )
            {

                this.students[ listId ] = this.$core
                                              .getBaseClassHelper()
                                              .get( 'student' )
                                              .getCache( true === list.archived ? 'archive' : 'cache' )

                /*eslint-disable*/
                for( const [ s, student ] of this.students[ listId ] )
                {
                    studentObjects.push( student )
                    studentList.push( student.localId )
                }
                /*eslint-enable*/

                this.students[ listId ] = studentObjects
                this.studentList[ listId ] = studentList

            }
            else
            {

                //let sortMap = this._getSortMap( 'student', list.archived ? 'archive' : 'cache' )

                studentReference = this.$core.getBaseClassHelper().getObjectById( selector )
                studentList = studentReference.students

                for( let s in studentList )
                {
                    let student = this.$core.getBaseClassHelper().getObjectById( studentList[ s ] )
                    if( null !== student
                        && undefined !== student )
                    {
                        studentObjects.push( student )
                    }
                }

                this.students[ listId ] = studentObjects
                this.studentList[ listId ] = studentList

                list = null
                selector = null
                studentList = null
                studentReference = null
                studentObjects = null

            }

        },

        prepareFilter()
        {

            if( 'office' !== this.$route.name
                && this.$core.getState( 'detailViewFor' ) )
            {
                let setup = this.$core.getState( 'detailViewFor' ).split( /:/ )
                this.reference = this.$core.getBaseClassHelper().getObjectById( setup[ 1 ] )
            }
            else
            {
                this.reference = false
            }

        },

        setShowListSelect()
        {
            this.activeListId = false
            this.showListSelect = true
            this.controlKey = this.$core.getUuid().generate()
        },

        setActiveList( listId )
        {
            this.showListSelect = false
            this.activeListId = listId
        },

        modeSwitch( mode )
        {
            this.editMode = mode
        },

        handleContainerUpdate( localId, key, value )
        {
            this[ key ][ localId ] = this[ key ][ localId ] || false
            this[ key ][ localId ] = value
        },

        handleHistory( history )
        {

            let found = false
            for( let h in this.snapshots[ this.activeListId ] )
            {
                if( this.snapshots[ this.activeListId ][ h ].id === parseInt( history ) )
                {
                    this.editHistory[ this.activeListId ] = this.snapshots[ this.activeListId ][ h ]
                    found = true
                }
            }

            if( !found )
            {
                this.editHistory[ this.activeListId ] = false
            }

        },

        handlePrint()
        {
            this.editPrint[ this.activeListId ] = true
            this.$core.getUi().delay( () =>
            {
                this.editPrint[ this.activeListId ] = false
            }, 500 )
        },

        handleExcel()
        {
            this.editExcel[ this.activeListId ] = true
            this.$core.getUi().delay( () =>
            {
                this.editExcel[ this.activeListId ] = false
            }, 500 )
        },

        handleDefinitionUpdate( objectClone )
        {
            this.$emit( 'definitionUpdate', objectClone )
        },

        handleFindTab( localId )
        {
            if( undefined !== this.activeLists.get( localId ) )
            {
                this.setActiveList( localId )
            }
        },

        /*eslint-disable*/
        handleCloseList( localId )
        {

            this.closing[ localId ] = true

            setTimeout( () =>
            {

                for( let s in this.fieldSetup )
                {
                    this[ this.fieldSetup[ s ] ][ localId ] = undefined
                    delete this[ this.fieldSetup[ s ] ][ localId ]
                }

                /*eslint-disable*/
                if( localId === this.activeListId )
                {
                    for( let [ localId, list ] in this.activeLists )
                    {
                        if( localId !== this.activeListId )
                        {
                            this.activeListId = localId
                            break
                        }
                    }
                }
                /*eslint-enable*/
                this.activeLists.delete( localId )

            }, this.closeDelay )

            return

        },

        handleUpdate()
        {
            alert( 'YAY' )
        },

        handleClose()
        {

            if( true === this.updateTimeout )
            {
                return
            }
            else
            {

                for( let i in this.initialized )
                {
                    this.handleCloseList( i )
                }

                setTimeout( () =>
                {
                    this.additionalCssClass = ' hiding'
                    this.$core.getUi().delay( () =>
                    {

                        this.visible = false
                        this.editorActive = false
                        this.$emit( 'close' )

                    }, 300 )
                }, ( 2 * this.closeDelay ) )

            }

        }
    }

}
</script>

<style scoped>

.padding-20 {
    padding: 20px;
}

.default-listeditor-overlay {
    transition: opacity 300ms ease;
}

.hiding {
    opacity: 0;
}

</style>