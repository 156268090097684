<template>
    <span v-if="0 < visibleCounter" :class="'counter counter-'+position"
          :key="'elementCounter-'+ownId">
        <strong>{{ visibleCounter }}</strong> Element<span v-if="1 !== visibleCounter">e</span>
        <span v-if="( 0 < filterCounter || viewIsFiltered ) && filterCounter !== visibleCounter"> insgesamt, <strong>{{ filterCounter }}</strong> gefiltert</span>
        <span v-if="0 < invisibleCounter"> ({{ invisibleCounter }} versteckt)</span>
    </span>
</template>

<script>
export default {
    name : "ElementCounter",
    props: {
        visibleCounter  : { type: Number, required: false, default: 0 },
        filterCounter   : { type: Number, required: false, default: 0 },
        invisibleCounter: { type: Number, required: false, default: 0 },
        viewIsFiltered  : { type: Boolean, required: false, default: false },
        position        : { type: String, required: false, default: 'top' }
    },
    data()
    {
        return {
            ownId                    : this.$core.getUuid().generate()
        }
    }
}
</script>