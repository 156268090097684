<template>
    <input v-if="!$core.states.get( 'excel-exporting' ) === true"
           type="text"
           :class="'smallnumberbox'+foreignHighlighted"
           :id="'form-element-'+id"
           :name="refName"
           :ref="refName"
           :value="display"
           :disabled="disabled"
           :autocomplete="autocomplete ? autocomplete : undefined"
           :placeholder="placeholder ? placeholder : '0'"
           @focusin="$emit( 'focussed', refName )"
           @focusout="$emit( 'blurred', refName )"
           @change="update"
           @blur="update"
           @keyup="checkKey"/>
    <span v-else>{{ display }}</span>
</template>

<script>
import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {

    name: 'SmallNumberBox',

    mixins: [ MixinResettableComponent ],

    props: {
        id          : { Type: String, required: true },
        refName     : { Type: String, required: false },
        vModel      : { Type: String, required: false },
        value       : { Type: String | Object, required: false },
        reformatter : { Type: String, required: false },
        highlighted : { Type: Object, required: false, default: [] },
        eventKey    : { Type: String, required: false },
        disabled    : { Type: Boolean, required: false, default: false },
        placeholder : { Type: String, required: false },
        autocomplete: { Type: String, required: false },
        className   : { Type: String, required: false },
        next        : { Type: String, required: false },
        steps       : { Type: Number, required: false },
        plain       : { Type: Boolean, required: false, default: false },
        allValues   : { Type: Object, required: false },
        unique      : { Type: Boolean, required: false }
    },

    emits: [ 'update', 'enterPressed', 'backspacePressed', 'focussed', 'blurred' ],

    data()
    {
        return {
            timer       : false,
            hasErrors   : false,
            display     : false,
            isProcessing: false,
            isInit      : undefined
        }
    },

    computed: {
        foreignHighlighted()
        {
            return ( this.$props.className || '' ) + ( this.hasErrors ? ' error' : '' ) /*+ ' '
                   + ( -1 < this.$props.highlighted.indexOf( this.$props.refName ) ? 'highlighted' : '' )*/
        }
    },

    created()
    {
        this.isInit = true
        this.parseValue()
        this.isInit = false
    },

    watch: {
        value: {
            immediate: true,
            deep     : true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.parseValue()
                }
            }
        }
    },

    methods: {

        parseValue()
        {

            if( !this.$core.f().valid( this.$props.value ) )
            {
                this.display = ''
            }
            else
            {
                this.display = ( this.$core.f().isObject( this.$props.value ) ? this.$props.value.value : this.$props.value )
            }
        },

        checkKey( key )
        {

            if( 13 === key.keyCode
                || 13 === key.which
                || 'Enter' === key.key )
            {
                key.preventDefault()
                this.$emit( 'enterPressed', this.$props.id )
            }
            else
            {
                if( this.validate() )
                {

                    let elm = document.querySelector( '#form-element-' + this.$props.id )
                    if( 8 === key.keyCode )
                    {
                        if( '' === elm.value.trim() )
                        {
                            this.$emit( 'backspacePressed', this.$props.id )
                        }
                    }
                    if( this.$core.f().isset( this.$props.reformatter ) )
                    {
                        if( this.timer )
                        {
                            clearTimeout( this.timer )
                        }
                        this.timer = setTimeout( () =>
                        {

                            this.reformatInput()

                        }, 5000 )
                    }

                    this.update()

                }

            }
        },

        validate()
        {
            let elm = document.querySelector( '#form-element-' + this.$props.id )
            if( null !== elm )
            {

                let value = elm.value.trim()
                this.display = value

                if( '' === value )
                {
                    return true
                }
                else
                {

                    let score = this.$core.getReformatter().float( value )
                    if( isNaN( score )
                        || 0 > score )
                    {
                        this.hasErrors = true
                        return false
                    }
                    this.hasErrors = false
                    return true

                }
            }
        },

        update( event )
        {

            if( !this.isProcessing
                && !this.isInit )
            {
                this.isProcessing = true

                let value = event && event.target ? event.target.value : this.$refs[this.$props.refName].value
                if( undefined === value )
                {
                    this.isProcessing = false
                    return
                }

/*                let elm = document.querySelector( '#form-element-' + this.$props.id )
                if( null !== elm )
                {*/

                    if( true === this.$props.unique )
                    {
                        this.hasErrors = false
                        let countval = 0
                        for( let v in this.$props.allValues )
                        {
                            let val = this.$props.allValues[ v ].value.trim()
                            if( value.trim() === val )
                            {
                                countval++
                            }
                        }
                        this.hasErrors = countval > 1
                    }

                    this.$emit( 'update', this.$props.refName, 'numberBox', 'update', undefined, ( value.trim() !== '' ? value : undefined ) )

                this.isProcessing = false
            }
        },

        reformatInput()
        {

            if( this.validate() )
            {

                let elm = document.querySelector( '#form-element-' + this.$props.id )
                if( null !== elm )
                {
                    if( this.$core.f().isset( this.$props.reformatter ) )
                    {
                        let value = this.$core.getReformatter().reformat( elm.value, this.$props.reformatter )
                        this.display = value
                    }

                    elm.value = this.display
                    this.$emit( 'update', this.$props.refName, 'numberBox', 'update', undefined, elm.value )

                }

            }

        }

    }

}
</script>