import AbstractShadowCopyClass
    from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/abstract/AbstractShadowCopyClass";

export default class CopyYeargroup extends AbstractShadowCopyClass
{

    constructor( parent )
    {

        super( parent, false, 'yeargroup', 'CopyYeargoup' )
        return this

    }

    check( shadowCopy )
    {

        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .get( this.type )
                .cacheHeatup()
                .then( () =>
                {

                    let shadowLocalId = 'shad-'
                                        + shadowCopy.referenceLocalId + '-'
                                        + this.parent.f.hashCyrB53( shadowCopy.studentLocalId ),
                        copyElement   = null,
                        original      = this.parent.baseClassHelper.get( this.type ).getById( shadowCopy.referenceLocalId, 'cache' )

                    for( let [ key, elm ] of this.parent.baseClassHelper.get( this.type ).registry.shadowCopies )
                    {
                        if( undefined !== key
                            && key === shadowLocalId )
                        {

                            copyElement = elm

                        }
                    }

                    let result = {
                        shadowLocalId: shadowLocalId,
                        localId      : shadowCopy.localId,
                        action       : undefined,
                        type         : this.type,
                        original     : original
                    }

                    if( undefined !== original )
                    {
                        if( null !== copyElement )
                        {
                            if( ( original.update > 0 && undefined === copyElement.update )
                                || original.update > copyElement.update )
                            {
                                result.action = 'update'
                                result.copy = shadowCopy
                                result.original = original
                            }
                        }
                        else
                        {
                            result.action = 'create'
                            result.copy = shadowCopy
                        }
                    }

                    return resolve( result )

                } )

        } )

    }

    create( job )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( undefined === job.access )
            {
                return reject( 'NO_ACCESS' )
            }

            this.prepareShadowCopyElement( job )
                .then( shadowCopy =>
                {

                    delete shadowCopy.students
                    shadowCopy.students = [ 'shad-' +
                                            job.access.studentLocalId + '-' +
                                            this.parent.f.hashCyrB53( job.access.studentLocalId ) ]

                    this.encryptShadowCopyElement( job, shadowCopy )
                        .then( result =>
                        {

                            return resolve( { plain: shadowCopy, db: result } )

                        } )
                        .catch( () =>
                        {
                            return reject()
                        } )

                } )

        } )

    }

}
