<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-show="visible" class="default-form-overlay">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <template v-if="ready">
                        <Button type="close"
                                @clicked="handleClose()"
                                addClass="absolute top right inverted"/>
                        <h2>{{ title }}</h2>
                        <div class="form">
                            <TabbedContent :tabs="tabs" :active="tab" @switched="setTab"/>
                            <div class="tabbed-content" v-if="tab === 'share'" :key="'tab-share'">
                                <div class="form-row">
                                    <div class="form-caption">
                                        teilen mit
                                    </div>
                                    <div class="form-component">
                                        <RowAdder component="ColleagueSelector"
                                                  refName="shareColleague"
                                                  unique=true
                                                  :showTeams="showTeams"
                                                  dragBlocked=true
                                                  id="shareColleague"
                                                  @update="handleChange"/>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="form-row">
                                    <div class="form-caption">
                                        verknüpfte Elemente
                                    </div>
                                    <div class="form-component">
                                        <ShareSelectors :object="objectList[0]"
                                                        @select="handleSelect"/>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="tabbed-content" v-if="tab === 'shared'" :key="'tab-shared'">
                                <template v-if="0 < shares.length">
                                    <div class="form-row">
                                        <div class="form-caption">
                                            Name
                                        </div>
                                        <div class="form-component">
                                            Aktion
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="form-row" v-for="share in shares"
                                         :key="share.colleagueId">
                                        <div class="form-caption">
                                            <strong>{{ share.colleague }}</strong>
                                        </div>
                                        <div class="form-component">
                                            <button class="delete"
                                                    @click="handleUnshare( share.colleagueId )">
                                                Teilen beenden
                                            </button>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-caption">
                                            <strong>&nbsp;</strong>
                                        </div>
                                        <div class="form-component">
                                            <button class="delete"
                                                    @click="handleUnshareAll()">
                                                Teilen mit allen Kolleg:innen beenden
                                            </button>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </template>
                                <template v-else>
                                    <strong>Das Element wird zur Zeit nicht geteilt.</strong>
                                </template>
                            </div>

                            <div class="form-row">
                            </div>
                            <div class="form-row buttons">
                                <div class="form-caption buttons">
                                    <button class="default-cancel"
                                            value="cancel"
                                            @click="handleClose()">abbrechen
                                    </button>
                                </div>
                                <div class="form-component buttons">
                                    <button class="submit"
                                            value="Bar"
                                            :disabled="disabled"
                                            @click="handleAction()">{{ title || 'ausgewählte Elemente teilen' }}
                                    </button>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </template>
                    <PreheatingIndicator v-else/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/*eslint-disable*/
import TabbedContent       from '@/components/layout/tabs/TabbedContent'
import ColleagueSelector   from '@/components/form/elements/ColleagueSelector'
import ShareSelectors      from '@/components/form/elements/ShareSelectors'
import RowAdder            from '@/components/form/elements/RowAdder'
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";

export default {

    name      : 'DefaultShareOverlay',
    components: { PreheatingIndicator, RowAdder, ShareSelectors, ColleagueSelector, TabbedContent },
    props     : {
        objectList: { type: Object, required: true }
    },
    emits     : [ 'close' ],

    data()
    {
        return {
            visible   : true,
            disabled  : true,
            selected  : {},
            showTeams : false,
            shareWith : false,
            ready     : false,
            shares    : [],
            colleagues: false,
            tab       : 'share',
            tabs      : [
                {
                    id     : 'share',
                    caption: this.$core.getTranslation().translate( 'share' )
                },
                {
                    id     : 'shared',
                    caption: this.$core.getTranslation().translate( 'shared' )
                }
            ]
        }
    },

    computed: {
        title()
        {
            if( this.$core.f().isset( this.$props.objectList )
                && this.$core.f().isset( this.$props.objectList[ 0 ] ) )
            {

                if( this.$props.objectList.length === 1 )
                {
                    return this.$core.getTranslation().translate( 'object-type-' + this.$props.objectList[ 0 ].type ) + ' teilen'
                }
                return this.$props.objectList.length + ' ' +
                       this.$core.getTranslation().translate( 'object-type-' + this.$props.objectList[ 0 ].type + 's' ) + ' teilen'

            }
            else
            {
                return ''
            }
        }
    },

    created()
    {

        let shares  = [],
            ccReady = false,
            shReady = false,
            tmReady = false

        this.$core.cc()
            .awaitReadiness()
            .then( () =>
            {
                this.colleagues = this.$core.cc().getAll()
                ccReady = true
                this.ready = tmReady === true && shReady === true && ccReady === true
            } )

        this.$core
            .getShare()
            .awaitShareHelperReady()
            .then( () =>
            {

                let share = this.$core
                                .getShare()
                                .shareList( this.$props.objectList[ 0 ] )

                for( let s in share )
                {
                    shares.push( {
                        colleagueId: share[ s ].uuid,
                        colleague  : share[ s ].displayName
                    } )
                }

                this.$core.sort().sortObjects( shares, 'colleague', 'ascending' )
                this.shares = shares
                shReady = true
                this.ready = tmReady === true && shReady === true && ccReady === true

            } )

        this.$core.getBaseClassHelper()
            .get( 'team' )
            .listAll()
            .then( list =>
            {
                this.showTeams = 0 < list.length
                tmReady = true
                this.ready = tmReady === true && shReady === true && ccReady === true
            } )

    },

    methods: {

        setTab( tab )
        {
            this.tab = tab
        },

        handleChange( refName, type, method, index, rows )
        {

            let value = []
            for( let r in rows )
            {
                let toValidate = rows[ r ].value.value
                if( -1 < toValidate.indexOf( ':' ) )
                {
                    let temp = toValidate.split( /:/g )
                    toValidate = temp[ 1 ]
                }
                if( rows[ r ].value.value.trim() !== ''
                    && this.$core.getUuid().validate( toValidate ) )
                {
                    value.push( rows[ r ].value.value )
                }
            }

            if( this.$core.f().valid( value )
                && 0 < value.length )
            {
                this.shareWith = value
                this.disabled = false
            }
            else
            {
                this.shareWith = false
                this.disabled = true
            }
        },

        isOwnKey( key )
        {
            return key.uuid === this.$store.getters.uuid
        },

        handleSelect( id, values )
        {
            this.selected[ id ] = values
        },

        finalize()
        {
            this.$core.getUi()
                .delay( () =>
                {
                    this.$core.getUi().hideBlocker()
                    this.handleClose()
                }, 1000 )
        },

        handleAction()
        {

            this.$core.getUi().showBlocker( 'Elemente werden geteilt', 'Die gewählten Elemente werden geteilt: Augenblick bitte...', 'progress' )
            this.$core.getUi().updateProgress( 100, 0 )

            this.$core.getUi()
                .delay( () =>
                {

                    let promises = [],
                        types    = [],
                        total    = this.shareWith.length,
                        step     = 0

                    this.$core.getUi().updateProgress( total, step )

                    let refreshList = []
                    for( let o in this.$props.objectList )
                    {
                        refreshList.push( {
                            type   : this.$props.objectList[ o ].type,
                            localId: this.$props.objectList[ o ].localId
                        } )
                    }

                    promises.push( () =>
                    {

                        return this.$core.getShare().share( this.shareWith, this.$props.objectList, this.selected )
                                   .then( typelist =>
                                   {
                                       step++
                                       this.$core.getUi().updateProgress( total, step )
                                       for( let t in typelist )
                                       {
                                           if( -1 === types.indexOf( typelist[ t ] ) )
                                           {
                                               types.push( typelist[ t ] )
                                           }
                                       }
                                   } )
                    } )

                    this.$core.f().promiseRunner( promises )
                        .then( () =>
                        {
                            this.finalize()
                        } )

                }, 500 )
        },

        handleUnshare( id )
        {

            this.$core.getUi().showBlocker( 'Teilen wird beendet', 'Die gewählten Elemente werden nicht mehr geteilt: Augenblick bitte...' )

            this.$core.getUi()
                .delay( () =>
                {

                    this.$core.getShare().unshare( id, this.$props.objectList )
                        .then( types =>
                        {

                            for( let t in types )
                            {
                                let type = types[ t ]
                                this.$core.getEventManager().dispatch( 'refreshed-cache-' + type )
                            }

                            this.finalize()

                        } )
                }, 500 )
        },

        handleUnshareAll()
        {

            this.$core.getUi().showBlocker( 'Teilen wird beendet', 'Die gewählten Elemente werden nicht mehr geteilt: Augenblick bitte...' )

            this.$core.getUi()
                .delay( () =>
                {

                    this.$core.getShare().unshareAll( this.$props.objectList )
                        .then( types =>
                        {

                            for( let t in types )
                            {
                                let type = types[ t ]
                                this.$core.getEventManager().dispatch( 'refreshed-cache-' + type )
                            }

                            this.finalize()

                        } )
                }, 500 )

        },

        handleClose()
        {
            this.$core.getUi().isOverlay = false
            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 300 )
        }

    }
}
</script>