<script>
export default {

    name: 'ListPreparationHelper',

    methods: {

        prepareFilterBy()
        {

            let tmp = this.$core.getState( 'detailViewFor' )
            if( undefined !== tmp )
            {
                let temp = tmp.split( ':' )
                return temp[ 0 ]
            }
            return undefined

        },

        _stats( identifier, row )
        {
            if( undefined === this.stats[ identifier ] )
            {
                this.stats[ identifier ] = {
                    hasData: false,
                    count  : 0
                }
            }

            if( -1 === row.marker.indexOf( 'empty' ) )
            {
                this.stats[ identifier ].hasData = true
                this.stats[ identifier ].count++
            }

        },

        _prepareSummaryData( calculation )
        {

            let preparedData = calculation.result,
                scope        = this.$props.item.archived === true ? 'archive' : 'cache',
                studentList  = calculation.result[ 0 ].listItem.columns[ 0 ].filterBy === 'all' ? this.$core.getBaseClassHelper().get( 'student' ).getCache( scope )
                                                                                                : this.$core.getBaseClassHelper().getObjectById( calculation.result[ 0 ].listItem.columns[ 0 ].filterBy ).students

            if( calculation.result[ 0 ].listItem.columns[ 0 ].filterBy === 'all' )
            {
                this.studentCount = studentList.size
            }
            else
            {
                /*eslint-disable*/
                for( let s in studentList )
                {
                    let test = this.$core.getBaseClassHelper().get( 'student' ).getById( studentList[ s ] )
                    if( undefined !== test )
                    {
                        this.studentCount++
                    }
                }
                /*eslint-enable*/
            }

            let distinctShorts = [],
                first          = true,
                addOpens       = []

            for( let r in preparedData )
            {

                let row           = preparedData[ r ],
                    shortSortable = this.$core.getFriendlyTimestamp().shortSortable( row.timestamp )

                if( -1 === distinctShorts.indexOf( shortSortable ) )
                {
                    distinctShorts.push( shortSortable )
                    first = distinctShorts.length === 1
                }

                this._stats( shortSortable, row )

                if( null !== this.calc )
                {

                    switch( this.$core.getTableCalculation().getSummaryDefinition( this.$props.item.lists, 'firstColumn' ) )
                    {
                        case 'translate-average':
                            this.firstcolumn.push( 'Durchschnitt' )
                            break
                        default:
                            this.countMonths[ shortSortable ] = undefined !== this.countMonths[ shortSortable ] ? this.countMonths[ shortSortable ] + 1 : 1
                            this.rowMonths.push( {
                                friendly: this.$core.getFriendlyTimestamp().friendlySortable( row.timestamp ),
                                sortable: shortSortable
                            } )
                            this.firstcolumn.push( this.$core.getFriendlyTimestamp().friendlyDate( row.timestamp ) )
                            break
                    }

                    if( first )
                    {
                        if( -1 === this.openMonths.indexOf( shortSortable ) )
                        {
                            this.openMonths.push( shortSortable )
                        }
                    }
                    else
                    {
                        addOpens.push( shortSortable )
                    }
                    first = false

                }

            }

            this.multiMonths = ( -1 !== parseInt( this.$core.settings().getSetting( 'listGroupLimit' ) )
                                 && this.$props.item.lists.length > parseInt( this.$core.settings().getSetting( 'listGroupLimit' ) )
                                 && distinctShorts.length > 1 )

            let allInOpen = !this.multiMonths
            if( allInOpen )
            {
                for( let a in addOpens )
                {
                    if( -1 === this.openMonths.indexOf( addOpens[ a ] ) )
                    {
                        this.openMonths.push( addOpens[ a ] )
                    }
                }
            }

            if( null !== this.calc )
            {

                this.columns = this.$core.getTableCalculation().getSummaryDefinition( this.$props.item.lists, 'columns' )
                if( this.$props.item.listType === 'test' )
                {
                    for( let i in this.$props.item.lists[ 0 ].columns )
                    {
                        if( undefined !== this.$props.item.lists[ 0 ].columns[ i ].score )
                        {
                            this.totalScore += parseFloat( this.$props.item.lists[ 0 ].columns[ i ].score )
                        }
                    }
                }

            }

            this.listData = preparedData

        },


        /* eslint-disable */
        prepareData( which, calculation )
        {

            try
            {

                let calculatedColumns

                if( Array.isArray( this.$props.item.lists ) )
                {
                    calculatedColumns = this.$props.item.lists[ 0 ].calculatedColumns
                    for( let l in this.$props.item.lists )
                    {
                        this.calculatedRows[ this.$props.item.lists[ l ].localId ] =
                            this.$core.getTableCalculation().resolveCalculatedColumnsSummary( this.$props.item.lists[ l ].values, this.$props.item.lists[ l ] )
                    }
                }
                else
                {
                    calculatedColumns = this.$props.item.calculatedColumns
                    if( null === this.calculatedRows )
                    {
                        return
                    }
                    this.calculatedRows[ this.$props.item.localId ]
                        = this.$core.getTableCalculation().resolveCalculatedColumnsSummary( this.$props.item.values, this.$props.item )
                }

                if( this.$core.f().isset( this.filterId ) )
                {
                    this.$core.getTableCalculation().summarizeByStudents( this.calculatedRows, calculatedColumns )
                }

                switch( which )
                {
                    case 'summary':
                        this._prepareSummaryData( calculation )
                        break
                }

                calculatedColumns = null

            }
            catch( e )
            {
                return
            }

        }

    }

}
</script>