export default class Proto
{

    /**
     * constructor
     * @returns {Proto}
     */
    constructor()
    {

        if( !Proto.instance )
        {

            Proto.instance = this
            this.init()

        }

        return Proto.instance

    }

    init()
    {
        String.prototype.hash = function()
        {

            let hash = 0;
            for (let charIndex = 0; charIndex < this.length; ++charIndex)
            {
                hash += this.charCodeAt(charIndex);
                hash += hash << 10;
                hash ^= hash >> 6;
            }
            hash += hash << 3;
            hash ^= hash >> 11;

            return (((hash + (hash << 15)) & 4294967295) >>> 0).toString(16)

        }
    }

}