export default class SyncLicense
{
    
    constructor( parent )
    {
        
        if( !SyncLicense.instance )
        {
            
            this.parent = parent
            this.firstSync = true
            SyncLicense.instance = this
            
        }
        
        return SyncLicense.instance
        
    }
    
    sync()
    {
    
        return new Promise( ( resolve, reject ) =>
        {
        
            if( false !== this.parent.store.getters.idSession
                && true === this.parent.store.getters.online )
            {
                
                if( false === this.parent.silent )
                {
                    this.parent.ui.showBlocker( 'Synchronisiere', 'Lizenzinformationen werden synchronisiert...' )
                }

                if( this.firstSync )
                {
                    this.parent.ui.setOnlineStatus( 'Synchronizing', 0, 'Lizenzinformationen...', 2000 )
                }

                let message = {
                    method: 'users.getLicense'
                }

                this.parent
                    .client.request( message, 10000, undefined )
                    .then( resultMessage =>
                    {

                        let license = resultMessage.result

                        if( this.parent.f.isset( license.days_expiring ) )
                        {
                            this.parent.store.commit( 'setLicense', btoa( JSON.stringify( license ) ) )
                            this.parent.eventManager.dispatch( 'on-license-updated' )
                        }

                        this.firstSync = false
                        return resolve()
                    
                    } )
                    .catch( () =>
                    {
                         return reject()
                    } )
            
            }
            else
            {
                return reject()
            }
        
        } )
        
    }
    
}