import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class ColleaguesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !ColleaguesObjectClass.instance )
        {

            let refList = [ 'firstname',
                            'lastname',
                            'school',
                            'school_zipcity',
                            'gender',
                            'color',
                            'inviteKey',
                            'publicKey',
                            'colleagueId',
                            'uuid',
                            'role',
                            'rights',
                            'state',
                            'initiator',
                            'timestamp',
                            'update' ]

            let personalAttributesStatesList = [
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'colleague', refList, personalAttributesStatesList )
            this.hasDetailView = true
            this.objectType = 'colleagues'
            this.client = core.getClient()
            this.uuid = core.getUuid()

            ColleaguesObjectClass.instance = this
        }

        return ColleaguesObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref         : 'inviteKey',
                caption     : 'PIN',
                type        : 'PinBox',
                reformatter : null,
                optional    : false,
                hiddenOnEdit: true,
                validator   : 'PIN'
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            }
        ]
    }

    getHiddenRefs()
    {
        return [
            'firstname',
            'lastname',
            'school',
            'school_zipcity',
            'gender',
            'color',
            'publicKey',
            'colleagueId',
            'uuid',
            'role',
            'rights',
            'state',
            'initiator',
        ]
    }

    getTabs()
    {
        // defined by view
        return [ 'own' ]
    }

    getFunctions()
    {
        return [ 'hide', 'edit', 'delete' ]
    }

    /*eslint-disable*/
    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        if( 2 === Object.keys( values ).length )
        {

            let ownJobId = this.uuid.generate()

            if( undefined !== values.inviteKey && undefined !== values.color )
            {
                let message = {
                    method: 'network.inviteByPin',
                    pin   : values.inviteKey
                }
                this.client.request( message )
                    .then( () =>
                    {

                        this.eventManager.dispatchAndRemove( 'on-late-ack-create-colleague' )
                        super.create( values, additional, clone, forceTimestamp, additionalKeys, ownJobId, local )

                    } )

                return ownJobId
            }
        }
        else
        {
            return super.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
        }

    }

    delete( localId, remoteId, forceRemote )
    {
        return new Promise( resolve =>
        {

            let colleague = this.getById( localId )

            if( undefined !== colleague )
            {

                let message = {
                    method      : 'network.deleteColleague',
                    id_colleague: colleague.colleagueId
                }
                this.client.request( message )
                    .then( () =>
                    {
                        return resolve( super.delete( localId, remoteId, forceRemote ) )
                    } )
                    .catch( () =>
                    {
                        return resolve( super.delete( localId, remoteId, forceRemote ) )
                    } )

            }
            else
            {
                return resolve( super.delete( localId, remoteId, forceRemote ) )
            }

        } )
    }

    deleteDouble( localId, remoteId, forceRemote )
    {
        return new Promise( resolve =>
        {
            return resolve( super.delete( localId, remoteId, forceRemote ) )
        } )
    }

    getColleagueById( idColleague )
    {

        for( let c of this.registry.cache.keys() )
        {

            let colleague = this.registry.cache.get( c )

            if( parseInt( colleague.colleagueId ) === parseInt( idColleague ) )
            {
                return colleague
            }

        }

        return undefined

    }

}