<template>
    <div id="scrolltotop" :class="'scrolltotop '+cssClass" @click="handleClick()">
    
    </div>
</template>

<script>
export default {
    
    name  : 'ScrollToTop',

    props : {
        base  : { type: Boolean, required: true },
        attach: { type: String, required: false }
    },
    
    data()
    {
        return {
            cssClass: false,
            attached: false
        }
    },
    
    mounted()
    {
        if( this.$props.base === true )
        {
            this.cssClass = 'base'
            this.attached = document.querySelector( '#app' )
        }
        else
        {
            this.cssClass = 'attach'
            this.attached = document.querySelector( '#' + this.$props.attach )
        }
    },
    
    methods: {
        
        scroller( top )
        {
            
            if( top > 0 )
            {
    
                let factor = 500,
                    timeout = 50

                if( top > 5000 )
                {
                    factor = 1000
                }
                else if( top > 3000 )
                {
                    factor = 700
                }
                else if( top > 2000 )
                {
                    factor = 500
                }
                else if( top > 1000 )
                {
                    factor = 300
                }
                else if( top > 300 )
                {
                    factor = 100
                }
                else if( top > 200 )
                {
                    factor = 50
                }
                else if( top > 100 )
                {
                    timeout = 30
                    factor = 25
                }
                else if( top > 50 )
                {
                    timeout = 20
                    factor = 10
                }
                else if( top > 20 )
                {
                    timeout = 10
                    factor = 5
                }
                else
                {
                    this.attached.scrollTop = 0
                    return
                }
    
                setTimeout( () =>
                {
        
        
                    top -= factor
                    top = 0 < top ? top : 0
                    this.attached.scrollTop = top
                    this.scroller( top )
        
                }, timeout )
    
            }
            
        },
        
        handleClick()
        {
            
            let top = this.attached.scrollTop
            this.scroller( top )
        }
        
    }
    
    
}
</script>