<template>
    <div class="calculated-column-tooltip"
         v-if="calcType">
        <h3>{{ title }}</h3>
        <p v-html="tooltip"></p>
    </div>
</template>

<script>
export default {
    name : "CalculatedColumnsToolTip",
    props: {
        calcType: { type: [ String, Boolean ], required: false }
    },
    computed: {
        title() {
            switch( this.$props.calcType )
            {
                case 'count':
                    return 'Zählen'
                case 'sum':
                    return 'Summieren'
                case 'average':
                    return 'Durchschnitt ermitteln'
                case 'percentage':
                    return 'Prozentualen Anteil ermitteln'
                case 'factor':
                    return 'Teilen'
                case 'subtract':
                    return 'Subtraktion'
                default:
                    return this.$props.calcType
            }
        },
        tooltip()
        {
            switch( this.$props.calcType )
            {
                case 'count':
                    return 'Die "zählen"-Funktion zählt die ausgefüllten Felder: Wähle die Spalten, die gezählt werden sollen in beliebiger Reihenfolge.'
                case 'sum':
                    return 'Mit der "summieren"-Funktion werden die Werte der ausgefüllten Felder summiert: Wähle die Spalten, die du aufsummieren möchtest in beliebiger Reihenfolge.'
                case 'average':
                    return 'Mit der "Durchschnitt ermitteln"-Funktion wird der Durchschnitt der Werte aus den gewählten Spalten ermittelt.'
                case 'percentage':
                    return 'Die "prozentualen Anteil ermitteln"-Funktion ermittelt den prozentualen Anteil des Wertes aus der zuerst gewählten Spalte von dem Wert aus der zweiten Spalte.<br/><strong>Es sind nur 2 Spalten auswählbar.</strong>'
                case 'factor':
                    return 'Die "Teilen"-Funktion teilt den Wert der zuerst gewählten Spalte durch den Wert der zweiten Spalte.<br/><strong>Es sind nur 2 Spalten auswählbar.</strong>'
                case 'subtract':
                    return 'Mit der "Subtraktion" wird der Wert der zuerst gewählten Spalte von dem Wert der zweiten Spalte abgezogen.<br/><strong>Es sind nur 2 Spalten auswählbar.</strong>'
                default:
                    return this.$props.calcType
            }
        }
    }
}
</script>