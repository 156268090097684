<script>
export default {
    name   : 'MixinCheckExists',
    methods: {

        _concatenate( setup )
        {

            let fields = setup.split( /,/g ),
                values = []

            for( let f in fields )
            {
                let value = document.querySelector( '#form-element-' + fields[ f ] ).value
                values.push( { field: fields[ f ], value: value } )
            }

            return values

        },

        _checkExists( field, value )
        {

            if( undefined === field
                || undefined === field.checkExists )
            {
                return
            }

            let key      = field.ref,
                elements = field.checkExists.elements,
                foreign  = field.checkExists.checkForeign,
                cache    = this.$core.getBaseClassHelper()
                               .get( elements )
                               .getCache( 'cache' ),
                matched  = false,
                values

            if( field.checkExists.concatenate )
            {
                values = this._concatenate( field.checkExists.concatenate )
            }
            else
            {
                values = [ { field: field.ref, value: value.trim() } ]
            }

            /*eslint-disable*/
            for( const [ c, cached ] of cache )
            {

                let allMatched = true
                for( let v in values )
                {
                    let validate = cached[ values[ v ].field ].trim()
                    if( validate.toLowerCase() !== values[ v ].value.toLowerCase()
                        || cached.archived === true
                        || ( foreign === false && parseInt( cached[ key ].idOwner ) !== parseInt( this.$store.getters.idUser ) ) )
                    {
                        allMatched = false
                    }
                }
                if( allMatched )
                {
                    matched = true
                }

            }
            /*eslint-enable*/

            if( matched )
            {
                this.messages = {
                    title  : 'Hinweis',
                    message: 'Entweder hast du bereits ein Element für <strong>' + value + '</strong> angelegt oder es wurde mit dir geteilt:<br/>Wenn du fortfährst, erzeugst du unter Umständen ein Duplikat.'
                }
            }
            else
            {
                this.messages = false
            }

        }

    }
}
</script>