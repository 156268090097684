<template>
    <TutorialMessage :caption="$core.t( 'tutorial-caption-todos' )"
                     :text="$core.t( 'tutorial-text-todos-'+step )"
                     :step="step"
                     :last="last"
                     :backBlocked="backBlocked"
                     @back="handleBack()"
                     @next="handleNext()"/>
    <TutorialElement v-if="fakeElement" :fakeElement="fakeElement"/>
</template>

<script>
import TutorialMessage from '@/components/elements/tutorial/TutorialMessage'
import TutorialBase    from '@/components/elements/tutorial/TutorialBase'
import TutorialElement from '@/components/elements/tutorial/TutorialElement'
import MixinTutorialElements from "@/components/elements/tutorial/MixinTutorialElements.vue";

export default {

    name      : 'TutorialTodos',
    components: { TutorialElement, TutorialMessage },
    extends   : TutorialBase,
    mixins    : [ MixinTutorialElements ],

    data()
    {
        return {
            last: 12,
            fake: {
                type            : 'todo',
                color           : 'green',
                body            : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel purus dignissim orci scelerisque pellentesque non ut velit.\n' +
                                  '\n' +
                                  'Pellentesque venenatis venenatis nibh ut rutrum. Aenean et congue ante, vitae pulvinar velit.',
                title           : 'Vorbereitungen Elternsprechtag',
                duedate         : ( Date.now() + ( 86400000 * 12 ) ),
                duedate_friendly: this.$core.getFriendlyTimestamp().friendlyDate( ( Date.now() + ( 86400000 * 12 ) ) ),
                timestamp       : ( Date.now() - 1720000 ),
                todos           : [ 'Lorem ipsum', 'scelerisque pellentesque',
                    'Pellentesque venenatis venenatis' ],
                done            : false,
                idOwner         : this.$store.getters.idUser,
                archived        : false
            }
        }
    },

    methods: {

        prepareHighlights()
        {
            this.highlights = []
            switch( this.step )
            {
                case 1:
                    break
                case 2:
                    this.highlights = [
                        {
                            prepare    : {
                                action : 'click',
                                root   : '.tab-space',
                                element: '#own'
                            },
                            root       : '.head-functions',
                            elements   : [ '.button.round.add.large' ],
                            waitForNext: '.default-form-container'
                        }
                    ]
                    break
                case 3:
                    this.highlights = [
                        {
                            root        : '.head-functions',
                            elements    : [],
                            unblurred   : true,
                            backdisabled: true,
                            showFill    : {
                                element: this.fake,
                                fill   : [ {
                                    root  : '.default-form-wrapper',
                                    type  : 'animated',
                                    fields: [ 't:title', 't:duedate_friendly', 't:color', 'd:body' ]
                                } ]
                            }
                        }
                    ]
                    break
                case 4:
                    this.highlights = [
                        {
                            prepare  : {
                                action : 'click',
                                root   : '.default-form-wrapper',
                                element: '.button.round.options.absolute.top.right'
                            },
                            root     : '.default-form-wrapper',
                            elements : [ '.button.round.options.absolute.top.right' ],
                            unblurred: true,
                            showFill : {
                                element: this.fake,
                                fill   : [
                                    {
                                        root  : '.default-form-wrapper',
                                        type  : 'static',
                                        fields: [ 't:title', 't:duedate_friendly', 't:color', 'd:body' ]
                                    } ]
                            }
                        }
                    ]
                    break
                case 5:
                    this.highlights = [
                        {
                            root       : '.default-list-page',
                            elements   : [],
                            prepare    : {
                                action : 'click',
                                root   : '.default-form-wrapper',
                                element: '.button.round.close.absolute.top.right.inverted'
                            },
                            stepsback  : 3,
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 6:
                    this.highlights = [
                        {
                            root       : '.default-list-page',
                            elements   : [],
                            fakeElement: this.fake,
                            waitForNext: '.touchable-active'
                        }
                    ]
                    break
                case 7:
                    this.highlights = [
                        {
                            root        : '.default-list-page',
                            elements    : [],
                            backdisabled: true,
                            fakeElement : this.fake
                        }
                    ]
                    break
                case 8:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.pin'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 9:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.edit'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 10:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.delete'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 11:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.archive'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 12:
                    break
            }
        }

    }

}
</script>