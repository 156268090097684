<script>
export default {

    /*eslint-disable*/
    name: "MixinDevHelper",

    data()
    {
    },

    mounted()
    {

        if( 'local' === this.$core.getConfig().branch )
        {

            switch( this.$options.name )
            {
                case 'Touchable':
                    this.$core.getEventManager()
                        .add( 'on-devhelper-trigger-' + this.item.localId, ( action ) =>
                        {
                            this.trigger( action )
                        } )
                    break
                case 'List':
                    this.$core.getEventManager()
                        .add( 'on-devhelper-open-editor-' + this.item.localId, ( listLocalId ) =>
                        {
                            this.setEditItem( listLocalId )
                        } )
                    break
            }

        }

    },

    beforeUnmount()
    {
        if( 'local' === this.$core.getConfig().branch )
        {

            this.$core.getEventManager()
                .remove( 'on-devhelper-open-editor-' + this.item.localId )
            this.$core.getEventManager()
                .remove( 'on-devhelper-trigger-' + this.item.localId )

        }
    },

    methods: {

        trigger( action )
        {
            switch( action.trigger )
            {
                case 'itemConfigure':
                    this.openItemConfigure( action.localId )
                    break
            }
        },

        awaitPrepared()
        {
            return new Promise( resolve =>
            {

                if( this.prepared )
                {
                    return resolve()
                }
                else
                {
                    setTimeout( () =>
                    {
                        return resolve( this.awaitPrepared() )
                    }, 1000 )
                }

            } )
        },

        setEditItem( localId )
        {

            this.awaitPrepared()
                .then( () =>
                {

                    for( let l in this.item.lists )
                    {
                        if( this.item.lists[ l ].localId === localId )
                        {
                            this.editItem = this.item.lists[ l ]
                        }
                    }

                } )

        },

        openItemConfigure()
        {
            this.$core.getLogger().clog( 'DHLP', 'EDIT: ' + this.item.localId )
            this.handleFunctionClick( 'edit' )
        }

    }

}
</script>