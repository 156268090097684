<template>
    <div v-if="undefined !== item"
         class="listinfo" @click="$emit('open', $event)"
         :key="'listInfo-'+ownId+'-'+item.contentKey"
         :id="'listInfo-'+ownId">
        <div class="markerspace absolute right" key="markerSpace">
            <LabelMarkers v-if="$core.f().valid( item.labels )"
                          additionalClass="right"
                          :selectable="false"
                          :item="item"/>
            <ClassReferenceMarker v-if="false !== classFilter"
                                  additionalClass="right"
                                  :reference="classFilter"
                                  @detailView="$emit('detailView', $event )"/>
            <GroupReferenceMarker v-if="false !== groupFilter"
                                  additionalClass="right"
                                  :reference="groupFilter"
                                  @detailView="$emit('detailView', $event )"/>
            <YeargroupReferenceMarker v-if="false !== yeargroupFilter"
                                      additionalClass="right"
                                      :reference="yeargroupFilter"
                                      @detailView="$emit('detailView', $event )"/>
            <ListStateMarker v-if="doneInfo !== false"
                             :contentKey="item.contentKey"
                             :state="doneInfo"/>
        </div>
        <div class="infoflex">
            <span class="hidden">{{ $core.getTranslation().translate( 'list-type-' + item.listType ) }}</span>
            <div v-if="!singleItems" class="infobox first">
                <span class="list-element-counter" v-if="undefined !== item.lists">{{ item.lists.length }}</span>
            </div>
            <div class="infobox second">
                <span v-html="timeInfo"></span>
                <LastEditor :reference="item" :elementKey="item.elementKey"/>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
/*eslint-disable*/
import ClassReferenceMarker     from "@/components/elements/markers/ClassReferenceMarker";
import GroupReferenceMarker     from "@/components/elements/markers/GroupReferenceMarker";
import YeargroupReferenceMarker from "@/components/elements/markers/YeargroupReferenceMarker";
import ListStateMarker          from "@/components/elements/markers/ListStateMarker";
import LastEditor               from "@/components/elements/markers/LastEditor";
import LabelMarkers             from "@/components/elements/markers/LabelMarkers";

export default {

    name      : "ListInfo",
    components: {
        LabelMarkers,
        LastEditor,
        ListStateMarker,
        YeargroupReferenceMarker,
        GroupReferenceMarker,
        ClassReferenceMarker
    },
    emits     : [ 'detailView', 'open' ],
    props     : {
        item       : { Type: Object, required: true },
        singleItems: { Type: Boolean, required: false },
        doneInfo   : { type: [ Object, Boolean ], required: false, default: false }
    },

    data()
    {
        return {
            ownId: this.$core.getUuid().generate()
        }
    },

    computed: {

        classFilter()
        {

            let listItem = undefined === this.$props.item.lists ? this.$props.item : this.$props.item.lists[ 0 ]

            if( this.$core.f().isset( listItem )
                && this.$core.f().isset( listItem.columns )
                && this.validColumns( listItem.columns ) )
            {
                if( 'class' === listItem.columns[ 0 ].filter )
                {
                    let filterBy = listItem.columns[ 0 ].filterBy

                    if( 'all' !== filterBy )
                    {
                        return listItem.columns[ 0 ].filterBy
                    }
                    return 'all'
                }
            }

            return false

        },

        groupFilter()
        {

            let listItem = undefined === this.$props.item.lists ? this.$props.item : this.$props.item.lists[ 0 ]

            if( this.$core.f().isset( listItem )
                && this.validColumns( listItem.columns ) )
            {
                if( 'group' === listItem.columns[ 0 ].filter )
                {
                    return listItem.columns[ 0 ].filterBy
                }
            }

            return false

        },

        yeargroupFilter()
        {

            let listItem = undefined === this.$props.item.lists ? this.$props.item : this.$props.item.lists[ 0 ]

            if( this.$core.f().isset( listItem )
                && this.validColumns( listItem.columns ) )
            {
                if( 'yeargroup' === listItem.columns[ 0 ].filter )
                {
                    return listItem.columns[ 0 ].filterBy
                }
            }

            return false

        },

        timeInfo()
        {

            if( undefined === this.$props.item.lists )
            {
                return 'vom <strong>' + this.$core.getFriendlyTimestamp().friendlyDate( this.$props.item.timestamp ) + '</strong>'
                       + ( 0 < this.$props.item.update ? '<br/><span class="updatetimestamp">zuletzt aktualisiert am <strong>' +
                       this.$core.getFriendlyTimestamp().friendlyDate( this.$props.item.update ) + '</strong></span>' : '' )
            }

            let maxUpdate = 0
            let maxTime = 0
            let minTime = 9999999999999999999

            for( let l in this.$props.item.lists )
            {

                let elm = this.$props.item.lists[ l ]
                if( this.$core.f().isset( elm.update )
                    && elm.update > maxUpdate )
                {
                    maxUpdate = elm.update
                }

                if( this.$core.f().isset( elm.timestamp ) )
                {
                    if( minTime > elm.timestamp )
                    {
                        minTime = elm.timestamp
                    }
                    if( maxTime < elm.timestamp )
                    {
                        maxTime = elm.timestamp
                    }
                }

            }

            let timeInfo

            if( 1 < this.$props.item.lists.length )
            {
                timeInfo = 'vom <strong>' + this.$core.getFriendlyTimestamp().friendlyDate( minTime ) + '</strong> ' +
                           'bis zum <strong>' + this.$core.getFriendlyTimestamp().friendlyDate( maxTime ) + '</strong>'
            }
            else
            {
                timeInfo = 'vom <strong>' + this.$core.getFriendlyTimestamp().friendlyDate( minTime ) + '</strong>'
            }

            if( 0 < maxUpdate )
            {
                timeInfo += '<br/><span class="updatetimestamp">zuletzt aktualisiert am <strong>' +
                            this.$core.getFriendlyTimestamp().friendlyDate( maxUpdate ) + '</strong></span>'
            }

            return timeInfo

        },

        filterInfo()
        {
            if( this.$core.settings().getSetting( 'studentFileShowOnlyFilled' ) === true )
            {
                let filterId = this.$core.getState( 'detailViewFor' )
                if( undefined !== filterId )
                {

                    let temp      = filterId.split( ':' ),
                        filterBy  = temp[ 0 ],
                        filterKey = temp[ 1 ]

                    let count = 0

                    if( undefined !== filterId
                        && 'student' === filterBy )
                    {
                        for( let l in this.$props.item.lists )
                        {

                            let inList = false
                            let list = this.$props.item.lists[ l ]
                            for( let key in list.values )
                            {
                                if( -1 < key.indexOf( filterKey ) && !inList )
                                {
                                    inList = true
                                    count++
                                }
                            }

                        }
                        if( count < this.$props.item.lists.length )
                        {
                            return this.$core.t( 'lists-count-filterInfo', [ count, this.$core.t( 'element' + ( count !== 1 ? 's' : '' ) ) ] )
                        }
                    }
                }
            }
            return ''
        }
    },

    methods: {

        validColumns( columns )
        {
            return Array.isArray( columns ) && 0 < columns.length
        }

    }

}
</script>