<script>
export default {
    name: "MixinComponentCleanup",
    /*eslint-disable*/
    /*beforeUnmount()
    {
        for( let key in this.$data )
        {

            this.remove( key )

        }
    },

    unmounted()
    {
        for( let d in this.$data )
        {
            this[ d ] = undefined
            delete this[ d ]
        }
    },

    methods: {
        remove( key )
        {

            switch( typeof this.$data[ key ] )
            {
                case "object":
                    if( Array.isArray( this.$data[ key ] ) )
                    {
                        while( 0 < this.$data[ key ].length )
                        {
                            this.$data[ key ].pop()
                        }
                        this.$data[ key ] = null
                    }
                    else if( this.$data[ key ] instanceof Map )
                    {
                        this.$data[ key ].forEach( ( mkey, mvalue ) =>
                        {
                            this.$data[ key ].set( mkey, undefined )
                            this.$data[ key ].delete( mkey )
                            mvalue = null
                        } )
                        this.$data[ key ] = null
                    }
                    else
                    {
                        this.$data[ key ] = {}
                        this.$data[ key ] = null
                    }
                case "undefined":
                case "boolean":
                case "number":
                case "string":
                    this.$data[ key ] = undefined
                    break
                default:
                    this.$data[ key ] = undefined
                    break
            }

        }
    }*/
}
</script>