import CleanOrphanedCopies        from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/CleanOrphanedCopies";
import SyncCopies                 from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/SyncCopies";
import FixStudentAccesses         from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/FixStudentAccesses";
import CleanOrphanedStudentCopies from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/CleanOrphanedStudentCopies";

export default class SyncShadowCopies
{

    constructor( parent )
    {

        this.parent = parent
        this.uuid = parent.uuid

        this.getState = ( key ) =>
        {
            return this.parent.getState( key )
        }

        this.logSign = 'SyncWorker::SyncShadowCopies'
        this.parent.logger.clog( this.logSign, 'initialized' )

        this.studentAccessClass = undefined

        this.parent.eventManager.append( 'on-baseclasses-available', () =>
        {
            this.studentAccessClass = this.parent.getBaseClassHelper().get( 'studentAccess' )
        } )

        this.shadowCopyCache = {}
        this.baseClassHelper = parent.getBaseClassHelper()
        if( undefined === this.baseClassHelper )
        {
            this.parent.eventManager.append( 'on-baseclasses-available', () =>
            {
                this.baseClassHelper = this.parent.getBaseClassHelper()
                this.initHandlers()
            } )
        }
        else
        {
            this.initHandlers()
        }

        this.lastAction = 0
        this.minInterval = 30000

    }

    /*eslint-disable*/
    /**
     * initHandlers
     */
    initHandlers()
    {
        this.handlers = {
            'fixStudentAccesses'        : new FixStudentAccesses( this ),
            'cleanOrphanedCopies'       : new CleanOrphanedCopies( this ),
            'cleanOrphanedStudentCopies': new CleanOrphanedStudentCopies( this ),
            'syncCopies'                : new SyncCopies( this )
        }
    }

    /**
     * resetCaches
     */
    resetCaches()
    {

        this.shadowCopyCache = null
        this.shadowCopyCache = {}

    }

    /**
     * prepareObjectsCache
     */
    prepareObjectsCache()
    {

        return new Promise( resolve =>
        {

            this.resetCaches()

            let promises = []

            for( let s in this.shadowHelpers )
            {

                promises.push( () =>
                {
                    return this.baseClassHelper
                               .get( s )
                               .cacheHeatup()
                } )

            }

            this.baseClassHelper
                .get( 'shadowCopy' )
                .getPreparedCache()
                .then( copies =>
                {

                    let list   = [],
                        scopes = [ 'cache', 'archive' ]

                    for( let s in scopes )
                    {
                        /* eslint-disable-next-line no-unused-vars */
                        for( const [ localId, copy ] of copies[ scopes[ s ] ] )
                        {
                            list.push( copy )
                        }
                    }
                    this.shadowCopyCache = list

                    this.parent.f.promiseRunner( promises )
                        .then( () =>
                        {
                            return resolve()
                        } )

                } )

        } )

    }

    sync()
    {
        return new Promise( resolve =>
        {

            if( true !== this.getState( 'first-crud-sync-done' ) )
            {
                return resolve()
            }

            if( 0 === this.parent.store.getters.isStudent
                && this.parent.f.isOnlineSyncableState()
                && this.lastAction < Date.now() - this.minInterval )
            {

                this.parent.logger.clog( this.logSign + '::sync', 'executing sync' )

                let start    = Date.now(),
                    promises = []

                this.handlers
                    .fixStudentAccesses
                    .handle()
                    .then( () =>
                    {

                        this.prepareObjectsCache()
                            .then( () =>
                            {

                                this.handlers
                                    .cleanOrphanedCopies
                                    .handle()
                                    .then( () =>
                                    {

                                        this.handlers
                                            .syncCopies
                                            .handle()
                                            .then( () =>
                                            {

                                                this.parent.logger.clog( this.logSign + '::sync', 'done after ' + ( Date.now() - start ) + 'ms' )
                                                return resolve()

                                            } )

                                    } )

                            } )

                    } )
            }
            else if( 1 === this.parent.store.getters.isStudent
                     && this.parent.f.isOnlineSyncableState()
                     && this.lastAction < Date.now() - this.minInterval )
            {

                this.parent.logger.clog( this.logSign + '::sync', 'executing sync' )

                let start    = Date.now(),
                    promises = []

                this.prepareObjectsCache()
                    .then( () =>
                    {

                        this.handlers
                            .cleanOrphanedStudentCopies
                            .handle()
                            .then( () =>
                            {

                                this.parent.logger.clog( this.logSign + '::sync', 'done after ' + ( Date.now() - start ) + 'ms' )
                                return resolve()

                            } )

                    } )

            }
            else
            {
                return resolve()
            }

        } )
    }


}