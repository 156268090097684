<template>
    <div v-if="ready">
        <input type="hidden" :id="'form-element-'+refName"/>
        <div v-for="( row, index ) in rows"
             :key="'ccs-idx-'+index"
             class="spaced-adder-wrap">

            <CalculatedColumnsToolTip :calcType="row.calcType" />

            <div class="row-adder-wrapper">
                <div class="left">
                    <input type="text"
                           :id="'calc-label-'+index"
                           :value="row.label"
                           placeholder="gib deiner Rechenspalte einen Namen"
                           @change="handleChange"/>
                    <div class="select-wrapper">
                        <select :id="'calc-type-'+index" @change="select">
                            <option value="">{{ $core.t( 'generic-please-select-type' ) }}</option>
                            <template v-for="calcType in calcTypes"
                                      :key="'key-'+refName+'-calcType-'+calcType+'-'+index">
                                <option :value="calcType.type" :selected="row.calcType === calcType.type">
                                    {{ calcType.label }}
                                </option>
                            </template>
                        </select>
                    </div>
                    <template v-if="row.calcType !== undefined">
                        <AvailableColumnsSelector :index="index"
                                                  :refName="row.refName"
                                                  :allValues="row.values"
                                                  :asPlainList="true"
                                                  :maxRowCount="maxRowCount[ index ]"
                                                  :self="row.label"
                                                  @update="handleUpdate"/>
                    </template>
                </div>
                <div class="right">
                    <transition appear :name="$core.settings().getTransition( 'zoom' )">
                        <Button v-if="row.valid"
                                :type="'row-'+row.mode"
                                @click="$core.f().skip"
                                @clicked="handleClick( row.mode, index )"/>
                    </transition>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import AvailableColumnsSelector from "@/components/form/elements/AvailableColumnsSelector";
import CalculatedColumnsToolTip from "@/components/form/elements/CalculatedColumnsToolTip";

export default {

    name      : 'CalculatedColumnsSelector',
    components: { CalculatedColumnsToolTip, AvailableColumnsSelector },
    emits     : [ 'extendedFilterSelection', 'enterPressed', 'backspacePressed', 'update', 'selected', 'adderValid',
                  'adderInvalid' ],

    props: {

        value       : { Type: String, required: false },
        set         : { Type: Object, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false },

    },

    data()
    {
        return {
            ready           : false,
            formElm         : false,
            rows            : [],
            columns         : [],
            selectedCalcType: undefined,
            excludeList     : [],
            setup           : {},
            maxRowCount     : [],
            calcTypes       : [
                {
                    type       : 'count',
                    label      : 'zählen',
                    maxRowCount: -1
                },
                {
                    type       : 'sum',
                    label      : 'summieren',
                    maxRowCount: -1
                },
                {
                    type       : 'average',
                    label      : 'Durchschnitt ermitteln',
                    maxRowCount: -1
                },
                {
                    type       : 'subtract',
                    label      : 'Subtraktion',
                    maxRowCount: 2
                },
                {
                    type       : 'factor',
                    label      : 'Teilen',
                    maxRowCount: 2
                },
                {
                    type       : 'percentage',
                    label      : 'Prozentualen Anteil ermitteln',
                    maxRowCount: 2
                }
            ]
        }
    },

    created()
    {
    },

    mounted()
    {

        if( !this.$core.f().valid( this.$props.value ) )
        {
            this.rows.push( {
                valid   : false,
                mode    : 'add',
                refName : this.$props.refName + '-0',
                calcType: false,
                label   : '',
                values  : []
            } )
        }
        else
        {
            this.prepareValues()
        }

        this.$nextTick()
            .then( () =>
            {
                this.ready = true
            } )

    },

    methods: {

        _getIndex( id )
        {
            let temp  = id.split( /-/g ),
                last  = temp.pop(),
                index = parseInt( last )

            return index
        },

        prepareValues()
        {

            let index = 0
            for( let v in this.$props.value )
            {
                this.rows.push( {
                    valid   : true,
                    mode    : index < ( this.$props.value[ v ].length - 1 ) ? 'remove' : 'add',
                    refName : this.$props.refName + '-' + index,
                    calcType: this.$props.value[ v ].calcType,
                    label   : this.$props.value[ v ].label,
                    values  : this.$props.value[ v ].values
                } )
                this.maxRowCount[ index ] = -1
                for( let c in this.calcTypes )
                {
                    if( this.calcTypes[ c ].type === this.$props.value[ v ].calcType )
                    {
                        this.maxRowCount[ index ] = this.calcTypes[ c ].maxRowCount
                    }
                }
                index++
            }
        },

        handleChange( event )
        {
            if( undefined !== event.target )
            {

                let index = this._getIndex( event.target.id )
                this.rows[ index ].label = '' !== event.target.value ? event.target.value : undefined
                this.performEmit()

            }
        },

        select( event )
        {
            if( undefined !== event.target )
            {

                let index = this._getIndex( event.target.id )
                this.rows[ index ].calcType = '' !== event.target.value ? event.target.value : undefined

                this.maxRowCount[ index ] = -1
                for( let c in this.calcTypes )
                {
                    if( this.calcTypes[ c ].type === event.target.value )
                    {
                        this.maxRowCount[ index ] = this.calcTypes[ c ].maxRowCount
                    }
                }

                this.performEmit()

            }
        },

        handleClick( mode, index )
        {
            switch( mode )
            {
                case 'add':
                    for( let r in this.rows )
                    {
                        this.rows[ r ].mode = 'remove'
                    }
                    this.rows.push( {
                        valid   : false,
                        mode    : 'add',
                        refName : this.$props.refName + '-' + ( index + 1 ),
                        calcType: false,
                        label   : '',
                        values  : {}
                    } )
                    break
                case 'remove':
                    this.rows.splice( index, 1 )
                    this.resort()
                    break
            }
        },

        resort()
        {

            let newRows = []
            for( let i = 0; i < this.rows.length; i++ )
            {
                newRows.push( this.rows[ i ] )
            }
            this.rows = newRows
            //this.performEmit()
        },

        handleUpdate( index, type, component, call, value )
        {

            this.rows[ index ].valid = Array.isArray( value.linked ) && 0 < value.linked.length
            this.rows[ index ].values = Array.isArray( value.linked ) && 0 < value.linked.length ? value.linked : []

            this.performEmit()

        },

        performEmit()
        {

            let emittable = []
            for( let r in this.rows )
            {
                emittable.push( {
                    calcType: this.rows[ r ].calcType,
                    label   : this.rows[ r ].label,
                    values  : this.rows[ r ].values
                } )
            }

            this.$emit( 'update', this.$props.refName, 'calculatedColumnSelector', 'calculatedColumns', 'form-element-' + this.$props.refName, emittable )

        }

    }

}
</script>