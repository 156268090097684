<!--eslint-disable-->
<template>
    <div :class="'planner-element-wrapper'+( overFilled ? ' planner-wrapper-overfilled' : '' )"
         @mousedown="checkMouseDown()"
         @mouseup="checkMouseUp"
         @click="handleClicked"
         @touchstart.passive="checkMouseDown()"
         @touchend.passive="checkMouseUp"
         @mousemove="releaseTimer"
         @contextmenu="instantFullscreen"
         :id="'planner-element-wrapper-'+id">
        <input type="hidden" :id="'draggable-first-index-'+timestamp" :value="firstIdx"/>
        <div class="planner-scrollable">
            <draggable v-model='copy'
                       class="test"
                       :group="{ name: 'draggery' }"
                       ghost-class="drag-ghost"
                       :animation="200"
                       handle=".draggable"
                       :item-key="'draggable-planner-element-'+timestamp"
                       :id="'draggable-organizer-'+timestamp"
                       :empty-insert-threshold="1000"
                       :move="checkMove"
                       @start="handleDragStart( $event, 'draggable-organizer-' )"
                       @end="handleMove">
                <template #item="{ element, index }" :key="'draggery-inner-'+index">
                    <PlannerElement :element="element"
                                    :key="'transg-'+timestamp+'-'+index"
                                    :index="index"
                                    :timestamp="timestamp"
                                    :selectMode="selectMode"
                                    :selected="selected"
                                    :filterList="filterList"
                                    @clicked="handleClick"/>
                </template>
                <template #footer>
                    <div :id="'draggable-footer-'+timestamp"
                         :class="'planner-draggable-footer'+ ( inDragMode ? ' dragging' : '')"></div>
                </template>
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable      from 'vuedraggable'
import PlannerElement from '@/components/elements/viewItems/plannerView/PlannerElement'
import MixinDraggable from '@/mixins/MixinDraggable'
import MixinEvents    from "@/mixins/MixinEvents.vue";

export default {

    name: 'PlannerElements',

    mixins: [ MixinDraggable, MixinEvents ],

    props: {
        timestamp : { type: Number, required: true },
        elements  : { type: Object, required: false, default: undefined },
        filterList: { type: Object, required: false, default: undefined },
        changeKey : { type: String, required: true },
        selectMode: { type: Boolean, required: false, default: false }
    },

    components: { PlannerElement, draggable },

    emits: [ 'showPlannerDay', 'clicked', 'dragover', 'refresh', 'move' ],

    data()
    {
        return {
            id            : this.$core.getUuid().generate(),
            overFilled    : false,
            mouseDownTime : false,
            vibrationTimer: null,
            mountedBefore : false,
            clickedOnce   : false,
            clickedElm    : false,
            blockedClick  : false,
            firstIdx      : -1,
            lastEvent     : 0,
            selected      : [],
            zoomId        : false,
            zoomElement   : false,
            skipPlannerDay: false
        }
    },

    mounted()
    {
        this.markOverfilled()
        this.checkFirstIdx()
        this.addIndexedEvent( 'on-drag-move', which =>
        {
            if( this.$props.timestamp !== which )
            {
                this.inDragMode = true
            }
        } )
        this.addIndexedEvent( 'on-drag-end', which =>
        {
            if( this.$props.timestamp !== which )
            {
                this.inDragMode = false
            }
        } )

        this.mountedBefore = true
    },

    beforeUnmount()
    {
    },

    computed: {
        copy: {
            get()
            {
                return JSON.parse( JSON.stringify( this.$props.elements ) )
            },
            set()
            {
            }
        }
    },

    watch: {
        changeKey : {
            immediate: true,
            handler( oldKey, newKey )
            {
                if( oldKey !== newKey
                    && this.mountedBefore )
                {
                    if( false !== this.zoomId )
                    {
                        this.$core.getUi()
                            .updateZoom( this.zoomId, this.zoomElement, this.$props.changeKey )
                    }
                }
            }
        },
        timestamp : {
            immediate: true,
            handler()
            {
                this.remark()
            }
        },
        filterList: {
            deep     : true,
            immediate: true,
            handler()
            {
                this.remark()
            }
        },
        selectMode: {
            immediate: true,
            handler( newValue )
            {
                if( false === newValue )
                {
                    this.selected = []
                }
            }
        }
    },

    methods: {

        checkFirstIdx()
        {
            for( let elm in this.$props.elements )
            {
                let element = this.$props.elements[ elm ]
                if( element.isHoliday || element.isSchoolHoliday )
                {
                    this.firstIdx = parseInt( elm )
                }
            }
        },

        remark()
        {
            if( this.mountedBefore === true )
            {
                this.$nextTick()
                    .then( () =>
                    {
                        setTimeout( () =>
                        {
                            this.markOverfilled()
                        }, 1000 )
                    } )
            }
        },

        checkMouseDown()
        {

            this.mouseDownTime = Date.now()
            this.vibrationTimer = setTimeout( () =>
            {
                this.$core.getUi().vibrate()
            }, 1000 )

        },

        releaseTimer()
        {
            clearTimeout( this.vibrationTimer )
            this.mouseDownTime = Date.now()
        },

        instantFullscreen( event, timestamp )
        {

            if( ( undefined !== event
                  && undefined !== event.button
                  && 2 === event.button )
                || undefined !== timestamp )
            {

                if( undefined !== event )
                {
                    event.preventDefault()
                    event.stopPropagation()
                }
                this.$emit( 'showPlannerDay' )

            }

        },

        checkMouseUp()
        {

            if( !this.clickedElm
                && !this.skipPlannerDay
                && !this.inDragMode )
            {

                if( this.selectMode )
                {
                    return
                }

                let interval = Date.now() - this.mouseDownTime
                this.$core.getUi().removeSelection()
                clearTimeout( this.vibrationTimer )

                if( 750 < interval )
                {
                    this.blockedClick = true
                    this.$core.getUi()
                        .delay( () =>
                        {
                            this.blockedClick = false
                        }, 500 )
                    this.$emit( 'showPlannerDay' )
                }

            }

        },

        markOverfilled()
        {

            this.overFilled = false
            let wrapperElm = document.querySelector( '#planner-element-wrapper-' + this.id )
            if( null !== wrapperElm )
            {
                let elms = wrapperElm.querySelectorAll( '.planner-element' )
                let height = 0

                for( let e in elms )
                {

                    if( elms[ e ].style !== undefined
                        && elms[ e ].classList )
                    {

                        height += parseInt( elms[ e ].offsetHeight )
                        height += parseInt( window.getComputedStyle( elms[ e ], '' ).getPropertyValue( 'margin-bottom' ) )
                        height += parseInt( window.getComputedStyle( elms[ e ], '' ).getPropertyValue( 'margin-top' ) )

                    }

                }

                if( height > wrapperElm.offsetHeight )
                {
                    this.overFilled = true
                }
            }

        },

        handleClick( vars )
        {

            let element = vars.element,
                event   = vars.event

            if( this.selectMode )
            {

                let moveAllowed = [ 'list', 'note', 'todo', 'date' ]

                if( Date.now() - 500 > this.lastEvent )
                {

                    if( -1 < moveAllowed.indexOf( element.type ) )
                    {

                        let idx = this.selected.indexOf( element.localId )
                        if( -1 < idx )
                        {
                            this.selected.splice( idx, 1 )
                        }
                        else
                        {
                            this.selected.push( element.localId )
                        }

                        this.$core.getEventManager().dispatch( 'on-item-select', element )
                        this.lastEvent = Date.now()

                    }

                }
                return

            }

            if( !this.clickedElm )
            {

                this.clickedElm = true
                if( undefined !== event )
                {
                    if( 2 === event.button )
                    {
                        this.clickedElm = false
                        return false
                    }
                }

                let zoomId = this.$core.getUi()
                                 .showElementZoom( element, this.changeKey )

                this.zoomElement = element
                this.zoomId = zoomId

                this.addEvent( 'on-update-zoom-' + zoomId, () =>
                    {
                        this.$emit( 'refresh' )
                    } )
                this.appendEvent( 'on-close-zoom-' + zoomId, () =>
                    {
                        this.zoomElement = false
                        this.zoomId = false
                    } )

                this.$core.getUi().delay(
                    () =>
                    {
                        this.clickedElm = false
                    }, 500 )

            }

        },

        handleClicked()
        {
            if( !this.blockedClick )
            {
                this.$emit( 'clicked' )
            }
        }

    }

}
</script>