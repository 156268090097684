import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class UnsharesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !UnsharesObjectClass.instance )
        {

            let refList = [ 'idReference',
                            'objectType',
                            'sharedWith',
                            'timestamp',
                            'update' ]

            let personalAttributesStatesList = []

            super( core, 'unshare', refList, personalAttributesStatesList )

            this.objectType = 'unshares'
            this.eventManager = core.getEventManager()

            UnsharesObjectClass.instance = this

        }

        return UnsharesObjectClass.instance

    }

    fillCache()
    {

        return new Promise( resolve =>
        {

            super.fillCache()
                 .then( () =>
                 {

                     let scopes = [ 'cache', 'archive' ]
                     for( let s in scopes )
                     {
                         for( const [ localId, share ] of this.registry[ scopes[ s ] ] )
                         {

                             this.registry.byReference.set( share.idReference, localId )

                         }
                     }

                     this.eventManager
                         .dispatchIndexed( 'on-share-refresh-component' )

                     return resolve()

                 } )

        } )

    }

    listAll( attach, filter, skipArchived, skipWait )
    {
        return new Promise( ( resolve, reject ) =>
        {
            super.listAll( attach, filter, skipArchived, skipWait )
                 .then( list =>
                 {

                     for( let l in list )
                     {
                         this.registry.byReference.set( list[ l ].idReference, list[ l ].localId )
                     }

                     this.eventManager
                         .dispatchIndexed( 'on-share-refresh-component' )

                     return resolve( list )

                 } )
                 .catch( e =>
                 {
                     return reject( e )
                 } )
        } )
    }

    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        let localId = super.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
        this.registry.byReference.set( values.idReference, localId )

        this.eventManager.append( 'storable-after-update-' + localId, () =>
        {
            this.eventManager
                .dispatchIndexed( 'on-share-refresh-component' )
        } )

        return localId

    }

    update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
    {

        this.eventManager.append( 'after-updated-'+localId, () => {
            this.eventManager
                .dispatchIndexed( 'on-share-refresh-component' )
        })
        return super.update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )

    }

    delete( localId, remoteId, forceRemote )
    {

        return new Promise( resolve =>
        {

            super.delete( localId, remoteId, forceRemote )
                .then( () => {

                    for( const[ idReference, idLocal ] of this.registry.byReference )
                    {
                        if( idLocal === localId )
                        {
                            this.registry.byReference.delete( idReference )
                            break
                        }
                    }

                    this.eventManager
                        .dispatchIndexed( 'on-share-refresh-component' )

                    return resolve()

                })

        } )

    }

}