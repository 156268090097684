/* eslint-disable */
import { createStore } from 'vuex'

export const store = createStore( {

    state()
    {
        return {
            dbConnected              : false,
            logoutMessage            : null,
            coreResetTriggering      : null,
            autoRouteDisabled        : null,
            uuid                     : null,
            online                   : false,
            appState                 : 'offline',
            authorized               : false,
            offlineAuthorized        : false,
            passphrase               : false,
            idSession                : false,
            idUser                   : false,
            isStudent                : false,
            lastIdUser               : false,
            lastLogin                : 0,
            username                 : false,
            encryptedPrivateKey      : false,
            lastLicenseBooking       : false,
            lastLicenseUpgradeBooking: false,
            offlineFailures          : null,
            secondFactor             : null,
            privateKey               : null,
            publicKey                : false,
            signature                : null,
            lastTick                 : null,
            metadata                 : null,
            shouldSync               : false,
            lastSyncRun              : false,
            license                  : false,
            storeInitialized         : false,
            updateVersion            : false,
            updateRunning            : false,
            registrySnapshot         : false,
            registrySnapshotTsmp     : false,
            registrySnapshotCount    : false,
            registryNeedsRecovery    : false,
            registryFastloadDone     : false,
            registryState            : undefined,
            appWasFrozen             : false,
            reloadDetected           : false,
            poolMaster               : null,
            timerCallbacks           : {},
            poolRights               : {},
            flags                    : [],
            secretaryUser            : []
        }
    },

    mutations: {

        setDatabase( state, value )
        {
            this.database = value
            state.dbConnected = true
        },

        setLogoutMessage( state, value )
        {
            state.logoutMessage = value
            this.database.writeStorage( 'logoutMessage', value )
        },

        setCoreResetTriggering( state, value )
        {
            state.coreResetTriggering = value
        },

        setAutoRouteDisabled( state, value )
        {
            state.autoRouteDisabled = value
        },

        setEventManager( state, value )
        {
            this.eventManager = value
        },

        setOfflineAuthorized( state, value )
        {
            state.offlineAuthorized = value
            this.database.writeStorage( 'offlineAuthorized', value )
        },

        setLastLogin( state, value )
        {
            state.lastLogin = value
            this.database.writeStorage( 'lastLogin', value )
        },

        setRegistrySnapshot( state, value )
        {
            state.registrySnapshot = value
            this.database.writeStorage( 'registrySnapshot', value )
        },

        setRegistrySnapshotTsmp( state, value )
        {
            state.registrySnapshotTsmp = value
            this.database.writeStorage( 'registrySnapshotTsmp', value )
        },

        setRegistrySnapshotCount( state, value )
        {
            state.registrySnapshotCount = value
            this.database.writeStorage( 'registrySnapshotCount', value )
        },

        setRegistryNeedsRecovery( state, value )
        {
            state.registryNeedsRecovery = value
            this.database.writeStorage( 'registryNeedsRecovery', value )
        },

        setRegistryFastloadDone( state, value )
        {
            state.registryFastloadDone = value
            this.database.writeStorage( 'registryFastloadDone', value )
        },

        setRegistryState( state, value )
        {
            state.registryState = value
        },

        setAppWasFrozen( state, value )
        {
            state.appWasFrozen = value
            this.database.writeStorage( 'appWasFrozen', value )
        },

        setReloadDetected( state, value )
        {
            state.reloadDetected = value
            this.database.writeStorage( 'reloadDetected', value )
        },

        setOfflineFailures( state, value )
        {
            state.offlineFailures = value
            this.database.writeStorage( 'offlineFailures', value )
        },

        setPassphrase( state, value )
        {
            state.passphrase = value
            this.database.writeStorage( 'passphrase', value )
        },

        setUuid( state, value )
        {
            state.uuid = value
            this.database.writeStorage( 'uuid', value )
        },

        setOnline( state, value )
        {
            state.online = value
        },

        setAppState( state, value )
        {
            state.appState = value
        },

        setAuthorized( state, value )
        {
            state.authorized = value
            if( true === value )
            {
                window.authKey = this.getters.uuid
                this.eventManager.dispatch( 'on-auth-trigger' )
            }
            this.database.writeStorage( 'authorized', value )
        },

        setSecondFactor( state, value )
        {
            state.secondFactor = value
            this.database.writeStorage( 'secondFactor', value )
        },

        setIdSession( state, value )
        {
            state.idSession = value
            this.database.writeStorage( 'idSession', value )
        },

        setIdUser( state, value )
        {
            state.idUser = value
            this.database.writeStorage( 'idUser', value )
        },

        setIsStudent( state, value )
        {
            state.isStudent = value
            this.database.writeStorage( 'isStudent', value )
        },

        setLastIdUser( state, value )
        {
            state.lastIdUser = value
            this.database.writeStorage( 'lastIdUser', value )
        },

        setLastLicenseBooking( state, value )
        {
            state.lastLicenseBooking = value
            this.database.writeStorage( 'lastLicenseBooking', value )
        },

        setLastLicenseUpgradeBooking( state, value )
        {
            state.lastLicenseUpgradeBooking = value
            this.database.writeStorage( 'lastLicenseUpgradeBooking', value )
        },

        setUsername( state, value )
        {
            state.username = value
            this.database.writeStorage( 'username', value )
        },

        setEncryptedPrivateKey( state, value )
        {
            state.encryptedPrivateKey = value
            this.database.writeStorage( 'encryptedPrivateKey', value )
        },

        setPrivateKey( state, value )
        {
            state.privateKey = value
            this.database.writeStorage( 'privateKey', value )
        },

        setPublicKey( state, value )
        {
            state.publicKey = value
            this.database.writeStorage( 'publicKey', value )
        },

        setSignature( state, value )
        {
            state.signature = value
            this.database.writeStorage( 'signature', value )
        },

        setLastTick( state, value )
        {
            state.lastTick = value
            this.database.writeStorage( 'lastTick', value )
        },

        setMetadata( state, value )
        {
            state.metadata = value
            this.database.writeStorage( 'metadata', value )
        },

        setShouldSync( state, value )
        {
            state.shouldSync = value
            this.database.writeStorage( 'shouldSync', value )
        },

        setLastSyncRun( state, value )
        {
            state.lastSyncRun = value
            this.database.writeStorage( 'lastSyncRun', value )
        },

        setLicense( state, value )
        {
            state.license = value
            this.database.writeStorage( 'license', value )
        },

        setStoreInitialized( state, value )
        {
            let oldValue = state.storeInitialized
            state.storeInitialized = value
            this.database.writeStorage( 'storeInitialized', value )
            if( true === value
                && false === oldValue )
            {
                this.eventManager.dispatch( 'on-store-ready' )
            }
        },

        setUpdateVersion( state, value )
        {
            state.updateVersion = value
            this.database.writeStorage( 'updateVersion', value )
            this.eventManager.dispatch( 'on-version-update' )
        },

        setPoolMaster( state, value )
        {
            state.poolMaster = value
            this.database.writeStorage( 'poolMaster', value )
        },

        setPoolRights( state, value )
        {
            state.poolRights = value
            this.database.writeStorage( 'poolRights', value )
        },

        setUpdateRunning( state, value )
        {
            if( false !== value )
            {
                state.updateRunning = value
                this.database.writeStorage( 'updateRunning', value )
            }
            if( 'reset' === value )
            {
                state.updateRunning = false
                this.database.writeStorage( 'updateRunning', false )
            }
        },

        setFlags( state, value )
        {
            state.flags = value
            this.database.writeStorage( 'flags', value )
        },

        setSecretaryUser( state, value )
        {
            state.secretaryUser = value
            this.database.writeStorage( 'secretaryUser', value )
        },

        setTimerCallbacks( state, value )
        {
            state.timerCallbacks = value
        },

        initStates()
        {

            let promises = []

            for( const [ key, value ] of Object.entries( this.state ) )
            {

                if( 'dbConnected' !== key
                    && 'online' !== key )
                {

                    let todo = 'set' + key.charAt( 0 ).toUpperCase() + key.slice( 1 )

                    promises.push( new Promise( resolve =>
                    {

                        this.database.readStorage( key )
                            .then( result =>
                            {
                                this.commit( todo, result )
                                return resolve()

                            } )
                            .catch( () =>
                            {
                                this.commit( todo, value )
                                return resolve()
                            } )

                    } ) )

                }

            }

            Promise.all( promises )
                   .then( () =>
                   {

                       if( false !== this.getters.idSession
                           && this.getters.lastTick < ( Date.now() - ( 119 * 60000 ) ) )
                       {
                           this.commit( 'setIdSession', false )
                           setTimeout( () =>
                           {
                               this.eventManager.dispatch( 'on-session-timeout' )
                           }, 500 )
                       }

                       this.eventManager.dispatchIndexed( 'on-store-initialized' )
                       this.eventManager.dispatch( 'after-store-init-states' )
                       this.commit( 'setStoreInitialized', true )

                   } )

        }

    },

    getters: {

        dbConnected              : state => state.dbConnected,
        autoRouteDisabled        : state => state.autoRouteDisabled,
        logoutMessage            : state => state.logoutMessage,
        coreResetTriggering      : state => state.coreResetTriggering,
        uuid                     : state => state.uuid,
        online                   : state => state.online,
        appState                 : state => state.appState,
        authorized               : state => state.authorized,
        flags                    : state => state.flags,
        idSession                : state => state.idSession,
        idUser                   : state => state.idUser,
        isStudent                : state => state.isStudent,
        lastIdUser               : state => state.lastIdUser,
        lastLogin                : state => state.lastLogin,
        username                 : state => state.username,
        encryptedPrivateKey      : state => state.encryptedPrivateKey,
        secondFactor             : state => state.secondFactor,
        privateKey               : state => state.privateKey,
        publicKey                : state => state.publicKey,
        signature                : state => state.signature,
        lastTick                 : state => state.lastTick,
        metadata                 : state => state.metadata,
        shouldSync               : state => state.shouldSync,
        lastSyncRun              : state => state.lastSyncRun,
        license                  : state => state.license,
        storeInitialized         : state => state.storeInitialized,
        updateVersion            : state => state.updateVersion,
        offlineAuthorized        : state => state.offlineAuthorized,
        offlineFailures          : state => state.offlineFailures,
        passphrase               : state => state.passphrase,
        poolMaster               : state => state.poolMaster,
        poolRights               : state => state.poolRights,
        secretaryUser            : state => state.secretaryUser,
        updateRunning            : state => state.updateRunning,
        lastLicenseBooking       : state => state.lastLicenseBooking,
        lastLicenseUpgradeBooking: state => state.lastLicenseUpgradeBooking,
        registrySnapshot         : state => state.registrySnapshot,
        registrySnapshotTsmp     : state => state.registrySnapshotTsmp,
        registrySnapshotCount    : state => state.registrySnapshotCount,
        registryNeedsRecovery    : state => state.registryNeedsRecovery,
        registryFastloadDone     : state => state.registryFastloadDone,
        registryState            : state => state.registryState,
        reloadDetected           : state => state.reloadDetected,
        timerCallbacks           : state => state.timerCallbacks,
        appWasFrozen             : state => state.appWasFrozen

    },

    actions: {

        waitReady()
        {
            return new Promise( ( resolve ) =>
            {
                if( this.getters.storeInitialized )
                {
                    return resolve()
                }
                else
                {
                    setTimeout( () =>
                    {
                        return resolve( this.dispatch( 'waitReady' ) )
                    }, 300 )
                }
            } )
        },

        destroySession( { commit } )
        {
            return new Promise( ( resolve ) =>
            {

                commit( 'setAuthorized', false )
                commit( 'setOfflineAuthorized', false )
                commit( 'setIdSession', false )
                commit( 'setPrivateKey', null )
                commit( 'setLastTick', null )
                commit( 'setShouldSync', false )
                commit( 'setLastLicenseBooking', false )
                commit( 'setLastLicenseUpgradeBooking', false )
                commit( 'setStoreInitialized', false )
                commit( 'setOfflineFailures', 0 )
                commit( 'setPassphrase', false )
                commit( 'setFlags', [] )
                commit( 'setSecretaryUser', [] )
                commit( 'setIdUser', false )
                commit( 'setRegistryFastloadDone', false )
                commit( 'setRegistryNeedsRecovery', false )
                commit( 'setRegistryState', undefined )
                commit( 'setAppWasFrozen', false )
                commit( 'setReloadDetected', false )
                commit( 'setTimerCallbacks', {} )
                this.eventManager.dispatch( 'reset-all' )

                return resolve()

            } )
        },

        flushStorage( { commit } )
        {

            return new Promise( ( resolve ) =>
            {

                commit( 'setAuthorized', false )
                commit( 'setIdSession', false )
                commit( 'setPrivateKey', null )
                commit( 'setSignature', null )
                commit( 'setLastTick', null )
                commit( 'setShouldSync', false )
                commit( 'setLastSyncRun', false )
                commit( 'setStoreInitialized', false )
                commit( 'setOfflineAuthorized', false )
                commit( 'setOfflineFailures', 0 )
                commit( 'setPassphrase', false )
                commit( 'setSecondFactor', null )
                commit( 'setFlags', [] )
                commit( 'setPoolMaster', null )
                commit( 'setPoolRights', {} )
                commit( 'setSecretaryUser', [] )
                commit( 'setUuid', null )
                commit( 'setOnline', false )
                commit( 'setAppState', 'offline' )
                commit( 'setIdUser', false )
                commit( 'setIsStudent', false )
                commit( 'setLastIdUser', false )
                commit( 'setLastLogin', 0 )
                commit( 'setUsername', false )
                commit( 'setEncryptedPrivateKey', false )
                commit( 'setPrivateKey', false )
                commit( 'setPublicKey', false )
                commit( 'setMetadata', null )
                commit( 'setLicense', null )
                commit( 'setUpdateVersion', false )
                commit( 'setUpdateRunning', false )
                commit( 'setLastLicenseBooking', false )
                commit( 'setLastLicenseUpgradeBooking', false )
                commit( 'setRegistrySnapshot', false )
                commit( 'setRegistrySnapshotTsmp', false )
                commit( 'setRegistrySnapshotCount', false )
                commit( 'setRegistryNeedsRecovery', false )
                commit( 'setRegistryFastloadDone', false )
                commit( 'setRegistryState', undefined )
                commit( 'setAppWasFrozen', false )
                commit( 'setReloadDetected', false )
                commit( 'setTimerCallbacks', {} )

                setTimeout( () => {
                    this.eventManager.dispatch( 'reset-all' )
                }, 1000 )

                return resolve()

            } )

        },

        prepareUserChange( { commit } )
        {

            return new Promise( ( resolve ) =>
            {

                commit( 'setAuthorized', false )
                commit( 'setIdSession', false )
                commit( 'setPrivateKey', null )
                commit( 'setSignature', null )
                commit( 'setShouldSync', false )
                commit( 'setLastSyncRun', false )
                commit( 'setOfflineAuthorized', false )
                commit( 'setOfflineFailures', 0 )
                commit( 'setPassphrase', false )
                commit( 'setSecondFactor', null )
                commit( 'setFlags', [] )
                commit( 'setSecretaryUser', [] )
                commit( 'setPoolMaster', null )
                commit( 'setPoolRights', {} )
                commit( 'setUuid', null )
                commit( 'setIdUser', false )
                commit( 'setIsStudent', false )
                commit( 'setLastIdUser', false )
                commit( 'setLastLogin', false )
                commit( 'setUsername', false )
                commit( 'setEncryptedPrivateKey', false )
                commit( 'setPrivateKey', false )
                commit( 'setPublicKey', false )
                commit( 'setMetadata', null )
                commit( 'setLicense', null )
                commit( 'setUpdateVersion', false )
                commit( 'setLastLicenseBooking', false )
                commit( 'setLastLicenseUpgradeBooking', false )
                commit( 'setRegistrySnapshot', false )
                commit( 'setRegistrySnapshotTsmp', false )
                commit( 'setRegistrySnapshotCount', false )
                commit( 'setRegistryNeedsRecovery', false )
                commit( 'setRegistryFastloadDone', false )
                commit( 'setRegistryState', undefined )
                commit( 'setAppWasFrozen', false )
                commit( 'setReloadDetected', false )
                commit( 'setTimerCallbacks', {} )

                return resolve()

            } )

        }

    }

} )