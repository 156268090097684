<script>
import MixinTypeTranslations from "@/mixins/MixinTypeTranslations";

/*eslint-disable*/
export default {

    name  : "MixinDefaultDetailViewFiltering",
    mixins: [ MixinTypeTranslations ],

    data()
    {
        return {
            reference                : false,
            referenceId              : false,
            objectType               : false,
            objectList               : {},
            referenceClassId         : false,
            referenceGroupId         : [],
            referenceYeargroupId     : [],
            studentFileShowOnlyFilled: this.$core.settings().getSetting( 'studentFileShowOnlyFilled' ),
            filterBy                 : [ 'class', 'group', 'yeargroup' ]
        }
    },

    created()
    {
        this.setup()
    },

    methods: {

        setup()
        {

            this.reference = {
                type       : this.$props.object.type,
                localId    : this.$props.object.localId,
                classId    : this.$props.object.classId || undefined,
                groupId    : this.$props.object.inGroups || [],
                yeargroupId: this.$props.object.yeargroupId || undefined
            }

        },

        setupFilter( object )
        {

            object = object || this.$props.object

            if( this.$core.f().valid( this.tab )
                && false !== object )
            {

                let objectFilter = this.tab.split( '_' )

                this.detailObjectType = objectFilter[ 0 ].type
                /*this.reference = object.type
                this.referenceId = object.localId*/

                if( 1 === objectFilter.length )
                {
                    this.objectList = {
                        type  : objectFilter[ 0 ],
                        filter: false,
                        except: [],
                        only  : []
                    }
                }
                else
                {
                    if( 3 === objectFilter.length )
                    {
                        this.objectList = {
                            type  : objectFilter[ 0 ],
                            filter: objectFilter[ 1 ],
                            except: [],
                            only  : []
                        }
                        if( objectFilter[ 1 ] === 'only' )
                        {
                            this.objectList.only.push( objectFilter[ 2 ] )
                        }
                        else
                        {
                            this.objectList.except.push( objectFilter[ 2 ] )
                        }
                    }
                }

            }

        },

        hasScoreBox( object )
        {
            if( undefined === object.lists
                || undefined === object.lists[ 0 ] )
            {
                return false
            }
            for( let c in object.lists[ 0 ].columns )
            {
                let col = object.lists[ 0 ].columns[ c ]
                if( col.type.substr( 0, 8 ) === 'scoreBox' )
                {
                    return true
                }
            }
        },

        /* TODO */
        listRangeMatch( object )
        {

            if( this.referenceObject.archived !== true )
            {
                return true
            }

            let temp = this.referenceObject.archiveKey.split( /-/g )
            temp.shift()
            let timestamp = parseInt( temp[ 0 ] )

            if( undefined !== object.tsmpEnd )
            {
                if( parseInt( object.tsmpEnd ) < timestamp
                    && parseInt( object.tsmpStart ) > parseInt( this.referenceObject.timestamp ) )
                {
                    return true
                }
                else
                {
                    return false
                }
            }
            else
            {
                if( parseInt( object.timestamp ) < timestamp
                    && parseInt( object.timestamp ) > parseInt( this.referenceObject.timestamp ) )
                {
                    return true
                }
                return false
            }

        },

        /* NEW !!! filterMatch( filterType, localId )
        {

            let filter = Array.isArray( filterType ) ? filterType[ 0 ] : filterType
            if( undefined === filter )
            {
                return true
            }

            let indexMap = this.$core.getBaseClassHelper()
                               .get( this.$props.viewItem.toLowerCase() ).registry.indexMaps.get( localId )

            if( indexMap[ filter.attr ] === filter.value )
            {
                return true
            }

            switch( filter.attr )
            {
                case 'classId':
                case 'groupId':
                case 'yeargroupId':
                    if( indexMap.filterBy === filter.value )
                    {
                        return true
                    }
                    if( indexMap[ filter.attr.replace( 'Id', 'Reference' ) ] === filter.value )
                    {
                        return true
                    }
                    break
                case 'labelId':
                    if( undefined !== indexMap.labels
                        && -1 < indexMap.labels.indexOf( filter.value ) )
                    {
                        return true
                    }
                    break
            }

            return false

        },*/

        detailViewFilterMatch( localId )
        {

            let indexMap     = this.$core.getBaseClassHelper()
                                   .get( this.tabMap.get( this.tab ).viewItem.toLowerCase() ).registry.indexMaps.get( localId ),
                haveEntries  = this.$core.getBaseClassHelper()
                                   .get( this.tabMap.get( this.tab ).viewItem.toLowerCase() )
                                   .hasValuesFor( localId, this.reference.localId ),
                referenceMap = undefined !== this.$core.getBaseClassHelper()
                                                 .get( this.reference.type ) ? this.$core.getBaseClassHelper()
                                                                                   .get( this.reference.type ).registry.indexMaps
                                                                                   .get( this.reference.localId ) : null

            if( undefined === indexMap )
            {
                return false
            }

            if( 'lists' === this.objectList.type )
            {

                if( this.studentFileShowOnlyFilled
                    && 'student' === this.$props.object.type
                    && !haveEntries )
                {
                    return false
                }

                if( 'not' === this.objectList.filter
                    && this.$core.f().isObject( this.objectList.except )
                    && -1 < this.objectList.except.indexOf( indexMap.listType ) )
                {
                    return false
                }

                if( 'only' === this.objectList.filter
                    && this.$core.f().isObject( this.objectList.only )
                    && -1 === this.objectList.only.indexOf( indexMap.listType ) )
                {
                    return false
                }

            }

            if( indexMap.filterBy === this.reference.localId
                || ( 'lists' === this.objectList.type && 'all' === indexMap.filterBy ) )
            {
                return true
            }

            if( null !== referenceMap )
            {
                switch( this.reference.type )
                {
                    case 'group':
                    case 'yeargroup':
                    case 'class':
                        if( Array.isArray( referenceMap.students )
                            && -1 < referenceMap.students.indexOf( localId ) )
                        {
                            return true
                        }
                        break
                }
            }

            if( Array.isArray( indexMap[ 'studentReference' ] )
                && -1 < indexMap[ 'studentReference' ].indexOf( this.reference.localId ) )
            {
                return true
            }

            for( let f in this.filterBy )
            {
                if( this.reference.localId === indexMap[ this.filterBy[ f ] + 'Id' ] )
                {
                    return true
                }
                if( undefined !== indexMap.filterBy
                    && indexMap.filterBy === this.reference[ this.filterBy[ f ] + 'Id' ] )
                {
                    return true
                }
                if( Array.isArray( indexMap[ this.filterBy[ f ] + 'Reference' ] )
                    && -1 < indexMap[ this.filterBy[ f ] + 'Reference' ].indexOf( this.reference.localId ) )
                {
                    return true
                }
                if( indexMap[ this.filterBy[ f ] + 'Reference' ] === this.reference.localId )
                {
                    return true
                }
            }

            return false


        }

    }
}
</script>