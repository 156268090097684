import AbstractListClass from "@/objects/abstract/ListClass";

export default class ListsObjectClass extends AbstractListClass
{

    constructor( core )
    {

        if( !ListsObjectClass.instance )
        {

            super( core )

            this.t = ( key, params ) =>
            {
                return core.t( key, params )
            }

            this.core = core
            this.objectType = 'lists'
            this.isStudentShareable = true
            this.isStudentEditable = true
            this.creationDependsOn = [ 'student', 'class', 'group', 'yeargroup' ]

            ListsObjectClass.instance = this

        }

        return ListsObjectClass.instance

    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'pin', 'hide', 'edit', 'share', 'stats', 'delete' ]
    }

    getFormFields()
    {

        return [
            {
                ref        : 'listSetup',
                caption    : '',
                type       : 'ListSetupSelector',
                styleUpdate: false,
                reformatter: null,
                colSpan    : 2,
                optional   : false,
                validator  : 'select'
            },
            {
                ref        : 'listType',
                caption    : 'Typ wählen',
                type       : 'ListTypeSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : 'select'
            },
            {
                ref        : 'preSelectedClass',
                caption    : false,
                type       : 'Hidden',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : null
            },
            {
                ref        : 'fieldHistory',
                caption    : false,
                type       : 'Hidden',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : null
            }
        ]
    }

    getListById( referenceKey, localId, scope )
    {

        let list = this.registry[ scope ].get( referenceKey )

        if( undefined !== list
            && undefined !== list.lists )
        {
            return list.lists.find( o => o.localId === localId )
        }

        return undefined

    }

    /*eslint-disable*/
    findListById( localId, scope )
    {

        if( undefined === scope )
        {
            return this.findListByIdInAllScopes( localId )
        }
        for( let [ key, list ] of this.registry[ scope ] )
        {
            if( undefined !== list.lists.find( o => o.localId === localId ) )
            {
                return list.lists.find( o => o.localId === localId )
            }
        }

        return undefined

    }

    findListByIdInAllScopes( localId )
    {
        let scopes = [ 'cache', 'archive' ]
        for( let s in scopes )
        {
            let list = this.findListById( localId, scopes[s] )
            if( undefined !== list )
            {
                return list
            }
        }
        return undefined
    }

    updateShadowCopy( identifier, copy )
    {
        for( let [ key, list ] of this.registry.shadowCopies )
        {
            if( key === identifier )
            {
                return
            }
            else
            {
                if( undefined !== list.lists )
                {
                    for( let l in list.lists )
                    {
                        if( list.lists[ l ].localId === identifier )
                        {
                            list.lists[ l ] = copy
                        }
                    }
                }
            }
        }
        this.registry.shadowCopyKey = this.functions.objectHash( this.list( 'shadowCopies' ), true )

    }

    deleteShadowCopy( identifier )
    {
        for( let [ key, list ] of this.registry.shadowCopies )
        {
            if( key === identifier )
            {
                this.registry.shadowCopies.delete( identifier )
                break
            }
            else
            {
                if( undefined !== list.lists )
                {
                    for( let l in list.lists )
                    {
                        if( list.lists[ l ].localId === identifier )
                        {
                            this.registry.shadowCopies.delete( identifier )
                            delete list.lists[ l ]
                            break
                        }
                    }
                }
            }
        }
        this.registry.shadowCopyKey = this.functions.objectHash( this.list( 'shadowCopies' ), true )
    }

}