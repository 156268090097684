<template>
    <div :class="'student hoverable '+color+( selected ? ' selected-item' : '' )"
         :key="'student-'+item.localId+'-'+item.elementKey+'-'+selected" @click="$emit( 'clicked' )">
        <div
            :class="'sensitive studentName'+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )">
            <strong>{{ item.lastname }}</strong>, {{ item.firstname }}
        </div>
        <div class="student-markerspace">
            <div class="inner">
                <ItemReferenceMarkers :item/>
            </div>
        </div>
        <div :class="'classname' + classLongClassnames"
             :style="widthLongClassnames"
             v-if="$core.f().isset( item.classname ) && 'Z_FALSE' !== item.classname">
            {{ item.classname }}
        </div>
    </div>
</template>
<script>

import MixinWatchElementKey from "@/mixins/MixinWatchElementKey";
import ItemReferenceMarkers from "@/components/elements/markers/ItemReferenceMarkers.vue";

export default {
    name      : 'Student',
    components: { ItemReferenceMarkers },

    props: {
        item      : { type: Object, required: true },
        selected  : { type: Boolean, required: false, default: false },
        elementKey: { type: String, required: false, default: '' }
    },

    emits : [ 'clicked' ],
    mixins: [ MixinWatchElementKey ],

    data()
    {
        return {
            colorModel          : this.$core.settings().getSetting( 'studentColorModel' ),
            color               : '',
            classLongClassnames : '',
            widthLongClassnames : '',
            marginLongClassnames: ''
        }
    },

    created()
    {
        this.prepare()
    },

    methods: {
        prepare()
        {
            this.color = 'colors' === this.colorModel ?
                         this.$props.item.color : this.$core.f().genderColorClass( this.$props.item.gender )
            this.classLongClassnames = -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) ? ' long' : ''
            this.widthLongClassnames = -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) ?
                                       'width: ' + Math.ceil( ( this.$core.settings().getSetting( 'maxClassNameLength' ) / 4 ) * 100 ) + 'px' : ''
            this.marginLongClassnames = -1 < this.$core.settings().getSetting( 'maxClassNameLength' ) ?
                                        'margin-left: ' + Math.ceil( ( this.$core.settings().getSetting( 'maxClassNameLength' ) / 4 ) * 100 ) + 'px' : ''
        }
    }

}
</script>