export default class Session
{
    constructor( core )
    {
        if( !Session.instance )
        {

            this.logger = core.getLogger()
            this.client = core.getClient()
            this.store = core.getStore()
            this.timer = core.getCoreTimer()
            this.authenticator = core.getAuthenticator()
            this.eventManager = core.getEventManager()

            this.interval = 30000
            this.checkGraceTime = 2000
            this.maxInactivityTime = 60000 * 120
            this.lastActivity = Date.now()
            this.lastCheck = 0

            this.logger.cconstructed( 'Core::Session', 'session controller instantiated...' )

            this.timer.addInterval( 'session-control', this.interval, () =>
            {
                this.checkActivityTimer()
                this.checkSession()
            } )

            this.eventManager.add( 'on-activity', () =>
            {
                this.lastActivity = Date.now()
            } )

            Session.instance = this

        }

        return Session.instance

    }

    destruct()
    {

        this.timer = null
        this.logger = null
        this.client = null
        this.store = null
        this.authenticator = null

        delete Session.instance

    }

    checkActivityTimer()
    {

        if( true !== this.store.getters.authorized )
        {
            this.lastActivity = Date.now()
        }

        let diff = Date.now() - this.lastActivity,
            readableDiff = ( diff / 60000 ).toPrecision( 2 )
        this.logger.clog( 'Core::Session::checkActivityTimer', 'last activity '+readableDiff+' minutes ago' )
        if( diff > this.maxInactivityTime )
        {
            this.logger.clog( 'Core::Session::checkActivityTimer', 'max inactivity time reached: forcefully ending session' )
            this.authenticator.logout( true )
        }

    }

    checkSession()
    {

        if( true === this.store.getters.storeInitialized
            && true === this.store.getters.authorized
            && ( Date.now() - this.interval - this.checkGraceTime ) > this.lastCheck )
        {

            this.lastCheck = Date.now()

            this.logger.clog( 'Core::Session', 'verifying session...' )
            let message = {
                method      : 'users.checkSession',
                idSession   : '' + this.store.getters.idSession,
                looseSession: true
            }

            this.client.request( message, 5000, true )
                .then( result =>
                {
                    if( result.state === true
                        && result.idUser === false )
                    {
                        this.authenticator.logout( true )
                    }
                } )
                .catch( e =>
                {
                    if( e === 'E_NOT_FOUND' )
                    {
                        this.authenticator.logout( true )
                    }
                    this.logger.cdebug( 'Core::Session', 'session verification failed:', e )
                } )

        }

    }

}

