import NotesObjectClass                from "@/objects/NotesObjectClass";
import TodosObjectClass                from "@/objects/TodosObjectClass";
import StudentsObjectClass             from "@/objects/StudentsObjectClass";
import ClassesObjectClass              from "@/objects/ClassesObjectClass";
import YeargroupsObjectClass           from "@/objects/YeargroupsObjectClass";
import GroupsObjectClass               from "@/objects/GroupsObjectClass";
import ListsObjectClass                from "@/objects/ListsObjectClass";
import ColleaguesObjectClass           from "@/objects/ColleaguesObjectClass";
import AvatarsObjectClass              from "@/objects/AvatarsObjectClass";
import DatesObjectClass                from "@/objects/DatesObjectClass";
import TeamsObjectClass                from "@/objects/TeamsObjectClass";
import TemplatesObjectClass            from "@/objects/TemplatesObjectClass";
import ChecklistClass                  from "@/objects/lists/ChecklistClass";
import CombilistClass                  from "@/objects/lists/CombilistClass";
import CustomFixedListClass            from "@/objects/lists/CustomFixedListClass";
import RecallListClass                 from "@/objects/lists/RecallListClass";
import RatinglistClass                 from "@/objects/lists/RatinglistClass";
import TestClass                       from "@/objects/lists/TestClass";
import StudentAccessObjectClass        from "@/objects/StudentAccessObjectClass";
import ElementsObjectClass             from "@/objects/ElementsObjectClass";
import MessagesObjectClass             from "@/objects/MessagesObjectClass";
import ShadowCopyObjectClass           from "@/objects/ShadowCopyObjectClass";
import SharesObjectClass               from "@/objects/SharesObjectClass";
import MediaObjectClass                from "@/objects/MediaObjectClass";
import LabelsObjectClass               from "@/objects/LabelsObjectClass";
import OrganizerObjectClass            from "@/objects/OrganizerObjectClass";
import OrganizerSlotsObjectClass       from "@/objects/OrganizerSlotsObjectClass";
import OrganizerContentObjectClass     from "@/objects/OrganizerContentObjectClass";
import OrganizerLabelsObjectClass      from "@/objects/OrganizerLabelObjectClass";
import UnsharesObjectClass             from "@/objects/UnsharesObjectClass";
import CompetencesObjectClass          from "@/objects/CompetencesObjectClass";
import CompetenceCategoriesObjectClass from "@/objects/CompetenceCategoriesObjectClass";
import CompetencesTemplateObjectClass  from "@/objects/CompetencesTemplateObjectClass";
import CompetenceCategoriesTemplateObjectClass from "@/objects/CompetenceCategoriesTemplateObjectClass";

export default class BaseClass
{

    constructor( core )
    {

        if( !BaseClass.instance )
        {

            this.baseClasses = {}
            this.subClasses = {}
            this.shareableQueues = [ 'note',
                                     'todo',
                                     'student',
                                     'group',
                                     'yeargroup',
                                     'class',
                                     'list',
                                     'avatar',
                                     'date',
                                     'organizer',
                                     'organizerslot',
                                     'organizercontent',
                                     'team',
                                     'template',
                                     'studentAccess',
                                     'media',
                                     'label',
                                     'organizerlabel',
                                     'competence',
                                     'competenceCategory' ]

            this.elementQueues = [ 'note',
                                   'todo',
                                   'list',
                                   'date',
                                   'media',
                                   'competence',
                                   'competenceCategory' ]

            this.eventManager = core.getEventManager()
            this.store = core.getStore()
            this.f = core.f()
            this.ready = false

            this.eventManager.add( 'onLazyUpdate', ( setup ) =>
            {
                this.lazyUpdate( setup )
            } )

            BaseClass.instance = this

        }

        return BaseClass.instance

    }

    init( core )
    {

        this.baseClasses = {
            'list'                      : new ListsObjectClass( core ),
            'template'                  : new TemplatesObjectClass( core ),
            'note'                      : new NotesObjectClass( core ),
            'todo'                      : new TodosObjectClass( core ),
            'date'                      : new DatesObjectClass( core ),
            'organizer'                 : new OrganizerObjectClass( core ),
            'organizerslot'             : new OrganizerSlotsObjectClass( core ),
            'organizercontent'          : new OrganizerContentObjectClass( core ),
            'student'                   : new StudentsObjectClass( core ),
            'class'                     : new ClassesObjectClass( core ),
            'group'                     : new GroupsObjectClass( core ),
            'yeargroup'                 : new YeargroupsObjectClass( core ),
            'avatar'                    : new AvatarsObjectClass( core ),
            'colleague'                 : new ColleaguesObjectClass( core ),
            'team'                      : new TeamsObjectClass( core ),
            'studentAccess'             : new StudentAccessObjectClass( core ),
            'element'                   : new ElementsObjectClass( core ),
            'message'                   : new MessagesObjectClass( core ),
            'shadowCopy'                : new ShadowCopyObjectClass( core ),
            'share'                     : new SharesObjectClass( core ),
            'unshare'                   : new UnsharesObjectClass( core ),
            'media'                     : new MediaObjectClass( core ),
            'label'                     : new LabelsObjectClass( core ),
            'organizerlabel'            : new OrganizerLabelsObjectClass( core ),
            'competence'                : new CompetencesObjectClass( core ),
            'competenceCategory'        : new CompetenceCategoriesObjectClass( core ),
            'competenceTemplate'        : new CompetencesTemplateObjectClass( core ),
            'competenceCategoryTemplate': new CompetenceCategoriesTemplateObjectClass( core )

        }

        this.subClasses = {
            'list': {
                'checklist'  : new ChecklistClass( core ),
                'combilist'  : new CombilistClass( core ),
                'customFixed': new CustomFixedListClass( core ),
                'ratinglist' : new RatinglistClass( core ),
                'recallList' : new RecallListClass( core ),
                'test'       : new TestClass( core )
            }
        }

        this.translations = {
            'competencecategory': 'competenceCategory'
        }

        this.ready = true
        this.eventManager.dispatch( 'on-baseclasses-available', this )
    }

    get( which, subType )
    {

        if( undefined === subType )
        {
            return this.baseClasses[ which ] || this.baseClasses[ this.translations[ which ] ]
        }
        else
        {
            return this.subClasses[ which ][ subType ]
        }

    }

    getState( which, subType )
    {

        if( undefined === subType )
        {
            return this.baseClasses[ which ].state
        }
        else
        {
            return this.subClasses[ which ][ subType ].state
        }
    }

    getCacheKey( which, subType )
    {

        if( undefined === subType )
        {
            return this.baseClasses[ which ].registry.cacheKey
        }
        else
        {
            return this.subClasses[ which ][ subType ].registry.cacheKey
        }
    }

    getElementKey( which, subType, localId )
    {
        let elm = null
        if( undefined === this.baseClasses[ which ] )
        {
            return
        }
        if( undefined === subType )
        {
            elm = this.baseClasses[ which ].registry.cache.get( localId )
        }
        else
        {
            elm = this.subClasses[ which ][ subType ].registry.cache.get( localId )
        }
        if( undefined !== elm )
        {
            return elm.elementKey
        }
    }

    getArchiveKey( which, subType )
    {

        if( undefined === subType )
        {
            return this.baseClasses[ which ].registry.archiveKey
        }
        else
        {
            return this.subClasses[ which ][ subType ].registry.archiveKey
        }
    }

    getMultiEdit( which, subType )
    {

        if( undefined === subType )
        {
            return this.baseClasses[ which ].multiEdit
        }
        else
        {
            return this.subClasses[ which ][ subType ].multiEdit
        }
    }

    getAllObjects()
    {
        return new Promise( resolve =>
        {

            let resultList = [],
                promises   = [],
                scopes     = [ 'cache', 'archive' ]

            for( let t in this.baseClasses )
            {
                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {

                        this.baseClasses[ t ]
                            .getPreparedCache()
                            .then( results =>
                            {

                                for( let s in scopes )
                                {
                                    /* eslint-disable-next-line no-unused-vars */
                                    for( const [ localId, element ] of results[ scopes[ s ] ] )
                                    {
                                        resultList.push( element )
                                    }
                                }

                                return resolve()

                            } )

                    } )
                } )
            }

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    return resolve( resultList )

                } )

        } )

    }

    getObjectById( localId, recurse )
    {

        for( let l in this.baseClasses )
        {
            let scope = this.baseClasses[ l ].hasObject( localId )
            if( null !== scope )
            {
                return this.baseClasses[ l ].registry[ scope ].get( localId )
            }
        }

        if( 1 === this.store.getters.isStudent
            && undefined === recurse )
        {
            return this.getStudentVersionForId( localId )
        }

        return undefined

    }

    getStudentVersionForId( localId )
    {

        for( let l in this.baseClasses )
        {
            let shadowedId = this.baseClasses[ l ].registry.studentShadows.get( localId )
            if( undefined !== shadowedId )
            {
                return this.getObjectById( shadowedId, true )
            }
        }

        return undefined
    }

    getShadowCopyById( localId )
    {

        for( let l in this.baseClasses )
        {
            let element = this.baseClasses[ l ].getShadowCopyById( localId )
            if( undefined !== element )
            {
                return element
            }
        }

        return undefined

    }

    getLocalIdByShadowedId( shadowId )
    {
        for( let l in this.baseClasses )
        {
            let localId = this.baseClasses[ l ].getLocalIdByShadowedId( shadowId )
            if( undefined !== localId )
            {
                return localId
            }
        }

        return undefined
    }

    getObjectRespectingTimestamp( type, timestamp )
    {

        return new Promise( resolve =>
        {

            this.baseClasses[ type ]
                .getPreparedCache()
                .then( result =>
                {

                    let rows   = [],
                        scopes = [ 'cache', 'archive' ]

                    for( let s in scopes )
                    {
                        /* eslint-disable-next-line no-unused-vars */
                        for( const [ localId, row ] of result[ scopes[ s ] ] )
                        {

                            if( true !== row.archived )
                            {
                                rows.push( row )
                            }
                            else
                            {
                                let temp = row.archiveKey.split( /-/g )
                                temp.shift()
                                let archiveTimestamp = parseInt( temp.join( '' ) )

                                if( archiveTimestamp >= parseInt( timestamp ) )
                                {
                                    rows.push( row )
                                }
                            }

                        }
                    }

                    return resolve( result )

                } )

        } )

    }

    lazyUpdate( setup )
    {

        this.get( setup.type, setup.listType )
            .update( setup.item, setup.localId, setup.remoteId, setup.timestamp, setup.localKey )

    }

    /*eslint-disable*/
    getCacheRef( scope, type )
    {

        let list = this.get( type )
                       .readCache( scope )

        return new WeakRef( list )
            .deref()
    }

    isElementHidden( localId, type )
    {
        if( !this.baseClasses[ type ].personalAttributes.registry.hideLists.has( type ) )
        {
            return false
        }
        return -1 < this.baseClasses[ type ].personalAttributes.registry.hideLists
                                            .get( type ).indexOf( localId )
    }

}