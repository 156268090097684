<template>
    <Teleport :to="null === targetId || $core.getState( 'in-element-zoom' ) ? '#app' : '#listBoundaries-'+targetId">
        <ListEditor v-if="!inListEditor
                      && null !== editItem
                      && ( $core.getLicense().isUnrestricted( 'listFunctions', 'listEditor' ) || editItem.isStudentEditable )"
                    :loadStudents="getStudentsForList(editItem)"
                    :list="editItem"
                    :escapeNested="escapeNested"
                    :disableMulti
                    :openVertical="'vertical' === mode"
                    :listFilter="listFilter"
                    @definitionUpdate="handleDefinitionUpdate"
                    @close="handleClose"/>
    </Teleport>
</template>
<script>

import ListEditor from "@/components/elements/viewItems/listEditor/ListEditor.vue";
/*eslint-disable*/
export default {

    name      : 'ListEditorOverlay',
    components: { ListEditor },

    props: {
        setup: { type: Object, required: true },
    },

    emits: [ 'close' ],

    data()
    {
        return {
            targetId    : false,
            inListEditor: false,
            editItem    : false,
            escapeNested: false,
            mode        : false,
            listFilter  : false,
            disableMulti: false
        }
    },

    created()
    {
        this.targetId = this.$props.setup.targetId
        this.inListEditor = this.$props.setup.inListEditor
        this.editItem = this.$props.setup.editItem
        this.escapeNested = this.$props.setup.escapeNested
        this.mode = this.$props.setup.mode
        this.listFilter = this.$props.setup.listFilter
        this.disableMulti = this.$props.setup.disableMulti
    },

    methods: {

        _archiveMatch( student, timestamp )
        {

            if( undefined !== student )
            {

                if( student.archived !== true )
                {
                    return true
                }

                let temp = student.archiveKey.split( /-/g )
                temp.shift()
                let archiveTimestamp = parseInt( temp.join( '' ) )
                if( archiveTimestamp >= parseInt( timestamp ) )
                {
                    return true
                }

            }
            return false

        },

        getStudentsForList( item )
        {

            let refs  = [],
                temp  = [],
                scope = item.archived === true ? 'archive' : 'cache'

            /*eslint-disable*/
            switch( item.columns[ 0 ].filterBy )
            {
                case 'all':
                    temp = this.$core.getBaseClassHelper()
                               .get( 'student' )
                               .getCache( scope )
                    refs = []
                    for( const[ key, student ] in temp )
                    {
                        let st = this.$core.getBaseClassHelper()
                                     .get( 'student' )
                                     .getById( student.localId )
                        if( undefined !== st )
                        {
                            let archiveMatch = this._archiveMatch( st, item.timestamp )
                            if( ( 'archive' === scope && archiveMatch )
                                || archiveMatch )
                            {
                                refs.push( st )
                            }
                        }
                    }
                    break
                default:
                    temp = this.$core
                               .getBaseClassHelper()
                               .getObjectById( item.columns[ 0 ].filterBy )
                    if( undefined === temp )
                    {
                        temp = this.$core.getBaseClassHelper()
                                   .getObjectById( this.$core.getBaseClassHelper()
                                                       .getLocalIdByShadowedId( item.columns[ 0 ].filterBy ) )

                    }
                    if( undefined !== temp )
                    {
                        refs = []
                        for( let t in temp.students )
                        {
                            let ref = this.$core.getBaseClassHelper()
                                          .get( 'student' )
                                          .getById( temp.students[ t ] )
                            if( undefined === ref )
                            {
                                ref = this.$core.getBaseClassHelper()
                                          .getObjectById( this.$core.getBaseClassHelper()
                                                              .getLocalIdByShadowedId( temp.students[ t ] ) )
                            }
                            if( this.$core.f().isset( ref )
                                && ( 'archive' === scope || this._archiveMatch( ref, item.timestamp ) ) )
                            {
                                refs.push( ref )
                            }
                        }
                    }
                    break
            }

            temp = undefined
            return refs
            /*eslint-enable*/
        },

        handleDefinitionUpdate( event )
        {
            this.$core.getEventManager()
                .dispatch( 'on-definition-update-' + this.targetId, event )
        },

        handleClose( event )
        {
            this.$core.getEventManager()
                .dispatch( 'on-close-editor-' + this.targetId, event )
            this.$emit( 'close' )
        },

    }

}

</script>