<template>
    <template v-if="inline">
        <template v-if="ready">
            <span class="rotated" v-html="preparedCaption"></span>
        </template>
        <template v-else>
            <span class="rotated"><Placeholder type="caption"/></span>
        </template>
        <template v-if="score">
            <br/><span class="score-heading" @click="$emit('table-head-clicked')">{{
                $core.getReformatter().localizedFloat( score, 1 )
            }} Punkte</span>
        </template>
    </template>
    <template v-else>
        <th v-if="null !== translateType( type )"
            :class="( fixed ? 'fixed ' : '' )+color"
            :key="'tableHeadRotated-'+keyV"
            :id="'anchor-'+keyV"
            @click="$emit('table-head-clicked')">
            <template v-if="ready">
                <span class="rotated" v-html="preparedCaption"></span>
            </template>
            <template v-else>
                <span class="rotated"><Placeholder type="caption"/></span>
            </template>
            <template v-if="score">
                <br/><span class="score-heading">{{
                    $core.getReformatter().localizedFloat( score, 1 )
                }} Punkte</span>
            </template>
            <template v-else-if="!hideSub && ( typeForce || false !== translateType( type ) )">
                <br/><span class="score-heading clickable"
                           v-html="typeForce ? translateHead( typeForce ) : translateType( type )"
                           @click="handleSetupClick()"></span>
            </template>
        </th>
    </template>
</template>

<script>
/*eslint-disable*/
import Opener              from "@/components/elements/defaults/Opener";
import MixinCachePreheater from "@/mixins/MixinCachePreheater.vue";
import Placeholder         from "@/components/form/elements/Placeholder.vue";

export default {
    name      : "TableHeadRotated",
    components: { Placeholder, Opener },
    mixins    : [ MixinCachePreheater ],
    props     : {
        keyV     : { Type: String, required: true },
        allKeys  : { Type: Number, required: false, default: 0 },
        caption  : { Type: String, required: true },
        type     : { Type: String, required: false },
        typeForce: { Type: String, required: false },
        score    : { Type: Number, required: false },
        hideSub  : { Type: Boolean, required: false, default: false },
        inline   : { Type: Boolean, required: false, default: false },
        fixed    : { Type: Boolean, required: false, default: false },
        color    : { Type: String, required: false, default: '' },
        debug    : { Type: Object, required: false }
    },

    emits: [ 'table-head-clicked', 'switch-setup' ],

    data()
    {
        return {
            neededCaches   : [ 'competence', 'competenceCategory' ],
            needsTransition: false,
            preparedCaption: '',
            ready          : false,
            width          : 0,
            maxCaptionWidth: 10
        }
    },

    created()
    {
        if( 0 === this.$props.caption.indexOf( 'competence:' )
            || 0 === this.$props.caption.indexOf( 'subCompetence:' )
            || 0 === this.$props.caption.indexOf( 'cat:' )
            || 0 === this.$props.caption.indexOf( 'subCompetenceSub:' ) )
        {
            this.needsTransition = true
        }
    },

    mounted()
    {

        if( this.needsTransition )
        {
            this.awaitNeededCaches()
                .then( () =>
                {

                    this.$core.getCompetenceCategoryTree().awaitReadiness()
                        .then( () =>
                        {

                            this.prepare()
                            this.ready = true

                        } )
                } )

        }
        else
        {
            this.prepare()
            this.ready = true
        }

    },

    methods: {

        prepare()
        {
            this.preparedCaption = this.prepareCaption( this.$props.caption )
            this.getMaxCaptionWidth()
        },

        translateHead( head )
        {
            switch( head )
            {
                case 'addvalue':
                case 'totals':
                    return '&sum;'
                case 'count':
                case 'counts':
                    return '#'
                case 'average':
                    return '&#8709;'
                case 'hide':
                    return null
                default:
                    return false
            }
        },

        translateType( type )
        {
            let setting        = this.$core.settings().getSetting( 'listCalcType-' + type ),
                defaultSetting = this.$core.settings().getSetting( 'listDefaultCalcType-' + type )

            switch( setting )
            {
                case 'default':
                    return this.translateHead( defaultSetting )
                default:
                    return this.translateHead( setting )
            }
        },

        getMaxCaptionWidth()
        {

            let style = getComputedStyle( document.body )
                .getPropertyValue( '--max-column-header-height' )

            if( null !== style )
            {
                this.maxCaptionWidth = parseInt( style )
            }
            else
            {
                this.maxCaptionWidth = 20
            }

        },

        fetchCompetenceHeader( captionHtml )
        {

            let temp    = captionHtml.split( /:/g ),
                localId = temp[ 1 ],
                item    = this.$core.getCompetenceCategoryTree().tree.byRoot[ localId ]

            if( undefined !== item.breadcrumb )
            {
                return 'CCC::: ' + item.breadcrumb + ' > :::CCC ' + item.label
            }
            else
            {
                return item.label
            }

        },

        prepareCaption( captionHtml )
        {

            if( this.needsTransition )
            {
                captionHtml = this.fetchCompetenceHeader( captionHtml )
            }

            let w = this.getCaptionWidth( captionHtml )

            if( this.maxCaptionWidth > w )
            {
                return captionHtml
            }
            else
            {

                let parts = captionHtml.split( / /g ),
                    rows  = [],
                    row   = [],
                    cMode = false

                while( 0 < parts.length )
                {

                    let field = parts.shift()

                    if( 'CCC:::' === field )
                    {
                        cMode = true
                        continue
                    }
                    else if( ':::CCC' === field )
                    {
                        cMode = false
                        continue
                    }

                    if( cMode )
                    {
                        field = '<span class="breadcrumb">'+field.trim()+'</span>'
                    }

                    let widthRow   = this.getCaptionWidth( row.join( ' ' ).trim() ),
                        widthField = this.getCaptionWidth( field.trim() )

                    if( this.maxCaptionWidth >= widthRow
                        && this.maxCaptionWidth >= ( widthRow + widthField ) )
                    {
                        row.push( field )
                    }
                    else if( this.maxCaptionWidth >= widthRow
                             && this.maxCaptionWidth < ( widthRow + widthField ) )
                    {
                        if( 0 < row.length )
                        {
                            rows.push( row.join( ' ' ) )
                            row = []
                            row.push( field )
                        }
                        else
                        {
                            rows.push( field )
                        }
                    }
                    else if( this.maxCaptionWidth <= widthRow )
                    {
                        if( '' !== field.trim() )
                        {
                            row.push( field )
                        }
                        rows.push( row.join( ' ' ) )
                        row = []
                    }

                }

                if( 0 < row.length )
                {
                    rows.push( row.join( ' ' ) )
                }

                return rows.reverse().join( '<br/>' )

            }

        },

        getCaptionWidth( captionHtml )
        {

            let span = document.createElement( 'span' )
            span.innerHTML = captionHtml
            return ( '' + span.innerText ).length

        },

        handleSetupClick( event )
        {
            this.$core.f().skip( event )
            switch( this.$props.typeForce )
            {
                case 'counts':
                    this.$emit( 'switch-setup', 'totals' )
                    break
                case 'average':
                    this.$emit( 'switch-setup', 'counts' )
                    break
                case 'totals':
                    this.$emit( 'switch-setup', 'average' )
                    break
            }
            //this.$emit( 'switch-setup', 'counts' )
        }

    }

}
</script>