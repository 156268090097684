<script>
export default {

    name: "MixinCachePreheater",

    data()
    {
        return {
            preheatReady       : false,
            mixinCacheEventName: false,
            cachesReady        : false
        }
    },

    created()
    {

        this.mixinCacheEventName = 'cache-preheater-' + this.$core.getUuid().generate()

        this.awaitNeededCacheSetup()
            .then( () =>
            {

                if( !this.$core.getBaseClassHelper().ready )
                {
                    this.$core.getEventManager().append( 'on-baseclasses-available', () =>
                    {
                        this.prepareCaches()
                    } )
                }
                else
                {
                    this.prepareCaches()
                }

            } )

    },

    methods: {

        awaitNeededCacheSetup()
        {
            return new Promise( resolve =>
            {

                if( 0 < this.neededCaches.length )
                {
                    return resolve()
                }
                else
                {
                    setTimeout( () =>
                    {
                        return resolve( this.awaitNeededCacheSetup() )
                    }, 100 )
                }

            } )
        },

        prepareCaches()
        {

            for( let s in this.neededCaches )
            {

                let state = this.$core.getBaseClassHelper().get( this.neededCaches[ s ] ).state
                if( 'filled' !== state )
                {
                    if( 'filling' !== state )
                    {
                        this.$core.getBaseClassHelper()
                            .get( this.neededCaches[ s ] )
                            .cacheHeatup()
                    }
                }

            }

            this.$core.getCoreTimer()
                .addInterval( 'cache-watch-' + this.mixinCacheEventName, 50, () =>
                {
                    this.watchStates()
                } )

        },

        watchStates()
        {

            let allDone = true
            for( let s in this.neededCaches )
            {
                if( 'filled' !== this.$core.getBaseClassHelper().get( this.neededCaches[ s ] ).state )
                {
                    allDone = false
                }
            }

            if( allDone )
            {

                this.$core.getCoreTimer().removeInterval( 'cache-watch-' + this.mixinCacheEventName )
                this.preheatReady = true
                this.cachesReady = true
                this.$core.getEventManager().dispatchAndRemove( this.mixinCacheEventName )

            }
        },

        awaitNeededCaches()
        {

            return new Promise( resolve =>
            {

                if( this.cachesReady )
                {
                    return resolve()
                }

                this.$core.getEventManager()
                    .add( this.mixinCacheEventName, () =>
                    {
                        return resolve()
                    } )

            } )
        }

    }

}
</script>