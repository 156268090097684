export default class CalculationHelper
{
    constructor( f, settings, tableCalculation, uuid )
    {

        if( !CalculationHelper.instance )
        {
            this.f = f
            this.settings = settings
            this.tableCalculation = tableCalculation
            this.uuid = uuid

            CalculationHelper.instance = this
        }

        return CalculationHelper.instance

    }

    /*eslint-disable*/
    _prepareSummaryData( calculation )
    {

        let distinctShorts = [],
            firstColumn    = [],
            first          = true,
            addOpens       = []

        for( let r in preparedData )
        {

            let row           = preparedData[ r ],
                shortSortable = this.$core.getFriendlyTimestamp().shortSortable( row.timestamp )

            if( -1 === distinctShorts.indexOf( shortSortable ) )
            {
                distinctShorts.push( shortSortable )
                first = distinctShorts.length === 1
            }

            this._stats( shortSortable, row )

            if( null !== this.calc )
            {

                switch( this.$core.getTableCalculation().getSummaryDefinition( this.$props.item.lists, 'firstColumn' ) )
                {
                    case 'translate-average':
                        firstcolumn.push( 'Durchschnitt' )
                        break
                    default:
                        this.countMonths[ shortSortable ] = undefined !== this.countMonths[ shortSortable ] ? this.countMonths[ shortSortable ] + 1 : 1
                        this.rowMonths.push( {
                            friendly: this.$core.getFriendlyTimestamp().friendlySortable( row.timestamp ),
                            sortable: shortSortable
                        } )
                        firstcolumn.push( this.$core.getFriendlyTimestamp().friendlyDate( row.timestamp ) )
                        break
                }

                if( first )
                {
                    if( -1 === this.openMonths.indexOf( shortSortable ) )
                    {
                        this.openMonths.push( shortSortable )
                    }
                }
                else
                {
                    addOpens.push( shortSortable )
                }
                first = false

            }

        }

        this.multiMonths = ( -1 !== parseInt( this.$core.settings().getSetting( 'listGroupLimit' ) )
                             && this.$props.item.lists.length > parseInt( this.$core.settings().getSetting( 'listGroupLimit' ) )
                             && distinctShorts.length > 1 )

        let allInOpen = !this.multiMonths
        if( allInOpen )
        {
            for( let a in addOpens )
            {
                if( -1 === this.openMonths.indexOf( addOpens[ a ] ) )
                {
                    this.openMonths.push( addOpens[ a ] )
                }
            }
        }

        if( null !== this.calc )
        {

            this.columns = this.$core.getTableCalculation().getSummaryDefinition( this.$props.item.lists, 'columns' )
            if( this.$props.item.listType === 'test' )
            {
                for( let i in this.$props.item.lists[ 0 ].columns )
                {
                    if( undefined !== this.$props.item.lists[ 0 ].columns[ i ].score )
                    {
                        this.totalScore += parseFloat( this.$props.item.lists[ 0 ].columns[ i ].score )
                    }
                }
            }

        }

        this.listData = preparedData

    }

    /*eslint-disable*/
    calculateListSummary( listContainer )
    {

        if( undefined !== listContainer.lists )
        {
            return this.tableCalculation.calculateListSummary( listContainer.lists )
        }
        else
        {
            return this.tableCalculation.calculateListSummary( [ listContainer ] )
        }

    }

    calculateColumns( listContainer )
    {

        let summary = {},
            byList  = {},
            byStudents

        try
        {

            let calculatedColumns

            if( Array.isArray( listContainer.lists )
                && undefined !== listContainer.lists[ 0 ].calculatedColumns )
            {
                calculatedColumns = listContainer.lists[ 0 ].calculatedColumns
                for( let l in listContainer.lists )
                {
                    byList[ listContainer.lists[ l ].localId ] =
                        this.tableCalculation.resolveCalculatedColumnsSummary( listContainer.lists[ l ].values, listContainer.lists[ l ] )
                }
            }
            else if( undefined !== listContainer.calculatedColumns )
            {
                calculatedColumns = listContainer.calculatedColumns
                byList[ listContainer.localId ]
                    = this.tableCalculation.resolveCalculatedColumnsSummary( listContainer.values, listContainer )
            }

            if( 0 < Object.keys( byList ).length )
            {
                this.tableCalculation.summarizeByStudents( byList, calculatedColumns )
            }

        }
        catch( e )
        {
        }

        return {
            summary       : summary,
            byList        : byList,
            byStudents    : byStudents,
            calculationKey: this.uuid.generate()
        }

    }


}