<template>
    <div class="open-in-editor-display" v-if="display">
        {{ $core.t( 'opened' ) }}
    </div>
</template>

<script>
export default {
    name : "ListOpenInEditorDisplay",
    props: {
        display: { Type: Boolean, required: true }
    }
}
</script>