<template>
    <div :class="'competence-category hoverable '+item.color+( selected ? ' selected-item' : '' )"
         :id="'printable-'+item.localId"
         :key="'competence-'+item.localId+'-'+$core.getBaseClassHelper().getElementKey( 'competence', undefined, item.localId )">
        <div class="header">
            <div class="category-title" @click="handleClick">
                {{ item.title }}
                <div class="category-functions">
                    <Button :type="open ? 'list-closer' : 'list-opener'" addClass="list-function"
                            @clicked="handleOpen"/>
                </div>
            </div>
        </div>
        <div class="competence-body sensitive competenceBody">
            <div class="element-info infoflex">
                <div v-if="0 < competences.length" class="infobox first">
                    <span class="element-counter">{{ competences.length }}</span>
                </div>
                <div class="infobox second">
                    <span v-html="timeInfo"></span>
                    <LastEditor :reference="item" :elementKey="item.elementKey"/>
                </div>
                <div class="markers">
                    <ReferenceMarkers :item="item"/>
                </div>
            </div>
            <div v-if="ready && open" class="competences">
                <template v-if="Array.isArray( competences ) && 0 < competences.length">
                    <div class="competences-sub">
                        <h2>Kompetenzen</h2>
                        <DefaultViewItemList viewItem="Competence"
                                             :filterKey
                                             :controlKey
                                             :itemList="competences"
                                             :viewIsFiltered="false"
                                             :showEmptyState="false"
                                             :sortable="true && 1 < competences.length"
                                             @resort="handleResort"
                                             scope="own"/>
                    </div>
                </template>
                <template v-if="Array.isArray( item.subCompetences ) && 0 < item.subCompetences.length">
                    <div class="competences-sub">
                        <h2>Teilkompetenzen</h2>
                        <template v-for="subCompetence in subCompetences"
                                  :key="'subComp-'+subCompetence.localId">

                            <div v-if="undefined !== subCompetence.caption"
                                 class="subcomptence-wrapper">

                                <div class="sub-header">
                                    <div class="title">
                                        {{ subCompetence.caption }}
                                    </div>
                                    <div class="category-functions">
                                        <Button
                                            :type="openSubs[ subCompetence.localId ] === true ? 'list-closer' : 'list-opener'"
                                            addClass="list-function"
                                            @clicked="openSubs[ subCompetence.localId ] = !openSubs[ subCompetence.localId ]"/>
                                    </div>
                                </div>
                                <div class="body">
                                    <p>{{ subCompetence.description }}</p>
                                </div>
                                <div class="subcompetence-list" v-if="openSubs[ subCompetence.localId ] === true">
                                    <DefaultViewItemList viewItem="Competence"
                                                         :filterKey
                                                         :controlKey
                                                         :itemList="subCompetence.subCompetences"
                                                         :viewIsFiltered="false"
                                                         :showEmptyState="false"
                                                         :sortable="true && 1 < subCompetence.subCompetences.length"
                                                         @resort="(...args) => handleSubResort( [subCompetence.localId, ...args ] )"
                                                         scope="own"/>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
            <div v-if="!ready && open" class="competences">
                <PreheatingIndicator/>
            </div>
        </div>
    </div>
</template>

<script>
import ViewItemHandler       from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import LastEditor            from '@/components/elements/markers/LastEditor'
import ReferenceMarkers      from '@/components/elements/markers/ReferenceMarkers'
import MixinWatchElementKey  from "@/mixins/MixinWatchElementKey";
import MixinLivingTimestamps from "@/mixins/MixinLivingTimestamps";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup";
import Button                from "@/components/elements/defaults/Button.vue";
import DefaultViewItemList   from "@/components/defaults/DefaultViewItemList.vue";
import PreheatingIndicator   from "@/components/elements/indicators/PreheatingIndicator.vue";

/*eslint-disable*/
export default {

    name      : 'CompetenceCategory',
    components: { PreheatingIndicator, DefaultViewItemList, Button, ReferenceMarkers, LastEditor },
    extends   : ViewItemHandler,
    mixins    : [ MixinWatchElementKey, MixinLivingTimestamps, MixinComponentCleanup ],

    props: {
        item     : { type: Object, required: true },
        changeKey: { type: String, required: false },
        viewKey  : { type: String, required: false },
        selected : { type: Boolean, required: false, default: false }
    },

    emits: [ 'click' ],

    data()
    {
        return {
            ready         : false,
            unblurred     : false,
            open          : false,
            openSubs      : {},
            filterKey     : this.$core.getUuid().generate(),
            controlKey    : this.$core.getUuid().generate(),
            competences   : [],
            subCompetences: [],
            awaitUpdates  : [],
            sortMapCount  : 0,
            clickBlocked  : false
        }
    },

    computed: {
        cacheKey()
        {
            return this.$core.getBaseClassHelper().get( 'competence' ).registry.cacheKey + '-' + this.$core.getBaseClassHelper().get( 'competence' ).registry.archiveKey
        },
        classList()
        {
            return ( !this.unblurred && this.$props.item.blurry ) ? 'blurred' : ''
        },
        timeInfo()
        {
            return 'angelegt am <strong>' + this.$core.getFriendlyTimestamp().friendlyDate( this.$props.item.timestamp ) + '</strong>'
                   + ( 0 < this.$props.item.update ? '<br/><span class="updatetimestamp">zuletzt aktualisiert am <strong>' +
                   this.$core.getFriendlyTimestamp().friendlyDate( this.$props.item.update ) + '</strong></span>' : '' )
        }
    },

    watch: {
        cacheKey: {
            immediate: true,
            handler( newVal, oldVal )
            {
                if( undefined !== oldVal && newVal !== oldVal )
                {
                    this.prepare( true )
                }
            }
        }
    },

    created()
    {
        this.prepare()
    },

    methods: {

        prepare( afterResort )
        {

            this.$core.getBaseClassHelper()
                .get( 'competence' )
                .cacheHeatup()
                .then( () =>
                {

                    let competences = [],
                        scope       = this.$props.item.archived ? 'archive' : 'cache'

                    this.competences = []
                    this.subCompetences = []

                    for( const [ localId, map ] of this.$core.getBaseClassHelper()
                                                       .get( 'competence' ).registry.indexMaps )
                    {
                        if( map.competenceCategory === this.$props.item.localId )
                        {
                            competences.push( localId )
                        }
                    }

                    let sortMap = this.$core.getBaseClassHelper().get( 'competence' ).registry.sortMap.get( scope )

                    for( let s in sortMap )
                    {
                        if( -1 < competences.indexOf( sortMap[ s ] ) )
                        {
                            this.competences.push( sortMap[ s ] )
                        }
                    }

                    if( Array.isArray( this.$props.item.subCompetences ) && 0 < this.$props.item.subCompetences.length )
                    {
                        for( let i in this.$props.item.subCompetences )
                        {

                            let subCompetence = {
                                caption       : this.$props.item.subCompetences[ i ].value.caption,
                                description   : this.$props.item.subCompetences[ i ].value.description,
                                localId       : this.$props.item.subCompetences[ i ].value.localId,
                                subCompetences: []
                            }

                            for( const [ localId, map ] of this.$core.getBaseClassHelper()
                                                               .get( 'competence' ).registry.indexMaps )
                            {
                                if( map.competenceCategory === 'subCompetence:' + subCompetence.localId )
                                {
                                    subCompetence.subCompetences.push( localId )
                                }
                            }

                            if( !afterResort )
                            {
                                this.openSubs[ subCompetence.localId ] = false
                            }
                            this.subCompetences.push( subCompetence )

                        }
                    }

                    this.filterKey = this.$core.getUuid().generate()
                    if( afterResort )
                    {
                        this.controlKey = this.$core.getUuid().generate()
                    }
                    this.ready = true

                } )
        },

        handleOpen( event )
        {
            this.$core.f().skip( event );
            this.clickBlocked = true
            setTimeout( () => {
                this.clickBlocked = false
            }, 300 )
            this.open = !this.open
        },

        handleClick()
        {
            if( !this.clickBlocked )
            {
                this.$emit( 'clicked' )
            }
        },

        handleAfterUpdate( localId )
        {
            if( 0 < this.awaitUpdates.length )
            {
                this.$core.f().removeFromArray( this.awaitUpdates, localId )
            }
        },

        handleAfterSortmap()
        {
            this.sortMapCount -= 1
            if( this.sortMapCount === 0 )
            {
                this.sortMapCount = 0
                this.$core.getEventManager()
                    .remove( 'after-sortmap-ready' )
                this.prepare( true )
            }
        },

        _getSubCompetence( localId )
        {
            for( let s in this.subCompetences )
            {
                if( this.subCompetences[ s ].localId === localId )
                {
                    return this.subCompetences[ s ]
                }
            }
            return null
        },

        handleSubResort( args )
        {

            let localId       = args[ 0 ],
                newIndex      = args[ 1 ],
                oldIndex      = args[ 2 ],
                subCompetence = this._getSubCompetence( localId )

            if( null !== subCompetence )
            {

                let targetElement = this.$core.getBaseClassHelper()
                                        .getObjectById( subCompetence.subCompetences[ newIndex ] ),
                    targetLevel   = parseInt( targetElement.level ),
                    direction     = newIndex > oldIndex ? 'down' : 'up',
                    firstElement  = newIndex > oldIndex ? oldIndex : newIndex,
                    lastElement   = newIndex > oldIndex ? newIndex : oldIndex,
                    updateQueue   = []

                for( let i = firstElement; i <= lastElement; i++ )
                {

                    let element = this.$core.getBaseClassHelper()
                                      .getObjectById( subCompetence.subCompetences[ i ] )

                    updateQueue.push( element )

                    if( i === oldIndex )
                    {
                        element.level = targetLevel
                    }
                    else
                    {
                        switch( direction )
                        {
                            case 'up':
                                element.level++
                                break
                            case 'down':
                                element.level -= 1
                                break
                        }
                    }

                }

                this.sortMapCount = updateQueue.length
                this.$core.getEventManager()
                    .add( 'after-sortmap-ready', () =>
                    {
                        this.handleAfterSortmap()
                    } )

                while( 0 < updateQueue.length )
                {

                    let element = updateQueue.shift()
                    this.awaitUpdates.push( element.localId )
                    this.$core.getEventManager()
                        .append( 'on-storable-updated-' + element.localId, () =>
                        {
                            this.handleAfterUpdate( element.localId )
                        } )
                    this.$core.getBaseClassHelper()
                        .get( 'competence' )
                        .update( element, element.localId, element.remoteId, element.timestamp, element.localKey )

                }

            }

        },

        handleResort( newIndex, oldIndex )
        {

            let targetElement = this.$core.getBaseClassHelper()
                                    .getObjectById( this.competences[ newIndex ] ),
                targetLevel   = parseInt( targetElement.level ),
                direction     = newIndex > oldIndex ? 'down' : 'up',
                firstElement  = newIndex > oldIndex ? oldIndex : newIndex,
                lastElement   = newIndex > oldIndex ? newIndex : oldIndex,
                updateQueue   = []

            for( let i = firstElement; i <= lastElement; i++ )
            {

                let element = this.$core.getBaseClassHelper()
                                  .getObjectById( this.competences[ i ] )

                updateQueue.push( element )

                if( i === oldIndex )
                {
                    element.level = targetLevel
                }
                else
                {
                    switch( direction )
                    {
                        case 'up':
                            element.level++
                            break
                        case 'down':
                            element.level -= 1
                            break
                    }
                }

            }

            this.sortMapCount = updateQueue.length
            this.$core.getEventManager()
                .add( 'after-sortmap-ready', () =>
                {
                    this.handleAfterSortmap()
                } )

            while( 0 < updateQueue.length )
            {

                let element = updateQueue.shift()
                this.awaitUpdates.push( element.localId )
                this.$core.getEventManager()
                    .append( 'on-storable-updated-' + element.localId, () =>
                    {
                        this.handleAfterUpdate( element.localId )
                    } )
                this.$core.getBaseClassHelper()
                    .get( 'competence' )
                    .update( element, element.localId, element.remoteId, element.timestamp, element.localKey )

            }

        }

    }

}
</script>