<template>
    <div class="shared-with-student-boundaries">
        <div v-if="prepared && 0 < shadowedFor.length"
             :key="'own-shared-'+item.localId+'-'+shadowId"
             class="quickmark shared-with-student"
             v-bind:class="open ? 'open' : ''"
             @click="open = !open"
             v-html="open ? 'bereitgestellt für '+shareList : ''"></div>
        <div v-if="prepared && studentEditable"
             :key="'own-shared-studentEditable-'+item.localId+'-'+shadowId"
             class="quickmark is-student-editable"
             v-bind:class="hintOpen ? 'open' : ''"
             @click="hintOpen = !hintOpen"
             v-html="hintOpen ? 'für Schüler:innen editierbar' : ''"></div>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {
    name  : 'SharedWithStudentsIndicator',
    mixins: [ MixinCachePreheater ],
    props : {
        scope: { Type: String, required: true },
        item : { Type: Object, required: true }
    },

    data()
    {
        return {
            neededCaches   : [ 'shadowCopy', 'student' ],
            open           : false,
            hintOpen       : false,
            prepared       : false,
            cacheKey       : false,
            studentEditable: false,
            shadowedFor    : []
        }
    },

    mounted()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.cacheKey = this.$core.getUuid().generate()
                this.prepare()
            } )
    },

    computed: {

        shadowId()
        {
            return this.$core.getBaseClassHelper()
                       .get( 'shadowCopy' )
                .registry
                .cacheKey
        },

        shareList()
        {
            let shareList = []

            for( let l in this.shadowedFor )
            {
                shareList.push( this.shadowedFor[ l ].firstname + ' ' + this.shadowedFor[ l ].lastname )
            }

            return shareList.join( ', ' )
        }

    },

    watch: {
        shadowId: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && false !== this.cacheKey )
                {
                    this.prepare()
                }
            }
        }
    },

    methods: {

        prepare()
        {

            let allShadows  = this.$core.getBaseClassHelper()
                                  .get( 'shadowCopy' )
                                  .getCache( this.$props.item.archived ? 'archive' : 'cache' ),
                sortRules   = this.$core.settings().getSetting( 'sortingDirections' ),
                shadowedFor = [],
                result      = []

            /*eslint-disable*/
            for( const[ a, shadow ] of allShadows )
            {
                if( undefined !== this.$props.item.lists )
                {
                    for( let l in this.$props.item.lists )
                    {
                        if( shadow.referenceLocalId === this.$props.item.lists[ l ].localId )
                        {
                            if( true === shadow.studentEditable )
                            {
                                this.studentEditable = true
                            }
                            if( -1 === shadowedFor.indexOf( shadow.studentLocalId ) )
                            {
                                shadowedFor.push( shadow.studentLocalId )
                            }
                        }
                    }
                }
                else
                {
                    if( shadow.referenceLocalId === this.$props.item.localId )
                    {
                        if( true === shadow.studentEditable )
                        {
                            this.studentEditable = true
                        }
                        if( -1 === shadowedFor.indexOf( shadow.studentLocalId ) )
                        {
                            shadowedFor.push( shadow.studentLocalId )
                        }
                    }
                }
            }
            /*eslint-enable*/

            for( let s in shadowedFor )
            {
                result.push( this.$core.getBaseClassHelper().get( 'student' ).getById( shadowedFor[ s ] ) )
            }

            this.$core.getSorter().multiSortObjects( result, sortRules.students )
            this.shadowedFor = result
            this.prepared = true

        }

    }
}
</script>