export default class CleanOrphanedStudentCopies
{
    constructor( parent )
    {

        this.parent = parent
        this.logger = parent.parent.logger
        this.database = parent.parent.database
        this.f = parent.parent.f

        this.baseClassHelper = parent.baseClassHelper

        this.logSign = 'SyncShadowCopies::CleanOrphanedStudentCopies (SHC)'

        this.logger.cconstructed( this.logSign, 'initialized' )

        return this

    }

    /*eslint-disable*/
    checkOrphanedCopies()
    {
        return new Promise( resolve =>
        {

            let queue = [ 'list',
                          'note',
                          'todo',
                          'date' ]

            for( let q in queue )
            {

                let type     = queue[ q ],
                    itemList = this.baseClassHelper.get( type )
                                   .getCache( 'cache' )

                if( 'filled' === this.baseClassHelper.get( type ).state )
                {
                    for( const [ i, item ] of itemList )
                    {
                        if( undefined !== item.lists )
                        {
                            for( let l in item.lists )
                            {
                                if( item.lists[ l ]._keys.length <= 1 )
                                {

                                    this.logger.clog( this.logSign + ':checkOrphanedCopies', 'found item of type "lists" to be cleaned...' )
                                    this.baseClassHelper.get( type )
                                        .delete( item.lists[ l ].localId )

                                }
                            }
                        }
                        else
                        {
                            if( item._keys.length <= 1 )
                            {
                                this.logger.clog( this.logSign + ':checkOrphanedCopies', 'found item of type "' + item.type + '" to be cleaned...' )
                                this.baseClassHelper.get( type )
                                    .delete( item.localId )
                            }
                        }
                    }
                    return resolve()
                }

            }

        } )
    }

    /**
     * handle
     */
    handle()
    {

        return new Promise( resolve =>
        {

            let start = Date.now()

            this.checkOrphanedCopies()
                .then( () =>
                {

                    this.logger.cdebug( this.logSign + ':handle', 'process took ' + ( Date.now() - start ) + 'ms' )
                    return resolve()

                } )

        } )

    }

}