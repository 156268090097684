<template>
    <transition appear mode="out-in" :name="$core.settings().getTransition( 'next' )">
        <div v-if="active" :class="'page-message' +addClass+classname" @click="removePageMessage()">
            <h2>{{ title }}</h2>
            <p v-html="message"></p>
        </div>
    </transition>
</template>

<script>

export default {
    
    name  : 'PageMessage',
    data()
    {
        return {
            active   : false,
            title    : false,
            message  : false,
            classname: false,
            timeout  : false,
            scrollTop: false,
            addClass : ' ',
            timer    : false
        }
    },
    created()
    {
        
        this.$core.getEventManager()
            .add( 'ui-set-page-message', ( values ) =>
            {
                this.setPageMessage( values )
            } )
        
        this.$core.getEventManager()
            .add( 'ui-remove-page-message', () =>
            {
                this.removePageMessage()
            } )
        
    },
    
    beforeUnmount()
    {
        
        this.$core.getEventManager()
            .remove( 'ui-set-page-message' )
        
        this.$core.getEventManager()
            .remove( 'ui-remove-page-message' )
        
    },
    
    methods: {
        
        getScrollPosition()
        {
            let app = document.querySelector( '#app' )
            this.scrollTop = app.scrollTop
            this.addClass = this.scrollTop > 300 ? ' absolute-top ' : ' '
        },
        
        setPageMessage( values )
        {
            
            this.getScrollPosition()
            
            this.active = values.active
            this.title = values.title
            this.message = values.message
            this.classname = values.classname
            
            let timeout = values.timeout !== undefined ? values.timeout : 10000
            if( false !== this.timeout )
            {
                clearTimeout( this.timeout )
                this.timeout = false
            }
            
            this.timer = setInterval( () =>
            {
                this.getScrollPosition()
            }, 300 )
            
            this.timeout = setTimeout( () =>
            {
                this.removePageMessage()
            }, timeout )
            
        },
        
        removePageMessage()
        {
            clearTimeout( this.timeout )
            clearInterval( this.timer )

            this.active = false
            this.title = false
            this.message = false
            this.classname = false

            this.timeout = null
            this.timer = null
        }
        
    }
}
</script>