import { createApp }        from 'vue'
import { store }            from '@/classes/Core/Store'
import App                  from './App.vue'
import router               from '@/classes/Router/Router'
import CorePlugin           from '@/plugins/Core'
import MixinStateHandlers   from '@/mixins/MixinStateHandlers'
import CommonComponents     from '@/components/commonComponents'
import FastBusPlugin        from '@/plugins/FastBus'
import VueCookies           from 'vue-cookies'

import './registerServiceWorker'

console.log( '%c * entzettelt version ' + process.env.VERSION + '@' + process.env.BRANCH, 'background:#cefece; color:#2c992c;' )

const app = createApp( {
    extends: App,
    mixins : [ MixinStateHandlers ]
} ).use( router )
   .use( store )
   .use( VueCookies, { expires: '2h' } )
   .use( CorePlugin, store, router )

app.config.devtools = false

app.use( FastBusPlugin, app.config.globalProperties.$core )

CommonComponents.forEach( component =>
{
    app.component( component.name, component )
} )

app.config.performance = 'local' === process.env.BRANCH
app.mount( '#app' )