import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class CompetencesTemplateObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !CompetencesTemplateObjectClass.instance )
        {

            let refList                      = [
                    'templateId',
                    'idCompetenceCategory',
                    'title',
                    'description',
                    'color',
                    'classReference',
                    'groupReference',
                    'yeargroupReference',
                    'level',
                    'subs',
                    'timestamp',
                    'update'
                ],
                personalAttributesStatesList = []

            super( core, 'competenceTemplate', refList, personalAttributesStatesList )
            this.isStudentShareable = false
            this.objectType = 'competences'

            CompetencesTemplateObjectClass.instance = this

        }

        return CompetencesTemplateObjectClass.instance

    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return []
    }

    getFormFields()
    {
        return [
            {
                ref        : 'templateId',
                caption    : 'TemplateId',
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : 'text',
                styleUpdate: false
            },
            {
                ref         : 'idCompetenceCategory',
                caption     : 'Kompetenzbereich',
                type        : 'CompetenceCategorySelector',
                allowForeign: true,
                reformatter : null,
                optional    : false,
                validator   : 'select',
                styleUpdate : false
            },
            {
                ref        : 'title',
                caption    : 'Bezeichnung / Kompetenzstufe',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'text',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref        : 'description',
                caption    : 'Beschreibung',
                type       : 'TextArea',
                reformatter: null,
                optional   : true,
                validator  : 'text',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref         : 'subs',
                caption     : 'Kompetenzen',
                type        : 'PartialCompetence',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false
            },
            {
                ref        : 'level',
                caption    : '',
                type       : 'Hidden',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                validator  : null
            }
        ]
    }


}