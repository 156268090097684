<template>
    <div class="element-filters" :id="'element-filters-'+viewId">
        <div v-for="( type, index ) in types"
             :class="'element-filter filter-'+type+( isActive( type ) ? ' active' : '' )+( index === 0 ? ' marge-right' : '' )"
             :key="'key_elementFilter_'+type"
             @click="filter( type )"
             :title="title">
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>

export default {
    name  : 'ElementFilter',
    props : [ 'title', 'scope', 'filterPreset' ],

    data()
    {
        return {
            viewId : false,
            height : false,
            types  : [],
            filters: []
        }
    },
    created()
    {
        
        let possible = [ 'note', 'list', 'todo', 'date' ]
        let setting = [ 'Notes', 'Lists', 'Todos', '+' ]
        
        for( let p in possible )
        {
            if( '+' === setting[p]
                ||this.$core.settings().getSetting( 'calendarShow'+setting[p] ) )
            {
                if( this.$core.getLicense().isAllowed( 'page_' + possible[ p ] + 's' ) )
                {
                    this.types.push( possible[ p ] )
                }
            }
        }
        
        this.viewId = this.$core.getUuid().generate()
        if( undefined !== this.$props.filterPreset )
        {
            for( let p in this.$props.filterPreset )
            {
                this.filters.push( this.$props.filterPreset[ p ] )
            }
            this.$emit( 'filter', this.filters )
        }
        this.$emit( 'attach', { type: 'type', filter: this.filters } )
        
    },
    
    mounted()
    {
        let view = document.querySelector( '#element-filters-' + this.viewId )
        this.height = view.offsetHeight - 10
    },
    
    beforeUnmount()
    {
        this.$emit( 'detach', { type: 'type', filter: this.filters } )
    },
    
    methods: {
        
        isActive( type )
        {
            return ( -1 < this.filters.indexOf( type ) ? true : false )
        },
        
        filter( type )
        {

            this.$core.s().count( 'clicked-element-filter' )
            if( this.isActive( type ) )
            {
                this.filters = this.filters.filter( function( value )
                {
                    return value !== type
                } )
            }
            else
            {
                if( this.$core.settings().getSetting( 'multiFilter' ) !== true )
                {
                    this.filters = []
                }
                this.filters.push( type )
            }
            
            let emitFilters = []
            for( let f in this.filters )
            {
                emitFilters.push( { attr: 'type', value: this.filters[ f ] } )
            }
            
            this.$emit( 'filter', 'type', emitFilters )
            
        }
        
    }
}
</script>