<template>
    <div v-if="prepared" class="select-wrapper">
        <select :id="'competence-selector-'+refName" @change="handleUpdate()">
            <option>{{ $core.t( 'generic-please-select' ) }}</option>
            <template v-for="row in tree"
                      :key="'level-1-'+row.localId">
                <template v-if="Array.isArray( row.subs ) && 0 < row.subs.length">

                    <optgroup :label="row.label">
                        <option :selected="value === 'competence:'+row.localId"
                                :value="'competence:'+row.localId">
                            {{ row.label }} (gesamter Bereich)
                        </option>
                        <template v-for="sub in row.subs"
                                  :key="'level-2'+sub.localId">
                            <option v-if="undefined !== sub.localId"
                                    :selected="value === 'competence:'+sub.localId"
                                    :value="'competence:'+sub.localId">
                                &nbsp;&nbsp;&nbsp;{{ sub.label }}
                            </option>
                            <template v-if="Array.isArray( sub.subs ) && 0 < sub.subs.length">
                                <template v-for="subsub in sub.subs"
                                          :key="'level-3-'+subsub.localId">
                                    <option v-if="undefined !== subsub.localId && Array.isArray( subsub.subs ) && 0 < subsub.subs.length"
                                            :selected="value === 'competence:'+subsub.localId"
                                            :value="'competence:'+subsub.localId">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ subsub.label }}
                                    </option>
                                </template>
                            </template>
                        </template>
                    </optgroup>

                </template>
                <template v-else>
                    <option :value="'competence:'+row.localId"
                            :selected="value === 'competence:'+row.localId">{{ row.label }}
                    </option>
                </template>
            </template>
        </select>
    </div>
</template>

<script>
/*eslint-disable*/
import MixinCachePreheater from "@/mixins/MixinCachePreheater.vue";
import Placeholder         from "@/components/form/elements/Placeholder.vue";

export default {

    name      : "CompetenceSelector",
    components: { Placeholder },

    mixins: [ MixinCachePreheater ],

    props: {
        refName: { type: String, required: true },
        value  : { type: String, required: false },
        display: { type: String, required: false }
    },

    data()
    {
        return {
            neededCaches: [ 'competence', 'competenceCategory' ],
            tree        : [],
            hasSubs     : new Map(),
            prepared    : false
        }
    },

    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepare()
            } )
    },

    methods: {
        /*eslint-disable*/
        prepare()
        {

            this.$core.getCompetenceCategoryTree()
                .awaitReadiness()
                .then( () =>
                {

                    this.tree = this.$core.getCompetenceCategoryTree()
                        .tree.root

                    this.prepared = true

                } )

        },

        handleUpdate()
        {
            let elm = document.querySelector( '#competence-selector-' + this.$props.refName )
            if( null !== elm )
            {
                this.$emit( 'update', this.$props.refName, 'competenceSelector', 'update', undefined, elm.value )
            }
        }

    }

}
</script>