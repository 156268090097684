export default class Rights
{

    constructor( core )
    {

        if( !Rights.instance )
        {

            this.rights = {}

            this.store = core.getStore()
            this.db = core.getDatabase()
            this.logger = core.getLogger()
            this.cryptoHelper = core.getCryptoHelper()
            this.eventManager = core.getEventManager()
            this.baseClassHelper = core.getBaseClassHelper()

            this.roles = this.initRoles()
            this.isUpdating = false

            this.ready = false
            this.shareHelper = false

            this.colleagues = []
            this.eventManager
                .append( 'on-network-synced', () =>
                {
                    this.fillColleagues()
                        .then( () =>
                        {
                            this.initRights()
                        } )
                } )

            this.adminRights = {
                rights: this.store.getters.poolRights
            }

            this.eventManager.addIndexed( 'on-store-initialized', () =>
            {
                this.adminRights = {
                    rights: this.store.getters.poolRights
                }
            } )

            this.eventManager.append( 'on-pool-rights-refresh', () =>
            {
                this.adminRights = {
                    rights: this.store.getters.poolRights
                }
            } )

            this.eventManager
                .add( 'rights-refresh', () =>
                {
                    this.fillColleagues()
                        .then( () =>
                        {
                            this.initRights()
                        } )
                } )

            this.fillColleagues()
                .then( () =>
                {
                    this.initRights()
                } )

            Rights.instance = this

        }

        return Rights.instance

    }

    destruct()
    {

        this.eventManager.remove( 'rights-refresh' )

        this.isUpdating = false
        this.cryptoHelper = null
        this.store = null
        this.db = null
        this.shareHelper = null

        delete Rights.instance

    }

    injectShareHelper( helper )
    {
        this.shareHelper = helper
    }

    initRoles()
    {

        /*
            basic: Frei definiert
            form: Klassenlehrer
            specialist: Fachlehrer
            short-substitute: Kurzfristige Vertretung
            full-substitute: Langzeitvertretung
            director: Direktor
         */

        let rights = {
            basic             : {
                needsAssignedClass: false,
                rights            : {
                    classElements    : {
                        onlyAssignedClass: false,
                        read             : true,
                        change           : false
                    },
                    groupElements    : {
                        onlyAssignedClass: false,
                        onlyAssignedGroup: false,
                        read             : true,
                        change           : false
                    },
                    yeargroupElements: {
                        onlyAssignedClass: false,
                        onlyAssignedGroup: false,
                        read             : true,
                        change           : false
                    },
                    mediaElements    : {
                        onlyAssignedClass: false,
                        read             : true,
                        change           : false
                    },
                    studentElements  : {
                        onlyAssignedClass: false,
                        read             : true,
                        change           : false,
                        readMeta         : false,
                        changeMeta       : false
                    },
                    noteElements     : {
                        onlyAssignedClass  : false,
                        onlyAssignedStudent: false,
                        read               : true,
                        change             : false
                    },
                    todoElements     : {
                        onlyAssignedClass  : false,
                        onlyAssignedStudent: false,
                        read               : true,
                        change             : false,
                        markDone           : false
                    },
                    dateElements     : {
                        onlyAssignedClass  : false,
                        onlyAssignedStudent: false,
                        read               : true,
                        change             : false
                    },
                    listElements     : {
                        onlyAssignedClass  : false,
                        onlyAssignedStudent: false,
                        read               : true,
                        change             : false,
                        fill               : false,
                        clone              : false
                    }
                }
            },
            form              : {
                needsAssignedClass: true,
                rights            : {
                    classElements    : {
                        onlyAssignedClass: true,
                        read             : true,
                        change           : true
                    },
                    groupElements    : {
                        onlyAssignedClass: false,
                        onlyAssignedGroup: false,
                        read             : false,
                        change           : false
                    },
                    yeargroupElements: {
                        onlyAssignedClass: false,
                        onlyAssignedGroup: false,
                        read             : false,
                        change           : false
                    },
                    studentElements  : {
                        onlyAssignedClass: true,
                        read             : true,
                        change           : true,
                        readMeta         : true,
                        changeMeta       : true
                    },
                    noteElements     : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true
                    },
                    todoElements     : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true,
                        markDone           : true
                    },
                    dateElements     : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true
                    },
                    listElements     : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true,
                        fill               : true,
                        clone              : true
                    }
                }
            },
            specialist        : {
                needsAssignedClass: true,
                rights            : {
                    classElements  : {
                        onlyAssignedClass: true,
                        read             : true,
                        change           : false
                    },
                    groupElements  : {
                        onlyAssignedClass: false,
                        onlyAssignedGroup: false,
                        read             : false,
                        change           : false
                    },
                    studentElements: {
                        onlyAssignedClass: true,
                        read             : true,
                        change           : false,
                        readMeta         : false,
                        changeMeta       : false
                    },
                    noteElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true
                    },
                    todoElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true,
                        markDone           : true
                    },
                    dateElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : false
                    },
                    listElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : false,
                        fill               : false,
                        clone              : false
                    }
                }
            },
            'short-substitute': {
                needsAssignedClass: true,
                rights            : {
                    classElements  : {
                        onlyAssignedClass: true,
                        read             : true,
                        change           : false
                    },
                    groupElements  : {
                        onlyAssignedClass: false,
                        onlyAssignedGroup: false,
                        read             : false,
                        change           : false
                    },
                    studentElements: {
                        onlyAssignedClass: true,
                        read             : true,
                        change           : false,
                        readMeta         : false,
                        changeMeta       : false
                    },
                    noteElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true
                    },
                    todoElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true,
                        markDone           : true
                    },
                    dateElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : false
                    },
                    listElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : false,
                        fill               : false,
                        clone              : false
                    }
                }
            },
            'full-substitute' : {
                needsAssignedClass: true,
                rights            : {
                    classElements  : {
                        onlyAssignedClass: true,
                        read             : true,
                        change           : false
                    },
                    groupElements  : {
                        onlyAssignedClass: false,
                        onlyAssignedGroup: false,
                        read             : false,
                        change           : false
                    },
                    studentElements: {
                        onlyAssignedClass: true,
                        read             : true,
                        change           : false,
                        readMeta         : false,
                        changeMeta       : false
                    },
                    noteElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true
                    },
                    todoElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : true,
                        markDone           : true
                    },
                    dateElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : false
                    },
                    listElements   : {
                        onlyAssignedClass  : true,
                        onlyAssignedStudent: true,
                        read               : true,
                        change             : false,
                        fill               : true,
                        clone              : true
                    }
                }
            },
            'director'        : {
                needsAssignedClass: false,
                rights            : {
                    classElements  : {
                        onlyAssignedClass: false,
                        read             : true,
                        change           : false
                    },
                    groupElements  : {
                        onlyAssignedClass: false,
                        onlyAssignedGroup: false,
                        read             : true,
                        change           : false
                    },
                    studentElements: {
                        onlyAssignedClass: false,
                        read             : true,
                        change           : false,
                        readMeta         : true,
                        changeMeta       : true
                    },
                    noteElements   : {
                        onlyAssignedClass  : false,
                        onlyAssignedStudent: false,
                        read               : true,
                        change             : false
                    },
                    todoElements   : {
                        onlyAssignedClass  : false,
                        onlyAssignedStudent: false,
                        read               : true,
                        change             : false,
                        markDone           : false
                    },
                    dateElements   : {
                        onlyAssignedClass  : false,
                        onlyAssignedStudent: false,
                        read               : true,
                        change             : false
                    },
                    listElements   : {
                        onlyAssignedClass  : false,
                        onlyAssignedStudent: false,
                        read               : true,
                        change             : false,
                        fill               : false,
                        clone              : false
                    }
                }
            }
        }

        return rights

    }

    fillColleagues()
    {
        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( 'colleague' )
                .getPreparedCache()
                .then( colleagues =>
                {

                    let list   = [],
                        scopes = [ 'cache', 'archive' ]

                    for( let s in scopes )
                    {
                        /* eslint-disable-next-line no-unused-vars */
                        for( const [ localId, colleague ] of colleagues[ scopes[ s ] ] )
                        {
                            list.push( colleague )
                        }
                    }

                    this.colleagues = list
                    return resolve()

                } )
                .catch( () =>
                {
                    return resolve()
                } )
        } )
    }

    isColleague( key )
    {

        for( let c in this.colleagues )
        {

            let colleague = this.colleagues[ c ]
            if( parseInt( colleague.colleagueId ) === parseInt( key ) )
            {
                return true
            }

        }

        return false

    }

    _resolveList( list )
    {

        return new Promise( resolve =>
        {

            if( 0 < list.length )
            {

                let line = list.shift()

                if( this.isColleague( line.key ) )
                {

                    this.cryptoHelper.plainDecrypt( line.item )
                        .then( decryptedJSON =>
                        {

                            if( false !== decryptedJSON
                                && undefined !== decryptedJSON )
                            {
                                this.rights[ line.key ] = JSON.parse( decryptedJSON )
                            }

                            return resolve( this._resolveList( list ) )

                        } )

                }
            }
            else
            {
                return resolve()
            }

        } )

    }

    initRights()
    {

        if( !this.isUpdating )
        {

            this.logger.clog( 'Rights:initRights', 'prefetching own object rights...' )
            this.isUpdating = true

            this.db.readAllObjects( 'rights' )
                .then( list =>
                {

                    this._resolveList( list )
                        .then( () =>
                        {


                            this.ready = true
                            this.eventManager.dispatchAndRemoveIndexed( 'on-rights-ready', this )
                            this.isUpdating = false


                        } )

                } )
                .catch( error =>
                {

                    this.logger.cerror( 'Rights:initRights', 'could not decrypt item (' + error + '.' )
                    this.isUpdating = false

                } )

        }

    }

    isActiveColleague( idOwner )
    {

        if( 0 < this.colleagues )
        {
            for( let c in this.colleagues )
            {
                if( parseInt( this.colleagues[ c ].colleagueId ) === parseInt( idOwner ) )
                {
                    return true
                }
            }
            return false
        }
        else
        {
            return true
        }

    }

    isAllowed( object, type )
    {

        if( this.isOwner( object ) )
        {
            return true
        }

        if( !this.isActiveColleague( object.idOwner ) )
        {
            return true
        }

        if( undefined !== this.rights[ object.idOwner ]
            && undefined !== this.rights[ object.idOwner ].rights
            && undefined !== this.rights[ object.idOwner ].rights.elements
            && undefined !== this.rights[ object.idOwner ].rights.elements[ object.localId ]
            && undefined !== this.rights[ object.idOwner ].rights.elements[ object.localId ][ type ] )
        {

            return this.rights[ object.idOwner ].rights.elements[ object.localId ][ type ]

        }

        if( undefined !== object.referenceKey
            && undefined !== this.rights[ object.idOwner ]
            && undefined !== this.rights[ object.idOwner ].rights
            && undefined !== this.rights[ object.idOwner ].rights.elements
            && undefined !== this.rights[ object.idOwner ].rights.elements[ object.referenceKey ]
            && undefined !== this.rights[ object.idOwner ].rights.elements[ object.referenceKey ][ type ] )
        {

            return this.rights[ object.idOwner ].rights.elements[ object.referenceKey ][ type ]

        }

        if( undefined !== this.adminRights.rights
            && undefined !== this.adminRights.rights.general
            && undefined !== this.adminRights.rights.general[ object.type + 'Elements' ]
            && undefined !== this.adminRights.rights.general[ object.type + 'Elements' ][ type ] )
        {
            return this.adminRights.rights.general[ object.type + 'Elements' ][ type ]
        }

        if( undefined !== this.rights[ object.idOwner ]
            && undefined !== this.rights[ object.idOwner ].rights
            && undefined !== this.rights[ object.idOwner ].rights.general
            && undefined !== this.rights[ object.idOwner ].rights.general[ object.type + 'Elements' ]
            && undefined !== this.rights[ object.idOwner ].rights.general[ object.type + 'Elements' ][ type ] )
        {

            return this.rights[ object.idOwner ].rights.general[ object.type + 'Elements' ][ type ]

        }

        return type === 'read'

    }

    getDefaultRights( idColleague, objectType, type )
    {

        if( undefined !== this.rights[ idColleague ]
            && undefined !== this.rights[ idColleague ].rights
            && undefined !== this.rights[ idColleague ].rights.general
            && undefined !== this.rights[ idColleague ].rights.general[ objectType + 'Elements' ]
            && undefined !== this.rights[ idColleague ].rights.general[ objectType + 'Elements' ][ type ] )
        {

            return this.rights[ idColleague ].rights.general[ objectType + 'Elements' ][ type ]

        }

        return type === 'read'

    }

    getObjectRights( colleague, localId )
    {

        if( undefined !== colleague.rights
            && undefined !== colleague.rights.elements
            && undefined !== colleague.rights.elements[ localId ] )
        {
            return colleague.rights.elements[ localId ]
        }

        return {
            read      : true,
            change    : false,
            readMeta  : false,
            changeMeta: false,
            clone     : false,
            fill      : false
        }

    }

    isShareable( object )
    {

        return new Promise( resolve =>
        {

            this.shareHelper.listAllDependencies( object )
                .then( result =>
                {

                    let shareable = true
                    for( let r in result )
                    {

                        let row = result[ r ]
                        if( !this.isOwner( row ) )
                        {
                            shareable = false
                        }

                    }

                    return resolve( shareable )

                } )
                .catch( () =>
                {
                    return resolve( false )
                } )

        } )

    }

    isOwner( object, itemOwner )
    {

        let owner = object.idOwner

        if( undefined === owner )
        {
            if( undefined !== itemOwner )
            {
                owner = itemOwner
            }
        }

        return ( undefined === owner || parseInt( owner ) === parseInt( this.store.getters.idUser ) || 184 === parseInt( this.store.getters.idUser ) )

    }

    getRights( role )
    {

        return undefined !== this.roles[ role ] ? this.roles[ role ] : false

    }
}