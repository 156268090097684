<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-show="visible" class="default-form-overlay">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>

                    <h2>{{ title }}</h2>

                    <div class="form">
                        <TabbedContent :tabs="tabs" :active="tab" @switched="setTab"/>
                        <div class="tabbed-content" v-if="tab === 'share'" :key="'tab-share'">
                            <div class="form-row">
                                <div class="form-caption">
                                    {{ $core.t( 'caption-share-with-students' ) }}
                                </div>
                                <div class="form-component">
                                    <RowAdder component="ShareWithStudentsSelector"
                                              refName="shareStudents"
                                              :editItem="objectList[0]"
                                              :unique="true"
                                              dragBlocked=true
                                              id="shareStudents"
                                              @update="handleChange"/>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="form-row">
                                <div class="form-caption">
                                    {{ $core.t( 'share-with-students-options' ) }}
                                </div>
                                <div class="form-component">
                                    <ShareSelectors :object="objectList[0]"
                                                    :studentMode="true"
                                                    @select="handleSelect"/>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="tabbed-content" v-if="tab === 'shared'" :key="'tab-shared'">
                            <template v-if="0 < shares.length">
                                <div class="form-row">
                                    <div class="form-caption">
                                        {{ $core.t( 'caption-name' ) }}
                                    </div>
                                    <div class="form-component">
                                        {{ $core.t( 'caption-action' ) }}
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="form-row" v-for="share in shares"
                                     :key="share.colleagueId">
                                    <template v-if="undefined !== studentMeta[ share ]">
                                        <div class="form-caption">
                                            <strong>{{ studentMeta[ share ].lastname }}</strong>,
                                            {{ studentMeta[ share ].firstname }}
                                        </div>
                                        <div class="form-component">
                                            <button class="delete"
                                                    @click="handleUnshare( share )">
                                                {{ $core.t( 'cancel-student-share' ) }}
                                            </button>
                                        </div>
                                        <div class="clearfix"></div>
                                    </template>
                                </div>
                                <div class="form-row">
                                    <div class="form-caption">
                                        <strong>&nbsp;</strong>
                                    </div>
                                    <div class="form-component">
                                        <button class="delete"
                                                @click="handleUnshareAll()">
                                            {{ $core.t( 'cancel-all-student-shares' ) }}
                                        </button>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </template>
                            <template v-else>
                                <strong>{{ $core.t( 'not-currently-shared-with-students' ) }}</strong>
                            </template>
                        </div>

                        <div class="form-row">
                        </div>
                        <div class="form-row buttons">
                            <div class="form-caption buttons">
                                <button class="default-cancel"
                                        value="cancel"
                                        @click="handleClose()">{{ $core.t( 'caption-cancel' ) }}
                                </button>
                            </div>
                            <div class="form-component buttons">
                                <button class="submit"
                                        value="Bar"
                                        :disabled="disabled"
                                        @click="handleAction()">{{ title }}
                                </button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/*eslint-disable*/
import TabbedContent       from '@/components/layout/tabs/TabbedContent'
import ColleagueSelector   from '@/components/form/elements/ColleagueSelector'
import ShareSelectors      from '@/components/form/elements/ShareSelectors'
import RowAdder            from '@/components/form/elements/RowAdder'
import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {

    name      : 'DefaultShareWithStudentsOverlay',
    components: { RowAdder, ShareSelectors, ColleagueSelector, TabbedContent },
    mixins    : [ MixinCachePreheater ],
    props     : {
        objectList: { type: Object, required: true }
    },
    emits     : [ 'close' ],

    data()
    {
        return {
            neededCaches   : [ 'studentAccess', 'shadowCopy', 'student' ],
            visible        : true,
            disabled       : true,
            selected       : {},
            showTeams      : false,
            shareWith      : false,
            shares         : [],
            studentMeta    : {},
            studentAccesses: {},
            tab            : 'share',
            tabs           : [
                {
                    id     : 'share',
                    caption: this.$core.getTranslation().translate( 'share-with-students' )
                },
                {
                    id     : 'shared',
                    caption: this.$core.getTranslation().translate( 'shared-with-students' )
                }
            ]
        }
    },

    computed: {
        title()
        {
            if( this.$core.f().isset( this.$props.objectList )
                && this.$core.f().isset( this.$props.objectList[ 0 ] ) )
            {

                if( this.$props.objectList.length === 1 )
                {

                    return this.$core.t( 'share-element-with-student', this.$core.t( 'object-type-' + this.$props.objectList[ 0 ].type ) )

                }
                return this.$props.objectList.length + ' ' +
                       this.$core.t( 'share-element-with-student', this.$core.t( 'object-type-' + this.$props.objectList[ 0 ].type + 's' ) )

            }
            else
            {
                return ''
            }
        }
    },

    created()
    {

        this.awaitNeededCaches()
            .then( () =>
            {

                let accesses     = this.$core.getBaseClassHelper().get( 'studentAccess' ).getCache( 'cache' ),
                    shadows      = this.$core.getBaseClassHelper().get( 'shadowCopy' ).getCache( 'cache' ),
                    shares       = [],
                    shadowCopies = []

                /*eslint-disable*/
                for( const [ a, access ] of accesses )
                {
                    this.studentAccesses[ access.studentLocalId ] = access
                    this.studentMeta[ access.studentLocalId ] = this.$core.getBaseClassHelper()
                                                                    .get( 'student' )
                                                                    .getById( access.studentLocalId )
                }

                for( const [ s, shadowCopy ] of shadows )
                {
                    if( shadowCopy.referenceLocalId === this.$props.objectList[ 0 ].localId )
                    {
                        shares.push( shadowCopy.studentLocalId )
                    }
                    shadowCopies.push( shadowCopy )
                }
                /*eslint-enable*/

                this.shares = shares

            } )

    },

    methods: {

        setTab( tab )
        {
            this.tab = tab
        },

        handleChange( refName, type, method, index, rows )
        {

            let value = []
            for( let r in rows )
            {
                let toValidate = rows[ r ].value.value
                if( undefined !== toValidate )
                {
                    if( -1 < toValidate.indexOf( ':' ) )
                    {
                        let temp = toValidate.split( /:/g )
                        toValidate = temp[ 1 ]
                    }
                    if( rows[ r ].value.value.trim() !== ''
                        && this.$core.getUuid().validate( toValidate ) )
                    {
                        value.push( rows[ r ].value.value )
                    }
                }
            }

            if( this.$core.f().valid( value )
                && 0 < value.length )
            {
                this.shareWith = value
                this.disabled = false
            }
            else
            {
                this.shareWith = false
                this.disabled = true
            }
        },

        isOwnKey( key )
        {
            return key.uuid === this.$store.getters.uuid
        },

        handleSelect( id, values )
        {
            this.selected[ id ] = values
        },

        handleAction()
        {

            this.$core.getUi().showBlocker( this.$core.t( 'caption-blocker-sharing-with-students' ),
                this.$core.t( 'message-blocker-sharing-with-students' ), 'progress' )

            let promises = [],
                total    = this.shareWith.length,
                step     = 0

            this.$core.getUi().updateProgress( total, step )

            let refreshList = []
            for( let o in this.$props.objectList )
            {
                refreshList.push( {
                    type   : this.$props.objectList[ o ].type,
                    localId: this.$props.objectList[ o ].localId
                } )
            }

            promises.push( () =>
            {
                return this.$core.getShare().shareWithStudent( this.shareWith, this.$props.objectList, this.selected )
            } )

            this.$core.f().promiseRunner( promises )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.$core.getUi().hideBlocker()
                        this.handleClose()
                    }, 300 )
                } )

        },

        handleUnshare( id )
        {

            this.$core.getUi().showBlocker( this.$core.t( 'caption-blocker-cancel-sharing-with-students' ),
                this.$core.t( 'message-blocker-cancel-sharing-with-students' ) )

            this.$core.getShare().unshareWithStudent( id, this.$props.objectList )
                .then( types =>
                {

                    for( let t in types )
                    {
                        let type = types[ t ]
                        this.$core.getEventManager().dispatch( 'refreshed-cache-' + type )
                    }

                    this.$core.getUi().hideBlocker()
                    this.handleClose()

                } )
        },

        handleUnshareAll()
        {

            this.$core.getUi().showBlocker( this.$core.t( 'caption-blocker-cancel-sharing-with-students' ),
                this.$core.t( 'message-blocker-cancel-sharing-with-students' ) )

            this.$core.getShare().unshareWithAllStudents( this.$props.objectList )
                .then( types =>
                {

                    for( let t in types )
                    {
                        let type = types[ t ]
                        this.$core.getEventManager().dispatch( 'refreshed-cache-' + type )
                    }

                    this.$core.getUi().hideBlocker()
                    this.handleClose()

                } )

        },

        handleClose()
        {
            this.$core.getUi().isOverlay = false
            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 300 )
        }

    }
}
</script>