<script>
export default {

    name: "MixinLinkedColumns",

    methods: {

        _allColumns()
        {

            let columns = {}
            for( let c in this.$props.list.columns )
            {

                if( undefined !== this.$props.list.columns[ c ].id )
                {

                    let id       = this.$props.list.columns[ c ].id,
                        idx      = parseInt( c ) - 1,
                        repl     = idx + '_',
                        columnId = id.replace( repl, '' )

                    columns[ columnId ] = c

                }

            }
            return columns

        },

        resolveLinkedColumns( fieldInfo, id, value )
        {

            if( undefined === value
                || '' === ( '' + value ).trim() )
            {
                return
            }

            let linkedColumnsList = []
            if( Array.isArray( this.$props.list.linkedColumns ) )
            {
                linkedColumnsList = this.$props.list.linkedColumns
            }
            else
            {
                linkedColumnsList.push( this.$props.list.linkedColumns )
            }

            for( let idx in linkedColumnsList )
            {

                let base          = false,
                    linked        = [],
                    temp          = false,
                    allColumns    = this._allColumns(),
                    linkedColumns = linkedColumnsList[ idx ]

                for( let l in linkedColumns )
                {
                    switch( l )
                    {
                        case 'linkTo':
                            base = this.$core.getSanitizers().cleanId( linkedColumns[ l ] )
                            break
                        case 'linked':
                            temp = linkedColumns[ l ]
                            for( let t in temp )
                            {
                                linked.push( this.$core.getSanitizers().cleanId( temp[ t ] ) )
                            }
                            break
                    }
                }

                if( base !== fieldInfo.name && -1 === linked.indexOf( fieldInfo.name ) )
                {
                    continue
                }

                if( fieldInfo.name === base )
                {
                    for( let l in linked )
                    {
                        let fieldId = fieldInfo.localId + '___' + this.$core.getSanitizers().cleanId( linked[ l ] ) + '___' + allColumns[ linked[ l ] ]
                        this._handleLineUpdate( fieldId, undefined, true )
                        this._handleLineUpdate( id, value, ( undefined === value ) )
                        this.handleFieldUpdate( fieldId, undefined, undefined, undefined, undefined, true )
                    }
                }

                for( let l in linked )
                {

                    let fieldId = false

                    if( this.$core.getSanitizers().cleanId( linked[ l ] ) === fieldInfo.name )
                    {
                        fieldId = fieldInfo.localId + '___' + base + '___' + allColumns[ base ]
                        this._handleLineUpdate( fieldId, undefined, true )
                        this._handleLineUpdate( id, value, ( undefined === value ) )
                        this.handleFieldUpdate( fieldId, undefined, undefined, undefined, undefined, true )
                        this.handleFieldUpdate( id, undefined, undefined, undefined, value, true )
                    }
                    else
                    {
                        let lookup = this.$core.getSanitizers().cleanId( linked[ l ] )
                        fieldId = fieldInfo.localId + '___' + lookup + '___' + allColumns[ lookup ]
                        this._handleLineUpdate( fieldId, undefined, true )
                        this.handleFieldUpdate( fieldId, undefined, undefined, undefined, undefined, true )
                    }

                }

            }

        }


    }

}
</script>