<template>
    <transition appear mode="in-out" :name="$core.settings().getTransition( 'popover' )">
        <div v-if="routeMatches" id="goback" class="goback" @click="handleClick()">
        </div>
    </transition>
</template>

<script>
export default {

    name: 'GoBack',

    props: {
        base: { type: Boolean, required: true }
    },

    data()
    {
        return {
            routes: [ 'students', 'classes', 'groups', 'yeargroups', 'office', 'documents', 'todos', 'notes', 'planner', 'colleagues', 'settings', 'messages', 'admin' ]
        }
    },

    computed: {
        routeMatches()
        {
            return -1 < this.routes.indexOf( this.$route.name )
        }
    },

    methods: {
        handleClick()
        {
            this.$core.getEventManager()
                .dispatch( 'reset-scroll-to-top' )
            this.$router.back()
        }
    }


}
</script>