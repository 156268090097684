<template>
    <transition appear name="fade">
        <ListEditorMessage :active="true"
                           @clicked="$emit( 'clicked' )"
                           classname="differs"
                           message="<h3>Eine neue Version dieser Liste liegt vor!</h3><br/>Änderungen werden Dir angezeigt: <span class='added'>neue</span>, <span class='changed'>geänderte</span> und <span class='deleted'>gelöschte</span> Inhalte werden farbig hinterlegt.<br/>Als gelöscht markierte Inhalte musst du selbst löschen, wenn sie wirklich gelöscht werden sollen."/>
    </transition>
</template>

<script>
import ListEditorMessage from "@/components/messages/ListEditorMessage";

export default {
    name      : "ListUpdatedMessage",
    components: { ListEditorMessage },
    emits     : [ 'clicked' ]
}
</script>