<template>
    <transition appear mode="out-in" :name="$core.settings().getTransition( 'crossfade' )">
        <div v-if="ready">
            <template v-if="true === $core.settings().getSetting( 'studentFileShowOnlyFilled' ) && !previewMode">
                <button type="button" class="styled auto" @click="toggleShowAll()">
                    {{ showAll ? 'leere Zeilen ausblenden' : 'alle einblenden' }}
                </button>
            </template>
            <table class="list" :id="'printable-'+item.referenceKey" :key="structureKey">
                <thead>
                <tr class="list-header">
                    <th>
                <span v-if="1 < data.length">
                    <template v-if="0 < opened.length">
                        <Button type="list-detail-opener"
                                @click="toggleAllRows( 'close' )"
                                addClass="list-closer"/>
                    </template>
                    <template v-else>
                        <Button type="list-detail-opener"
                                @click="toggleAllRows( 'open' )"
                                addClass="list-opener"/>
                    </template>
                </span>
                        <span v-else>&nbsp;</span>
                    </th>
                    <th v-if="1 < data.length">
                        <span class="rotated">&nbsp;</span>
                    </th>
                    <TableHeadRotated v-for="( col, index ) in data[0].columns"
                                      :key="'th-col-'+col.columnId+'-'+index"
                                      :keyV="index"
                                      :caption="(col.type !== 'studentData' ? col.caption : $core.getTranslation().translate( 'caption_'+col.caption ))"/>
                    <CalculationTableHeaders :list="item"
                                             :viewKey="elementKey"
                                             @count="expandHeaders = $event"/>
                </tr>
                </thead>
                <tbody>
                <template v-for="( row, index ) in students"
                          :key="'template-'+row.localId+'-'+index">
                    <template v-if="archiveMatch( row, data[0].timestamp )">
                        <tr @click="handleRowClick( data[0].listItem )"
                            v-if="( false === $core.settings().getSetting( 'studentFileShowOnlyFilled' )
                            || ( true === $core.settings().getSetting( 'studentFileShowOnlyFilled' ) && 0 < totals.rowCounts[row.localId] )
                            || showAll ) && true === isVisible.get( row.localId )"
                            :id="'tr-'+item.localId+'-'+row.localId+'-'+index+'-'+elementKey">
                            <td>
                                <Button v-if="1 < data.length && -1 < opened.indexOf( row.localId )"
                                        type="list-detail-opener"
                                        @click="toggleRowOpen( row.localId )"
                                        addClass="list-closer"/>
                                <Button v-if="1 < data.length && -1 === opened.indexOf( row.localId )"
                                        type="list-detail-opener"
                                        @click="toggleRowOpen( row.localId )"
                                        addClass="list-opener"/>
                                <div
                                    :class="'sensitive studentName'+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )">
                                    <strong>{{ row.lastname }}</strong>, {{ row.firstname }}
                                </div>
                            </td>
                            <td v-if="1 < data.length">{{ totals.rowCounts[ row.localId ] }}</td>
                            <template v-if="1 < data.length">
                                <td v-for="( col, indx ) in data[0].columns"
                                    :class="indx % 2 === 0 ? 'even' : 'odd'"
                                    :key="'td-'+row.localId+'-'+col.columnId">
                                    <template v-if="headTypes[col.columnId] === 'averages'
                                      && undefined !== totals.averages[ row.localId ]">
                                        {{
                                            $core
                                                .getReformatter()
                                                .reformat( totals.averages[ row.localId ][ col.columnId + '___' + ( indx + 1 ) ], 'localizedFloat' )
                                        }}
                                    </template>
                                    <template v-else-if="headTypes[col.columnId] === 'distinct'">
                                        <ListElementDistincts
                                            :distinct="totals.distinct[ col.columnId + '___' + ( indx + 1 ) ][ row.localId ] "
                                            :totalCount="data.length"
                                            :type="col.type"/>
                                    </template>
                                    <template v-else>
                                        {{ totals[ col.columnId + '___' + ( indx + 1 ) ].byRow[ row.localId ] }}
                                    </template>
                                </td>
                            </template>
                            <template v-else>
                                <td v-for="( col, id ) in data[0].columns"
                                    v-bind:class="'td-'+data[0].columns[ id ].type"
                                    :key="'td-'+row.localId+'-'+col.columnId+'-'+id">
                                    <ListElement :type="data[0].columns[ id ].type"
                                                 :value="$core.f().valid( data[0].listItem.values ) ? data[0].listItem.values[ row.localId + '___' + col.columnId + '___' + ( 1 + id ) ] : ''"
                                                 :row="row.localId+'-'+col.columnId"
                                                 :referenceObjectId="data[id] ? data[id].listItem.localId : data[0].listItem.localId"/>
                                </td>
                            </template>
                            <template v-for="( col, calcIndex ) in data[0].listItem.calculatedColumns"
                                      :key="'calc-filler-all-'+calcIndex">
                                <td class="td-total">
                                    <template v-if="$core.f().isset( calculatedRows.byStudent[ row.localId ] )
                                                    && $core.f().isset( calculatedRows.byStudent[ row.localId ][ $core.getSanitizers().cleanId( col.label ) ] )">
                                        <strong>{{
                                                calculatedRows.byStudent[ row.localId ][ $core.getSanitizers().cleanId( col.label ) ].display
                                            }}</strong>
                                    </template>
                                    <template v-else>
                                        &ndash;
                                    </template>
                                </td>
                            </template>
                        </tr>
                    </template>
                    <template v-for="( d, idx ) in data">
                        <template v-if="archiveMatch( row, data[idx].timestamp )">
                            <tr v-if="( -1 < opened.indexOf( row.localId )
                              && ( ( false === $core.settings().getSetting( 'studentFileShowOnlyFilled' ) )
                              || ( true === $core.settings().getSetting( 'studentFileShowOnlyFilled' ) && ( undefined !== item._tc.summary.byStudent[ d.listItem.localId ][ row.localId ] || -1 < row.localId.indexOf( 'demo-' ) ) )
                              || showAll ) && true === isVisible.get( row.localId ) )"
                                :id="'tr-'+item.localId+'-'+row.localId+'-'+idx"
                                :key="'tr-data-'+row.localId+'-'+idx+'-'+elementKey"
                                @click="handleRowClick( d.listItem )">
                                <td><span class="list-date-blocker"></span><span class="non-bold">
                                    {{
                                        $core.getFriendlyTimestamp().friendlyDate( d.listItem.timestamp )
                                    }}</span></td>
                                <td>&nbsp;</td>
                                <td v-for="( col, id ) in data[idx].columns"
                                    v-bind:class="'td-'+data[ idx ].columns[ id ].type"
                                    :key="'td-'+row.localId+'-'+col.columnId+'-'+id">
                                    <ListElement :type="data[ idx ].columns[ id ].type"
                                                 :value="$core.f().valid( data[ idx ].listItem.values ) ? data[ idx ].listItem.values[ row.localId + '___' + col.columnId + '___' + ( 1 + id ) ] : ''"
                                                 :row="row.localId+'-'+col.columnId"
                                                 :referenceObjectId="data[idx].listItem.localId"/>
                                </td>
                                <template v-for="( col, calcIndex ) in data[ idx ].listItem.calculatedColumns"
                                          :key="'calc-filler-std-'+idx+'-'+calcIndex">
                                    <td class="td-total">
                                        <template v-if="$core.f().isset( calculatedRows[  data[ idx ].listItem.localId ] )
                                                        && $core.f().isset( calculatedRows[  data[ idx ].listItem.localId ][ $core.getSanitizers().cleanId( col.label ) ] )
                                                        && $core.f().isset( calculatedRows[  data[ idx ].listItem.localId ][ $core.getSanitizers().cleanId( col.label ) ].listResult[ $core.getSanitizers().cleanId( col.label ) ] )
                                                        && $core.f().isset( calculatedRows[  data[ idx ].listItem.localId ][ $core.getSanitizers().cleanId( col.label ) ].listResult[ $core.getSanitizers().cleanId( col.label ) ][ row.localId ] )">
                                            <strong>
                                                {{
                                                    calculatedRows[ data[ idx ].listItem.localId ][ $core.getSanitizers().cleanId( col.label ) ].listResult[ $core.getSanitizers().cleanId( col.label ) ][ row.localId ].display
                                                }}
                                            </strong>
                                        </template>
                                        <template v-else>
                                            &ndash;
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </template>
                </template>
                </tbody>
            </table>
        </div>
    </transition>
</template>

<script>
/* eslint-disable */
import ListElement             from '@/components/elements/viewItems/listViews/ListElement'
import ListElementDistincts
                               from '@/components/elements/viewItems/listViews/listElement-distincts/ListElementDistincts'
import TableHeadRotated        from '@/components/elements/viewItems/listViews/tableElements/TableHeadRotated'
import ListFilterable          from "@/components/elements/viewItems/listViews/ListFilterable";
import CalculationTableHeaders from "@/components/elements/viewItems/listViews/CalculationTableHeaders";
import CalculationTableFields  from "@/components/elements/viewItems/listViews/CalculationTableFields";
import MixinStructureKey       from "@/components/elements/viewItems/listViews/mixins/MixinStructureKey";
import MixinComponentCleanup   from "@/mixins/MixinComponentCleanup.vue";

export default {

    name      : 'ListDetails',
    extends   : ListFilterable,
    components: {
        CalculationTableFields,
        CalculationTableHeaders,
        TableHeadRotated,
        ListElementDistincts,
        ListElement
    },
    emits     : [ 'rowClick', 'dataUpdate' ],
    mixins    : [ MixinStructureKey, MixinComponentCleanup ],

    props: {
        item        : { type: Object, required: true },
        elementKey  : { Type: String, required: false },
        changeKey   : { Type: String, required: false },
        previewMode : { Type: Boolean, required: false, default: false },
        inListEditor: { type: Boolean, required: false, default: false }
    },

    watch: {
        elementKey: {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( true !== this.$core.getState( 'listEditorOpen' )
                    && newValue !== oldValue
                    && undefined !== oldValue )
                {
                    this.$emit( 'dataUpdate' )
                    this.prepare()
                }
                else if( false === this.registeredEvent
                         && true === this.$core.getState( 'listEditorOpen' )
                         && newValue !== oldValue
                         && this.ready )
                {
                    this.$core.getEventManager()
                        .append( 'on-listeditor-closed', () =>
                        {
                            this.$emit( 'dataUpdate' )
                        } )
                    this.registeredEvent = true
                    this.prepare()
                }

            }
        },
        changeKey : {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( true !== this.$core.getState( 'listEditorOpen' )
                    && newValue !== oldValue
                    && this.ready )
                {
                    //this.reset()
                    this.$nextTick()
                        .then( () =>
                        {
                            this.prepare()
                        } )
                }
            }
        },
        item      : {
            immediate: true,
            handler()
            {
                this.reset()
                this.$nextTick()
                    .then( () =>
                    {
                        this.prepare()
                    } )
            }
        }
    },

    data()
    {
        return {
            registeredEvent: false,
            expandHeaders  : 0,
            calculationKey : 0,
            calculatedRows : {},
            data           : false,
            totals         : false,
            ready          : false,
            firstcolumn    : [],
            columns        : false,
            students       : false,
            totalScore     : 0,
            opened         : [],
            counts         : {},
            rowCounts      : {},
            headTypes      : false,
            showAll        : false
        }
    },

    created()
    {
        this.prepare()
    },

    methods: {

        reset()
        {
            this.data = false
            this.totals = false
            this.ready = false
            this.headTypes = false
            this.firstcolumn = []
            this.students = false
            this.columns = false
            this.totalScore = 0
            this.counts = {}
            this.rowCounts = {}
            this.showAll = false
        },

        toggleShowAll()
        {
            this.showAll = !this.showAll
        },

        prepareCalculatedRows()
        {

            let calcSetup
            this.calculatedRows = {}
            if( Array.isArray( this.$props.item.lists ) )
            {
                calcSetup = this.$props.item.lists[ 0 ].calculatedColumns
                for( let l in this.$props.item.lists )
                {
                    this.calculatedRows[ this.$props.item.lists[ l ].localId ] =
                        this.$core.getTableCalculation().resolveCalculatedColumnsSummary( this.$props.item.lists[ l ].values, this.$props.item.lists[ l ] )
                }
            }
            else
            {
                calcSetup = this.$props.item.calculatedColumns
                this.calculatedRows[ this.$props.item.localId ] =
                    this.$core.getTableCalculation().resolveCalculatedColumnsSummary( this.$props.item.values, this.$props.item )
            }

            this.$core.getTableCalculation().summarizeByStudents( this.calculatedRows, calcSetup )

        },

        prepare()
        {

            return new Promise( resolve =>
            {

                let data      = [],
                    sortRules = this.$core.settings().getSetting( 'sortingDirections' ),
                    itemList  = this.$props.item.lists

                this.$core.sort().multiSortObjects( itemList, sortRules[ 'lists' ] )

                this.prepareCalculatedRows()

                this.$core.getTableCalculation().getSummary( itemList, true )
                    .then( calculation =>
                    {

                        this.totals = calculation.totals
                        this.headTypes = calculation.headTypes

                        let scope       = this.$props.item.archived === true ? 'archive' : 'cache',
                            temp        = calculation.result[ 0 ],
                            studentList = temp.listItem.columns[ 0 ].filterBy === 'all' ? this.$core.getBaseClassHelper().get( 'student' ).getCache( scope )
                                                                                        : this.$core.getBaseClassHelper().getObjectById( temp.listItem.columns[ 0 ].filterBy ).students

                        if( temp.listItem.columns[ 0 ].filterBy === 'all' )
                        {
                            let studentListNew = []
                            for( const[ localId, student ] of studentList )
                            {
                                studentListNew.push( student )
                            }
                            studentList = studentListNew
                        }

                        for( let l in calculation.result )
                        {

                            let list = calculation.result[ l ]
                            data.push( list )
                            this.firstcolumn.push( this.$core.getFriendlyTimestamp().friendlyDate( list.timestamp ) )
                            this.columns = this.$core.getTableCalculation().getSummaryDefinition( this.$props.item.lists, 'columns' )

                            this.students = []

                            if( list.listItem.columns[ 0 ].filterBy === 'all' )
                            {
                                this.$core.sort().multiSortObjects( studentList, sortRules[ 'students' ] )
                                this.students = studentList
                                let newList = []
                                for( let s in this.students )
                                {
                                    newList.push( this.students[ s ].localId )
                                }
                            }
                            else
                            {
                                for( let s in studentList )
                                {

                                    let rawStudent = this.$core.getBaseClassHelper().get( 'student' ).getById( studentList[ s ] )
                                    if( undefined !== rawStudent )
                                    {

                                        let student = JSON.parse( JSON.stringify( rawStudent ) )
                                        if( student.isShadowCopy )
                                        {
                                            student.localId = student.shadowedId
                                        }

                                        this.students.push( student )
                                    }
                                }
                            }

                        }

                        this.$core.sort().multiSortObjects( this.students, sortRules[ 'students' ] )

                        this.data = data
                        this.ready = true

                        this.$nextTick()
                            .then( () =>
                            {

                                if( 1 === this.$core.getStore().getters.isStudent
                                    && 1 < this.data.length )
                                {
                                    this.toggleAllRows( 'open' )
                                }

                            } )

                        return resolve()

                    } )

            } )

        },

        handleRowClick( row )
        {
            this.$emit( 'rowClick', row )
        },

        toggleRowOpen( id )
        {
            let index = this.opened.indexOf( id )
            if( -1 < index )
            {
                this.opened.splice( index, 1 )
            }
            else
            {
                this.opened.push( id )
            }
        },

        toggleAllRows( mode )
        {

            for( let s in this.students )
            {
                let id    = this.students[ s ].localId,
                    index = this.opened.indexOf( id )

                if( -1 < index )
                {
                    if( 'close' === mode )
                    {
                        this.toggleRowOpen( this.students[ s ].localId )
                    }
                }
                else
                {
                    if( 'open' === mode )
                    {
                        this.toggleRowOpen( this.students[ s ].localId )
                    }
                }
            }
        },

        archiveMatch( student, t )
        {

            if( true !== student.archived )
            {
                return true
            }

            let temp = student.archiveKey.split( /-/g )

            temp.shift()

            let archiveTimestamp = parseInt( temp.join( '' ) ),
                listTimestamp    = parseInt( t )

            if( archiveTimestamp >= listTimestamp )
            {
                return true
            }

            return false

        },
    }


}
</script>