import sharedepsAvatar             from '@/classes/Core/Share/dependencies/ShareDependenciesAvatar'
import sharedepsClass              from '@/classes/Core/Share/dependencies/ShareDependenciesClass'
import sharedepsStudent            from '@/classes/Core/Share/dependencies/ShareDependenciesStudent'
import sharedepsDate               from '@/classes/Core/Share/dependencies/ShareDependenciesDate'
import sharedepsGroup              from '@/classes/Core/Share/dependencies/ShareDependenciesGroup'
import sharedepsYeargroup          from '@/classes/Core/Share/dependencies/ShareDependenciesYeargroup'
import sharedepsList               from '@/classes/Core/Share/dependencies/ShareDependenciesList'
import sharedepsMessage            from '@/classes/Core/Share/dependencies/ShareDependenciesMessage'
import sharedepsNote               from '@/classes/Core/Share/dependencies/ShareDependenciesNote'
import sharedepsTeam               from '@/classes/Core/Share/dependencies/ShareDependenciesTeam'
import sharedepsTemplate           from '@/classes/Core/Share/dependencies/ShareDependenciesTemplate'
import sharedepsTodo               from '@/classes/Core/Share/dependencies/ShareDependenciesTodo'
import sharedepsMedia              from '@/classes/Core/Share/dependencies/ShareDependenciesMedia'
import sharedepsCompetenceCategory from '@/classes/Core/Share/dependencies/ShareDependenciesCompetenceCategory'
import DeepDependencyWalkerCompetence
                                   from "@/classes/Core/Share/helpers/deepDependencyWalker/DeepDependencyWalkerCompetence";

export default class DependencyWalker
{

    constructor( parent )
    {
        if( !DependencyWalker.instance )
        {

            this.core = parent.core
            this.logger = parent.logger
            this.store = parent.store
            this.f = parent.f

            this.eventManager = parent.eventManager
            this.queueWorker = parent.queueWorker
            this.database = parent.database
            this.client = parent.client

            this.baseClassHelper = parent.baseClassHelper
            this.deepResolve = true

            this.dependencies = {
                avatar            : sharedepsAvatar,
                class             : sharedepsClass,
                student           : sharedepsStudent,
                date              : sharedepsDate,
                group             : sharedepsGroup,
                yeargroup         : sharedepsYeargroup,
                list              : sharedepsList,
                message           : sharedepsMessage,
                note              : sharedepsNote,
                team              : sharedepsTeam,
                template          : sharedepsTemplate,
                todo              : sharedepsTodo,
                media             : sharedepsMedia,
                competenceCategory: sharedepsCompetenceCategory
            }

            this.deepDependencyWalkers = {
                competence: new DeepDependencyWalkerCompetence( parent )
            }

            DependencyWalker.instance = this

        }

        return DependencyWalker.instance

    }

    /*eslint-disable*/

    _cleanList( list )
    {

        let idx = list.indexOf( false )
        while( -1 < idx )
        {
            list.splice( idx, 1 )
            idx = list.indexOf( false )
        }

        idx = list.indexOf( undefined )
        while( -1 < idx )
        {
            list.splice( idx, 1 )
            idx = list.indexOf( undefined )
        }

        return list

    }

    _toList( params )
    {

        let list = []
        if( !Array.isArray( params ) )
        {
            list.push( params )
        }
        else
        {
            list = [ ...params ]
        }

        return this._cleanList( list )

    }

    _resolve( type, localId )
    {

        return new Promise( resolve =>
        {

            let list      = this._toList( localId ),
                baseClass = this.baseClassHelper.get( type ),
                results   = []

            baseClass.listAll()
                     .then( () =>
                     {

                         for( let l in list )
                         {

                             let element = this.baseClassHelper.getObjectById( list[ l ] )
                             if( undefined !== element )
                             {
                                 results.push( element )
                             }

                         }

                         return resolve( results )

                     } )

        } )

    }

    _resolveContainer( reference, dependency, queue )
    {
        return new Promise( resolve =>
        {

            this.baseClassHelper.get( queue )
                .listAll()
                .then( list =>
                {

                    let dependencies = []

                    for( let l in list )
                    {

                        if( true !== list[ l ].archived )
                        {

                            let check   = list[ l ],
                                compare = undefined !== dependency.sub ?
                                          check[ dependency.sub ][ dependency.subIndex ][ dependency.location ] :
                                          check[ dependency.location ]

                            if( undefined !== compare )
                            {
                                if( undefined !== dependency.index )
                                {
                                    compare = compare[ dependency.index ]
                                }
                                if( undefined !== compare )
                                {
                                    if( undefined !== compare[ dependency.attribute ] )
                                    {
                                        if( undefined !== compare[ dependency.filter.key ]
                                            && reference === compare[ dependency.attribute ]
                                            && dependency.filter.value === compare[ dependency.filter.key ] )
                                        {
                                            if( undefined === dependency.sub )
                                            {
                                                dependencies.push( check )
                                            }
                                            else
                                            {
                                                for( let s in check[ dependency.sub ] )
                                                {
                                                    dependencies.push( check[ dependency.sub ][ s ] )
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }

                    }

                    return resolve( dependencies )

                } )

        } )
    }

    _resolveReference( reference, dependency, queue )
    {

        return new Promise( resolve =>
        {

            this.baseClassHelper.get( queue )
                .listAll()
                .then( list =>
                {

                    let dependencies = []
                    for( let l in list )
                    {

                        if( true !== list[ l ].archived )
                        {

                            if( undefined !== list[ l ][ dependency.attribute ]
                                && Array.isArray( list[ l ][ dependency.attribute ] ) )
                            {
                                if( !Array.isArray( reference )
                                    && -1 < list[ l ][ dependency.attribute ].indexOf( reference ) )
                                {
                                    dependencies.push( list[ l ] )
                                    continue
                                }
                                if( Array.isArray( reference ) )
                                {
                                    let found = false
                                    for( let r in reference )
                                    {
                                        if( -1 < list[ l ][ dependency.attribute ].indexOf( reference[ r ] ) )
                                        {
                                            found = true
                                        }
                                    }

                                    if( found === true )
                                    {
                                        dependencies.push( list[ l ] )
                                        continue
                                    }
                                }
                            }

                            if( ( !Array.isArray( reference )
                                  && list[ l ][ dependency.attribute ] === reference )
                                || ( Array.isArray( reference )
                                     && -1 < reference.indexOf( list[ l ][ dependency.attribute ] ) ) )
                            {
                                dependencies.push( list[ l ] )
                            }

                        }
                    }

                    return resolve( dependencies )

                } )

        } )

    }

    _resolveFromRegistry( queue, localId, key, self )
    {
        return new Promise( resolve =>
        {
            if( queue === 'students' && localId === 'all' )
            {
                let students = []
                this.baseClassHelper.get( 'student' )
                    .listAll()
                    .then( list =>
                    {
                        for( let l in list )
                        {
                            if( true !== list[ l ].archived )
                            {
                                students.push( list[ l ].localId )
                            }
                        }
                        return resolve( this._resolve( queue, students ) )
                    } )
            }
            else
            {
                let elm = this.baseClassHelper.getObjectById( localId )
                if( undefined !== elm
                    && undefined !== elm.type
                    && elm.type === key )
                {
                    if( self )
                    {
                        return resolve( [ elm ] )
                    }
                    else
                    {
                        return resolve( this._resolve( queue, elm[ queue ] ) )
                    }
                }
                else
                {
                    return resolve( [] )
                }
            }
        } )
    }

    _resolveDeep( resolver, element, queue )
    {

        return new Promise( resolve =>
        {

            let lookups             = {},
                directObjectLookups = [],
                promises            = [],
                self                = false

            for( let r in resolver )
            {
                let rslv = resolver[ r ]
                self = rslv.self

                if( -1 !== rslv.subIndex )
                {
                    if( undefined !== element[ rslv.sub ]
                        && undefined !== element[ rslv.sub ][ rslv.subIndex ]
                        && undefined !== element[ rslv.sub ][ rslv.subIndex ][ rslv.location ]
                        && element[ rslv.sub ][ rslv.subIndex ][ rslv.location ][ rslv.index ][ rslv.filter.key ] === rslv.filter.value )
                    {
                        let lookup = element[ rslv.sub ][ rslv.subIndex ][ rslv.location ][ rslv.index ][ rslv.attribute ]
                        if( undefined === lookups[ lookup ] )
                        {
                            lookups[ lookup ] = element[ rslv.sub ][ rslv.subIndex ][ rslv.location ][ rslv.index ][ rslv.filter.key ]
                        }
                    }
                }
                else
                {

                    if( undefined !== element[ rslv.sub ]
                        && undefined !== element[ rslv.sub ][ 0 ] )
                    {
                        for( let rr in element[ rslv.sub ][ 0 ][ rslv.location ] )
                        {
                            if( element[ rslv.sub ][ 0 ][ rslv.location ][ rr ].type === rslv.filter.key )
                            {

                                let value = rslv.filter.value,
                                    temp  = element[ rslv.sub ][ 0 ][ rslv.location ][ rr ].id.split( value[ 1 ] ),
                                    key   = temp[ 1 ]

                                directObjectLookups.push( {
                                    helper: rslv.filter.helper,
                                    call  : rslv.filter.call,
                                    id    : key
                                } )
                            }
                        }
                    }

                }

            }

            let objectList = []

            for( let l in lookups )
            {
                promises.push( () =>
                {
                    return this._resolveFromRegistry( queue, l, lookups[ l ], self )
                               .then( list =>
                               {
                                   for( let l in list )
                                   {
                                       objectList.push( list[ l ] )
                                   }
                               } )
                } )
            }

            for( let d in directObjectLookups )
            {

                let call         = 'get' + directObjectLookups[ d ].helper,
                    functionCall = directObjectLookups[ d ].call,
                    helperResult = this.core[ call ]()[ functionCall ]( directObjectLookups[ d ].id )

                for( let h in helperResult )
                {
                    objectList.push( helperResult[ h ] )
                }

            }

            this.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve( objectList )
                } )

        } )

    }

    resolveDependency( element, dependency, queue )
    {
        return new Promise( resolve =>
        {

            if( undefined !== dependency.resolver )
            {
                return resolve( this._resolveDeep( dependency.resolver, element, queue ) )
            }

            if( true === dependency.contained )
            {
                return resolve( this._resolve( dependency.type, element[ dependency.attribute ] ) )
            }

            if( undefined !== dependency.location )
            {
                return resolve( this._resolveContainer( element[ dependency.reference ], dependency, queue ) )
            }

            return resolve( this._resolveReference( element[ dependency.reference ], dependency, queue ) )

        } )
    }

    performResolveOptionals( elements, optional, result, deepResolve )
    {
        return new Promise( resolve =>
        {

            result = result || []

            if( 0 < elements.length )
            {
                let element = elements.shift()
                this.performResolveDependencies( [ element ], optional, deepResolve )
                    .then( resolved =>
                    {

                        let push = []

                        for( let r in resolved )
                        {
                            if( resolved[ r ].localId !== element.localId )
                            {
                                push.push( resolved[ r ] )
                            }
                        }

                        result = [ ...result, ...push ]

                        return resolve( this.performResolveOptionals( elements, optional, result, deepResolve ) )

                    } )
            }
            else
            {
                return resolve( result )
            }
        } )
    }

    resolveDependencies( elements, optional, reset )
    {
        return new Promise( resolve =>
        {

            let result   = [],
                solved   = [],
                promises = []

            if( reset !== false )
            {
                this.resetResolver()
            }

            this.performResolveDependencies( elements, undefined )
                .then( dependencies =>
                {

                    result = [ ...result, ...dependencies ]

                    this.performResolveOptionals( this.f.deref( elements ), optional )
                        .then( optionals =>
                        {

                            result = [ ...result, ...optionals ]

                            for( let d in dependencies )
                            {
                                solved.push( dependencies[ d ].localId )
                            }

                            if( false !== this.deepResolve )
                            {
                                for( let d in dependencies )
                                {

                                    if( 'list' !== dependencies[ d ].type )
                                    {

                                        promises.push( () =>
                                        {
                                            return new Promise( resolve =>
                                            {

                                                this.performResolveDependencies( [ dependencies[ d ] ], optional )
                                                    .then( deepDeps =>
                                                    {

                                                        for( let dd in deepDeps )
                                                        {
                                                            if( -1 === solved.indexOf( deepDeps[ dd ].localId ) )
                                                            {

                                                                solved.push( deepDeps[ dd ].localId )
                                                                result.push( deepDeps[ dd ] )

                                                            }
                                                        }

                                                        return resolve()

                                                    } )

                                            } )
                                        } )
                                    }
                                }
                            }

                            this.f.promiseRunner( promises )
                                .then( () =>
                                {

                                    return resolve( result )

                                } )

                        } )

                } )

        } )
    }

    resetResolver()
    {
        this.deepResolve = true
    }

    performResolveDependencies( elements, optional )
    {

        return new Promise( resolve =>
        {

            let dependencies = [],
                promises     = []

            for( let e in elements )
            {

                let elementType = elements[ e ].type

                if( this.dependencies[ elementType ] )
                {

                    if( true === this.dependencies[ elementType ].addSelf )
                    {
                        dependencies.push( elements[ e ] )
                    }

                    if( undefined !== this.dependencies[ elementType ].addSubs
                        && undefined !== elements[ e ][ this.dependencies[ elementType ].addSubs ] )
                    {
                        let subs = elements[ e ][ this.dependencies[ elementType ].addSubs ]
                        for( let s in subs )
                        {
                            dependencies.push( subs[ s ] )
                        }
                    }

                    if( false === this.dependencies[ elementType ].deepResolve )
                    {
                        this.deepResolve = false
                    }
                    else
                    {
                        this.deepResolve = true
                    }

                    if( this.f.isObject( this.dependencies[ elementType ].required ) )
                    {
                        for( let d in this.dependencies[ elementType ].required )
                        {

                            let dependency = this.dependencies[ elementType ].required[ d ]
                            if( true === dependency.deep )
                            {
                                let deepDependencies = this.deepDependencyWalkers[ dependency.type ].resolve( elements[ e ].localId, dependencies )
                                dependencies = [ ...dependencies, ...deepDependencies ]
                            }
                            else
                            {
                                promises.push( () =>
                                {
                                    return this.resolveDependency( elements[ e ], dependency, d )
                                               .then( deps =>
                                               {
                                                   dependencies = [ ...dependencies, ...deps ]
                                               } )
                                } )
                            }

                        }
                    }

                    if( this.f.isObject( this.dependencies[ elementType ].optional ) )
                    {

                        for( let d in this.dependencies[ elementType ].optional )
                        {

                            let dependency = this.dependencies[ elementType ].optional[ d ]
                            if( undefined !== optional
                                && true === optional[ 'share_' + d ] )
                            {
                                promises.push( () =>
                                {
                                    return this.resolveDependency( elements[ e ], dependency, d )
                                               .then( deps =>
                                               {
                                                   dependencies = [ ...dependencies, ...deps ]
                                               } )
                                } )

                            }

                        }
                    }
                }

            }

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    let objectList = []
                    for( let d in dependencies )
                    {
                        if( this.core.r().isAllowed( dependencies[ d ], 'share' ) )
                        {
                            objectList.push( dependencies[ d ] )
                        }
                    }

                    return resolve( objectList )

                } )

        } )

    }

}